import { gql } from "@apollo/client"
import {
    updateVehicleOperatingTimes,
    updateVehicleOperatingTimesVariables,
    updateVehicleOperatingTimes_updateVehicleOperatingTimes
} from "./types/updateVehicleOperatingTimes"

export const UPDATE_VEHICLE_OPERATING_TIMES_MUTATION = gql`
  mutation updateVehicleOperatingTimes(
    $id: ID!
    $operating_time: VehicleOperatingTimesInput!
  ) {
    updateVehicleOperatingTimes(id: $id, operating_time: $operating_time) {
      id
    }
  }
`

export type UpdateVehicleOperatingTimesResult = updateVehicleOperatingTimes
export type UpdateVehicleOperatingTimes = updateVehicleOperatingTimes_updateVehicleOperatingTimes
export type UpdateVehicleOperatingTimesVariables = updateVehicleOperatingTimesVariables
