import { gql } from "@apollo/client"
import {
  collectionPointFilllevelsForDate,
  collectionPointFilllevelsForDateVariables,
  collectionPointFilllevelsForDate_collectionPointFilllevelsForDate,
} from "./types/collectionPointFilllevelsForDate"

export const COLLECTION_POINTS_FOR_DATE_QUERY = gql`
  query collectionPointFilllevelsForDate($id: ID!, $date: DateTime!) {
    collectionPointFilllevelsForDate(id: $id, date: $date) {
      material_id
      material_name
      fill_level {
        timestamp
        value
      }
      collection_point_id
      weight
    }
  }
`

export type CollectionPointFilllevelsForDateResult = collectionPointFilllevelsForDate
export type CollectionPointFilllevelsForDateVariables = collectionPointFilllevelsForDateVariables
export type CollectionPointFilllevel = collectionPointFilllevelsForDate_collectionPointFilllevelsForDate
