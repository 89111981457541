import { gql } from "@apollo/client"
import { counties, countiesVariables, counties_counties } from "./types/counties"

export const COUNTIES_QUERY = gql`
  query counties($districtId: ID!) {
    counties(districtId: $districtId) {
      id
      name
    }
  }
`
export type CountiesResult = counties
export type CountiesVariables = countiesVariables
export type County = counties_counties
