import { gql } from "@apollo/client"
import {
  getAssociationsForUserManagement,
  getAssociationsForUserManagement_getAssociationsForUserManagement,
} from "./types/getAssociationsForUserManagement"

export const GET_ASSOCIATIONS_FOR_USER_MANAGEMENT_QUERY = gql`
  query getAssociationsForUserManagement {
    getAssociationsForUserManagement {
      id
      name
    }
  }
`

export type AssociationsResult = getAssociationsForUserManagement
export type Associations = getAssociationsForUserManagement_getAssociationsForUserManagement
