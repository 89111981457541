import { gql } from "@apollo/client"
import {
  getCollectionPartnerForUser,
  getCollectionPartnerForUser_getCollectionPartnerForUser,
  getCollectionPartnerForUser_getCollectionPartnerForUser_districts,
} from "./types/getCollectionPartnerForUser"

export const GETCOLLECTIONPARTNERFORUSER_QUERY = gql`
  query getCollectionPartnerForUser {
    getCollectionPartnerForUser {
      id
      name
      town
      street
      postal
      email
      contact_name
      phone_number
      districts {
        id
        name
      }
      amount_vehicles
      is_deletable
      automatic_break
      automatic_break_reminder
    }
  }
`

export type CollectionPartnerResult = getCollectionPartnerForUser
export type CollectionPartner = getCollectionPartnerForUser_getCollectionPartnerForUser
export type District = getCollectionPartnerForUser_getCollectionPartnerForUser_districts
