import React, { FunctionComponent, Fragment } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts"
import { theme } from "../../../../styles/theme"
import lodash from "lodash"

const now = moment()

export interface IFilllevel {
  timestamp: Date
  value: number | null
  isExtrapolated: boolean | null
}

interface IFilllevelChartProps {
  data?: IFilllevel[]
  prepareFillLevel(fillLevel: number | null): number | null
}

export const FilllevelChart: FunctionComponent<IFilllevelChartProps> = (props) => {
  const { t } = useTranslation()
  // format of data on x-axis
  const INTERNAL_DATE_FORMAT = t("date_time_format")

  const getNonExtrapolatedValue = (dataPoint: IFilllevel) => (dataPoint.isExtrapolated ? null : dataPoint.value)
  const getExtrapolatedValue = (dataPoint: IFilllevel) => (dataPoint.isExtrapolated ? dataPoint.value : null)

  const getFormattedDate = (dataPoint: IFilllevel): string => moment(dataPoint.timestamp).format(INTERNAL_DATE_FORMAT)
  const getFormattedTick = (value: string): string => moment(value, INTERNAL_DATE_FORMAT).format("D.M.")

  const findAndGetReferenceLineDate = (filllevelData: IFilllevel[]) => {
    const filllevelsOfToday = filllevelData.filter((entry) => now.isSame(entry.timestamp, "day"))
    if (filllevelsOfToday.length <= 0) {
      // no filllevel for today
      return ""
    }

    // get closest filllevel entry to now
    const closestFilllevelToNow = lodash.minBy(filllevelsOfToday, (filllevel) =>
      Math.abs(moment(filllevel.timestamp).diff(now)),
    )
    return moment(closestFilllevelToNow?.timestamp).format(INTERNAL_DATE_FORMAT)
  }

  const getData = (): IFilllevel[] => {
    if (!props.data) {
      return []
    }

    return props.data.map((d) => ({
      timestamp: d.timestamp,
      value: props.prepareFillLevel(d.value),
      isExtrapolated: d.isExtrapolated,
    }))
  }
  const data = getData()

  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={data}
          margin={{
            top: 30,
            right: 30,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />"
          <XAxis dataKey={getFormattedDate} tickFormatter={getFormattedTick} />
          <YAxis
            padding={{ top: 0, bottom: 10 }}
            unit="%"
            domain={[0, (dataMax) => (dataMax < 100 ? 100 : lodash.min([130, dataMax]))]}
            allowDataOverflow={true}
            tickFormatter={(value) => Math.round(value)}
          />
          <Tooltip formatter={(value) => [`${Math.round(Number(value))}%`, t("fill_level")]} />
          <Line type="monotone" dataKey={getNonExtrapolatedValue} stroke={theme.PRIMARY_COLOR.light} dot={false} />
          <Line
            type="monotone"
            dataKey={getExtrapolatedValue}
            stroke={theme.PRIMARY_COLOR.light}
            dot={false}
            strokeDasharray="5 5"
          />
          <ReferenceLine x={findAndGetReferenceLineDate(data)} strokeWidth={2}>
            <Label position="top">{t("today")}</Label>
          </ReferenceLine>
        </LineChart>
      </ResponsiveContainer>
    </Fragment>
  )
}
