import { useQuery } from "@apollo/client"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../../api/graphql/queries/get-driver-locations"
import { useRefetch } from "../../../../context/refetch-context"
import { DriverLocationData } from "./driver-location-data"
import { DriverLocationSelection } from "./driver-location-selection"
 
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  dataContainer: {
    padding: theme.spacing(2)
  },
  container: {
    height: "calc(100vh - 56px)"
  },
  selectorContainer: {
    flex: "1",
    overflowY: "auto",
  },
}))

interface IDriverLocationAdministrationProps {}

export const DriverLocationAdministration: FunctionComponent<IDriverLocationAdministrationProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const { loading, data } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false)
  })

  return (
    <Grid container direction="column" spacing={2} className={classes.container}>
      <Grid container item direction="row" spacing={2} className={classes.dataContainer}>
        <Grid item xs>
          <DriverLocationData />
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.title}>
          {t("guidance_administration.driver_location.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.selectorContainer}>
        <DriverLocationSelection driverLocations={data?.driverLocations || []} loading={loading} />
      </Grid>
    </Grid>
  )
}
