import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  Card,
  Grid,
  Button,
  IconButton
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { DisposalMerchantStatsVacationTimes } from "../../../../api/graphql/queries/get-disposal-merchant-stats-with-id"
import { KeyboardDatePicker } from "@material-ui/pickers"
import AddIcon from "@material-ui/icons/Add"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import moment from "moment"

const useStyles = makeStyles((theme: Theme) => ({
  vacationTimesCard: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    height: `calc(100% - ${theme.spacing(2)}px)`
  },
  addDisposalMerchantVacationTimes: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  addIcon: {
    color: theme.palette.primary.main
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  noDataInfo: {
    textAlign: "center"
  }
}))

interface IDisposalMerchantVacationTimesProps {
  vacationTimes: DisposalMerchantStatsVacationTimes[]
  updateVacationTimes: (
    vacationTimes: DisposalMerchantStatsVacationTimes[]
  ) => void
  addIdToDeletedVacationTimeIds: (id: number) => void
}

export const DisposalMerchantVacationTimes: FunctionComponent<IDisposalMerchantVacationTimesProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    vacationTimes,
    updateVacationTimes,
    addIdToDeletedVacationTimeIds
  } = props

  const handleChangeStartDate = (
    vacationTime: DisposalMerchantStatsVacationTimes
  ) => (dateTime: moment.Moment | null, value: any) => {
    vacationTime.from = (dateTime as moment.Moment).utc(true).toDate()

    if (moment(vacationTime.to).diff(dateTime as moment.Moment) < 0) {
      vacationTime.to = (dateTime as moment.Moment).utc(true).toDate()
    }

    updateVacationTimes(vacationTimes.slice())
  }

  const handleChangeEndDate = (
    vacationTime: DisposalMerchantStatsVacationTimes
  ) => (dateTime: moment.Moment | null, value: any) => {
    vacationTime.to = (dateTime as moment.Moment).utc(true).toDate()

    if (moment(vacationTime.from).diff(dateTime as moment.Moment) > 0) {
      vacationTime.from = (dateTime as moment.Moment).utc(true).toDate()
    }

    updateVacationTimes(vacationTimes.slice())
  }

  return (
    <Card className={classes.vacationTimesCard}>
      <Grid container direction="column" spacing={2}>
        <Grid item container alignItems="center" justify="space-between">
          <Grid item>
            <Text bold>
              {t("disposal_merchant_overview.data.vacation_times")}
            </Text>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              className={classes.addDisposalMerchantVacationTimes}
              onClick={() =>
                updateVacationTimes(
                  vacationTimes.concat({
                    from: moment()
                      .utc(true)
                      .startOf("day")
                      .toDate(),
                    to: moment()
                      .utc(true)
                      .startOf("day")
                      .toDate()
                  } as DisposalMerchantStatsVacationTimes)
                )
              }
              fullWidth
            >
              <AddIcon className={classes.addIcon} />
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column">
          {vacationTimes.length ? (
            vacationTimes.map((vacationTime, index) => (
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                justify="space-between"
                key={`vacationTime${index}`}
              >
                <Grid item container xs={11} spacing={2}>
                  <Grid item xs>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog-from"
                      label={t("date_picker.from")}
                      format={t("date_format")}
                      value={vacationTime.from}
                      fullWidth
                      onChange={handleChangeStartDate(vacationTime)}
                      cancelLabel={t("date_picker.cancel")}
                      okLabel={t("date_picker.ok")}
                      clearLabel={t("date_picker.clear")}
                    />
                  </Grid>
                  <Grid item xs>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog-from"
                      label={t("date_picker.to")}
                      format={t("date_format")}
                      value={vacationTime.to}
                      fullWidth
                      onChange={handleChangeEndDate(vacationTime)}
                      minDate={moment()
                        .utc(true)
                        .startOf("day")}
                      minDateMessage={t(
                        "disposal_merchant.data.vacation_date_in_future"
                      )}
                      cancelLabel={t("date_picker.cancel")}
                      okLabel={t("date_picker.ok")}
                      clearLabel={t("date_picker.clear")}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={() => {
                      if (vacationTime.id) {
                        addIdToDeletedVacationTimeIds(vacationTime.id)
                      }
                      updateVacationTimes(
                        vacationTimes.filter((vacationTime, i) => index !== i)
                      )
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Text className={classes.noDataInfo}>
              {t("disposal_merchant_overview.data.no_vacation_times")}
            </Text>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
