import React, { FunctionComponent, useContext } from "react"
import { Button, Backdrop, CircularProgress, Theme, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CollectionPointsExportDialog } from "./collection-points-export-dialog"
import { CollectionPointsExportContext } from "./collection-points-export-context"

interface ICollectionPointExportButtonProps {}

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

export const CollectionPointExportButton: FunctionComponent<ICollectionPointExportButtonProps> = (props) => {
  const { t } = useTranslation()
  const { exportLoading, setDialogOpen } = useContext(CollectionPointsExportContext)
  const classes = useStyles()

  return (
    <>
      <Backdrop open={exportLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CollectionPointsExportDialog />
      <Button variant="contained" type="button" color="primary" onClick={() => setDialogOpen(true)}>
        {t("collection_points.export_containers")}
      </Button>
    </>
  )
}
