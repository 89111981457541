import React, { FunctionComponent } from "react"

import { DisposalMerchantOverview } from "./partials/disposal-merchant-overview"
import { SelectedDisposalMerchantProvider } from "../../../context/selected-disposal-merchant-context"

interface IDisposalMerchantOverViewPageProps {}

export const DisposalMerchantOverviewPage: FunctionComponent<IDisposalMerchantOverViewPageProps> = props => {
  return (
    <SelectedDisposalMerchantProvider>
      <DisposalMerchantOverview />
    </SelectedDisposalMerchantProvider>
  )
}