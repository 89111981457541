import React, { useState } from "react"
import { Grid, IconButton, TableCell, TableRow, Theme, Tooltip, makeStyles } from "@material-ui/core"
import { getContainerTypLabel } from "../../../../utils/container-type"
import { HouseholdContainerForTable } from "../context/household-overview-table-context"
import { HouseholdContainerDeleteConfirmDialog } from "./household-container-delete-confirm-dialog"
import { useTranslation } from "react-i18next"
import { DeleteSweep } from "@material-ui/icons"

interface IHouseholdOverviewTableRowProps {
  container: HouseholdContainerForTable
}

const useStyles = makeStyles((theme: Theme) => ({
  lastCell: {
    width: theme.spacing(6),
  },
}))

export const HouseholdOverviewTableRow: React.FC<IHouseholdOverviewTableRowProps> = (props) => {
  const { container } = props
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)

  const classes = useStyles()

  return (
    <>
      <HouseholdContainerDeleteConfirmDialog container={container} open={open} onClose={() => setOpen(false)} />
      <TableRow key={`household_container_${container.id}`}>
        <TableCell align="center">
          <Grid container direction="column">
            <Grid item>
              {container.household.street} {container.household.number}
            </Grid>
            <Grid item>
              {container.household.postal} {container.household.place}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="center">
          <Grid item>{container.material?.name || "-"}</Grid>
        </TableCell>
        <TableCell align="center">
          <Grid item>{container.containerType ? getContainerTypLabel(container.containerType) : "-"}</Grid>
        </TableCell>
        <TableCell align="center">
          <Grid item>{container.comment ? t(`households.comment_types.${container.comment}`) : "-"}</Grid>
        </TableCell>
        <TableCell align="center">
          <Grid item>{container.rfid || "-"}</Grid>
        </TableCell>
        <TableCell align="center" className={classes.lastCell}>
          <Grid item>
            <Tooltip title={t("households.table.delete_container")}>
              <IconButton onClick={() => setOpen(true)}>
                <DeleteSweep />
              </IconButton>
            </Tooltip>
          </Grid>
        </TableCell>
      </TableRow>
    </>
  )
}
