import { gql } from "@apollo/client"
import { getTourAsExcelFile, getTourAsExcelFileVariables } from "./types/getTourAsExcelFile"

export const GET_TOUR_AS_EXCEL_FILE_QUERY = gql`
  query getTourAsExcelFile($tour_id: ID!) {
    getTourAsExcelFile(tour_id: $tour_id)
  }
`
export type GetTourAsExcelFileResult = getTourAsExcelFile
export type GetTourAsExcelFileVariables = getTourAsExcelFileVariables
