import React, { FunctionComponent, Fragment, useEffect, useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { TourSelector } from "./tour-selector"
import moment, { duration } from "moment"
import {
  Grid,
  Card as MaterialCard,
  makeStyles,
  Theme,
  CircularProgress,
  Button,
  IconButton,
  Tooltip,
  Paper,
  CardContent,
  Typography,
} from "@material-ui/core"
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { Card } from "../../../partials/layout/card/model/card"
import { CardInfoHelper } from "../../../../utils/CardInfoHelper"
import { StopsTable } from "../../../partials/layout/table/stops-table"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { MinimalTour } from "../../../../api/graphql/queries/get-minimal-tours-with-region-id"
import lodash from "lodash"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { UserService } from "../../../../services/user-service"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { Edit, ReportProblem, Delete, Warning, ScheduleSharp } from "@material-ui/icons"
import { UserGroups } from "../../../../models/user-groups"
import { EditTourModal } from "./edit-tour-modal"
import { DefectContainersDialog } from "./defect-containers-dialog"
import { useRefetch } from "../../../../context/refetch-context"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { useTourOverview } from "../context/tour-overview-context"
import { toast } from "react-toastify"
import { TourOverviewMap } from "./tour-overview-map/tour-overview-map"
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined"

import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined"
import TodayOutlinedIcon from "@material-ui/icons/TodayOutlined"
import { TourDetail } from "./tour-detail"

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    marginTop: 10,
  },
  contentContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    overflowY: "auto",
    width: "100%",
    height: "100%",
    flex: "1",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
    flexDirection: "column",
  },
  associationFilterCard: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "0 0 auto",
    marginBottom: theme.spacing(1),
  },
  tourDataContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%",
  },
  selectorContainer: {
    height: "100%",
  },
  button: {
    width: 140,
  },
  progressIconContainer: {
    display: "flex",
  },
  bold12px: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  bold14px: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  vehicle: {
    fontSize: "10px",
    marginTop: "-5px",
  },
  duration: {
    fontSize: "12px",
  },
  paper: {
    borderRadius: "16px",
    height: "60px",
    padding: "2px",
  },
  icon: {
    fontSize: "10",
    marginTop: "3px",
  },
  tableTitle: {
    fontSize: "20px",
    marginTop: "10px",
    fontWeight: "bold",
    color: "grey",
  },
  tourDetailContainer: {
    height: "100%",
  },
}))

interface ITourOverviewProps {}

export const TourOverview: FunctionComponent<ITourOverviewProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles(props)

  const { selectedTour, tours, setSelectedTour } = useSelectedTour()
  const [isShowDefectContainersModalOpen, setIsShowDefectContainersModalOpen] = useState(false)
  const [isEditTourModalOpen, setIsEditTourModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { setNeedToRefetch } = useRefetch()

  const {
    selectedStopId,
    setSelectedStopId,
    deleteTour,
    tourStatsData,
    tourStatsError,
    tourStatsLoading,
    getTourAsExcelFile,
    excelLoading,
  } = useTourOverview()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const resetSelectedStopId = useCallback(() => {
    setSelectedStopId(undefined)
  }, [setSelectedStopId])

  useEffect(() => {
    resetSelectedStopId()
  }, [resetSelectedStopId])

  const onDeleteTour = async () => {
    const result = await deleteTour({
      variables: {
        tour_id: String(selectedTour?.id),
      },
    })
    if (result.data?.deleteTour?.error === "false") {
      toast.info(t("tour_overview.tour_deleted"))
      setSelectedTour(undefined)
      setNeedToRefetch(true)
      setIsDeleteModalOpen(false)
    } else {
      toast.error(t("tour_overview.could_not_delete_tour"))
    }
  }

  const tourDate = useMemo(() => moment(tourStatsData?.getTourStatsWithId?.emptying_stats?.emptying_date), [
    tourStatsData,
  ])

  const cards = useMemo(() => {
    if (tourStatsData?.getTourStatsWithId?.emptying_stats != null) {
      const emptyingStats = tourStatsData.getTourStatsWithId.emptying_stats
      const tour = tourStatsData.getTourStatsWithId.tour
      const durationCard = tour?.duration
        ? CardInfoHelper.getTourDurationCard(tour.duration, tour.actual_duration)
        : undefined

      if (durationCard && !lodash.isNil(tour?.updated_at)) {
        durationCard.heading = (
          <Tooltip placement="top" title={t("tour_overview.duration_warning") as string}>
            <Grid container direction="row" alignItems="center" justify="center">
              <Grid item>
                <ReportProblem fontSize="small" />
              </Grid>
              <Grid item>{durationCard.heading}</Grid>
            </Grid>
          </Tooltip>
        )
      }
      return [
        durationCard,
        emptyingStats.collected_amount_stats &&
          CardInfoHelper.getCollectedAmountCard(emptyingStats.collected_amount_stats),
        CardInfoHelper.getPartnerCard(emptyingStats.partner),
        emptyingStats.licence_plate ? CardInfoHelper.getVehicleCard(emptyingStats.licence_plate) : undefined,
        tour?.distance_driven ? CardInfoHelper.getDistanceDrivenCard(tour.distance_driven) : undefined,
      ].filter((e) => e) as Card[]
    }
    return []
  }, [t, tourStatsData])

  const actualDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.actual_duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.actual_duration)
        : null,
    [tourStatsData],
  )

  const plannedDuration = useMemo(
    () =>
      tourStatsData?.getTourStatsWithId?.tour?.duration
        ? CardInfoHelper.getFormattedTourDuration(tourStatsData.getTourStatsWithId.tour.duration)
        : null,
    [tourStatsData],
  )

  return (
    <Fragment>
      <EditTourModal
        open={!lodash.isEmpty(tourStatsData?.getTourStatsWithId?.stop_infos) && isEditTourModalOpen}
        onClose={() => setIsEditTourModalOpen(false)}
        stopInfos={tourStatsData?.getTourStatsWithId?.stop_infos || []}
        districtId={Number(tourStatsData?.getTourStatsWithId?.tour?.district_id)}
        date={tourStatsData?.getTourStatsWithId?.tour?.date}
        tourId={String(selectedTour?.id)}
      />
      <DeleteDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        content={t("tour_overview.confirm_delete_text")}
        onDelete={() => onDeleteTour()}
      />
      <DefectContainersDialog
        open={isShowDefectContainersModalOpen}
        onClose={() => setIsShowDefectContainersModalOpen(false)}
        tourParametersId={String(selectedTour?.tour_parameters_id)}
      />
      <Grid className={classes.container}>
        {/* Association Select */}
        <ContentContainer style={classes.associationFilterCard}>
          <MaterialCard style={{ padding: 15 }}>
            <RegionSelect />
          </MaterialCard>
        </ContentContainer>

        <Grid className={classes.tourDataContainer} container direction="row">
          <Grid item className={classes.selectorContainer}>
            <TourSelector />
          </Grid>
          <Grid className={classes.contentContainer} item>
            <Grid container direction="column" spacing={2}>
              {tourStatsLoading && (
                <Grid container item justify="center" alignItems="center">
                  <CircularProgress />
                </Grid>
              )}
              {tourStatsData && !tourStatsError && !tourStatsLoading && tourDate && (
                <Fragment>
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid item>
                      <Grid item container justify="flex-start" alignItems="center">
                        <Grid item>
                          <PageHeading>
                            {`${selectedTour?.manuallyCreated ? `${t("tour_overview.heading_prefix_manual")} ` : ""}${t(
                              "tour_overview.heading",
                            )} ${tourDate!.format(t("date_format"))}`}
                          </PageHeading>
                        </Grid>

                        {UserService.getRole() === UserRole.SUPER_ADMIN && (
                          <Button onClick={() => setIsShowDefectContainersModalOpen(true)}>
                            <Warning color="primary" />
                            {`(${selectedTour?.countOfDefectContainers ?? "-"})`}
                          </Button>
                        )}

                        {selectedTour?.manuallyCreated && !selectedTour.finished && (
                          <Grid item className={classes.progressIconContainer}>
                            <Tooltip placement="top" title={t("tour_overview.in_progress") as string}>
                              <ScheduleSharp color="primary" />
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container xs={6} justify="flex-end">
                      <Grid item>
                        <IconButton
                          color="primary"
                          disabled={excelLoading}
                          onClick={() =>
                            getTourAsExcelFile({
                              variables: {
                                tour_id: String((selectedTour as MinimalTour).id),
                              },
                            })
                          }
                        >
                          <GetAppOutlinedIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        {UserService.isInUserGroup(UserGroups.TOUR_MANAGEMENT) &&
                          tourStatsData.getTourStatsWithId?.tour?.deletable && (
                            <Grid item>
                              <IconButton onClick={() => setIsDeleteModalOpen(true)}>
                                <Delete color="error" />
                              </IconButton>
                            </Grid>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="flex-start" alignItems="stretch" spacing={1}>
                    <Grid item xs={7}>
                      <TourOverviewMap />
                    </Grid>

                    <Grid item xs={5}>
                      <Grid container spacing={1} className={classes.tourDetailContainer}>
                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate && (
                          <Grid item xs={6}>
                            <Paper variant="outlined" className={classes.paper}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <LocalShippingOutlinedIcon className={classes.icon} />
                                  </Grid>
                                  <Grid container item xs direction="column" justify="center">
                                    <Grid item>
                                      <Typography variant="subtitle1" className={classes.bold14px}>
                                        {tourStatsData.getTourStatsWithId?.emptying_stats?.licence_plate}
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography className={classes.vehicle}> {t("Truck")} </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <Paper variant="outlined" className={classes.paper}>
                            <CardContent>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item>
                                  <TodayOutlinedIcon className={classes.icon} />
                                </Grid>
                                <Grid container item xs direction="column">
                                  <Grid item>
                                    <Typography variant="subtitle1" className={classes.bold12px}>
                                      {` ${tourDate!.format(t("date_format"))}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Paper>
                        </Grid>

                        <Grid item xs={12}>
                          {plannedDuration && (
                            <Paper variant="outlined" className={classes.paper}>
                              <CardContent>
                                <Grid container direction="row" spacing={1} alignItems="center">
                                  <Grid item>
                                    <QueryBuilderOutlinedIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item xs={4} container alignItems="center">
                                    <Typography variant="subtitle1" className={classes.bold14px}>
                                      {plannedDuration}
                                    </Typography>
                                    <Typography className={classes.duration}>
                                      &nbsp;{t("tour_overview.planned_duration")}
                                    </Typography>
                                  </Grid>
                                  <Grid item className={classes.bold14px}>
                                    |
                                  </Grid>
                                  <Grid item xs container alignItems="center">
                                    <Typography variant="subtitle1" className={classes.bold14px}>
                                      {actualDuration ? actualDuration : "--"}
                                    </Typography>
                                    <Typography className={classes.duration}>
                                      &nbsp;{t("tour_overview.actual_duration")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Paper>
                          )}
                        </Grid>
                        <Grid item xs={12} className={classes.tourDetailContainer}>
                          <TourDetail />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container justify="flex-start">
                    <Grid item>
                      <Typography className={classes.tableTitle}>{t("tour_overview.collection_points")}</Typography>
                    </Grid>
                    {UserService.isInUserGroup(UserGroups.TOUR_MANAGEMENT) && (
                      <Grid item>
                        <IconButton onClick={() => setIsEditTourModalOpen(true)}>
                          <Edit color="primary" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item>
                    <StopsTable
                      tourId={selectedTour?.id}
                      isInEditMode={false}
                      onRowClicked={(id: number) => {
                        if (id === selectedStopId) {
                          setSelectedStopId(undefined)
                        } else {
                          setSelectedStopId(id)
                        }
                      }}
                      stopInfos={(tourStatsData!.getTourStatsWithId!.stop_infos || []).concat(
                        tourStatsData!.getTourStatsWithId!.stopsInsertedByDriver,
                      )}
                      selectedStopId={selectedStopId}
                    />
                  </Grid>
                </Fragment>
              )}

              {tours && tours.length === 0 && (
                <CustomSnackBarContent
                  className={classes.snackBar}
                  variant="info"
                  message={t(
                    `errors.no_${UserService.hasAssociationFilter() ? "association" : "district"}_tours_found`,
                  )}
                />
              )}

              {tours && tours.length > 0 && selectedTour === undefined && (
                <CustomSnackBarContent
                  className={classes.snackBar}
                  variant="info"
                  message={t("tour_selector.choose")}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
