import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { Text } from "../../wrapper/text"

const useStyles = makeStyles((theme: Theme) => ({
  propertyName: {
    minWidth: 120,
    maxWidth: 120,
    paddingRight: theme.spacing(1),
  },
  propertyContainer: {
    alignItems: "center",
  },
}))

export interface ICustomMarkerTooltipPropertyProps {
  name: string
  content?: string | null
  classNames?: {
    name?: string
    content?: string
  }
}

export const CustomMarkerTooltipProperty: FunctionComponent<ICustomMarkerTooltipPropertyProps> = (props) => {
  const classes = useStyles()
  const { name, content, classNames } = props
  return (
    <Grid container className={classes.propertyContainer}>
      <Grid item container justify="space-between" className={classes.propertyName}>
        <Text bold className={`${classNames?.name}`}>
          {name}
        </Text>
      </Grid>
      <Grid item>
        <Text bold={false} className={classNames?.content}>
          {content}
        </Text>
      </Grid>
    </Grid>
  )
}
