import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from "@material-ui/core"
import lodash from "lodash"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { VehicleToMaterial } from "../../../../../api/graphql/queries/get-vehicle-with-id"
import { TourGenerationContext } from "../../../../../context/tour-generation-context"
import { DayOfWeek } from "../../../../../utils/day"
import { isOperatingTimeValid } from "../../../../../utils/vehicle-operating-times"
import { Text } from "../../../../partials/wrapper/text"
import { DeparturePointContextProvider } from "../../../partner-overview/partials/departure-point/context/departure-point-context"
import { formatDeparturePoint } from "../../../vehicle-overview/partials/vehicle-operation-point"
import { TourGenerationDays } from "../tour-generation-days"
import { TourGenerationVehicleMaterials } from "../tour-generation-vehicle-materials"
import { ICustomDeparturePoint, ICustomOperatingTime, IVehicleEntry } from "../tour-generation-vehicle-selection"

interface ITourGenerationVehicleConfigurationDialogProps {
  open: boolean
  onClose: () => void
  vehicle: IVehicleEntry
  handleChangeSelection: (vehicleId: number) => (days: DayOfWeek[]) => void
  updateMaterials: (vehicle: IVehicleEntry) => (materials: VehicleToMaterial[]) => void
  onCustomDeparturePointEntryChange: (customDeparturePoint: ICustomDeparturePoint) => void
  onCustomOperatingTimeEntryChange: (customOperatingTime: ICustomOperatingTime) => void
}

export const TourGenerationVehicleConfigurationDialog: FunctionComponent<ITourGenerationVehicleConfigurationDialogProps> = (
  props,
) => {
  const { open, onClose, vehicle, handleChangeSelection, updateMaterials, onCustomDeparturePointEntryChange, onCustomOperatingTimeEntryChange } = props
  const { t } = useTranslation()

  const { tourWithoutContainers } = useContext(TourGenerationContext)

  const canSave = !lodash.some(vehicle.customOperatingTimes, (item) => !isOperatingTimeValid(item.minTourDuration, item.maxTourDuration));

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{vehicle.licencePlate}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container direction="row" spacing={2} justify="space-around">
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Text bold>{t("vehicle_overview.data.starting_point")}</Text>
                  </Grid>
                  <Grid item>
                    <Text>{formatDeparturePoint(vehicle.standardDeparturePoint || undefined)}</Text>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Text bold>{t("vehicle_overview.data.end_point")}</Text>
                  </Grid>
                  <Grid item>
                    <Text>{formatDeparturePoint(vehicle.standardEndPoint || undefined)}</Text>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <DeparturePointContextProvider partnerId={open ? vehicle.partnerId : undefined}>
              <TourGenerationDays
                vehicle={vehicle}
                onDaySelectionChanged={handleChangeSelection(vehicle.vehicleId)}
                onCustomDeparturePointEntryChange={onCustomDeparturePointEntryChange}
                onCustomOperatingTimeEntryChange={onCustomOperatingTimeEntryChange}
              />
            </DeparturePointContextProvider>
          </Grid>
          {vehicle.materials.length > 0 && !tourWithoutContainers && (
            <>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <TourGenerationVehicleMaterials
                  selectedMaterials={vehicle.materials}
                  updateSelectedMaterials={updateMaterials(vehicle)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" disabled={!canSave}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
