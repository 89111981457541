import React, { FunctionComponent, useContext, useEffect, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  DialogActions,
  Button,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import moment, { Moment } from "moment"
import { KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { Info } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

interface IScheduleTourGenerationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ScheduleTourGenerationDialog: FunctionComponent<IScheduleTourGenerationDialogProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, onConfirm, open } = props

  const {
    maxTerminationTime,
    setMaxTerminationTime,
    scheduleDate,
    setScheduleDate,
    maxTerminationTimeValid,
  } = useContext(TourGenerationContext)

  const [scheduleDateValid, setScheduleDateValid] = useState<boolean>(false)

  useEffect(() => {
    if (scheduleDate === null && open) {
      setScheduleDate(moment().add(30, "minutes"))
    }
  }, [scheduleDate, setScheduleDate, open])

  useEffect(() => {
    setScheduleDateValid(
      scheduleDate !== null && scheduleDate.isValid() && scheduleDate.isAfter(moment(), "minutes"),
    )
  }, [scheduleDate])

  const onCancel = () => {
    setScheduleDate(null)
    onClose()
  }

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="lg">
      <DialogTitle>{t("tour_generation.schedule_dialog.title")}</DialogTitle>
      <DialogContent>
        <Grid container direction="row" className={classes.container} spacing={2}>
          <Grid item container xs={12} direction="row" alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <KeyboardTimePicker
                fullWidth
                ampm={false}
                openTo="hours"
                views={["hours", "minutes"]}
                format="HH:mm"
                label={t("tour_generation.data.max_termination_time")}
                value={maxTerminationTime}
                onChange={(date) => setMaxTerminationTime(date as Moment)}
                error={!maxTerminationTimeValid}
                helperText={null}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title={t("tour_generation.schedule_dialog.max_termination_time_info") as string}
              >
                <Info color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="row" alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <KeyboardDateTimePicker
                disablePast
                fullWidth
                ampm={false}
                format={t("date_time_input_format")}
                label={t("tour_generation.data.schedule_date")}
                value={scheduleDate}
                onChange={(date) => setScheduleDate(date as Moment)}
                error={!scheduleDateValid}
                helperText={null}
              />
            </Grid>
            <Grid item>
              <Tooltip title={t("tour_generation.schedule_dialog.schedule_date_info") as string}>
                <Info color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" spacing={2} justify="space-between">
          <Grid item>
            <Button onClick={onCancel} autoFocus variant="contained">
              {t("tour_generation.confirm_dialog.cancel")}
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="primary"
              disabled={!scheduleDateValid || !maxTerminationTimeValid}
            >
              {t("tour_generation.confirm_dialog.generate")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
