import { gql } from "@apollo/client"
import { RequestDbCloning } from "./types/requestDbCloning"

export const REQUEST_DB_CLONING_MUTATION = gql`
  mutation RequestDbCloning {
    requestDbCloning {
      id
      startedAt
      finishedAt
      status
    }
  }
`

export type RequestDbCloningResult = RequestDbCloning
