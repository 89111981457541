import React, { FunctionComponent, Fragment } from "react"
import { useTranslation } from "react-i18next"
import { RegionStats as RegionStatsType } from "../../../../api/graphql/queries/get-region-stats"
import { InfoCard } from "./info-card"
import { Info } from "./info"
import { CardInfoHelper } from "../../../../utils/CardInfoHelper"
import { UserService } from "../../../../services/user-service"

interface IRegionStatsProps {
  data: RegionStatsType
}

export const RegionStats: FunctionComponent<IRegionStatsProps> = (props) => {
  const { t } = useTranslation()
  const { data } = props

  return (
    <Fragment>
      <InfoCard header={t(data.type)}>
        <Info
          amount={String(data.amount_containers_above_filllevel_treshhold)}
          description={t(
            "collection_points.info_cards.region.amount_containers_above_filllevel_treshhold",
          )}
        />
        {data.collected_amount_stats &&
          data!.collected_amount_stats!.map((stat, idx) => (
            <Info
              key={idx}
              amount={String(
                `${(stat.amount / 1000).toLocaleString(UserService.getLanguage(), {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}t`,
              )}
              description={t("collection_points.info_cards.region.amount_material_emptied", {
                material: stat.material_name,
                timeRange: CardInfoHelper.getLastMonthString(),
              })}
            />
          ))}
      </InfoCard>
    </Fragment>
  )
}
