import { gql } from "@apollo/client"
import {
  removeVehicle,
  removeVehicleVariables,
  removeVehicle_removeVehicle
} from "./types/removeVehicle"

export const REMOVE_VEHICLE_MUTATION = gql`
  mutation removeVehicle($id: ID!) {
    removeVehicle(id: $id) {
      error
    }
  }
`

export type RemoveVehicleResult = removeVehicle
export type RemoveVehicle = removeVehicle_removeVehicle
export type RemoveVehicleVariables = removeVehicleVariables
