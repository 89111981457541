import { gql } from "@apollo/client"
import { getUser, getUserVariables } from "./types/getUser"

export const GET_USER_QUERY = gql`
  query getUser($email: String!) {
    getUser(email: $email) {
      id
      first_name
      last_name
      role
      email
      towns {
        id
        name
      }
      associations {
        id
        name
      }
      districts {
        id
        name
      }
      driver_locations {
        id
        name
      }
      collection_partner {
        id
        name
      }
    }
  }
`

export type UserResult = getUser
export type UserVariables = getUserVariables
