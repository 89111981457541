import React, { FunctionComponent, useEffect } from "react"
import { useRouteMatch } from "react-router-dom"
import { Theme, makeStyles, Grid, CircularProgress } from "@material-ui/core"
import lodash from "lodash"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { RefetchProvider } from "../../../../context/refetch-context"
import { VehicleSelector } from "./vehicle-selector"
import { VehicleData } from "./vehicle-data"
import { useSelectedPartner } from "../../../../context/selected-partner-context"

const useStyles = makeStyles((theme: Theme) => ({
  data: {
    display: "flex",
    flex: 1,
    overflowY: "auto",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
  },
  contentContainer: {
    height: "100%",
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1",
  },
  selectorContainer: {
    height: "100%",
  },
  headingWrapper: {
    paddingLeft: theme.spacing(2),
  },
}))

interface IVehicleOverviewProps {}

export const VehicleOverview: FunctionComponent<IVehicleOverviewProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { setSelectedPartnerId, selectedPartner, loading: partnerLoading } = useSelectedPartner()
  const match = useRouteMatch<{ partnerID: string }>()
  const partnerID = parseInt(lodash.get(match, "params.partnerID"), 10)

  useEffect(() => {
    if (!isNaN(partnerID)) {
      setSelectedPartnerId(partnerID)
    }
  }, [partnerID, setSelectedPartnerId])

  return (
    <Grid container direction="row" className={classes.container}>
      <RefetchProvider>
        <Grid item className={classes.selectorContainer}>
          <VehicleSelector />
        </Grid>
        <Grid container item direction="column" className={classes.contentContainer} xs>
          {partnerLoading ? (
            <Grid container justify="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item className={classes.headingWrapper}>
              <PageHeading>
                {selectedPartner
                  ? selectedPartner.name
                  : t("vehicle_overview.partner_not_found", {
                      partnerID,
                    })}
              </PageHeading>
            </Grid>
          )}
          <Grid className={classes.data}>
            <VehicleData />
          </Grid>
        </Grid>
      </RefetchProvider>
    </Grid>
  )
}
