import React, { FunctionComponent, ReactNode } from "react"
import { Theme, makeStyles, Card, CardContent, Grid } from "@material-ui/core"
import { Text } from "../../wrapper/text"
import classnames from "classnames"
import { CARD_TILE_WIDTH, CARD_TILE_HEIGHT } from "../../../../utils/constants"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: CARD_TILE_WIDTH,
    height: CARD_TILE_HEIGHT,
    cursor: "pointer",
    textAlign: "center",
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
  },
  secondaryContentText: {
    color: "#000",
  },
  cardContent: {
    height: `calc(100% - ${theme.spacing(4)}px)`,
    padding: theme.spacing(2),
  },
  gridContainer: {
    height: "100%",
  },
  gridItem: {
    height: "33%",
    position: "relative",
  },
  gridContent: {
    width: "100%",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
    lineHeight: 1,
  },
}))

interface ICardTileProps {
  headingText: ReactNode
  contentText: ReactNode
  secondary?: boolean
  descriptionText?: ReactNode
}

export const CardTile: FunctionComponent<ICardTileProps> = (props) => {
  const classes = useStyles()
  const { contentText, headingText, descriptionText, secondary } = props

  return (
    <Card
      className={classnames(classes.container, {
        [classes.primary]: !secondary,
        [classes.secondary]: secondary,
      })}
    >
      <CardContent className={classes.cardContent}>
        <Grid
          container
          alignItems="stretch"
          justify="center"
          direction="column"
          className={classes.gridContainer}
        >
          <Grid item className={classes.gridItem}>
            <Text
              variant={"subtitle2"}
              className={classnames(classes.gridContent, {
                [classes.secondaryContentText]: secondary,
              })}
            >
              {headingText}
            </Text>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Text variant={"h6"} className={classes.gridContent}>
              {contentText}
            </Text>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Text
              variant={"subtitle2"}
              className={classnames(classes.gridContent, {
                [classes.secondaryContentText]: secondary,
              })}
            >
              {descriptionText}
            </Text>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

CardTile.defaultProps = {
  secondary: false,
}
