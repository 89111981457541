import { gql } from "@apollo/client"
import {
  getDistrictsForUserManagement,
  getDistrictsForUserManagement_getDistrictsForUserManagement,
} from "./types/getDistrictsForUserManagement"

export const GET_DISTRICTS_FOR_USER_MANAGEMENT_QUERY = gql`
  query getDistrictsForUserManagement($collection_partner_id: ID) {
    getDistrictsForUserManagement(collection_partner_id: $collection_partner_id) {
      id
      name
    }
  }
`

export type DistrictsResult = getDistrictsForUserManagement
export type District = getDistrictsForUserManagement_getDistrictsForUserManagement
