import { gql } from "@apollo/client"
import { createUser, createUserVariables, createUser_createUser } from "./types/createUser"

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $role: UserRole!
    $password: String!
    $townIds: [ID!]!
    $associationIds: [ID!]!
    $districtIds: [ID!]!
    $guidedDriverLocationIds: [ID!]!
    $collection_partner_id: ID
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
      password: $password
      townIds: $townIds
      associationIds: $associationIds
      districtIds: $districtIds
      guidedDriverLocationIds: $guidedDriverLocationIds
      collection_partner_id: $collection_partner_id
    ) {
      id
      first_name
      last_name
      role
      email
      collection_partner {
        id
        name
      }
    }
  }
`

export type CreateUserResult = createUser
export type CreateUser = createUser_createUser
export type CreateUserVariables = createUserVariables
