import { IMarker } from "./custom-map"
import lodash from "lodash"
import { showInvalidValueInMapPin } from "../../../utils/collectionpoint"
import { MapCollectionPoint } from "../../../api/graphql/queries/get-collection-points-for-map"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../utils/constants"

export class MapsHelper {
  static getMaxFilllevelFromCollectionPoint(
    collectionPoint: MapCollectionPoint,
    problemSensorInterval: number,
  ): number {
    const filllevel = lodash.max(collectionPoint.collectionPointFilllevels.map((entry) => entry.filllevel))
    if (lodash.isNil(filllevel) || showInvalidValueInMapPin(collectionPoint, problemSensorInterval)) {
      return -1
    }
    return filllevel
  }

  static getMarker(collectionPoint: MapCollectionPoint, problemSensorInterval: number): IMarker {
    return {
      id: Number(collectionPoint.id),
      lat: collectionPoint.latitude,
      lng: collectionPoint.longitude,
      filllevel: this.getMaxFilllevelFromCollectionPoint(collectionPoint, problemSensorInterval),
      onDemand: collectionPoint.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND,
    }
  }
  static getMarkersWithFilllevels(collectionPoints: MapCollectionPoint[], problemSensorInterval: number): IMarker[] {
    return collectionPoints.map((collectionPoint) => ({
      id: Number(collectionPoint.id),
      lat: collectionPoint.latitude,
      lng: collectionPoint.longitude,
      filllevel: this.getMaxFilllevelFromCollectionPoint(collectionPoint, problemSensorInterval),
      onDemand: collectionPoint.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND,
    }))
  }
}
