import { gql } from "@apollo/client"
import {
  getVehicleWithId,
  getVehicleWithId_getVehicleWithId,
  getVehicleWithId_getVehicleWithId_vehicleToMaterial,
  getVehicleWithId_getVehicleWithId_operating_times,
  getVehicleWithId_getVehicleWithId_district,
} from "./types/getVehicleWithId"
import { OperationPoint } from "./types/OperationPoint"

export const GET_VEHICLE_WITH_ID_QUERY = gql`
  fragment OperationPoint on DeparturePoint {
    id
    name
    latitude
    longitude
  }
  query getVehicleWithId($id: ID!, $ignoreTrailer: Boolean) {
    getVehicleWithId(id: $id) {
      id
      type
      licence_plate
      district {
        id
        name
      }
      vehicleToMaterial(ignoreTrailer: $ignoreTrailer) {
        material {
          id
          name
        }
        amount
        is_trailer
      }
      trailer
      emission_standard
      length
      width
      height
      has_scale
      operating_times {
        id
        active
        day
        max_tour_duration
        min_tour_duration
      }
      departurePoint {
        ...OperationPoint
      }
      endPoint {
        ...OperationPoint
      }
      tmp_driver
      costs_per_hour
      costs_per_km
    }
  }
`

export type VehicleResult = getVehicleWithId
export type Vehicle = getVehicleWithId_getVehicleWithId
export type VehicleToMaterial = getVehicleWithId_getVehicleWithId_vehicleToMaterial
export type VehicleOperatingTime = getVehicleWithId_getVehicleWithId_operating_times
export type VehicleOperationPoint = OperationPoint
export type VehicleDistrict = getVehicleWithId_getVehicleWithId_district
