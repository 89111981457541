import { gql } from "@apollo/client"
import {
  getMinimalCollectionPointsForMap,
  getMinimalCollectionPointsForMapVariables,
  getMinimalCollectionPointsForMap_getMinimalCollectionPointsWithRegionID,
} from "./types/getMinimalCollectionPointsForMap"

export const GET_MINIMAL_COLLECTIONPOINTS_FOR_MAP_QUERY = gql`
  query getMinimalCollectionPointsForMap($id: Int!, $type: RegionType!) {
    getMinimalCollectionPointsWithRegionID(id: $id, type: $type) {
      id
      latitude
      longitude
      collectionPointFilllevels(sorted: false) {
        filllevel
        material_id
      }
      containers(sorted: false) {
        id
        last_time_send
        material_id
        lid_open
        lid_changed
        sensor_id
        defectiveSensor
      }
      fixed_unload_interval
    }
  }
`

export type GetMinimalCollectionPointsForMapResult = getMinimalCollectionPointsForMap
export type GetMinimalCollectionPointsForMapVariables = getMinimalCollectionPointsForMapVariables
export type MinimalMapCollectionPoint = getMinimalCollectionPointsForMap_getMinimalCollectionPointsWithRegionID
