import { useQuery } from "@apollo/client"
import { SelectOption } from "../components/partials/customselect/custom-select-component-commons"
import { useMemo, useState } from "react"
import {
  GET_TOWNS_WITH_REGION_ID_QUERY,
  GetTownsWithRegionIDResult,
  GetTownsWithRegionIDVariables,
} from "../api/graphql/queries/get-towns-with-region-id"

export const useTownFilter = (
  variables: GetTownsWithRegionIDVariables,
  onQueryCompleted: (data: GetTownsWithRegionIDResult) => void,
) => {
  const { data, loading, error } = useQuery<GetTownsWithRegionIDResult, GetTownsWithRegionIDVariables>(
    GET_TOWNS_WITH_REGION_ID_QUERY,
    {
      variables,
      fetchPolicy: "cache-first",
      onCompleted: onQueryCompleted,
      skip: !variables.id,
    },
  )

  const [selectedTowns, setSelectedTowns] = useState<SelectOption[]>([])
  const options = useMemo(
    () =>
      data?.getTownsWithRegionID.map(
        (town) => new SelectOption(town.id, !town.town_code ? town.name : `${town.name} - ${town.town_code}`),
      ) || [],
    [data],
  )

  return {
    options,
    selectedTowns,
    setSelectedTowns,
    loading,
    error,
  }
}
