import React, { useState, useContext, createContext, useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import {
  GETCOLLECTIONPARTNERWITHID_QUERY,
  CollectionPartner,
} from "../api/graphql/queries/get-collection-partner-with-id"
import { UserService } from "../services/user-service"
import { UserRole } from "../api/graphql/graphql-global-types"

interface ISelectedPartnerContextType {
  loading: boolean
  selectedPartnerId: number | undefined
  setSelectedPartnerId: (id: number | undefined) => void
  selectedPartner: CollectionPartner | undefined
  setSelectedPartner: (partner: CollectionPartner | undefined) => void
}

export const SelectedPartnerContext = createContext<ISelectedPartnerContextType>(
  {} as ISelectedPartnerContextType,
)

export const SelectedPartnerProvider = (props: any) => {
  const value = useSelectedPartnerProvider()

  return (
    <SelectedPartnerContext.Provider value={value}>
      {props.children}
    </SelectedPartnerContext.Provider>
  )
}

export const useSelectedPartner = () => {
  return useContext(SelectedPartnerContext)
}

const useSelectedPartnerProvider = (): ISelectedPartnerContextType => {
  const [selectedPartnerId, setSelectedPartnerId] = useState<number | undefined>(undefined)
  const [selectedPartner, setSelectedPartner] = useState<CollectionPartner | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState<boolean>(false)

  const client = useApolloClient()
  useEffect(() => {
    if (selectedPartnerId && selectedPartner?.id !== selectedPartnerId) {
      // do call for 
      const fetch = async () => {
        setLoading(true)
        const { data } = await client.query({
          query: GETCOLLECTIONPARTNERWITHID_QUERY,
          variables: { id: selectedPartnerId },
        })
        setLoading(false)

        if (data) {
          setSelectedPartner(data.getCollectionPartnerWithID)
        }
      }
      fetch()
    } else if(!selectedPartnerId && UserService.getRole() !== UserRole.COLLECTION_PARTNER){
      setSelectedPartner(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartnerId])

  return {
    loading,
    selectedPartnerId,
    setSelectedPartnerId,
    selectedPartner,
    setSelectedPartner,
  }
}
