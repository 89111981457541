import React, { FunctionComponent, createContext } from "react"
import { useQuery } from "@apollo/client"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { DisposalTrailerLocationsByCollectionPartnerResult, DisposalTrailerLocationsByCollectionPartnerVariables, GET_DISPOSAL_TRAILER_LOCATIONS_BY_COLLECTION_PARTNER } from "../../../../../../api/graphql/queries/get-disposal-trailer-locations-by-collection-partner"
import { DisposalTrailerLocation } from "../../../../../../api/graphql/queries/get-disposal-trailer-locations-by-collection-partner"

interface IDisposalTrailerLocationContextProviderProps {
  partnerId?: number
}

interface IDisposalTrailerLocationContext {
  disposalTrailerLocations: DisposalTrailerLocation[]
  disposalTrailerLocationsLoading: boolean
  refetchDisposalTrailerLocations: () => void
  partnerId?: number
}

export const DisposalTrailerLocationContext = createContext<IDisposalTrailerLocationContext>({
  disposalTrailerLocations: [],
  disposalTrailerLocationsLoading: false,
  refetchDisposalTrailerLocations: () => {},
  partnerId: undefined,
})

export const DisposalTrailerLocationContextProvider: FunctionComponent<IDisposalTrailerLocationContextProviderProps> = (
  props,
) => {
  const { partnerId, children } = props
  const { t } = useTranslation()
  const {
    data,
    loading: disposalTrailerLocationsLoading,
    refetch: refetchDisposalTrailerLocations,
  } = useQuery<DisposalTrailerLocationsByCollectionPartnerResult, DisposalTrailerLocationsByCollectionPartnerVariables>(GET_DISPOSAL_TRAILER_LOCATIONS_BY_COLLECTION_PARTNER, {
    variables: { collection_partner_id: String(partnerId) },
    skip: !partnerId,
    onError: () => toast.error(t("disposal_trailer_location_context.errors.could_not_load")),
  })

  const disposalTrailerLocations = data?.disposalTrailerLocationsByCollectionPartner || []

  return (
    <DisposalTrailerLocationContext.Provider
      value={{ disposalTrailerLocations, disposalTrailerLocationsLoading, refetchDisposalTrailerLocations, partnerId }}
    >
      {children}
    </DisposalTrailerLocationContext.Provider>
  )
}
