import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../partials/layout/selection/outlined-select"
import { useDistrictFilter } from "../../../context/DistrictFilterContext"
import { SelectPair } from "../layout/selection/select-pair"
import { CustomSnackBarContent } from "../../partials/wrapper/CustomSnackBarContent"
import { Skeleton } from "@material-ui/lab"
import { District } from "../../../api/graphql/queries/get-districts"

interface IDistrictSelectProps {
  filterOption?: (entry: District) => boolean
}

export const DistrictSelect: FunctionComponent<IDistrictSelectProps> = (props) => {
  const { filterOption } = props
  const { t } = useTranslation()
  const { setDistrictId, selectedDistrict, districts, districtsError, districtsLoading } = useDistrictFilter()

  const options = useMemo(() => {
    if (filterOption) {
      return districts.filter(filterOption).map((district) => new SelectPair(district.id, district.name))
    }

    return districts.map((district) => new SelectPair(district.id, district.name))
  }, [districts, filterOption])

  useEffect(() => {
    if (selectedDistrict && options.length && !options.find((o) => o.value === selectedDistrict.id)) {
      setDistrictId(options[0].value)
    }
  }, [options, selectedDistrict, setDistrictId])

  const handleDistrictChange = useCallback(
    (newId: string) => {
      const district = options.find((district) => district.value === newId)
      if (!!district) {
        setDistrictId(district.value)
      }
    },
    [options, setDistrictId],
  )

  if (districtsError) {
    return <CustomSnackBarContent variant="error" message={t("errors.generic")} />
  }
  if (districtsLoading) {
    return (
      <Grid container justify="center" alignItems="center">
        <Skeleton height={50} width="100%" />
      </Grid>
    )
  }

  return (
    <OutlinedSelect
      options={options}
      name={t("district")}
      onValueChange={(newValue) => handleDistrictChange(newValue)}
      value={selectedDistrict?.id || ""}
    />
  )
}
