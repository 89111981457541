import { gql } from "@apollo/client"
import { regenerateTour_regenerateTour } from "./types/regenerateTour"

export const REGENERATE_TOUR_MUTATION = gql`
  mutation regenerateTour($tourUuid: ID!, $preview_entries: [TourPreviewInput!]!) {
    regenerateTour(tourUuid: $tourUuid, preview_entries: $preview_entries) {
      error
      message
    }
  }
`
export type RegenerateTourResult = regenerateTour_regenerateTour
