import { gql } from "@apollo/client"
import {
  removeCollectionPartner,
  removeCollectionPartnerVariables,
  removeCollectionPartner_removeCollectionPartner
} from "./types/removeCollectionPartner"

export const REMOVE_COLLECTION_PARTNER_MUTATION = gql`
  mutation removeCollectionPartner($id: ID!) {
    removeCollectionPartner(id: $id) {
      error
    }
  }
`

export type RemoveCollectionPartnerResult = removeCollectionPartner
export type RemoveCollectionPartner = removeCollectionPartner_removeCollectionPartner
export type RemoveCollectionPartnerVariables = removeCollectionPartnerVariables
