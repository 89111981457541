import React, { FunctionComponent } from "react"
import { Grid, Chip, CircularProgress } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import {
  GET_ASSOCIATIONS_FOR_USER_MANAGEMENT_QUERY,
  AssociationsResult,
} from "../../../../../api/graphql/queries/get-associations-for-user-management"
import { sortASC } from "../../../../../utils/sort"
import { ILocationCollectionItem } from "./location-assignment-dialog"

interface IAssociationSelectionProps {
  selectedItems: ILocationCollectionItem[]
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
}

export const AssociationSelection: FunctionComponent<IAssociationSelectionProps> = (
  props
) => {
  const { onClick, selectedItems, searchString } = props

  const { data, loading } = useQuery<AssociationsResult>(GET_ASSOCIATIONS_FOR_USER_MANAGEMENT_QUERY)

  const associations =
    data?.getAssociationsForUserManagement
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((association) =>
        association.name.toLowerCase().includes(searchString.toLowerCase())
      ) || []

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        associations.map((association) => (
          <Grid item key={association.id}>
            <Chip
              label={association.name}
              color={
                selectedItems.find(
                  (item) =>
                    item.id === association.id && item.name === association.name
                )
                  ? "primary"
                  : "default"
              }
              onClick={() => onClick(association)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
