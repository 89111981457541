import { gql } from "@apollo/client"
import {
  uploadImageCollectionPoint,
  uploadImageCollectionPointVariables,
  uploadImageCollectionPoint_uploadImageCollectionPoint
} from "./types/uploadImageCollectionPoint"

export const UPLOAD_IMAGE_FOR_COLLECTION_POINT_MUTATION = gql`
  mutation uploadImageCollectionPoint(
    $collection_point_id: ID!
    $file: Upload!
  ) {
    uploadImageCollectionPoint(
      collection_point_id: $collection_point_id
      file: $file
    ) {
      id
    }
  }
`

export type UploadImageForCollectionPointResult = uploadImageCollectionPoint
export type UploadImageForCollectionPoint = uploadImageCollectionPoint_uploadImageCollectionPoint
export type UploadImageForCollectionPointVariables = uploadImageCollectionPointVariables
