import React, { FunctionComponent } from "react"
import { RefetchProvider } from "../../../context/refetch-context"
import { SelectedDriverLocationProvider } from "../../../context/selected-driver-location-context"
import { DriverLocationAdministration } from "./partials/driver-location-administration"

interface IGuidanceAdministrationPageProps {}

export const GuidanceAdministrationPage: FunctionComponent<IGuidanceAdministrationPageProps> = props => (
  <SelectedDriverLocationProvider>
    <RefetchProvider>
      <DriverLocationAdministration />
    </RefetchProvider>
  </SelectedDriverLocationProvider>
)
