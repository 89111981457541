import { gql } from "@apollo/client"
import {
  getContainerExcelWithRegionId,
  getContainerExcelWithRegionIdVariables,
} from "./types/getContainerExcelWithRegionId"

export const GET_CONTAINER_EXCEL_WITH_REGION_ID_QUERY = gql`
  query getContainerExcelWithRegionId(
    $id: ID!
    $type: RegionType!
    $startDate: DateTime!
    $endDate: DateTime!
    $filter: RegionFilterInput
  ) {
    getContainerExcelWithRegionId(id: $id, type: $type, startDate: $startDate, endDate: $endDate, filter: $filter)
  }
`
export type GetContainerExcelResult = getContainerExcelWithRegionId
export type GetContainerExcelVariables = getContainerExcelWithRegionIdVariables
