import { gql } from "@apollo/client"
import {
  getTourParameters,
  getTourParametersVariables,
  getTourParameters_getTourParameters_driving_days
} from "./types/GetTourParameters"

export const GET_TOUR_PARAMETERS = gql`
  query getTourParameters($tourParametersId: ID!) {
  getTourParameters(tour_parameters_id: $tourParametersId) {
    id
    start_date
    threshold
    threshold_min
    version
    reference_date
    disposal_trailer_locations {
      id
      name
      latitude
      longitude
    }
    towns {
      id
      name
    }
    without_containers
    calculation_start
    calculation_end
    district {
      id
      name
      towns {
        id
        name
      }
    }
    driving_days {
      tour_parameters_id
      week
      day
      vehicle {
        id
        licence_plate
        operating_times {
          active
          day
          min_tour_duration
          max_tour_duration
        }
      }
      departure_point {
        id
        name
      }
      end_point {
        id
        name
      }
    }
  }
}
`

export type GetTourParametersResult = getTourParameters
export type GetTourParametersVariables = getTourParametersVariables
export type GetTourParametersDrivingDays = getTourParameters_getTourParameters_driving_days
