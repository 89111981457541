import React, { useContext, createContext, useState, useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import {
  GETDISPOSALMERCHANTSTATSWITHID_QUERY,
  DisposalMerchantStats,
  DisposalMerchantStatsResult
} from "../api/graphql/queries/get-disposal-merchant-stats-with-id"

interface ISelectedDisposalMerchantContextType {
  selectedDisposalMerchantId: number | undefined
  setSelectedDisposalMerchantId: (id: number | undefined) => void
  selectedDisposalMerchantStats: DisposalMerchantStats | undefined
  needToRefetch: boolean
  setNeedToRefetch: (needToRefetch: boolean) => void
}

export const SelectedDisposalMerchantContext = createContext<
  ISelectedDisposalMerchantContextType
>({} as ISelectedDisposalMerchantContextType)

interface ISelectedDisposalMerchantProviderProps {
  children: React.ReactNode
}

export const SelectedDisposalMerchantProvider = (
  props: ISelectedDisposalMerchantProviderProps
) => {
  const value = useSelectedDisposalMerchantProvider()
  return (
    <SelectedDisposalMerchantContext.Provider value={value}>
      {props.children}
    </SelectedDisposalMerchantContext.Provider>
  )
}

export const useSelectedDisposalMerchant = () => {
  return useContext(SelectedDisposalMerchantContext)
}

const useSelectedDisposalMerchantProvider = (): ISelectedDisposalMerchantContextType => {
  const [selectedDisposalMerchantId, setSelectedDisposalMerchantId] = useState<
    number | undefined
  >(undefined)
  const [
    selectedDisposalMerchantStats,
    setSelectedDisposalMerchantStats
  ] = useState<DisposalMerchantStats | undefined>(undefined)

  const [needToRefetch, setNeedToRefetch] = useState<boolean>(false)
  const client = useApolloClient()
  useEffect(() => {
    if (selectedDisposalMerchantId) {
      // do call for stats
      const fetchStats = async () => {
        const { data } = await client.query({
          query: GETDISPOSALMERCHANTSTATSWITHID_QUERY,
          variables: { id: selectedDisposalMerchantId }
        })

        setSelectedDisposalMerchantStats(
          (data as DisposalMerchantStatsResult).getDisposalMerchantStatsWithId
        )
      }
      fetchStats()
    } else {
      setSelectedDisposalMerchantStats(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDisposalMerchantId])

  useEffect(() => {
    if (selectedDisposalMerchantId && needToRefetch) {
      // do call for stats
      const fetchStats = async () => {
        const { data } = await client.query({
          query: GETDISPOSALMERCHANTSTATSWITHID_QUERY,
          variables: { id: selectedDisposalMerchantId }
        })

        setSelectedDisposalMerchantStats(
          (data as DisposalMerchantStatsResult).getDisposalMerchantStatsWithId
        )
        setNeedToRefetch(false)
      }
      fetchStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToRefetch])

  return {
    selectedDisposalMerchantId,
    setSelectedDisposalMerchantId,
    selectedDisposalMerchantStats,
    needToRefetch,
    setNeedToRefetch
  }
}
