import { gql } from "@apollo/client"
import {
  getDeparturePoints,
  getDeparturePointsVariables,
  getDeparturePoints_getDeparturePoints,
} from "./types/getDeparturePoints"

export const GET_DEPARTURE_POINTS = gql`
  query getDeparturePoints($collectionPartnerId: ID!) {
    getDeparturePoints(collectionPartnerId: $collectionPartnerId) {
      id
      name
      latitude
      longitude
      vehicleCount
      tourCount
    }
  }
`

export type GetDeparturePointsResult = getDeparturePoints
export type GetDeparturePointsVariables = getDeparturePointsVariables
export type DeparturePoint = getDeparturePoints_getDeparturePoints
