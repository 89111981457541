import { gql } from "@apollo/client"
import {
  createDeparturePoint,
  createDeparturePointVariables,
  createDeparturePoint_createDeparturePoint,
} from "./types/createDeparturePoint"

export const CREATE_DEPARTURE_POINT_MUTATION = gql`
  mutation createDeparturePoint(
    $collectionPartnerId: ID!
    $name: String!
    $latitude: Float!
    $longitude: Float!
  ) {
    createDeparturePoint(
      collectionPartnerId: $collectionPartnerId
      name: $name
      latitude: $latitude
      longitude: $longitude
    ) {
      id
    }
  }
`

export type CreateDeparturePointResult = createDeparturePoint
export type CreateDeparturePoint = createDeparturePoint_createDeparturePoint
export type CreateDeparturePointVariables = createDeparturePointVariables
