import { gql } from "@apollo/client"
import {
  updateContainerWarningStatusVariables,
  updateContainerWarningStatus,
  updateContainerWarningStatus_updateContainerWarningStatus
} from "./types/updateContainerWarningStatus"

export const UPDATE_CONTAINER_WARNING_STATUS_MUTATION = gql`
  mutation updateContainerWarningStatus(
    $containerWarningId: ID!
    $status: ContainerWarningStatus!
  ) {
    updateContainerWarningStatus(containerWarningId: $containerWarningId, status: $status) {
      id
      since
      warning_status
      warning_type
      inserted_at
      container {
        id
        number
        sensor_id
        material {
          id
          name
        }
        collection_point {
          id
          description
          street
          cadastral_name
          postal
        }
      }
    }
  }
`

export type UpdateContainerWarningStatusResult = updateContainerWarningStatus
export type UpdateContainerWarningStatusVariables = updateContainerWarningStatusVariables
export type ContainerWarning = updateContainerWarningStatus_updateContainerWarningStatus
