import { MutationFunctionOptions, QueryLazyOptions, useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { ExecutionResult } from "graphql"
import React, { useContext, createContext, useState } from "react"
import {
  DeleteTourResult,
  DeleteTourVariables,
  DELETE_TOUR_MUTATION,
} from "../../../../api/graphql/mutations/delete-tour"
import { deleteTour, deleteTourVariables } from "../../../../api/graphql/mutations/types/deleteTour"
import {
  GetTourStatsWithIdVariables,
  GETTOURSTATS_WITH_ID_QUERY,
  TourStatsResult,
} from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { useRefetch } from "../../../../context/refetch-context"
import lodash from "lodash"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { UserService } from "../../../../services/user-service"
import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { RegionType } from "../../../../api/graphql/graphql-global-types"
import { getTourStatsWithId } from "../../../../api/graphql/queries/types/getTourStatsWithId"
import {
  GetTourAsExcelFileResult,
  GetTourAsExcelFileVariables,
  GET_TOUR_AS_EXCEL_FILE_QUERY,
} from "../../../../api/graphql/queries/get-tour-as-excel-file"
import { getTourAsExcelFileVariables } from "../../../../api/graphql/queries/types/getTourAsExcelFile"
import { ApolloError } from "@apollo/client"
import { toast } from "react-toastify"
import i18next from "i18next"
import { DownloadManagerContext } from "../../../partials/download-manager/download-manager-context"

interface TourOverviewContextType {
  selectedStopId: number | undefined
  setSelectedStopId: (value: number | undefined) => void
  deleteTour: (
    options?: MutationFunctionOptions<deleteTour, deleteTourVariables>,
  ) => Promise<ExecutionResult<deleteTour>>
  tourStatsData: getTourStatsWithId | undefined
  tourStatsError: ApolloError | undefined
  tourStatsLoading: boolean
  getTourAsExcelFile: (options?: QueryLazyOptions<getTourAsExcelFileVariables> | undefined) => void
  excelLoading: boolean
}

const TourOverviewContext = createContext<TourOverviewContextType>({} as TourOverviewContextType)

export const TourOverviewContextProvider = (props: any) => {
  const value = useTourOverviewProvider()
  return <TourOverviewContext.Provider value={value}>{props.children}</TourOverviewContext.Provider>
}

export const useTourOverview = () => {
  return useContext(TourOverviewContext)
}

const useTourOverviewProvider = (): TourOverviewContextType => {
  const { selectedTour } = useSelectedTour()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const { setTriggerQuery } = useContext(DownloadManagerContext)

  const [selectedStopId, setSelectedStopId] = useState<number>()
  const [deleteTour] = useMutation<DeleteTourResult, DeleteTourVariables>(DELETE_TOUR_MUTATION)

  const { needToRefetch } = useRefetch()

  const { data: tourStatsData, error: tourStatsError, loading: tourStatsLoading } = useQuery<
    TourStatsResult,
    GetTourStatsWithIdVariables
  >(GETTOURSTATS_WITH_ID_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-and-network",
    variables: {
      id: !lodash.isNil(selectedTour) ? Number(selectedTour!.id) : -1,
      regionId: (UserService.hasAssociationFilter() ? selectedAssociation?.id : selectedDistrict?.id) || "",
      regionType: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
    },
    skip:
      (UserService.hasAssociationFilter() && !selectedAssociation) ||
      (!UserService.hasAssociationFilter() && !selectedDistrict) ||
      selectedTour === undefined,
  })

  const showError = () => {
    toast.error(i18next.t("export.unknown_error"))
  }

  const onRequestCompleted = (data: GetTourAsExcelFileResult) => {
    if (!data.getTourAsExcelFile) {
      showError()
    }
    setTriggerQuery(true)
  }

  const [getTourAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetTourAsExcelFileResult,
    GetTourAsExcelFileVariables
  >(GET_TOUR_AS_EXCEL_FILE_QUERY, {
    onCompleted: onRequestCompleted,
    onError: showError,
    fetchPolicy: "no-cache",
  })

  return {
    selectedStopId,
    setSelectedStopId,
    deleteTour,
    tourStatsData,
    tourStatsError,
    tourStatsLoading,
    getTourAsExcelFile,
    excelLoading,
  }
}
