import { gql } from "@apollo/client"
import { getMe, getMe_getMe, getMe_getMe_theme } from "./types/getMe"

export const GET_ME_QUERY = gql`
  # Write your query or mutation here
  query getMe {
    getMe {
      id
      first_name
      last_name
      role
      email
      rfidModuleEnabled
      towns {
        id
        name
      }
      associations {
        id
        name
      }
      districts {
        id
        name
      }
      collection_partner {
        id
        name
      }
      slocUser {
        id
        problem_sensor_interval
        show_open_lid_warning
        showDefectiveSensorWarning
        verbose_excel_tour_export
        enable_tour_export
      }
      theme {
        id
        name
        portal {
          primary_main
          primary_light
          primary_dark
          primary_contrast_text
          secondary_main
          secondary_light
          secondary_dark
          secondary_contrast_text
          error
          background
          text
        }
        logo_url
      }
    }
  }
`

export type UserResult = getMe
export type User = getMe_getMe
export type UserTheme = getMe_getMe_theme
