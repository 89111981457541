import { IconButton, MenuItem, Select, TableCell, TableRow, Tooltip, useTheme } from "@material-ui/core"
import OpenInNew from "@material-ui/icons/OpenInNew"
import { fade } from '@material-ui/core/styles/colorManipulator';
import moment from "moment"
import React, { FC, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { ContainerWarningStatus } from "../../../../api/graphql/graphql-global-types"
import { ContainerWarning } from "../../../../api/graphql/queries/get-current-container-warnings"
import { replaceUriParameter, PATH } from "../../../../router/router"
import { ContainerWarningsContext } from "../../../../context/container-warnings-context"

interface ContainerWarningsRowProps {
  warning: ContainerWarning
}

const SELECTABLE_CONTAINER_WARNING_STATUSES = [ContainerWarningStatus.OPEN, ContainerWarningStatus.PROGRESS] as const

export const ContainerWarningsRow: FC<ContainerWarningsRowProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme();

  const { loading, update, updateLoading } = useContext(ContainerWarningsContext)

  const { warning } = props
  const { id, container, warning_status, warning_type, since } = warning
  const { collection_point } = container

  const message = t(`container_warnings.message.${warning_type}`, { date: moment(since).format(t("date_format")) })

  return (
    <TableRow style={{
      backgroundColor: warning.warning_status === ContainerWarningStatus.OPEN
        ? theme.palette.background.default
        : warning.warning_status === ContainerWarningStatus.PROGRESS ? fade(theme.palette.primary.main, 0.25) : "" ,
    }}>
      <TableCell>{container.number ?? "-"}</TableCell>
      <TableCell>{container.sensor_id ?? "-"}</TableCell>
      <TableCell>{container.material?.name ?? "-"}</TableCell>
      <TableCell>{collection_point?.description ?? "-"}</TableCell>
      <TableCell>
        {`${collection_point?.street ?? "-"}, ${collection_point?.postal ?? "-"} ${
          collection_point?.cadastral_name ?? "-"
        }`}
      </TableCell>
      <TableCell>{message}</TableCell>
      <TableCell style={{ textAlign: "center" }}>
        <Select
          id={`${id}-container-warning-status`}
          value={warning_status}
          disabled={loading || updateLoading}
          onChange={(event) => update(id, event.target.value as ContainerWarningStatus)}
        >
          {SELECTABLE_CONTAINER_WARNING_STATUSES.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`container_warnings.status.${item}`).toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <Tooltip title={t("container_warnings.table.collection_point_tooltip") as string}>
          <IconButton
            onClick={() => {
              const url = replaceUriParameter(PATH.COLLECTIONPOINT.route, "collectionPointID", collection_point.id)
              history.push(url)
            }}
          >
            <OpenInNew color="primary" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
