import React, { FunctionComponent, Fragment, useState, useEffect } from "react"
import {
  Theme,
  makeStyles,
  Card,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { Cancel, SwapHorizontalCircle } from "@material-ui/icons"
import { Manufacturer } from "../../../../api/graphql/queries/get-manufacturers"
import lodash from "lodash"
import { Material } from "../../../../api/graphql/queries/get-materials"
import { ContainerType } from "../../../../api/graphql/queries/get-container-types"
import InputMask from "react-input-mask"
import {
  CREATE_OR_UPDATE_CONTAINER_MUTATION,
  CreateOrUpdateContainerResult,
  CreateOrUpdateContainerVariables,
} from "../../../../api/graphql/mutations/create-or-update-container"
import { useMutation } from "@apollo/client"
import { IEditableContainer } from "./container-administration"
import { ChangeContainerDialog } from "./change-container-dialog"
import { toast } from "react-toastify"
import {
  REMOVE_CONTAINER_MUTATION,
  RemoveContainerResult,
  RemoveContainerVariables,
} from "../../../../api/graphql/mutations/remove-container"
import { SPACING, theme } from "../../../../styles/theme"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { getContainerTypLabel } from "../../../../utils/container-type"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
    height: `calc(100% - 2 * ${theme.spacing(2)}px)`,
  },
  deleteButton: {
    cursor: "pointer",
  },
  changeButton: {
    cursor: "pointer",
  },
  actionButton: {
    width: 130,
  },
  buttonContainer: {
    width: "auto",
  },
  inputLabel: {
    backgroundColor: theme.palette.background.default,
  },
}))

interface IContainerAdministrationCardProps {
  manufacturers: Manufacturer[]
  materials: Material[]
  containerTypes: ContainerType[]
  container: IEditableContainer
  onContainerRemoved: () => void
  onContainerUpdated: (container: IEditableContainer) => void
}

export const ContainerAdministrationCard: FunctionComponent<IContainerAdministrationCardProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { manufacturers, materials, containerTypes, container, onContainerRemoved, onContainerUpdated } = props

  const getMaterialFromExistingContainer = () => materials.find((m) => m.id === container.material_id) || null
  const getContainerTypeFromExistingContainer = () => containerTypes.find((c) => c.id === container.type_id) || null
  const getManufacturerFromExistingContainer = () =>
    manufacturers.find((m) => m.id === container.manufacturer_id) || null

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [changeDialogOpen, setChangeDialogOpen] = useState<boolean>(false)
  const [manufacturer, setManufacturer] = useState<Manufacturer | null>(getManufacturerFromExistingContainer())
  const [material, setMaterial] = useState<Material | null>(getMaterialFromExistingContainer())
  const [containerType, setContainerType] = useState<ContainerType | null>(getContainerTypeFromExistingContainer())
  const [sensorId, setSensorId] = useState<string>(container.sensor_id || "04-C3-E6-80")
  const [number, setNumber] = useState<string>(container.number || "")
  const [emptyingRequested, setEmptyingRequested] = useState<boolean>(container.emptying_requested)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const [createOrUpdateContainer] = useMutation<CreateOrUpdateContainerResult, CreateOrUpdateContainerVariables>(
    CREATE_OR_UPDATE_CONTAINER_MUTATION,
  )
  const [removeContainer] = useMutation<RemoveContainerResult, RemoveContainerVariables>(REMOVE_CONTAINER_MUTATION)

  useEffect(() => {
    updateStateFromContainer()
    setIsDirty(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const updateStateFromContainer = () => {
    setManufacturer(getManufacturerFromExistingContainer())
    setMaterial(getMaterialFromExistingContainer())
    setContainerType(getContainerTypeFromExistingContainer())
    setSensorId(container.sensor_id || "")
    setNumber(container.number || "")
    setEmptyingRequested(container.emptying_requested)
    setIsDirty(false)
  }

  const getManufacturerLabel = (manufacturer: Manufacturer): string => {
    return `${manufacturer.name} - ${manufacturer.construction_type}`
  }

  const onSaveClicked = async () => {
    if (lodash.isNil(manufacturer) || lodash.isNil(material) || lodash.isNil(containerType)) {
      toast.error(t("collection_point_administration.errors.required"))
      return
    }

    const result = await createOrUpdateContainer({
      variables: {
        collection_point_id: String(selectedCollectionPoint!.id),
        container: {
          id: container.id || null,
          manufacturer_id: manufacturer ? Number(manufacturer.id) : null,
          material_id: Number(material!.id),
          number: number || null,
          sensor_id: sensorId || null,
          type_id: containerType ? Number(containerType.id) : null,
          emptying_requested: emptyingRequested,
        },
      },
    })

    const resultContainer = result.data && result.data.createOrUpdateContainer
    if (resultContainer) {
      if (container.id) {
        toast.info(t("collection_point_administration.container_updated"))
      } else {
        toast.info(t("collection_point_administration.container_created"))
      }
      onContainerUpdated({
        id: resultContainer.id,
        number: resultContainer.number,
        sensor_id: resultContainer.sensor_id,
        manufacturer_id: resultContainer.manufacturer!.id,
        material_id: resultContainer.material!.id,
        type_id: resultContainer.type!.id,
        emptying_requested: !!resultContainer.emptying_requested_at,
      })
    } else {
      if (container.id) {
        toast.error(t("collection_point_administration.could_not_update_container"))
      } else {
        toast.error(t("collection_point_administration.could_not_create_container"))
      }
    }
  }

  const onCancelClicked = async () => {
    if (container.id) {
      updateStateFromContainer()
    } else {
      onContainerRemoved()
    }
  }

  const onDeleteConfirmed = async () => {
    const result = await removeContainer({
      variables: {
        id: container.id as string,
      },
    })
    if (result.data) {
      toast.info(t("collection_point_administration.container_deleted"))
      onContainerRemoved()
    } else {
      toast.error(t("collection_point_administration.could_not_delete_container"))
    }
    setDeleteDialogOpen(false)
  }

  return (
    <Fragment>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        content={t("collection_point_administration.delete_dialog_text_container")}
        onDelete={onDeleteConfirmed}
      />
      <ChangeContainerDialog
        open={changeDialogOpen}
        onClose={() => setChangeDialogOpen(false)}
        container={container}
        manufacturer={
          !lodash.isNil(getManufacturerFromExistingContainer())
            ? getManufacturerLabel(getManufacturerFromExistingContainer() as Manufacturer)
            : ""
        }
        material={getMaterialFromExistingContainer()?.name || ""}
        containerType={
          !lodash.isNil(getContainerTypeFromExistingContainer())
            ? getContainerTypLabel(getContainerTypeFromExistingContainer() as ContainerType, material?.id)
            : ""
        }
        onContainerChanged={() => {
          setChangeDialogOpen(false)
          onContainerUpdated(container)
        }}
      />
      <Card className={classes.card}>
        <Grid container direction="column" spacing={2}>
          {container.id && (
            <Grid container item direction="row">
              <Grid item container direction="row" justify="flex-end" spacing={2}>
                <Grid item container direction="row" justify="flex-end" spacing={1} className={classes.buttonContainer}>
                  <Grid item className={classes.deleteButton} onClick={() => setChangeDialogOpen(true)}>
                    <Typography>{t("collection_point_administration.change_container")}</Typography>
                  </Grid>
                  <Grid item className={classes.changeButton} onClick={() => setChangeDialogOpen(true)}>
                    <SwapHorizontalCircle color="primary" width={24} height={24} />
                  </Grid>
                </Grid>
                <Grid item container direction="row" justify="flex-end" spacing={1} className={classes.buttonContainer}>
                  <Grid item className={classes.deleteButton} onClick={() => setDeleteDialogOpen(true)}>
                    <Typography>{t("collection_point_administration.delete_container")}</Typography>
                  </Grid>
                  <Grid item className={classes.deleteButton} onClick={() => setDeleteDialogOpen(true)}>
                    <Cancel color="error" width={24} height={24} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="manufacturer-label" className={classes.inputLabel}>
                  {t("collection_point_administration.data.manufacturer")}
                </InputLabel>
                <Select
                  labelId="manufacturer-label"
                  fullWidth
                  onChange={(event) => {
                    setManufacturer(manufacturers.find((m) => m.id === event.target.value) || null)
                    setIsDirty(true)
                  }}
                  value={lodash.get(manufacturer, "id", null)}
                >
                  {manufacturers.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {getManufacturerLabel(m)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="**-**-**-**-**-**"
                maskChar=" "
                onChange={(ev) => {
                  setSensorId(ev.target.value)
                  setIsDirty(true)
                }}
                value={sensorId}
              >
                {() => (
                  <TextField
                    label={t("collection_point_administration.data.sensor_id")}
                    type="search"
                    fullWidth
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="material-label" className={classes.inputLabel}>
                  {t("collection_point_administration.data.material")}
                </InputLabel>
                <Select
                  labelId="material-label"
                  fullWidth
                  onChange={(event) => {
                    setMaterial(materials.find((m) => m.id === event.target.value) || null)
                    setIsDirty(true)
                  }}
                  value={lodash.get(material, "id", null)}
                >
                  {materials.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("collection_point_administration.data.number")}
                type="search"
                fullWidth
                variant="outlined"
                value={number}
                onChange={(ev) => {
                  setNumber(ev.target.value)
                  setIsDirty(true)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="container-type-label" className={classes.inputLabel}>
                  {t("collection_point_administration.data.container_type")}
                </InputLabel>
                <Select
                  labelId="container-type-label"
                  fullWidth
                  onChange={(event) => {
                    setContainerType(containerTypes.find((c) => c.id === event.target.value) || null)
                    setIsDirty(true)
                  }}
                  value={lodash.get(containerType, "id", null)}
                >
                  {containerTypes.map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {getContainerTypLabel(c, material?.id)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container xs={12} justify="flex-end">
              <FormControlLabel
                control={
                  <Checkbox
                    id="emptying-requested"
                    onChange={(event: any, value: any) => {
                      setEmptyingRequested(value)
                      setIsDirty(true)
                    }}
                    checked={emptyingRequested}
                    style={{
                      color: theme.PRIMARY_COLOR.light,
                      padding: `0 ${SPACING}px`,
                    }}
                  />
                }
                label={t("collection_point_administration.data.request_emptying")}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          {((container.id && isDirty) || !container.id) && (
            <Grid item container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Button
                  className={classes.actionButton}
                  type="button"
                  variant="contained"
                  onClick={() => onCancelClicked()}
                  fullWidth
                >
                  {t("collection_point_administration.data.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.actionButton}
                  type="button"
                  variant="contained"
                  onClick={onSaveClicked}
                  fullWidth
                  color="primary"
                >
                  {lodash.isNil(container.id)
                    ? t("collection_point_administration.data.create")
                    : t("collection_point_administration.data.save")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </Fragment>
  )
}
