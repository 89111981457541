import React, { useState, useContext, Fragment, useEffect } from "react"
import { Switch, Redirect, RouteComponentProps } from "react-router"
import "../../../styles/css/react-table-style.css"
import { Sidebar } from "../../partials/sidebar/sidebar"
import { Grid, Theme } from "@material-ui/core"
import { makeStyles, ThemeProvider } from "@material-ui/styles"

import { AuthRoute } from "../../partials/wrapper/auth.route"
import { PATH } from "../../../router/router"
import { DRAWER_WIDTH_OPEN, getTheme } from "../../../styles/theme"
import { UserService } from "../../../services/user-service"
import { UserRole } from "../../../api/graphql/graphql-global-types"
import { UserContext } from "../../../context/user-context"
import { useHistory } from "react-router-dom"
import { isPortalUpdateAvailable, getRemotePortalVersion, updatePortal } from "../../../utils/portal-update"
import { Button, Snackbar } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { DownloadManager } from "../../partials/download-manager/download-manager"
import { DownloadManagerContextProvider } from "../../partials/download-manager/download-manager-context"

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  marginLeftDrawerOpen: {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
}))

const CHECK_PORTAL_VERSION_POLL_INTERVAL = 60 * 1000

interface IPortalProps extends RouteComponentProps {}

export const Portal: React.FunctionComponent<IPortalProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isUpdateAvailable, setIsUpdateAvailable] = useState<boolean>(false)
  const { user, isUserLoading } = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    const interval = setInterval(async () => {
      setIsUpdateAvailable(await isPortalUpdateAvailable())
    }, CHECK_PORTAL_VERSION_POLL_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  if (!user && UserService.isUserLoggedIn()) {
    return <Fragment />
  }

  if (!user && !isUserLoading) {
    history.push("/login")
    return <Fragment />
  }

  const userTheme = getTheme(user!.theme)

  return (
    <ThemeProvider theme={userTheme}>
      <DownloadManagerContextProvider>
        <DownloadManager />
        <Grid container direction={"column"}>
          <Grid item>
            <Sidebar />
          </Grid>
          <Grid item className={`${classes.content} ${classes.marginLeftDrawerOpen}`}>
            <Switch>
              {UserService.getAvailablePaths().map((key, index) => (
                <AuthRoute
                  key={index}
                  path={PATH[key].route}
                  component={PATH[key].component}
                  redirectTo={PATH[key].redirectTo}
                />
              ))}
              <Redirect
                to={
                  UserService.getRole() === UserRole.GUIDANCE_ADMIN
                    ? PATH.ROUTES_OVERVIEW.route
                    : PATH.COLLECTIONPOINTS.route
                }
              />
            </Switch>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          color="primary"
          open={isUpdateAvailable}
          message={<Trans i18nKey="portal.update_available" values={{ version: getRemotePortalVersion() }} />}
          action={
            <React.Fragment>
              <Button color="primary" size="small" onClick={() => updatePortal()}>
                <strong>{t("portal.update")}</strong>
              </Button>
            </React.Fragment>
          }
        />
      </DownloadManagerContextProvider>
    </ThemeProvider>
  )
}
