import React, { FunctionComponent, ReactNode } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Theme
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: "#f44336"
  }
}))

interface IDeleteDialogProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
  content: ReactNode
}

export const DeleteDialog: FunctionComponent<IDeleteDialogProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { open, onClose, onDelete, content } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t("delete_dialog.no")}
        </Button>
        <Button onClick={onDelete} className={classes.deleteButton}>
          {t("delete_dialog.agree_on_delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
