import { gql } from "@apollo/client"
import {
  createCollectionPartner,
  createCollectionPartnerVariables,
  createCollectionPartner_createCollectionPartner,
} from "./types/createCollectionPartner"

export const CREATE_COLLECTION_PARTNER_MUTATION = gql`
  mutation createCollectionPartner(
    $name: String!
    $town: String!
    $postal: String!
    $street: String!
    $contact_name: String!
    $phone_number: String!
    $email: String!
    $districtIds: [ID!]!
    $automatic_break: Int!
    $automatic_break_reminder: Int!
  ) {
    createCollectionPartner(
      name: $name
      town: $town
      postal: $postal
      street: $street
      contact_name: $contact_name
      phone_number: $phone_number
      email: $email
      districtIds: $districtIds
      automatic_break: $automatic_break
      automatic_break_reminder: $automatic_break_reminder
    ) {
      id
    }
  }
`

export type CreateCollectionPartnerResult = createCollectionPartner
export type CreateCollectionPartner = createCollectionPartner_createCollectionPartner
export type CreateCollectionPartnerVariables = createCollectionPartnerVariables
