import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react"
import { Grid, CircularProgress, IconButton, Tooltip, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Check, Close, GetApp, ScheduleSharp, Warning } from "@material-ui/icons"
import { FileExportStatus } from "../../../api/graphql/graphql-global-types"
import { DownloadManagerContext, IFileExportItem } from "./download-manager-context"

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: theme.typography.body2.fontSize,
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
}))

interface IDownloadItemProps {
  fileExport: IFileExportItem
}

const REMOVE_AFTER_MILLIS = 15000

export const DownloadItem: FunctionComponent<IDownloadItemProps> = (props) => {
  const classes = useStyles()
  const { fileExport } = props
  const { t } = useTranslation()
  const { removeCompletedExport, cancelSingleFileExport } = useContext(DownloadManagerContext)

  useEffect(() => {
    if (fileExport.completedAt) {
      const timeout = REMOVE_AFTER_MILLIS - (new Date().getTime() - fileExport.completedAt.getTime())

      const timer = setTimeout(() => {
        removeCompletedExport(fileExport.id)
      }, timeout)
      return () => clearTimeout(timer)
    }
  }, [fileExport, removeCompletedExport])

  const onCancelClicked = useCallback(() => {
    if (fileExport.status !== FileExportStatus.DOWNLOADED) {
      cancelSingleFileExport(fileExport.id)
    } else {
      removeCompletedExport(fileExport.id)
    }
  }, [cancelSingleFileExport, fileExport, removeCompletedExport])

  const Icon = useMemo(() => {
    switch (fileExport.status) {
      case FileExportStatus.REQUESTED: {
        return <ScheduleSharp className={classes.icon} fontSize="small" />
      }
      case FileExportStatus.PROCESSING:
      case FileExportStatus.SUCCESS: {
        return <CircularProgress className={classes.icon} size={20} />
      }
      case FileExportStatus.ERROR: {
        return <Warning className={classes.icon} fontSize="small" />
      }
      case FileExportStatus.DOWNLOADED: {
        return <Check className={classes.icon} fontSize="small" />
      }
      default: {
        return null
      }
    }
  }, [fileExport.status, classes.icon])

  const tooltipText = useMemo(() => {
    switch (fileExport.status) {
      case FileExportStatus.REQUESTED:
        return "queued"
      case FileExportStatus.PROCESSING:
      case FileExportStatus.SUCCESS:
        return "processing"
      case FileExportStatus.ERROR:
        return "error"
      case FileExportStatus.DOWNLOADED:
        return "finished"
    }
  }, [fileExport.status])

  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      <Grid item>
        <GetApp className={classes.icon} fontSize="small" />
      </Grid>
      <Grid item className={classes.text} xs>
        {fileExport.filename}
      </Grid>
      {Icon && (
        <Grid item>
          <Tooltip title={t(`export.download_item.${tooltipText}`)} placement="top" arrow>
            {Icon}
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <IconButton size="small" onClick={onCancelClicked}>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  )
}
