import { gql } from "@apollo/client"
import {
  getInitialImagesWithCollectionPointId,
  getInitialImagesWithCollectionPointIdVariables,
  getInitialImagesWithCollectionPointId_getInitialImagesWithCollectionPointId,
  getInitialImagesWithCollectionPointId_getInitialImagesWithCollectionPointId_image_type
} from "./types/getInitialImagesWithCollectionPointId"

export const GETINITIALIMAGESWITHCOLLECTIONPOINTIDTYPE = gql`
  query getInitialImagesWithCollectionPointId($collection_point_id: ID!) {
    getInitialImagesWithCollectionPointId(
      collection_point_id: $collection_point_id
    ) {
      id
      inserted_at
      url
      type
      subtype
      alternative_subtype
      image_type {
        id
        name
      }
      sub_image_type {
        id
        name
      }
    }
  }
`

export type Image = getInitialImagesWithCollectionPointId_getInitialImagesWithCollectionPointId
export type ImageResult = getInitialImagesWithCollectionPointId
export type ImageVariables = getInitialImagesWithCollectionPointIdVariables
export type ImageType = getInitialImagesWithCollectionPointId_getInitialImagesWithCollectionPointId_image_type
