import { IVehicleEntry } from "../components/pages/tour-generation/partials/tour-generation-vehicle-selection"
import { AlgorithmType } from "../api/graphql/graphql-global-types"
import { Material } from "../api/graphql/queries/get-materials"

export const isVehicleActive = (vehicle: IVehicleEntry, version: AlgorithmType): boolean =>
  vehicle.isActive && (version === AlgorithmType.TO2TRAILER ? vehicle.trailer : !vehicle.trailer)

export const doesVehicleContainMaterial = (vehicle: IVehicleEntry, materials: Material[]): boolean =>
  materials.length > 0 &&
  materials.every((material) => {
    const matches = vehicle.materials.filter((vehicleMaterial) => vehicleMaterial.material.id === material.id)
    // normal and trailer chambers must have a capacity > 0
    return matches.length > 0 && matches.every((match) => match.amount > 0)
  })
