import React, { FunctionComponent, useMemo, useCallback } from "react"
import { Theme, makeStyles, Grid, CircularProgress, IconButton } from "@material-ui/core"
import { CustomMarkerTooltipProperty, ICustomMarkerTooltipPropertyProps } from "./custom-marker-tooltip-property"
import { BASE_PATH, PATH } from "../../../../router/router"
import {
  CollectionPointResult,
  GETCOLLECTIONPOINTWITHID_QUERY,
} from "../../../../api/graphql/queries/get-collection-points-with-id"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { Link } from "react-router-dom"

import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  infoWindow: {
    height: 200,
    width: 360,
    fontSize: 10,
  },
}))

interface ICustomMarkerTooltipProps {
  collectionPointId: string | number | undefined
  isOpen: boolean
  referenceDate: Date | undefined
}

export const CustomMarkerTooltip: FunctionComponent<ICustomMarkerTooltipProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { collectionPointId, isOpen, referenceDate } = props

  const { loading: loadingStats, data: collectionPointData } = useQuery<CollectionPointResult>(
    GETCOLLECTIONPOINTWITHID_QUERY,
    {
      variables: {
        id: Number(collectionPointId),
        date: referenceDate,
      },
      skip: !isOpen,
    },
  )

  const collectionPoint = useMemo(() => collectionPointData?.getCollectionPointWithID, [collectionPointData])

  const formatDate = useCallback(
    (date) => {
      return date ? moment(date).format(t("date_format")) : "-"
    },
    [t],
  )

  const propertiesToShow = useMemo(() => {
    if (!collectionPoint) return []
    const properties: ICustomMarkerTooltipPropertyProps[] = [
      {
        name: t("collection_points.table.id").toUpperCase(),
        content: collectionPoint.id,
      },
      {
        name: t("collection_points.table.description"),
        content: collectionPoint.description,
      },
      {
        name: t("collection_points.cards.next_emptying"),
        content: formatDate(collectionPoint.next_emptying),
      },
      {
        name: t("collection_points.cards.last_emptying"),
        content: formatDate(collectionPoint.last_emptying),
      },
      ...collectionPoint.collectionPointFilllevels.map((entry) => ({
        name: `${entry.material.name} (${entry.count_containers})`,
        content: `${t("collection_points.table.fill_level")} ${entry.filllevel.toFixed()}%`,
      })),
    ]
    return properties
  }, [collectionPoint, t, formatDate])

  return (
    <Grid container className={classes.infoWindow}>
      {loadingStats && <CircularProgress />}
      {!loadingStats && collectionPointData && (
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid item container xs>
            {propertiesToShow.map((property) => (
              <CustomMarkerTooltipProperty {...property} />
            ))}
          </Grid>
          <Grid item>
            <Link to={`${BASE_PATH}/${PATH.COLLECTIONPOINT.id}/${collectionPointId}`}>
              <IconButton>
                <OpenInNewIcon />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
