import { gql } from "@apollo/client"
import { disposalTrailerLocationsByDistrict, disposalTrailerLocationsByDistrict_disposalTrailerLocationsByDistrict } from "./types/disposalTrailerLocationsByDistrict"

export const DISPOSALTRAILERLOCATIONS_QUERY = gql`
  query disposalTrailerLocationsByDistrict($district_id: ID!) {
   disposalTrailerLocationsByDistrict(district_id: $district_id){
      id
      name
      latitude
      longitude
      district_id
    }
  }
`

export type DisposalTrailerLocationsResult = disposalTrailerLocationsByDistrict
export type DisposalTrailerLocation = disposalTrailerLocationsByDistrict_disposalTrailerLocationsByDistrict
