/*
    Hook which provides all AssociationFilter functionalities
*/

import React, { useState, useContext, createContext, useCallback, useMemo, useEffect } from "react"
import store from "store"
import { STORE_KEYS } from "../services/user-service"
import { ApolloError, useQuery } from "@apollo/client"
import { GETASSOCIATIONS_QUERY, AssociationsResult, Association } from "../api/graphql/queries/get-associations"
import { UserContext } from "./user-context"

interface IAssociationFilterContextType {
  selectedAssociation: Association | undefined
  setAssociationId: (id: string) => void
  associations: Association[]
  associationsLoading: boolean
  associationError: ApolloError | undefined
}

export const AssociationFilterContext = createContext<IAssociationFilterContextType>(
  {} as IAssociationFilterContextType,
)

export const AssociationFilterProvider = (props: any) => {
  const auth = useAssociationFilterProvider()

  return <AssociationFilterContext.Provider value={auth}>{props.children}</AssociationFilterContext.Provider>
}

export const useAssociationFilter = () => {
  return useContext(AssociationFilterContext)
}

const useAssociationFilterProvider = (): IAssociationFilterContextType => {
  const [selectedAssociation, setSelectedAssociation] = useState<Association | undefined>(undefined)

  const { user } = useContext(UserContext)

  const { data: associationsData, loading: associationsLoading, error: associationError } = useQuery<
    AssociationsResult
  >(GETASSOCIATIONS_QUERY, { skip: !user })

  const associations = useMemo(() => associationsData?.getAssociations || [], [associationsData])

  const setAssociationId = useCallback(
    (id: string) => {
      setSelectedAssociation(associations.find((a) => a.id === id))
      store.set(STORE_KEYS.ASSOCIATION, id)
    },
    [associations],
  )

  useEffect(() => {
    if (!selectedAssociation && associations.length) {
      const associationIdFromStore = store.get(STORE_KEYS.ASSOCIATION) as string
      const association = associations.find((association) => association.id.toString() === associationIdFromStore)
      setAssociationId(association?.id || associations[0].id)
    }
  }, [selectedAssociation, associations, setAssociationId])

  return {
    selectedAssociation,
    setAssociationId,
    associations,
    associationsLoading,
    associationError,
  }
}
