import React, { FunctionComponent, useState, useEffect, Fragment } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IEditableContainer } from "./container-administration"
import InputMask from "react-input-mask"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { getFormattedSensorId } from "../../../../utils/sensorId"
import {
  CHANGE_CONTAINERS_MUTATION,
  ChangeContainersResult,
  ChangeContainersVariables,
} from "../../../../api/graphql/mutations/change-containers"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  confirmDialogContent: {
    width: 500,
  },
}))

interface IChangeContainerDialogProps {
  open: boolean
  onClose: () => void
  container: IEditableContainer
  manufacturer: string
  material: string
  containerType: string
  onContainerChanged: () => void
}

export const ChangeContainerDialog: FunctionComponent<IChangeContainerDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, container, manufacturer, material, containerType, onContainerChanged } = props

  const [sensorId, setSensorId] = useState<string>(container.sensor_id || "")
  const [number, setNumber] = useState<string>(container.number || "")
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const [changeContainersMutation] = useMutation<ChangeContainersResult, ChangeContainersVariables>(
    CHANGE_CONTAINERS_MUTATION,
  )

  useEffect(() => {
    setSensorId(container.sensor_id || "")
    setNumber(container.number || "")
  }, [container])

  const isDirty =
    getFormattedSensorId(container.sensor_id) !== getFormattedSensorId(sensorId) || container.number !== number

  const changeContainer = async () => {
    setConfirmOpen(false)
    const result = await changeContainersMutation({
      variables: {
        containers: [{ id: container.id, number: number, sensor_id: sensorId }],
      },
    })
    if (result.data && result.data.changeContainers.error === "false") {
      toast.info(t("collection_point_administration.container_changed"))
      onContainerChanged()
    } else {
      setConfirmOpen(false)
      if (result.data?.changeContainers.message) {
        toast.error(t(`api_errors.${result.data.changeContainers.message}`))
      } else {
        toast.error(t("collection_point_administration.could_not_change_container"))
      }
    }
  }

  return (
    <Fragment>
      <ConfirmDialog
        confirmText={t("collection_point_administration.confirm_change")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => changeContainer()}
        open={confirmOpen}
        heading={t("collection_point_administration.confirm_change_heading")}
      >
        <Grid container direction="column" className={classes.confirmDialogContent}>
          <Grid item container direction="row">
            <Grid container item xs={3} direction="column">
              <Grid item>
                <Typography>{t("collection_point_administration.data.sensor_id")}</Typography>
              </Grid>
              <Grid container item>
                <Typography>{t("collection_point_administration.data.number")}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={9} container direction="column">
              <Grid item>
                <Typography>
                  {getFormattedSensorId(container.sensor_id)} {"--> "}
                  <b>{getFormattedSensorId(sensorId)}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {container.number} {"-->"} <b>{number}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConfirmDialog>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("collection_point_administration.change_container")}</DialogTitle>
        <DialogContent>
          <Grid container item direction="row" spacing={1}>
            <Grid item xs={12}>
              <TextField
                label={t("collection_point_administration.data.manufacturer")}
                type="search"
                fullWidth
                variant="outlined"
                value={manufacturer}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="**-**-**-**-**-**"
                maskChar=" "
                onChange={(ev) => setSensorId(ev.target.value)}
                value={sensorId}
              >
                {() => (
                  <TextField
                    label={t("collection_point_administration.data.sensor_id")}
                    type="search"
                    fullWidth
                    variant="outlined"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("collection_point_administration.data.material")}
                type="search"
                fullWidth
                variant="outlined"
                value={material}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("collection_point_administration.data.number")}
                type="search"
                fullWidth
                variant="outlined"
                value={number}
                onChange={(ev) => {
                  setNumber(ev.target.value)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("collection_point_administration.data.container_type")}
                type="search"
                fullWidth
                variant="outlined"
                value={containerType}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            {t("collection_point_administration.data.cancel")}
          </Button>
          <Button onClick={() => setConfirmOpen(true)} color="primary" variant="contained" disabled={!isDirty}>
            {t("collection_point_administration.change")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
