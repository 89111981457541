import React, { useState, useContext, createContext } from "react"
import { MinimalTour } from "../api/graphql/queries/get-minimal-tours-with-region-id"

interface ISelectedTourContextType {
  selectedTour: MinimalTour | undefined
  setSelectedTour: (tour: MinimalTour | undefined) => void
  tours: MinimalTour[] | undefined
  setTours: (tours: MinimalTour[] | undefined) => void
}

export const SelectedTourContext = createContext<ISelectedTourContextType>(
  {} as ISelectedTourContextType
)

export const SelectedTourProvider = (props: any) => {
  const value = useSelectedTourProvider()

  return (
    <SelectedTourContext.Provider value={value}>
      {props.children}
    </SelectedTourContext.Provider>
  )
}

export const useSelectedTour = () => {
  return useContext(SelectedTourContext)
}

const useSelectedTourProvider = (): ISelectedTourContextType => {
  const [selectedTour, setSelectedTour] = useState<MinimalTour | undefined>(
    undefined
  )

  const [tours, setTours] = useState<MinimalTour[] | undefined>(undefined)

  return {
    selectedTour,
    setSelectedTour,
    tours,
    setTours
  }
}
