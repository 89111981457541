import React, { FunctionComponent, useContext } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { useTranslation } from "react-i18next"
import { SPACING, theme } from "../../../../../styles/theme"
import { useCollectionPointsFilter } from "../../../../../context/CollectionPointsFilterContext"
import { CollectionPointsExportContext } from "./collection-points-export-context"

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

interface ICollectionPointsExportDialogProps {}

export const CollectionPointsExportDialog: FunctionComponent<ICollectionPointsExportDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { isFilterSet } = useCollectionPointsFilter()

  const {
    setDialogOpen,
    dialogOpen,
    exportLoading,
    onExportConfirmed,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetValues,
    forecastEnabled,
    useFilter,
    setUseFilter,
  } = useContext(CollectionPointsExportContext)

  const handleClose = () => {
    resetValues()
    setDialogOpen(false)
  }

  return (
    <Dialog open={dialogOpen} onClose={handleClose} className={classes.dialog}>
      <DialogTitle>{t("collection_points.export_dialog.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              label={t("date_picker.from")}
              format={t("date_format")}
              value={startDate}
              fullWidth
              onChange={(dateTime) => {
                if (dateTime) {
                  setStartDate(dateTime.utc(true).startOf("day"))
                }
              }}
              maxDate={endDate}
              cancelLabel={t("date_picker.cancel")}
              okLabel={t("date_picker.ok")}
              clearLabel={t("date_picker.clear")}
              disabled={exportLoading}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              margin="normal"
              label={t("date_picker.to")}
              format={t("date_format")}
              value={endDate}
              fullWidth
              onChange={(dateTime) => {
                if (dateTime) {
                  setEndDate(dateTime.utc(true).endOf("day"))
                }
              }}
              minDate={startDate}
              cancelLabel={t("date_picker.cancel")}
              okLabel={t("date_picker.ok")}
              clearLabel={t("date_picker.clear")}
              disabled={exportLoading || !forecastEnabled}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs={12} spacing={2} justify="space-between">
          <Grid item>
            {isFilterSet && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useFilter}
                    onChange={(event) => setUseFilter(event.target.checked)}
                    name="use-filter"
                    color="primary"
                    style={{
                      color: theme.PRIMARY_COLOR.light,
                      padding: `0 ${SPACING}px`,
                    }}
                  />
                }
                label={t("collection_points.export_dialog.filter")}
              />
            )}
          </Grid>
          <Grid item>
            <Button onClick={handleClose} color="primary" disabled={exportLoading}>
              {t("cancel")}
            </Button>
            <Button
              onClick={() => onExportConfirmed(startDate, endDate, useFilter)}
              color="primary"
              autoFocus
              disabled={!startDate.isValid() || !endDate.isValid() || startDate.isAfter(endDate) || exportLoading}
            >
              {t("collection_points.export_dialog.export")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
