import { gql } from "@apollo/client"
import { cancelScheduledTourGeneration, cancelScheduledTourGenerationVariables } from "./types/cancelScheduledTourGeneration"

export const CANCEL_SCHEDULED_TOUR_GENERATION_MUTATION = gql`
  mutation cancelScheduledTourGeneration($tour_id: ID!) {
    cancelScheduledTourGeneration(tour_id: $tour_id) {
      error
      message
    }
  }
`
export type CancelScheduledTourGeneration = cancelScheduledTourGeneration
export type CancelScheduledTourGenerationVariables = cancelScheduledTourGenerationVariables
