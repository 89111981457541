import React, { FunctionComponent } from "react"
import { theme } from "../../../../styles/theme"

interface ICustomIconProps {
  size?: number
  name: string
  color?: string
}

// make sure  the id: "root" is assigned to the svg tag of the file
export const CustomIcon: FunctionComponent<ICustomIconProps> = (props) => {
  const { name, size = 20, color = theme.PRIMARY_COLOR.main } = props
  return (
    <svg fill={color} width={size} height={size}>
      <use xlinkHref={`/icons/${name}.svg#root`} />
    </svg>
  )
}
