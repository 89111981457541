import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlProps,
} from "@material-ui/core"
import { SelectPair } from "./select-pair"

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
  },
}))

interface IOutlinedSelectProps extends FormControlProps {
  onValueChange: (value: string) => void
  value: string
  options: SelectPair[]
  name?: string
}

export const OutlinedSelect: FunctionComponent<IOutlinedSelectProps> = (props) => {
  const classes = useStyles()
  const { onValueChange, options, name, value, ...rest } = props

  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  const handleChangeEvent = (event: React.ChangeEvent<{ value: unknown }>) => {
    onValueChange(event.target.value as string)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} {...rest}>
      {name && (
        <InputLabel ref={inputLabel} htmlFor="outlined-select">
          {name}
        </InputLabel>
      )}
      <Select
        value={value}
        onChange={handleChangeEvent}
        inputProps={{
          name,
          id: "outlined-select",
        }}
        input={<OutlinedInput labelWidth={labelWidth} name="outlined-input" id="outlined-input" />}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
