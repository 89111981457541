import React, { FunctionComponent, useRef, MutableRefObject } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Theme, makeStyles, Card, Grid } from "@material-ui/core"

import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { UserService } from "../../../../services/user-service"
import moment from "moment"
import {
  GET_AVERAGE_EMPTYINGS_PER_HOUR_FOR_DISTRICT_QUERY,
  AverageEmptyingsPerHourForDistrictResult,
  AverageEmptyingsPerHourForDistrictVariables,
} from "../../../../api/graphql/queries/average-emptyings-per-hour-for-district"
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
  BarChart,
  Bar,
  Tooltip,
} from "recharts"
import { theme, SPACING } from "../../../../styles/theme"
import { Text } from "../../../partials/wrapper/text"
import { Skeleton } from "@material-ui/lab"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
    margin: "auto",
  },
}))

interface IEmptyingsPerHourChartProps {}

export const EmptyingsPerHourChart: FunctionComponent<IEmptyingsPerHourChartProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const ref = useRef(null)

  const { data, loading } = useQuery<
    AverageEmptyingsPerHourForDistrictResult,
    AverageEmptyingsPerHourForDistrictVariables
  >(GET_AVERAGE_EMPTYINGS_PER_HOUR_FOR_DISTRICT_QUERY, {
    variables: {
      id: (UserService.hasAssociationFilter() ? selectedAssociation?.id : selectedDistrict?.id) || "",
      startDate: moment().startOf("month").subtract(12, "months"),
      endDate: moment().endOf("month").subtract(1, "month"),
    },
    skip:
      (UserService.hasAssociationFilter() && !selectedAssociation) ||
      (!UserService.hasAssociationFilter() && !selectedDistrict),
  })

  const getData = () => {
    if (!data) {
      return []
    }

    return data.averageEmptyingsPerHourForDistrict.emptyingsPerHour.map((entry) => ({
      date: moment(entry.month, "YYYY-MM").locale(UserService.getLanguage()).format("MMM"),
      value: Math.round(entry.emptyings_per_hour * 10) / 10,
    }))
  }

  // defined width for chart in order to have similar bar sizes
  const chartWidth = 140 + getData().length * 55
  const cardWidth = (ref as MutableRefObject<any>).current?.clientWidth - SPACING * 4
  const useCardWidth = chartWidth > cardWidth || loading

  return (
    <Grid item ref={ref} xs={12} md={6}>
      <Card className={classes.card} ref={ref}>
        <Text variant="h6" bold>
          {t("emptyings_per_hour_chart.emptyings_per_hour")}
        </Text>
        {loading ? (
          <Skeleton width="100%" height={300} style={{ transform: "scale(1)" }} />
        ) : (
          <ResponsiveContainer width={lodash.max([useCardWidth ? cardWidth : chartWidth, 300])} height={300}>
            <BarChart
              data={getData()}
              margin={{
                left: 70,
                top: 70,
                right: 70,
                bottom: 70,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />"
              <XAxis dataKey="date" />
              <YAxis tickFormatter={(value) => Math.round(value)}>
                <Label position="top" offset={16}>
                  {t("emptyings_per_hour_chart.average_emptyings_label")}
                </Label>
              </YAxis>
              <Bar dataKey="value" fill={theme.PRIMARY_COLOR.light} />
              <Tooltip
                formatter={(value) => [
                  `${Number(value).toLocaleString(UserService.getLanguage(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })} ${t("emptyings_per_hour_chart.emptyings")}`,
                ]}
                cursor={false}
              />
              <ReferenceLine y={data?.averageEmptyingsPerHourForDistrict.average} strokeWidth={2}>
                <Label position="top" offset={10}>
                  {t("emptyings_per_hour_chart.average_emptyings", {
                    emptyings: data?.averageEmptyingsPerHourForDistrict.average.toLocaleString(
                      UserService.getLanguage(),
                      {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      },
                    ),
                  })}
                </Label>
              </ReferenceLine>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Grid>
  )
}
