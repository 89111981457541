import React, { FunctionComponent } from "react"
import { UserService } from "../../../services/user-service"
import { AssociationSelect } from "./association-select"
import { DistrictSelect } from "./district-select"
import { District } from "../../../api/graphql/queries/get-districts"
import { Association } from "../../../api/graphql/queries/get-associations"

interface IRegionSelectProps {
  filterDistricts?: (entry: District) => boolean
  filterAssociations?: (entry: Association) => boolean
}

export const RegionSelect: FunctionComponent<IRegionSelectProps> = (props) => {
  const { filterDistricts, filterAssociations } = props

  return UserService.hasAssociationFilter() ? (
    <AssociationSelect filterOption={filterAssociations} />
  ) : (
    <DistrictSelect filterOption={filterDistricts} />
  )
}
