import React, { Fragment, FunctionComponent } from "react"
import { CardContent, Grid, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useTourOverview } from "../context/tour-overview-context"
import { UserService } from "../../../../services/user-service"
import { CheckCircle, Warning } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "16px",
    flex: 1,
    padding: "2px",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  subtitle: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  successIcon: {
    color: "#58AF47",
  },
  warningIcon: {
    fill: "#ffcc00",
  },
}))

export const TourDetail: FunctionComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { tourStatsData } = useTourOverview()

  if (!tourStatsData?.getTourStatsWithId?.emptying_stats) {
    return null
  }

  const getIconForDeviation = (amount: number, amountDisposed: number, materialName: string) =>
    Math.abs(amountDisposed - amount) > amountDisposed * 0.25 ? (
      <Tooltip title={t("tour_overview.details.warning")} data-testid={`material_name_${materialName}.on_site_tooltip`}>
        <Warning className={classes.warningIcon} />
      </Tooltip>
    ) : (
      <CheckCircle className={classes.successIcon} data-testid={`material_name_${materialName}.on_site_success_icon`} />
    )

  return (
    <Paper variant="outlined" className={classes.paper}>
      <CardContent>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography variant={"subtitle1"} className={classes.tableHeader}>
              {t("tour_overview.details.on_site")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"subtitle1"} className={classes.tableHeader}>
              {t("tour_overview.details.disposal_merchant")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.subtitle}>{t("total")}</Typography>
          </Grid>
          {tourStatsData.getTourStatsWithId.emptying_stats.collected_amount_stats?.map((stats) => (
            <Fragment key={stats.material_id}>
              <Grid item xs={4}>
                <Typography data-testid={`material_name_${stats.material_name}`}>{stats.material_name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container justify={"center"} spacing={1}>
                  <Grid item>
                    <Typography className={classes.text} data-testid={`material_name_${stats.material_name}.on_site`}>
                      {weightFormatted(stats.amount)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {stats.amountDisposed !== null &&
                      getIconForDeviation(stats.amount, stats.amountDisposed, stats.material_name)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  className={classes.text}
                  data-testid={`material_name_${stats.material_name}.amount_disposed`}
                >
                  {weightFormatted(stats.amountDisposed)}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </CardContent>
    </Paper>
  )
}

const weightFormatted = (weight: number | null) => {
  if (typeof weight !== "number") {
    return "-"
  }
  return (
    (weight / 1000).toLocaleString(UserService.getLanguage(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + " t"
  )
}
