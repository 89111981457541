import React, { FunctionComponent, useState, useEffect } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  TextField,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { LocationType } from "../../helper/get-options-for-role"
import { TownSelection } from "./town-selection"
import { DistrictSelection } from "./district-selection"
import { AssociationSelection } from "./association-selection"
import { GuidedDriverLocationSelection } from "./guided-driver-location-selection"
import { Search } from "@material-ui/icons"
import { useDebounce } from "../../../../../utils/useDebounce"

const useStyles = makeStyles((theme: Theme) => ({
  selection: {
    padding: theme.spacing(1),
    minHeight: 200,
    maxHeight: 700,
    overflowY: "scroll",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.5),
  },
}))

export interface ILocationCollectionItem {
  id: string
  name: string
}

interface ILocationAssignmentDialogProps {
  type: LocationType | null
  open: boolean
  onClose: () => void
  onSave: (locations: ILocationCollectionItem[]) => void
  collection: ILocationCollectionItem[]
  selectedPartnerId?: number | null
}

export const LocationAssignmentDialog: FunctionComponent<ILocationAssignmentDialogProps> = (
  props,
) => {
  const { type, onClose, onSave, collection, open, selectedPartnerId } = props
  const [selectedItems, setSelectedItems] = useState<ILocationCollectionItem[]>([])
  const [searchString, setSearchString] = useState<string>("")
  const debouncedSearchString = useDebounce(searchString, 500)
  const classes = useStyles()
  const { t } = useTranslation()

  const onSaveClicked = () => {
    onSave(selectedItems)
    onClose()
  }

  const onClick = (clickedItem: ILocationCollectionItem) => {
    switch (type) {
      case LocationType.ASSOCIATION:
        updateSelectionSingle(clickedItem)
        break
      case LocationType.DISTRICT:
        updateSelectedMultiple(clickedItem)
        break
      case LocationType.TOWN:
        updateSelectedMultiple(clickedItem)
        break
      case LocationType.LOCATION:
        updateSelectedMultiple(clickedItem)
        break
    }
  }

  const updateSelectionSingle = (clickedItem: ILocationCollectionItem) => {
    if (selectedItems.find((item) => item.id === clickedItem.id)) {
      setSelectedItems([])
    } else {
      setSelectedItems([clickedItem])
    }
  }

  const updateSelectedMultiple = (clickedItem: ILocationCollectionItem) => {
    if (selectedItems.find((item) => item.id === clickedItem.id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== clickedItem.id))
    } else {
      setSelectedItems([...selectedItems, clickedItem])
    }
  }

  const renderSelection = () => {
    switch (type) {
      case LocationType.ASSOCIATION:
        return (
          <AssociationSelection
            selectedItems={selectedItems}
            onClick={onClick}
            searchString={debouncedSearchString}
          />
        )
      case LocationType.DISTRICT:
        return (
          <DistrictSelection
            selectedItems={selectedItems}
            onClick={onClick}
            searchString={debouncedSearchString}
            selectedPartnerId={selectedPartnerId}
          />
        )
      case LocationType.TOWN:
        return (
          <TownSelection
            selectedItems={selectedItems}
            onClick={onClick}
            searchString={debouncedSearchString}
          />
        )
      case LocationType.LOCATION:
        return (
          <GuidedDriverLocationSelection
            selectedItems={selectedItems}
            onClick={onClick}
            searchString={debouncedSearchString}
          />
        )
      default:
        break
    }
  }

  useEffect(() => {
    if (!!type) {
      setSearchString("")
      setSelectedItems(collection)
    }
  }, [type, setSelectedItems, collection, open])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("user_management.data.assign", {
          collection: t(`location_type.${type}`, { count: collection.length }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              placeholder={t("search")}
              fullWidth
              type="search"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
              InputProps={{ startAdornment: <Search color="secondary" /> }}
            />
          </Grid>
          <Grid item className={classes.selection}>
            {renderSelection()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button color="primary" variant="contained" onClick={onSaveClicked}>
          {t("assign")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
