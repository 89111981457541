import React, { FunctionComponent } from "react"
import { DriverLocationProvider } from "../../../context/driver-location-filter-context"
import { RefetchProvider } from "../../../context/refetch-context"
import { RoutesOverview } from "./partials/routes-overview"

interface IRoutesOverviewPageProps {}

export const RoutesOverviewPage: FunctionComponent<IRoutesOverviewPageProps> = (props) => {
  return (
    <RefetchProvider>
      <DriverLocationProvider>
        <RoutesOverview />
      </DriverLocationProvider>
    </RefetchProvider>
  )
}
