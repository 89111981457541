import { getRouteWithId, getRouteWithId_getRouteWithId, getRouteWithId_getRouteWithId_stops, getRouteWithId_getRouteWithId_stops_events, getRouteWithId_getRouteWithId_stops_events_images } from './types/getRouteWithId';
import { gql } from "@apollo/client"

export const GET_ROUTE_WITH_ID = gql`
  query getRouteWithId($id: ID!) {
    getRouteWithId(id: $id) {
      id
      name
      filename
      inserted_at
      url
      location_id
      stops {
        id
        sequence_number
        latitude
        longitude
        address
        text
        distance
        heading
        events {
          id
          info
          event_type
          images {
            id
            url
            inserted_at
          }
        }
      }
    }
  }
`

export type RouteResult = getRouteWithId
export type Route = getRouteWithId_getRouteWithId
export type RouteStop = getRouteWithId_getRouteWithId_stops
export type RouteStopEventImage = getRouteWithId_getRouteWithId_stops_events_images
export type RouteStopEvent = getRouteWithId_getRouteWithId_stops_events