/*
    Hook which provides all DistrictFilter functionalities
*/
import { ApolloError, useQuery } from "@apollo/client"
import React, { useState, useContext, createContext, useCallback, useMemo, useEffect } from "react"
import store from "store"
import { District, DistrictsResult, GET_DISTRICTS_QUERY } from "../api/graphql/queries/get-districts"
import { STORE_KEYS } from "../services/user-service"
import { UserContext } from "./user-context"

interface IDistrictFilterContextType {
  selectedDistrict: District | undefined
  setDistrictId: (id: string) => void
  districts: District[]
  districtsLoading: boolean
  districtsError: ApolloError | undefined
}

export const DistrictFilterContext = createContext<IDistrictFilterContextType>({} as IDistrictFilterContextType)

export const DistrictFilterProvider = (props: any) => {
  const auth = useDistrictFilterProvider()

  return <DistrictFilterContext.Provider value={auth}>{props.children}</DistrictFilterContext.Provider>
}

export const useDistrictFilter = () => {
  return useContext(DistrictFilterContext)
}

const useDistrictFilterProvider = (): IDistrictFilterContextType => {
  const [selectedDistrict, setSelectedDistrict] = useState<District | undefined>(undefined)

  const { user } = useContext(UserContext)

  const { data: districtsData, loading: districtsLoading, error: districtsError } = useQuery<DistrictsResult>(
    GET_DISTRICTS_QUERY,
    { skip: !user },
  )

  const districts = useMemo(() => districtsData?.getDistricts || [], [districtsData])

  const setDistrictId = useCallback(
    (id: string) => {
      setSelectedDistrict(districts.find((d) => d.id === id))
      store.set(STORE_KEYS.DISTRICT, id)
    },
    [districts],
  )

  useEffect(() => {
    if (!selectedDistrict && districts.length) {
      const districtIdFromStore = store.get(STORE_KEYS.DISTRICT) as string
      const district = districts.find((district) => district.id === districtIdFromStore)
      setDistrictId(district?.id || districts[0].id)
    }
  }, [selectedDistrict, districts, setDistrictId])

  return {
    selectedDistrict,
    setDistrictId,
    districts,
    districtsLoading,
    districtsError,
  }
}
