import { gql } from "@apollo/client"
import {
  availableDisposalMerchantsForTourGeneration,
  availableDisposalMerchantsForTourGenerationVariables,
  availableDisposalMerchantsForTourGeneration_availableDisposalMerchantsForTourGeneration,
  availableDisposalMerchantsForTourGeneration_availableDisposalMerchantsForTourGeneration_merchantsPerDay,
} from "./types/availableDisposalMerchantsForTourGeneration"

export const AVAILABLE_DISPOSAL_MERCHANTS_FOR_TOUR_GENERATION = gql`
  query availableDisposalMerchantsForTourGeneration($materialIds: [ID!]!, $startDate: DateTime!, $districtId: ID!) {
    availableDisposalMerchantsForTourGeneration(
      material_ids: $materialIds
      start_date: $startDate
      district_id: $districtId
    ) {
      merchantsPerDay {
        day
        disposalMerchants {
          id
          name
        }
      }
    }
  }
`
export type AvailableDisposalMerchantsForTourGenerationResult = availableDisposalMerchantsForTourGeneration
export type AvailableDisposalMerchantsForTourGeneration = availableDisposalMerchantsForTourGeneration_availableDisposalMerchantsForTourGeneration
export type AvailableDisposalMerchantsForTourGenerationVariables = availableDisposalMerchantsForTourGenerationVariables

export type AvailableDisposalMerchantsPerDay = availableDisposalMerchantsForTourGeneration_availableDisposalMerchantsForTourGeneration_merchantsPerDay[]
