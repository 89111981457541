import { Grid, makeStyles, Theme } from "@material-ui/core"
import moment from "moment"
import React, { FunctionComponent, useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { TourGenerationContext } from "../../../../../context/tour-generation-context"
import { theme } from "../../../../../styles/theme"
import { DayOfWeek, isSameWeekDay } from "../../../../../utils/day"
import { Text } from "../../../../partials/wrapper/text"
import { IVehicleEntry } from "../tour-generation-vehicle-selection"

interface ITourGenerationVehicleCardDaysProps {
  vehicle: IVehicleEntry
  handleChangeSelection: (vehicleId: number) => (days: DayOfWeek[]) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  weekLabel: {
    minWidth: "50px",
    maxWidth: "50px",
    paddingRight: theme.spacing(1),
    borderRight: "1px solid black",
  },
}))

export const TourGenerationVehicleCardDays: FunctionComponent<ITourGenerationVehicleCardDaysProps> = (props) => {
  const { t } = useTranslation()
  const { vehicle, handleChangeSelection } = props
  const classes = useStyles()

  const { tourWeeks } = useContext(TourGenerationContext)

  const handleClick = (vehicle: IVehicleEntry, day: DayOfWeek) => {
    if (!vehicle.availableDays.includes(day.day) || !vehicle.isActive) {
      return
    }
    if (vehicle.days.some(isSameWeekDay(day))) {
      // remove day
      handleChangeSelection(vehicle.vehicleId)(vehicle.days.filter((d) => !isSameWeekDay(d)(day)))
    } else {
      // append day
      handleChangeSelection(vehicle.vehicleId)([...vehicle.days, day])
    }
  }

  const isDaySelected = useCallback(
    (day: DayOfWeek): boolean => {
      return vehicle.days.some(isSameWeekDay(day)) && vehicle.isActive
    },
    [vehicle],
  )

  const isAvailableForPickUp = useCallback(
    (day: DayOfWeek): boolean => {
      return vehicle.availableDays.includes(day.day) && vehicle.isActive
    },
    [vehicle],
  )

  return (
    <Grid container direction="column">
      <Grid item>
        <Text bold>{t("days")}</Text>
      </Grid>
      {tourWeeks.map((week) => (
        <Grid item xs={12} container justify="space-around" key={`week-${week.week}`}>
          {tourWeeks.length > 1 && (
            <Grid item className={classes.weekLabel}>
              <Text bold>{`${t(`week_picker.calendar_week_short`, {
                week: moment(week.weekStartDate).isoWeek(),
              })}`}</Text>
            </Grid>
          )}
          {week.days.map((dayOfWeek) => (
            <Text
              style={{
                width: "25px",
                cursor: vehicle.availableDays.includes(dayOfWeek.day) ? "pointer" : "default",
                color: isAvailableForPickUp(dayOfWeek)
                  ? isDaySelected(dayOfWeek)
                    ? theme.PRIMARY_COLOR.main
                    : "black"
                  : "lightgray",
                userSelect: "none",
              }}
              onClick={() => handleClick(vehicle, dayOfWeek)}
              key={`week-${dayOfWeek.week}-${dayOfWeek.day}`}
              bold={isDaySelected(dayOfWeek)}
            >
              {t(`weekdays.${dayOfWeek.day}_short`)}
            </Text>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
