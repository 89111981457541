import React, { useState, useContext, createContext } from "react"

interface IDriverLocationContextType {
  driverLocationId: string | undefined
  setDriverLocationId: (value: string | undefined) => void
}

export const DriverLocationContext = createContext<IDriverLocationContextType>(
  {} as IDriverLocationContextType
)

export const DriverLocationProvider = (props: any) => {
  const value = useDriverLocationProvider()

  return (
    <DriverLocationContext.Provider value={value}>
      {props.children}
    </DriverLocationContext.Provider>
  )
}

export const useDriverLocation = () => {
  return useContext(DriverLocationContext)
}

const useDriverLocationProvider = (): IDriverLocationContextType => {
  const [driverLocationId, setDriverLocationId] = useState<string|undefined>("")

  return {
    driverLocationId,
    setDriverLocationId
  }
}