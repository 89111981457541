import { useQuery } from "@apollo/client"
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import lodash from "lodash"
import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { AlgorithmType, Day } from "../../../../api/graphql/graphql-global-types"
import {
  AvailableVehicleForTourGeneration,
  AvailableVehiclesForTourGenerationResult,
  AvailableVehiclesForTourGenerationVariables,
  AVAILABLE_VEHICLES_FOR_TOUR_GENERATION_QUERY,
  VehicleOperatingTimes,
} from "../../../../api/graphql/queries/available-vehicles-for-tour-generation"
import { DeparturePoint } from "../../../../api/graphql/queries/get-departure-points"
import { VehicleToMaterial } from "../../../../api/graphql/queries/get-vehicle-with-id"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { DayOfWeek } from "../../../../utils/day"
import { doesVehicleContainMaterial, isVehicleActive } from "../../../../utils/tourGeneration"
import { TourGenerationVehicleCard } from "./vehicles/tour-generation-vehicle-card"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontWeight: "bold",
  },
  vehiclesContainer: {
    padding: theme.spacing(1),
  },
  noVehiclesText: {
    textAlign: "center",
  },
}))

interface ITourGenerationVehicleSelectionProps {}

export interface ICustomDeparturePoint {
  day: DayOfWeek
  departurePoint?: DeparturePoint
  endPoint?: DeparturePoint
}

export interface ICustomOperatingTime {
  day: DayOfWeek
  minTourDuration?: number | null
  maxTourDuration?: number | null
}

export interface IVehicleEntry {
  vehicleId: number
  licencePlate: string
  trailer: boolean
  days: DayOfWeek[]
  availableDays: Day[]
  customDeparturePoints: ICustomDeparturePoint[]
  materials: VehicleToMaterial[]
  isActive: boolean
  standardDeparturePoint: DeparturePoint | null
  standardEndPoint: DeparturePoint | null
  partnerId: number
  customOperatingTimes: ICustomOperatingTime[]
  standardOperatingTimes: VehicleOperatingTimes[]
}

export const TourGenerationVehicleSelection: FunctionComponent<ITourGenerationVehicleSelectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [vehicles, setVehicles] = useState<IVehicleEntry[]>([])
  const [prevTourWithoutContainers, setPrevTourWithoutContainers] = useState<boolean>(false)

  const {
    district,
    firstWeek,
    materials,
    setVehicles: setVehiclesInContext,
    vehicles: vehiclesInContext,
    tourWithoutContainers,
    version,
  } = useContext(TourGenerationContext)

  const { data } = useQuery<AvailableVehiclesForTourGenerationResult, AvailableVehiclesForTourGenerationVariables>(
    AVAILABLE_VEHICLES_FOR_TOUR_GENERATION_QUERY,
    {
      variables: {
        distric_id: district?.id || "",
        start_date: firstWeek.clone().utc().toDate(),
      },
      skip: !district?.id,
      onCompleted: () => updateVehicles(),
    },
  )

  const filterVehicles = useCallback(
    (newVehicles: IVehicleEntry[]): IVehicleEntry[] =>
      newVehicles.filter((vehicle) => {
        if (!tourWithoutContainers) {
          return (
            vehicle.days.length > 0 &&
            isVehicleActive(vehicle, version) &&
            doesVehicleContainMaterial(vehicle, materials)
          )
        }
        return vehicle.days.length > 0 && vehicle.isActive
      }),
    [version, materials, tourWithoutContainers],
  )

  const updateVehicles = useCallback(() => {
    const newVehicles: IVehicleEntry[] =
      data?.availableVehiclesForTourGeneration.map((vehicle: AvailableVehicleForTourGeneration) => {
        const existing = vehicles.find((v) => v.vehicleId === vehicle.id)

        if (existing) {
          vehicle.vehicleToMaterial.forEach((entry) => {
            entry.amount =
              existing.materials.find((material) => material.material.id === entry.material.id)?.amount || entry.amount
          })

          existing.materials
            .filter(
              (material) => !vehicle.vehicleToMaterial.find((entry) => entry.material.id === material.material.id),
            )
            .forEach((material) => vehicle.vehicleToMaterial.push(material))
        }

        const vehicleMaterials = vehicle.vehicleToMaterial.filter((entry) =>
          materials.map((am) => am.id).includes(entry.material.id),
        )

        const newMaterials: VehicleToMaterial[] = materials
          .filter(
            (availableEntry) =>
              !vehicleMaterials.map((vehicleEntry) => vehicleEntry.material.id).includes(availableEntry.id),
          )
          .map((entry) => ({ amount: 0, material: entry, is_trailer: false, __typename: "VehicleToMaterial" }))

        const existingHasDays = !lodash.isNil(existing?.days)

        const days: DayOfWeek[] = !existingHasDays
          ? vehicle.daysForTourGeneration.map((day) => ({ week: 1, day }))
          : existing!.days.filter((existingDay) => vehicle.daysForTourGeneration.includes(existingDay.day)) ?? []

        return {
          vehicleId: vehicle.id,
          licencePlate: vehicle.licence_plate,
          trailer: vehicle.trailer,
          availableDays: vehicle.daysForTourGeneration,
          days,
          isActive: lodash.isNil(existing?.isActive) ? true : existing!.isActive,
          customDeparturePoints: existing?.customDeparturePoints || [],
          customOperatingTimes: existing?.customOperatingTimes || [],
          partnerId: vehicle.collection_partner.id,
          standardDeparturePoint: vehicle.departurePoint || null,
          standardEndPoint: vehicle.endPoint || null,
          standardOperatingTimes: vehicle.operating_times || [],
          materials: vehicleMaterials
            .concat(newMaterials)
            .sort((a, b) => Number(a.material.id) - Number(b.material.id)),
        }
      }) || []

    setVehicles(newVehicles)
    setVehiclesInContext(filterVehicles(newVehicles))
  }, [materials, data, setVehiclesInContext, filterVehicles, vehicles])

  useEffect(() => {
    const vehicleMaterialIds = vehicles.map((vehicle) => vehicle.materials.map((vm) => vm.material.id))
    const materialIds = materials.map((m) => m.id)

    const versionChanged = !!vehiclesInContext.find(
      (v) => (version === AlgorithmType.TO2 && v.trailer) || (version === AlgorithmType.TO2TRAILER && !v.trailer),
    )

    // AGR-796
    // compare selected materials and all materials of vehicles
    // update vehicles only on changes
    const materialsChanged = vehicleMaterialIds.some(
      (arr) => !lodash.isEqual(lodash.uniq(arr).sort(), lodash.uniq(materialIds).sort()),
    )

    let tourWithoutContainersChanged = false
    if (tourWithoutContainers !== prevTourWithoutContainers) {
      tourWithoutContainersChanged = true
      setPrevTourWithoutContainers(tourWithoutContainers)
    }

    if (
      vehicles.length > 0 &&
      ((materials.length > 0 && materialsChanged) || tourWithoutContainersChanged || versionChanged)
    ) {
      updateVehicles()
    }
  }, [
    updateVehicles,
    materials,
    vehicles,
    prevTourWithoutContainers,
    tourWithoutContainers,
    vehiclesInContext,
    version,
  ])

  const handleChangeActive = useCallback(
    (vehicleId: number) => () => {
      const temp = [...vehicles]
      const clickedVehicle = temp.find((vehicle) => vehicle.vehicleId === vehicleId)
      clickedVehicle!.isActive = !clickedVehicle!.isActive
      setVehicles(temp)
      setVehiclesInContext(filterVehicles(temp))
    },
    [setVehicles, vehicles, setVehiclesInContext, filterVehicles],
  )

  const handleChangeSelection = useCallback(
    (vehicleId: number) => (days: DayOfWeek[]) => {
      const temp = [...vehicles]
      if (days.length > 0) {
        temp.find((vehicle) => vehicle.vehicleId === vehicleId)!.days = days
        setVehicles(temp)
        setVehiclesInContext(filterVehicles(temp))
      }
    },
    [setVehicles, vehicles, setVehiclesInContext, filterVehicles],
  )

  const handleChangeCustomDeparturePoint = useCallback(
    (vehicleId: number) => (customDepaturePoint: ICustomDeparturePoint) => {
      const temp = [...vehicles]
      const tempVehicle = temp.find((vehicle) => vehicle.vehicleId === vehicleId)
      const customDeparturePointEntry = (tempVehicle?.customDeparturePoints || []).find(
        (entry) => entry.day === customDepaturePoint.day,
      )

      if (customDeparturePointEntry && (customDepaturePoint.departurePoint || customDepaturePoint.departurePoint)) {
        customDeparturePointEntry.departurePoint = customDepaturePoint.departurePoint
        customDeparturePointEntry.endPoint = customDepaturePoint.endPoint
      } else if (customDepaturePoint.departurePoint || customDepaturePoint.departurePoint) {
        tempVehicle?.customDeparturePoints.push(customDepaturePoint)
      } else if (customDeparturePointEntry) {
        const index = tempVehicle?.customDeparturePoints.indexOf(customDeparturePointEntry)
        tempVehicle?.customDeparturePoints.splice(index || -1, 1)
      }

      setVehicles(temp)
      setVehiclesInContext(filterVehicles(temp))
    },
    [setVehicles, vehicles, setVehiclesInContext, filterVehicles],
  )

  const handleChangeCustomOperatingTime = useCallback(
    (vehicleId: number) => (customOperatingTime: ICustomOperatingTime) => {
      const temp = [...vehicles]
      const tempVehicle = temp.find((vehicle) => vehicle.vehicleId === vehicleId)
      const customOperatingTimeEntry = (tempVehicle?.customOperatingTimes || []).find(
        (entry) => entry.day === customOperatingTime.day,
      )
      if (
        customOperatingTimeEntry &&
        (!lodash.isUndefined(customOperatingTime.minTourDuration) ||
          !lodash.isUndefined(customOperatingTime.maxTourDuration))
      ) {
        customOperatingTimeEntry.minTourDuration = customOperatingTime.minTourDuration
        customOperatingTimeEntry.maxTourDuration = customOperatingTime.maxTourDuration
      } else if (
        !lodash.isUndefined(customOperatingTime.minTourDuration) ||
        !lodash.isUndefined(customOperatingTime.maxTourDuration)
      ) {
        tempVehicle?.customOperatingTimes.push(customOperatingTime)
      } else if (customOperatingTimeEntry) {
        const index = tempVehicle?.customOperatingTimes.indexOf(customOperatingTimeEntry)
        tempVehicle?.customOperatingTimes.splice(index || -1, 1)
      }
      setVehicles(temp)
      setVehiclesInContext(filterVehicles(temp))
    },
    [setVehicles, vehicles, setVehiclesInContext, filterVehicles],
  )

  const updateMaterials = useCallback(
    (vehicle: IVehicleEntry) => (materials: VehicleToMaterial[]) => {
      const temp = [...vehicles]
      temp.find((v) => v.vehicleId === vehicle.vehicleId)!.materials = materials
      setVehicles(temp)
      setVehiclesInContext(filterVehicles(temp))
    },
    [setVehicles, vehicles, setVehiclesInContext, filterVehicles],
  )

  const vehiclesToDisplay = useMemo<IVehicleEntry[]>(() => {
    if (tourWithoutContainers) {
      return vehicles
    }
    return vehicles
      .filter((vehicle) => (version === AlgorithmType.TO2TRAILER ? vehicle.trailer : !vehicle.trailer))
      .filter((vehicle) => doesVehicleContainMaterial(vehicle, materials))
  }, [tourWithoutContainers, version, vehicles, materials])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>{t("tour_generation.data.vehicles")}</Typography>
      </Grid>

      {vehiclesToDisplay.length <= 0 && (
        <Grid item className={classes.noVehiclesText} xs={12}>
          <Typography color="textSecondary">{t("tour_generation.data.no_vehicles_available")}</Typography>
        </Grid>
      )}

      {vehiclesToDisplay.map((vehicle) => (
        <Grid item xs={12} key={`vehicle_${vehicle.vehicleId}`}>
          <TourGenerationVehicleCard
            vehicle={vehicle}
            handleChangeActive={handleChangeActive}
            handleChangeSelection={handleChangeSelection}
            updateMaterials={updateMaterials}
            handleChangeCustomDeparturePoint={handleChangeCustomDeparturePoint}
            handleChangeCustomOperatingTimes={handleChangeCustomOperatingTime}
          />
        </Grid>
      ))}
    </Grid>
  )
}
