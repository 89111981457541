import { gql } from "@apollo/client"
import {
  getRoutesForLocation,
  getRoutesForLocation_getRoutesForLocation,
} from "./types/getRoutesForLocation"

export const GET_ROUTES_FOR_LOCATION = gql`
  query getRoutesForLocation($locationId: String!) {
    getRoutesForLocation(locationId: $locationId) {
      id
      name
      filename
      inserted_at
    }
  }
`

export type RoutesForLocationResult = getRoutesForLocation
export type RoutesForLocation = getRoutesForLocation_getRoutesForLocation
