import { gql } from "@apollo/client"
import { cancelFileExports, cancelFileExportsVariables } from "./types/cancelFileExports"

export const CANCEL_FILE_EXPORTS_MUTATION = gql`
  mutation cancelFileExports($ids: [ID!]!) {
    cancelFileExports(ids: $ids)
  }
`
export type CancelFileExportsResult = cancelFileExports
export type CancelFileExportsVariables = cancelFileExportsVariables
