import { gql } from "@apollo/client"
import {
  changeContainers,
  changeContainersVariables,
  changeContainers_changeContainers,
} from "./types/changeContainers"

export const CHANGE_CONTAINERS_MUTATION = gql`
  mutation changeContainers($containers: [ContainerInput!]!) {
    changeContainers(containers: $containers) {
      error
      message
    }
  }
`

export type ChangeContainersResult = changeContainers
export type ChangeContainers = changeContainers_changeContainers
export type ChangeContainersVariables = changeContainersVariables
