import { useQuery } from "@apollo/client"
import { CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core"
import React, { Fragment, FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../api/graphql/queries/get-driver-locations"

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    backgroundColor: theme.palette.background.default,
  },
}))

interface ILocationSelectProps {
  defaultId?: number | null
  handleChange: (selectedLocationId: number) => void
}

export const LocationSelect: FunctionComponent<ILocationSelectProps> = (props) => {
  const { data, loading } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY)
  const { defaultId, handleChange } = props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Fragment>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading && (
        <FormControl fullWidth required variant="outlined">
          <InputLabel id="location-label" className={classes.inputLabel}>
          {t("routes_management.choose_location")}
          </InputLabel>
          <Select
            labelId="location-label"
            defaultValue={defaultId || ""}
            fullWidth
            onChange={(e) => handleChange(Number(e.target.value))}
          >
            {data?.driverLocations.map((driverLocation) => (
              <MenuItem key={driverLocation.id} value={driverLocation.id}>
                {driverLocation.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Fragment>
  )
}
