import { gql } from "@apollo/client"
import { getMaterials, getMaterials_getMaterials } from "./types/getMaterials"

export const GETMATERIALS_QUERY = gql`
  query getMaterials {
    getMaterials {
      id
      name
    }
  }
`

export type MaterialsResult = getMaterials
export type Material = getMaterials_getMaterials
