import React, { FunctionComponent, Fragment, useEffect, useState, useContext, useMemo } from "react"
import { Theme, makeStyles, CircularProgress, Grid, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"
import lodash from "lodash"
import { useQuery } from "@apollo/client"
import {
  GETCOLLECTIONPOINTWITHID_QUERY,
  CollectionPointResult,
  CollectionPointVariables,
} from "../../../api/graphql/queries/get-collection-points-with-id"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { CustomMap, ICenterOption } from "../../partials/maps/custom-map"
import { MapsHelper } from "../../partials/maps/maps-helper"
import { ContentContainer } from "../../partials/layout/content/content-container"
import { CardsContainer } from "../../partials/layout/card/cards-container"
import { Card } from "../../partials/layout/card/model/card"
import { FilllevelOverviewChart, IFilllevelOverview } from "./partials/filllevel-overview-chart"
import { ContainerStatsCard } from "./partials/container-stats-card"
import {
  CollectionPointFilllevelsResult,
  CollectionPointFilllevelsVariables,
  GETCOLLECTIONPOINTFILLLEVELS_QUERY,
} from "../../../api/graphql/queries/get-flattened-collection-point-fill-levels"
import moment from "moment"
import {
  GETCOLLECTIONPOINTSTATS_QUERY,
  CollectionPointStatsResult,
  EmptyingStats,
  CollectionPointStatsVariables,
} from "../../../api/graphql/queries/get-collection-point-stats-with-id"
import {
  GETINITIALIMAGESWITHCOLLECTIONPOINTIDTYPE,
  ImageResult,
  ImageVariables,
} from "../../../api/graphql/queries/get-initial-images-with-collection-point-id"
import { CardInfoHelper } from "../../../utils/CardInfoHelper"
import { CustomLightBox } from "../../partials/layout/lightbox/custom-lightbox"
import { Text } from "../../partials/wrapper/text"
import { getImagesFromCollectionPointImages, getImageTypesFromCollectionPointImages } from "../../../utils/lightbox"
import { UserContext } from "../../../context/user-context"
import { getAddressStringForCollectionPoint } from "../../../utils/collectionpoint"
import { UserService } from "../../../services/user-service"
import { UserGroups } from "../../../models/user-groups"
import { PATH, replaceUriParameter } from "../../../router/router"
import { MAX_EXTRAPOLATION_FILLLEVEL_PERCENTAGE } from "../../../utils/constants"

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    marginTop: 10,
  },
}))

interface ICollectionPointPageProps {}

const now = moment()

export const CollectionPointPage: FunctionComponent<ICollectionPointPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { problemSensorInterval } = useContext(UserContext)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const match = useRouteMatch()
  const collectionPointID = lodash.get(match, "params.collectionPointID")
  const [showImages, setShowImages] = useState<boolean>(false)

  const { loading, error, data } = useQuery<CollectionPointResult, CollectionPointVariables>(
    GETCOLLECTIONPOINTWITHID_QUERY,
    {
      variables: {
        id: Number(collectionPointID),
      },
    },
  )

  const { loading: loadingFilllevels, data: filllevelsData } = useQuery<
    CollectionPointFilllevelsResult,
    CollectionPointFilllevelsVariables
  >(GETCOLLECTIONPOINTFILLLEVELS_QUERY, {
    variables: {
      id: Number(collectionPointID),
      from: now.clone().subtract(6, "month").toDate(),
      to: now.clone().add(1, "month").toDate(),
    },
  })

  const { loading: loadingStats, data: statsData } = useQuery<
    CollectionPointStatsResult,
    CollectionPointStatsVariables
  >(GETCOLLECTIONPOINTSTATS_QUERY, {
    variables: {
      id: Number(collectionPointID),
    },
  })

  const { loading: loadingImages, data: imagesData } = useQuery<ImageResult, ImageVariables>(
    GETINITIALIMAGESWITHCOLLECTIONPOINTIDTYPE,
    {
      variables: {
        collection_point_id: collectionPointID,
      },
    },
  )

  const getCenter = (): ICenterOption => {
    return {
      id: statsData!.getCollectionPointStatsWithID.id,
      lat: statsData!.getCollectionPointStatsWithID.collection_point.latitude,
      lng: statsData!.getCollectionPointStatsWithID.collection_point.longitude,
    }
  }

  const forecastEnabled = useMemo(
    () =>
      UserService.isAdmin() ||
      (UserGroups.ASSOCIATION_SELECT.includes(UserService.getRole())
        ? data?.getCollectionPointWithID.town.association?.forecastEnabled
        : data?.getCollectionPointWithID.town.district?.forecastEnabled) ||
      false,
    [data],
  )

  if (loading || loadingStats || loadingFilllevels || loadingImages)
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )
  if (error) return null

  const marker = [MapsHelper.getMarker(data!.getCollectionPointWithID, problemSensorInterval)]

  const stats = statsData!.getCollectionPointStatsWithID

  const cards = [
    data!.getCollectionPointWithID.collectionPointFilllevels.length > 0 &&
      CardInfoHelper.getContainerInfoGroupCardForCollectionPoint(
        data!.getCollectionPointWithID.collectionPointFilllevels,
      ),
    stats.last_emptying && CardInfoHelper.getLastEmptyingCard(stats.last_emptying as EmptyingStats),
    stats.next_emptying && CardInfoHelper.getNextEmptyingCard(stats.next_emptying as EmptyingStats),
    data!.getCollectionPointWithID.collectionPointFilllevels.length > 0 &&
      CardInfoHelper.getFillLevelCard(data!.getCollectionPointWithID.collectionPointFilllevels),
    data!.getCollectionPointWithID.fixed_unload_interval &&
      CardInfoHelper.getFixedUnloadInterval(data!.getCollectionPointWithID.fixed_unload_interval),
    CardInfoHelper.getAverageFullCard(stats.avg_full),
  ].filter((card) => card) as Card[]

  const getImagesButtonName = () => {
    if (imagesData && imagesData.getInitialImagesWithCollectionPointId.length === 1) {
      return `1 ${t("image")}`
    } else if (imagesData && imagesData.getInitialImagesWithCollectionPointId.length > 0) {
      return `${imagesData!.getInitialImagesWithCollectionPointId.length} ${t("images")}`
    }

    return t("no_images")
  }

  const prepareFillLevel = (fillLevel: number | null): number | null => {
    return fillLevel ? Math.min(fillLevel, MAX_EXTRAPOLATION_FILLLEVEL_PERCENTAGE) : fillLevel
  }

  const canEditCollectionPoint = () => {
    return UserService.isInUserGroup(UserGroups.COLLECTION_POINT_MANAGEMENT)
  }

  const redirectToCollectionPointAdministration = () => {
    const url = replaceUriParameter(
      `${PATH.COLLECTIONPOINTS_ADMINISTRATION_PRE_SELECT.route}`,
      "collectionPointId",
      collectionPointID,
    )
    history.push(url)
  }

  return (
    <Fragment>
      {/* Image-LightBox */}
      {showImages && (
        <CustomLightBox
          images={getImagesFromCollectionPointImages(imagesData!.getInitialImagesWithCollectionPointId)}
          imageTypes={getImageTypesFromCollectionPointImages(imagesData!.getInitialImagesWithCollectionPointId)}
          onClose={() => setShowImages(false)}
        />
      )}
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <PageHeading>{`${t("collection_point")} ${data!.getCollectionPointWithID!.description}`}</PageHeading>
        </Grid>
        <Grid item>
          {canEditCollectionPoint() && (
            <Button variant="contained" type="button" color="primary" onClick={redirectToCollectionPointAdministration}>
              {t("collection_point_details.edit")}
            </Button>
          )}
        </Grid>
      </Grid>

      {/* Headerinfo */}
      <ContentContainer>
        <Grid container alignItems="center" justify="space-between">
          <Text bold>{getAddressStringForCollectionPoint(data!.getCollectionPointWithID)}</Text>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setShowImages(!showImages)}
            disabled={(imagesData && imagesData.getInitialImagesWithCollectionPointId.length === 0) || !imagesData}
          >
            {getImagesButtonName()}
          </Button>
        </Grid>
      </ContentContainer>
      {/* Map */}
      <ContentContainer>
        <CustomMap style={classes.map} center={getCenter()} markers={marker} />
      </ContentContainer>
      {/* CollectionPoint Stats */}
      <ContentContainer>
        <CardsContainer justify="center" cards={cards} />
      </ContentContainer>
      {/* CollectionPoint Chart */}
      <ContentContainer>
        <FilllevelOverviewChart
          data={
            filllevelsData
              ? (filllevelsData.getFlattenedCollectionPointFilllevels.filter(
                  (item) => item.material_id !== null && item.material_name !== null,
                ) as IFilllevelOverview[])
              : []
          }
          prepareFillLevel={prepareFillLevel}
        />
      </ContentContainer>
      {/* Containers */}
      {data!.getCollectionPointWithID!.containers!.map((container) => (
        <ContentContainer key={container.id}>
          <ContainerStatsCard
            container={container}
            fixedUnloadInterval={data!.getCollectionPointWithID!.fixed_unload_interval}
            prepareFillLevel={prepareFillLevel}
            forecastEnabled={forecastEnabled}
          />
        </ContentContainer>
      ))}
    </Fragment>
  )
}
