import { gql } from "@apollo/client"
import {
  getDisposalMerchants,
  getDisposalMerchants_getDisposalMerchants,
  getDisposalMerchants_getDisposalMerchants_materials
} from "./types/getDisposalMerchants"
export const GETDISPOSALMERCHANTS_QUERY = gql`
  query getDisposalMerchants {
    getDisposalMerchants {
      id
      name
      latitude
      longitude
      contact_name
      phone_number
      email
      town
      postal
      street
      materials {
        id
        name
      }
    }
  }
`

export type DisposalMerchantsResult = getDisposalMerchants
export type DisposalMerchant = getDisposalMerchants_getDisposalMerchants
export type DisposalMerchantMaterial = getDisposalMerchants_getDisposalMerchants_materials
