import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Theme, makeStyles, Grid, CircularProgress, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Badge } from "../../../components/pages/user-management/partials/badge"
import classnames from "classnames"
import { Person } from "@material-ui/icons"
import lodash from "lodash"
import { UserContext } from "../../../context/user-context"
import { getLocalPortalVersion } from "../../../utils/portal-update"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(2),
  },
  badge: {
    display: "flex",
    marginTop: theme.spacing(1),
    justifyContent: "center",
  },
  item: {
    textAlign: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  name: {
    fontWeight: 600,
  },
  email: {
    fontSize: 14,
  },
  icon: { color: "white" },
  version: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: -5,
    fontWeight: "bold"
  }
}))

interface IUserInfoProps {
}

export const UserInfo: FunctionComponent<IUserInfoProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isFullnameOverflowing, setIsFullnameOverflowing] = useState(false)
  const [isEmailOverflowing, setIsEmailOverflowing] = useState(false)
  const { user, isUserLoading } = useContext(UserContext)

  const fullname = [user?.first_name, user?.last_name].filter((namePart) => !!namePart).join(" ")

  return (
    <Grid
      container
      direction="column"
      className={classes.container}
      justify="center"
      alignContent="center"
    >
      {isUserLoading && (
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="center"
          spacing={2}
          className={classes.container}
        >
          <Grid item className={classes.icon}>
            <Person />
          </Grid>
          <Grid item>
            <CircularProgress size={24} />
          </Grid>
        </Grid>
      )}
      {!isUserLoading && (
        <Fragment>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isFullnameOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={fullname}
          >
            <Grid
              item
              className={classnames(classes.item, classes.name)}
              ref={(ref) =>
                setIsFullnameOverflowing(
                  lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth,
                )
              }
            >
              {fullname}
            </Grid>
          </Tooltip>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isEmailOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={user?.email || ""}
          >
            <Grid
              item
              className={classnames(classes.item, classes.email)}
              ref={(ref) =>
                setIsEmailOverflowing(lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth)
              }
            >
              {user?.email || ""}
            </Grid>
          </Tooltip>
          {user?.role && (
            <Grid item className={classes.badge}>
              <Badge color="primary" fontSize={12}>
                {t(`user_role.${user?.role}`)}
              </Badge>
            </Grid>
          )}
          <Grid item className={classnames(classes.item, classes.version)}>
            {getLocalPortalVersion() ?? t("portal.sidebar.no_version")}
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}
