import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Theme,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RoutesForLocation } from "../../../../api/graphql/queries/get-routes-for-location"
import {
  DELETE_ROUTE_MUTATION,
  DeleteRouteResult,
  DeleteRouteVariables,
} from "../../../../api/graphql/mutations/delete-route"
import { useMutation } from "@apollo/client"
import moment from "moment"
import { Delete, Map } from "@material-ui/icons"
import { Skeleton } from "@material-ui/lab"
import classnames from "classnames"
import { useRefetch } from "../../../../context/refetch-context"
import { toast } from "react-toastify"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { useHistory } from "react-router-dom";
import { PATH } from "../../../../router/router"

const useStyles = makeStyles((theme: Theme) => ({
  idColumn: {
    width: 40,
  },
  deleteColumn: {
    width: 40,
    borderRight: "none",
  },
  editColumn: {
    width: 40,
  },
  lastRow: {
    borderBottom: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

interface IRoutesTableProps {
  routes: RoutesForLocation[]
  loading: boolean
}

export const RoutesTable: FunctionComponent<IRoutesTableProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { routes, loading } = props

  const { setNeedToRefetch } = useRefetch()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<string | null>(null)
  const history = useHistory();

  const [deleteRoute, { loading: deleteLoading }] = useMutation<
    DeleteRouteResult,
    DeleteRouteVariables
  >(DELETE_ROUTE_MUTATION)

  const deleteClicked = async (id: string) => {
    setCurrentId(id)
    setDeleteDialogOpen(true)
  }

  const editClicked = (id: string) => {
    const path = PATH.ROUTE_DETAILS.route.replace(":routeId", id)
    history.push(path)
  }

  const deleteConfirmed = async () => {
    const result = await deleteRoute({
      variables: {
        id: String(currentId),
      },
    })

    if (result.data?.deleteRoute) {
      toast.info(t("routes_management.route_deleted"))
    } else {
      toast.error(t("routes_management.could_not_delete_route"))
    }
    closeDialog()
    setNeedToRefetch(true)
  }

  const closeDialog = () => {
    setDeleteDialogOpen(false)
    setCurrentId(null)
  }

  return (
    <Fragment>
      <Backdrop open={deleteLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => closeDialog()}
        content={t("routes_management.delete_dialog_text_route")}
        onDelete={() => deleteConfirmed()}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.idColumn}>
              {t("routes_management.table.id")}
            </TableCell>
            <TableCell align="center">{t("routes_management.table.filename")}</TableCell>
            <TableCell align="center">{t("routes_management.table.name")}</TableCell>
            <TableCell align="center">{t("routes_management.table.date")}</TableCell>
            <TableCell className={classes.editColumn}></TableCell>
            <TableCell className={classes.deleteColumn}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell align="center" className={classes.idColumn}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell className={classes.editColumn}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell className={classes.deleteColumn}>
                <Skeleton height={50} />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            routes.map((route, index) => (
              <TableRow key={route.id}>
                <TableCell
                  align="center"
                  className={classnames(classes.idColumn, {
                    [classes.lastRow]: index === routes.length - 1,
                  })}
                >
                  {route.id}
                </TableCell>
                <TableCell
                  align="center"
                  className={index === routes.length - 1 ? classes.lastRow : undefined}
                >
                  {route.filename}
                </TableCell>
                <TableCell
                  align="center"
                  className={index === routes.length - 1 ? classes.lastRow : undefined}
                >
                  {route.name}
                </TableCell>
                <TableCell
                  align="center"
                  className={index === routes.length - 1 ? classes.lastRow : undefined}
                >
                  {moment(route.inserted_at).format(t("date_time_format"))}
                </TableCell>
                <TableCell
                  className={classnames(classes.editColumn, {
                    [classes.lastRow]: index === routes.length - 1,
                  })}
                  align="center"
                >
                  <IconButton onClick={() => editClicked(route.id)}>
                    <Map />
                  </IconButton>
                </TableCell>
                <TableCell
                  className={classnames(classes.deleteColumn, {
                    [classes.lastRow]: index === routes.length - 1,
                  })}
                >
                  <IconButton onClick={() => deleteClicked(route.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Fragment>
  )
}
