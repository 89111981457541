import i18n from "../../../utils/i18n"

export enum SortGroupEnum {
  First = 1,    // Select All, Deselect all
  Second = 2,   // special custom options (all without xy)
  Third = 3,    // all selected options
  Fourth = 4,   // all other options
}
export enum CommonSelectOptionIdEnum {
  SelectAll = "SelectAll",
  ClearSelection = "ClearSelection",
  Null = "NULL",
}
export class SelectOption {
  constructor(public id: string, public name: string, public sortGroup: SortGroupEnum = SortGroupEnum.Fourth) {}
}
export const PredefinedSelections = {
  SelectAll: new SelectOption(
    CommonSelectOptionIdEnum.SelectAll,
    i18n.t("custom_autocomplete.selectAll"),
    SortGroupEnum.First
  ),
  ClearSelection: new SelectOption(
    CommonSelectOptionIdEnum.ClearSelection,
    i18n.t("custom_autocomplete.clearSelection"),
    SortGroupEnum.First
  ),
}