import { gql } from "@apollo/client"
import {
  createDisposalTrailerLocation,
  createDisposalTrailerLocationVariables,
  createDisposalTrailerLocation_createDisposalTrailerLocation,
} from "./types/createDisposalTrailerLocation"

export const CREATE_DISPOSAL_TRAILER_LOCATION_MUTATION = gql`
  mutation createDisposalTrailerLocation($disposal_trailer_location: DisposalTrailerLocationInput!) {
    createDisposalTrailerLocation(disposal_trailer_location: $disposal_trailer_location) {
      ... on DisposalTrailerLocation {
      id
      latitude
      longitude
      name
      district_id
      collection_partner_id
      }
    ... on DistrictNotFoundError {error}
    ... on CollectionPartnerNotFoundError {error}
    ... on DisposalTrailerLocationCreateError{error}
    ... on DisposalTrailerLocationDuplicateError{error unique}
    ... on GenericError {error}
    }
  }
`

export type CreateDisposalTrailerLocationResult = createDisposalTrailerLocation
export type CreateDisposalTrailerLocation = createDisposalTrailerLocation_createDisposalTrailerLocation
export type CreateDisposalTrailerLocationVariables = createDisposalTrailerLocationVariables
