import React, { useState, useContext, createContext } from "react"

interface IRefetchContextType {
  needToRefetch: boolean
  setNeedToRefetch: (value: boolean) => void
}

export const RefetchContext = createContext<IRefetchContextType>(
  {} as IRefetchContextType
)

export const RefetchProvider = (props: any) => {
  const value = useRefetchProvider()

  return (
    <RefetchContext.Provider value={value}>
      {props.children}
    </RefetchContext.Provider>
  )
}

export const useRefetch = () => {
  return useContext(RefetchContext)
}

const useRefetchProvider = (): IRefetchContextType => {
  const [needToRefetch, setNeedToRefetch] = useState<boolean>(false)

  return {
    needToRefetch,
    setNeedToRefetch
  }
}
