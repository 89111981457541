import React, { FunctionComponent, useEffect, useState, Fragment } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { StopsTable } from "../../../partials/layout/table/stops-table"
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { AddCollectionPointDialog } from "./add-collection-point-dialog"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import lodash from "lodash"
import { useMutation } from "@apollo/client"
import {
  UPDATE_TOUR_MUTATION,
  UpdateTourResult,
  UpdateTourVariables,
} from "../../../../api/graphql/mutations/update-tour"
import { STOP_TYPE } from "../../../../api/graphql/graphql-global-types"
import { useRefetch } from "../../../../context/refetch-context"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    overflowY: "auto",
  },
  confirmText: {
    textAlign: "center",
  },
}))

interface IEditTourModalProps {
  open: boolean
  onClose: () => void
  stopInfos: StopInfo[]
  districtId: number
  date: Date
  tourId: string
}

export const EditTourModal: FunctionComponent<IEditTourModalProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, stopInfos, districtId, date, tourId } = props

  const [stops, setStops] = useState<StopInfo[]>([])
  const [newSequenceNumber, setNewSequenceNumber] = useState<number>()
  const [addCollectionPointDialogOpen, setAddCollectionPointDialogOpen] = useState<boolean>(false)
  const [sequenceNumberToDelete, setSequenceNumberToDelete] = useState<number>()
  const { setNeedToRefetch } = useRefetch()

  const [updateTour, {loading}] = useMutation<UpdateTourResult, UpdateTourVariables>(UPDATE_TOUR_MUTATION)

  useEffect(() => {
    setStops(stopInfos)
  }, [stopInfos])

  const onStopChanged = (stop: StopInfo) => {
    const copy: StopInfo[] = JSON.parse(JSON.stringify(stops))
    const idx = copy.findIndex((s) => s.sequence_number === stop.sequence_number)
    copy[idx] = stop
    setStops(copy)
  }

  const handleCloseDialog = () => {
    setStops(stopInfos)
    onClose()
  }

  const onClickNewCollectionPoint = (sequenceNumber: number) => {
    setNewSequenceNumber(sequenceNumber + 1)
    setAddCollectionPointDialogOpen(true)
  }

  const onClickDeleteCollectionPoint = (sequenceNumber: number) => {
    setSequenceNumberToDelete(sequenceNumber)
  }

  const onCollectionPointAdded = (stop: StopInfo) => {
    setAddCollectionPointDialogOpen(false)
    const copy: StopInfo[] = JSON.parse(JSON.stringify(stops))

    const stopsWithLowerSequenceNumber = copy
      .filter((s) => s.sequence_number < stop.sequence_number)
      .sort((a, b) => a.sequence_number - b.sequence_number)

    // set sequence_number to next higher sequence_number to close gaps
    stop.sequence_number = (lodash.maxBy(stopsWithLowerSequenceNumber, "sequence_number")?.sequence_number || 0) + 1

    const stopsWithHigherSequenceNumber = copy
      .filter((s) => s.sequence_number >= stop.sequence_number)
      .sort((a, b) => a.sequence_number - b.sequence_number)

    // update higher sequence_numbers to prevent duplicates
    stopsWithHigherSequenceNumber.reduce((acc, cur) => (cur.sequence_number = acc + 1), stop.sequence_number)

    setStops([...stopsWithLowerSequenceNumber, stop, ...stopsWithHigherSequenceNumber])
  }

  const onCollectionPointRemoved = () => {
    const copy: StopInfo[] = JSON.parse(JSON.stringify(stops))
    const idx = copy.findIndex((s) => s.sequence_number === Number(sequenceNumberToDelete))
    for (let i = idx + 1; i < copy.length; i++) {
      copy[i].sequence_number -= 1
    }
    copy.splice(idx, 1)
    setStops(copy)
    setSequenceNumberToDelete(undefined)
  }

  const onClickSaveTour = async () => {
    const result = await updateTour({
      variables: {
        tour_id: tourId,
        stops: stops.map((s) => ({
          stop_id: s.stop_id !== -1 ? String(s.stop_id) : null,
          id: String(s.id),
          sequence_number: s.sequence_number,
          filllevels: s.stop_filllevels.map((f) => ({
            filllevel: f.filllevel,
            material_id: f.material.id,
          })),
          // FIXME: Case-mismatch of enum STOP_TYPE between web and api
          //        must also be fixed in app
          type: s.type.toUpperCase() as STOP_TYPE,
        })),
      },
    })
    if (result.data?.updateTour?.error === "false") {
      toast.info(t("edit_tour_dialog.tour_edited"))
      setNeedToRefetch(true)
      onClose()
    } else {
      toast.error(t("edit_tour_dialog.could_not_edit_tour"))
    }
  }

  return (
    <Fragment>
      <AddCollectionPointDialog
        open={addCollectionPointDialogOpen}
        newSequenceNumber={Number(newSequenceNumber)}
        onClose={() => setAddCollectionPointDialogOpen(false)}
        onAddCollectionPoint={(stopInfo: StopInfo) => onCollectionPointAdded(stopInfo)}
        districtId={districtId}
        date={date}
      />
      <DeleteDialog
        open={!lodash.isNil(sequenceNumberToDelete)}
        onClose={() => setSequenceNumberToDelete(undefined)}
        content={
          <Typography className={classes.confirmText}>
            {t("edit_tour_dialog.confirm_delete_text", {
              description: stops.find((s) => s.sequence_number === Number(sequenceNumberToDelete))
                ?.name,
            })}
          </Typography>
        }
        onDelete={() => onCollectionPointRemoved()}
      />
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="lg">
        <DialogTitle>{t("edit_tour_dialog.title")}</DialogTitle>
        <DialogContent>
          <StopsTable
            isInEditMode={true}
            stopInfos={stops}
            onStopChanged={onStopChanged}
            onClickNewCollectionPoint={onClickNewCollectionPoint}
            onClickDeleteCollectionPoint={onClickDeleteCollectionPoint}
          />
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justify="space-between">
            <Grid item>
              <Button onClick={handleCloseDialog} autoFocus variant="contained">
                {t("edit_tour_dialog.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={onClickSaveTour} variant="contained" color="primary" disabled={loading}>
                {loading
                 ? <CircularProgress size={24} />
                 : t("edit_tour_dialog.save")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
