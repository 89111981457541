import React, { FunctionComponent, useState, Fragment } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Dropzone } from "../../../partials/layout/dropzone/dropzone"
import { Cancel } from "@material-ui/icons"
import {
  UPLOAD_IMAGE_FOR_COLLECTION_POINT_MUTATION,
  UploadImageForCollectionPointResult,
  UploadImageForCollectionPointVariables
} from "../../../../api/graphql/mutations/upload-image-for-collection-point"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { ExecutionResult } from "graphql"
import lodash from "lodash"
import { useCollectionPointAdministrationContext } from '../collection-point-administration-context'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    maxWidth: 350,
    padding: theme.spacing(2)
  },
  dialogActions: {
    padding: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1
  }
}))

interface ICollectionPointAdministrationAddImageDialogProps {
  open: boolean
  onClose: () => void
  onImagesAdded: () => void
}

export const CollectionPointAdministrationAddImageDialog: FunctionComponent<ICollectionPointAdministrationAddImageDialogProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { open, onClose, onImagesAdded } = props
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)

  const [files, setFiles] = useState<File[]>([])

  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const [uploadImage] = useMutation<
    UploadImageForCollectionPointResult,
    UploadImageForCollectionPointVariables
  >(UPLOAD_IMAGE_FOR_COLLECTION_POINT_MUTATION)

  const uploadClicked = async () => {
    setUploadLoading(true)
    const results: ExecutionResult<UploadImageForCollectionPointResult>[] = []
    await Promise.all(
      files.map(async file => {
        const result = await uploadImage({
          variables: {
            collection_point_id: selectedCollectionPoint!.id,
            file
          }
        })
        results.push(result)
      })
    )
    if (!results.some(result => lodash.isNil(result.data))) {
      toast.info(t("collection_point_administration.images.images_uploaded"))
      setFiles([])
      onImagesAdded()
    } else {
      toast.error(
        t("collection_point_administration.images.could_not_upload_images")
      )
    }
    setUploadLoading(false)
  }

  return (
    <Fragment>
      <Backdrop open={uploadLoading} className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {t("collection_point_administration.images.add")}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Dropzone
                accept="image/*"
                text={t("collection_point_administration.images.dropzone_text")}
                updateFiles={setFiles}
                files={files}
                multiple
              />
            </Grid>
            <Grid item container direction="column">
              {files.map(file => (
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  key={file.name}
                >
                  <Grid item>
                    <Typography>{file.name}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        setFiles(files.filter(f => f.name !== file.name))
                      }
                    >
                      <Cancel color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <Button onClick={onClose} autoFocus variant="contained" fullWidth>
                {t("collection_point_administration.images.cancel")}
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                onClick={() => uploadClicked()}
                variant="contained"
                color="primary"
                fullWidth
                disabled={files.length === 0}
              >
                {t("collection_point_administration.images.upload")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
