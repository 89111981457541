export const compareArrays = <T>(a: T[] | null, b: T[] | null, equalsFunction: (a: T[], b: T[]) => boolean) => {
  if (!a && !b) {
    return true
  }
  if ((a && !b) || (b && !a)) {
    return false
  }

  return equalsFunction(a as T[], b as T[])
}
