import React, { FunctionComponent, Fragment, useState } from "react"
import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { District } from "../../../../api/graphql/queries/get-collection-partner-with-id"
import { LocationAssignmentDialog } from "../../user-management/partials/location-assignment/location-assignment-dialog"
import { LocationType } from "../../user-management/helper/get-options-for-role"

interface IPartnerDistrictSelectorProps {
  selectedDistricts: District[]
  onDistrictsUpdated: (selectedDistricts: District[]) => void
}

export const PartnerDistrictSelector: FunctionComponent<IPartnerDistrictSelectorProps> = props => {
  const { t } = useTranslation()
  const { selectedDistricts, onDistrictsUpdated } = props
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  return (
    <Fragment>
      <LocationAssignmentDialog
        collection={selectedDistricts}
        type={LocationType.DISTRICT}
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        onSave={districts => onDistrictsUpdated(districts as District[])}
      />
      <Button variant="contained" type="button" color="primary" onClick={() => setDialogOpen(true)}>
        {t("partner_overview.data.assign_districts")}
      </Button>
    </Fragment>
  )
}
