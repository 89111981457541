import { gql } from "@apollo/client"
import { getUsers, getUsers_getUsers, getUsers_getUsers_collection_partner } from "./types/getUsers"

export const GET_USERS_QUERY = gql`
  query getUsers {
    getUsers {
      id
      first_name
      last_name
      role
      email
      collection_partner {
        id
        name
      }
    }
  }
`

export type getUsersResult = getUsers
export type User = getUsers_getUsers
export type CollectionPartner = getUsers_getUsers_collection_partner