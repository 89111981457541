import { gql } from "@apollo/client"
import { getCollectionPartners, getCollectionPartners_getCollectionPartners } from "./types/getCollectionPartners"
export const GETCOLLECTIONPARTNERS_QUERY = gql`
  query getCollectionPartners {
    getCollectionPartners {
      id
      name
      town
      postal
      street
      contact_name
      phone_number
      email
      is_deletable
    }
  }
`

export type CollectionPartnersResult = getCollectionPartners
export type CollectionPartner = getCollectionPartners_getCollectionPartners
