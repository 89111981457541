import React, { FunctionComponent, Fragment } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  makeStyles,
  Theme
} from "@material-ui/core"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    color: "#f44336"
  }
}))

interface IVehicleDeleteDialogProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
}

export const VehicleDeleteDialog: FunctionComponent<IVehicleDeleteDialogProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("vehicle_overview.data.delete_prompt")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="default" autoFocus>
            {t("no")}
          </Button>
          <Button onClick={props.onDelete} className={classes.deleteButton}>
            {t("vehicle_overview.data.agree_on_delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
