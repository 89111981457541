import { Chip, Grid, Tooltip, Typography, useTheme } from "@material-ui/core"
import lodash from "lodash"
import moment from "moment"
import React, { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { GetTourParametersDrivingDays } from "../../../../api/graphql/queries/get-tour-parameters"
import { dayToNumber, isSameWeekDay, sortByDayOfWeek } from "../../../../utils/day"
import { Text } from "../../../partials/wrapper/text"

interface IDrivingDayChipsProps {
  startDate: Date
  drivingDays: GetTourParametersDrivingDays[]
}

export const DrivingDayChips: FC<IDrivingDayChipsProps> = (props) => {
  const { startDate, drivingDays } = props
  const { t } = useTranslation()
  const theme = useTheme()

  const days = drivingDays || []
  const sortedDays = lodash.uniqBy(days, (d) => `${d.day}-${d.week}`).sort(sortByDayOfWeek)

  const tourWeeks = useMemo(
    () =>
      Object.entries(lodash.groupBy(sortedDays, (day) => day.week)).map(([key, days]) => ({
        week: Number(key),
        weekStartDate: moment(startDate).add(Number(key) - 1, "weeks").toDate(),
        days,
      })),
    [sortedDays, startDate],
  )

  return (
    <Grid container item xs={12} justify="space-around">
      {tourWeeks.map(({ weekStartDate, days }) => (
        <Grid container item xs={12} alignItems="center" style={{ paddingBottom: theme.spacing(2) }}>
          {tourWeeks.length > 1 && (
            <Grid item>
              <Text bold>{` ${t(`week_picker.calendar_week_short`, { week: moment(weekStartDate).isoWeek() })} `}</Text>
            </Grid>
          )}
          {days.map((day) => (
            <Grid
              xs
              item
              container
              key={`${day.week}-${day.day}`}
              direction="column"
              style={{ flexBasis: "content", textAlign: "center" }}
            >
              <Grid item>
                <Tooltip
                  title={moment(startDate)
                    .isoWeekday(dayToNumber(day.day))
                    .add(day.week - 1, "week")
                    .format(t("date_format"))}
                >
                  <Chip clickable label={t(`weekdays.${day.day}_short`)} color="primary"></Chip>
                </Tooltip>
              </Grid>
              {drivingDays
                ?.filter(isSameWeekDay(day))
                .map((dd) => (
                  <Grid key={dd.vehicle?.id} item>
                    <Tooltip
                      style={{ cursor: "pointer" }}
                      title={
                        <React.Fragment>
                          {dd.vehicle?.operating_times
                            ?.filter((ot) => ot.day === day.day)
                            .map((ot) => (
                              <>
                                <Typography>{dd.vehicle?.licence_plate}</Typography>
                                <Typography>
                                  {ot.min_tour_duration}h - {ot.max_tour_duration}h
                                </Typography>
                                <Typography>
                                  {t("tour_generation.tour_parameters_dialog.data.departure_point", {
                                    departure_point: dd.departure_point.name,
                                  })}
                                </Typography>
                                <Typography>
                                  {t("tour_generation.tour_parameters_dialog.data.end_point", {
                                    departure_point: dd.end_point.name,
                                  })}
                                </Typography>
                              </>
                            ))}
                        </React.Fragment>
                      }
                    >
                      <b>{dd.vehicle?.licence_plate.substring(0, 6) ?? "-"}</b>
                    </Tooltip>
                  </Grid>
                ))}
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
