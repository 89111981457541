import { StopInfo, StopFilllevelMaterial } from "../api/graphql/queries/get-tour-stats-with-id"
import lodash from "lodash"

export const getFilllevel = (stopInfo: StopInfo, material: StopFilllevelMaterial) => {
  let filllevel = lodash.get(
    stopInfo.stop_filllevels.find((stopfilllevel) => stopfilllevel.material.id === material.id),
    "filllevel",
    "-",
  )
  if (filllevel !== "-") {
    filllevel += "%"
  }

  return filllevel
}
