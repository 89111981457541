import { gql } from "@apollo/client"
import { removeRouteStopEventInfo, removeRouteStopEventInfoVariables } from "./types/removeRouteStopEventInfo"

export const REMOVE_ROUTE_STOP_EVENT_INFO = gql`
  mutation removeRouteStopEventInfo($routeStopEventId: ID!) {
    removeRouteStopEventInfo(
      routeStopEventId: $routeStopEventId
    ) {
      message
    }
  }
`
export type RemoveRouteStopEventInfoResult = removeRouteStopEventInfo
export type RemoveRouteStopEventInfoVariables = removeRouteStopEventInfoVariables
