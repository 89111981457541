import {
  getDocumentTemplate,
  getDocumentTemplateVariables,
  getDocumentTemplate_getDocumentTemplate,
} from "./types/getDocumentTemplate"
import { gql } from "@apollo/client"

export const GET_DOCUMENT_TEMPLATE = gql`
  query getDocumentTemplate($type: DocumentTemplateType!) {
    getDocumentTemplate(type: $type) {
      url
      language
      type
    }
  }
`

export type GetDocumentTemplateResult = getDocumentTemplate
export type GetDocumentTemplateVariables = getDocumentTemplateVariables
export type DocumentTemplate = getDocumentTemplate_getDocumentTemplate
