import React, { FunctionComponent, createContext } from "react"
import { useQuery } from "@apollo/client"
import {
  DeparturePoint,
  GET_DEPARTURE_POINTS,
  GetDeparturePointsResult,
} from "../../../../../../api/graphql/queries/get-departure-points"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

interface IDeparturePointContextProviderProps {
  partnerId?: number
}

interface IDeparturePointContext {
  departurePoints: DeparturePoint[]
  departurePointsLoading: boolean
  refetchDeparturePoints: () => void
  partnerId?: number
}

export const DeparturePointContext = createContext<IDeparturePointContext>({
  departurePoints: [],
  departurePointsLoading: false,
  refetchDeparturePoints: () => {},
  partnerId: undefined,
})

export const DeparturePointContextProvider: FunctionComponent<IDeparturePointContextProviderProps> = (
  props,
) => {
  const { partnerId, children } = props
  const { t } = useTranslation()
  const {
    data: departurePointsData,
    loading: departurePointsLoading,
    refetch: refetchDeparturePoints,
  } = useQuery<GetDeparturePointsResult>(GET_DEPARTURE_POINTS, {
    variables: { collectionPartnerId: partnerId },
    skip: !partnerId,
    onError: () => toast.error(t("departure_point_context.errors.could_not_load")),
  })

  const departurePoints = departurePointsData?.getDeparturePoints || []

  return (
    <DeparturePointContext.Provider
      value={{ departurePoints, departurePointsLoading, refetchDeparturePoints, partnerId }}
    >
      {children}
    </DeparturePointContext.Provider>
  )
}
