import { gql } from "@apollo/client"
import { fileExports } from "./types/fileExports"
import { FExport } from "./types/FExport"


export const FILE_EXPORT_QUERY = gql`
  fragment FExport on FileExport {
    id
    filename
    status
  }
  query fileExports {
    fileExports {
      ...FExport
    }
  }
`

export type FileExport = FExport
export type FileExportsResult = fileExports
