import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Theme, makeStyles, Card, Grid, Button, TextField, Avatar, SnackbarContent } from "@material-ui/core"
import { gql, useApolloClient } from "@apollo/client"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { useTranslation } from "react-i18next"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { useHistory } from "react-router-dom"

import { ApiManager } from "../../../api/rest/api-manager"
import { Text } from "../../partials/wrapper/text"
import { PATH } from "../../../router/router"
import { theme } from "../../../styles/theme"
import { UserService } from "../../../services/user-service"
import jwt from "jsonwebtoken"
import { UserGroups } from "../../../models/user-groups"
import { UserContext } from "../../../context/user-context"

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    width: "100%",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  card: {
    height: 370,
    width: 320,
  },
  logo: {
    width: "60%",
  },
  logoContainer: {
    height: 50,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.dark,
  },
  loginContainer: {
    padding: 10,
    flex: 1,
  },
  fieldsContainer: {
    flex: 1,
  },
  button: {
    margin: 10,
  },
  formContainer: {
    height: "100%",
  },
  snackBar: {
    backgroundColor: theme.palette.error.dark,
    marginTop: 10,
  },
}))

interface ILoginPageProps {}

export const LoginPage: FunctionComponent<ILoginPageProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [shownError, setShownError] = useState<string | null>(null)
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false)
  const client = useApolloClient()
  const { loadUser } = useContext(UserContext)

  const onSnackBarClose = () => {
    setShownError(null)
  }

  const onLoginButtonClicked = async () => {
    setIsLoginDisabled(true)
    const validEmail = isValidEmail(email)
    const validPassword = isValidPassword(password)
    setIsEmailError(!validEmail)
    setIsPasswordError(!validPassword)

    if (validEmail && validPassword) {
      try {
        const res = await ApiManager.login(email, password)
        if (res.status >= 300) {
          throw Error()
        }

        const token = res.data.token
        const payload = jwt.decode(token) as any
        const role = payload && payload.role

        if (UserGroups.PORTAL_ACCESS.includes(role)) {
          UserService.login(token)
          client.writeQuery({
            query: gql`
              query {
                authStatus @client {
                  __typename
                  status
                }
              }
            `,
            data: {
              authStatus: {
                __typename: "authStatus",
                status: "loggedIn",
              },
            },
          })

          await loadUser()

          history.push(PATH.DASHBOARD.route)
        } else {
          setShownError(t("errors.no_access"))
          setIsEmailError(false)
          setIsPasswordError(false)
          setIsLoginDisabled(false)
        }
      } catch (error) {
        console.log(error)
        setShownError(t("errors.wrong_login"))
        setIsEmailError(true)
        setIsPasswordError(true)
        setIsLoginDisabled(false)
      }
    } else {
      setShownError(t("errors.wrong_login"))
      setIsLoginDisabled(false)
    }
  }

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const isValidPassword = (password: string) => {
    return password.length > 0
  }

  const onEnter = (ev: any) => {
    if (ev.key === "Enter") {
      onLoginButtonClicked()
    }
  }

  return (
    <Fragment>
      <Grid container direction="column" justify="center" alignItems="center" className={classes.pageContainer}>
        <Grid item>
          <Card raised className={classes.card}>
            <Grid container direction="column" className={classes.formContainer}>
              <Grid container justify="center" alignItems="center" className={classes.logoContainer}>
                <img src={theme.LOGO_PATH} className={classes.logo} alt="" />
              </Grid>

              <Grid container direction="column" alignItems="center" className={classes.loginContainer}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Text variant="h5">{t("sign_in")}</Text>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email_address")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={isEmailError}
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value)
                  }}
                  onKeyPress={onEnter}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={isPasswordError}
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                  onKeyPress={onEnter}
                />
              </Grid>
              <Button
                className={classes.button}
                variant="contained"
                type="button"
                color="primary"
                onClick={onLoginButtonClicked}
                disabled={isLoginDisabled}
              >
                {t("login")}
              </Button>
            </Grid>
          </Card>
        </Grid>
        <Grid>
          {shownError && (
            <SnackbarContent
              className={classes.snackBar}
              message={shownError}
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onSnackBarClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}
