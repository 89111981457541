import React, { FunctionComponent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Theme } from "@material-ui/core"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { PartnerSelector } from "./partner-selector"
import { PartnerData } from "./partner-data"
import { RefetchProvider } from "../../../../context/refetch-context"
import { UserService } from "../../../../services/user-service"
import { UserGroups } from "../../../../models/user-groups"

const useStyles = makeStyles((theme: Theme) => ({
  data: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    paddingLeft: UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) ? theme.spacing(2) : 0,
    paddingRight: theme.spacing(2),
    marginLeft: UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) ? theme.spacing(2) : 0,
    overflowY: "auto",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
    flexFlow: "column",
  },
  pageHeadingContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "0 0 auto",
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  contentContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%",
  },
}))

interface IPartnerOverviewProps {}

export const PartnerOverview: FunctionComponent<IPartnerOverviewProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles(props)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.pageHeadingContainer}>
        <PageHeading>{t("partner_overview.heading")}</PageHeading>
      </Grid>
      <Grid container item direction="row" className={classes.contentContainer}>
        <RefetchProvider>
          {UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) && <PartnerSelector />}
          <Grid className={classes.data}>
            <PartnerData />
          </Grid>
        </RefetchProvider>
      </Grid>
    </Grid>
  )
}
