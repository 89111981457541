import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { de, de_industry } from "../translations/de"
import { en, en_industry } from "../translations/en"
import LngDetector from "i18next-browser-languagedetector"

export const I18N_LOCAL_STORAGE = "i18nextLng"

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LngDetector)
  .init({
    resources: {
      de: {
        translation: de,
      },
      de_industry: {
        translation: de_industry,
      },
      en: {
        translation: en,
      },
      en_industry: {
        translation: en_industry,
      },
    },
    fallbackLng: {
      de_industry: ["de"],
      en_industry: ["en"],
      default: ["en"],
    },
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "navigator", "htmlTag", "path", "subdomain", "localStorage"],
      lookupLocalStorage: I18N_LOCAL_STORAGE,
    },
  })

export default i18n
