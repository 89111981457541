import React, { FunctionComponent, useCallback, useState, useEffect } from "react"
import { Theme, makeStyles, Typography, Grid } from "@material-ui/core"
import { useDropzone } from "react-dropzone"
import lodash from "lodash"
import classnames from "classnames"

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    cursor: "pointer",
  },
  text: {
    textAlign: "center",
    color: "#979797",
    fontSize: "16px",
    fontWeight: "bold",
  },
  underline: {
    cursor: "auto",
  },
}))

interface IDropzoneProps {
  files: File[]
  text: string
  updateFiles: (files: File[]) => void
  accept: string
  multiple?: boolean
  onAbort?: () => void
  onError?: () => void
  className?: string
  underline?: React.ReactNode
}

export const Dropzone: FunctionComponent<IDropzoneProps> = (props) => {
  const classes = useStyles()
  const { updateFiles, text, files, accept, onAbort, onError, className, multiple = false, underline } = props
  const [currentFile, setCurrentFile] = useState<File>()

  useEffect(() => {
    if (currentFile) {
      updateFiles(multiple ? files.concat(currentFile) : [currentFile])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => {
        if (lodash.isFunction(onAbort)) {
          onAbort()
        }
      }
      reader.onerror = () => {
        if (lodash.isFunction(onError)) {
          onError()
        }
      }
      reader.onload = () => {
        setCurrentFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, multiple })

  return (
    <div {...getRootProps()} className={classnames(classes.dropzone, className)}>
      <input {...getInputProps()} />
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
        <Grid item onClick={(e) => e.stopPropagation()} className={classes.underline}>
          {underline}
        </Grid>
      </Grid>
    </div>
  )
}
