import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"
import { Text } from "../../../../partials/wrapper/text"

const FONT_SIZE = 13

const useStyles = makeStyles((theme: Theme) => ({
  propertyName: {
    paddingRight: theme.spacing(1 / 2),
  },
  name: {
    fontSize: FONT_SIZE,
  },
  value: {
    whiteSpace: "break-spaces",
    fontSize: FONT_SIZE,
  },
}))

export interface ITourOverviewMapMarkerTooltipPropertyProps {
  name: string
  content?: string | null
  classNames?: {
    root?: string | undefined
    name?: string | undefined
    content?: string | undefined
  }
}

export const TourOverviewMapMarkerTooltipProperty: FunctionComponent<ITourOverviewMapMarkerTooltipPropertyProps> = (
  props,
) => {
  const classes = useStyles()
  const { name, content, classNames } = props
  return (
    <Fragment>
      <Grid
        item
        container
        xs={6}
        justify="space-between"
        alignItems="flex-start"
        className={`${classes.propertyName} ${classNames?.root}`}
      >
        <Text bold className={`${classes.name} ${classNames?.name}`}>
          {name}
        </Text>
        <Text bold className={`${classes.name} ${classNames?.name}`}>
          :
        </Text>
      </Grid>
      <Grid item xs={6}>
        <Text bold={false} className={`${classes.value} ${classNames?.content}  ${classNames?.root}`}>
          {content}
        </Text>
      </Grid>
    </Fragment>
  )
}
