import React, { Fragment, useMemo, useCallback, useContext } from "react"
import { useState } from "react"
import {
  Drawer,
  Grid,
  Icon,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  ListItem,
  Typography,
  Divider,
} from "@material-ui/core"
import { useLocation, useHistory, matchPath } from "react-router-dom"
import lodash from "lodash"

import { LogoutDialog } from "../wrapper/logout-dialog"
import { ResetDialog } from "../wrapper/reset-dialog"
import { CloneDialog } from "../wrapper/clone-dialog"
import { CloneDbStatusDialog } from "../wrapper/clone-db-status-dialog"
import { DRAWER_WIDTH_OPEN, theme } from "../../../styles/theme"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { PATH } from "../../../router/router"
import { Text } from "../wrapper/text"
import { RESET_DATABASE_QUERY, ResetDatabaseResult } from "../../../api/graphql/queries/reset-database"
import { REQUEST_DB_CLONING_MUTATION, RequestDbCloningResult } from "../../../api/graphql/mutations/request-db-cloning"
import { useLazyQuery, useApolloClient, useMutation } from "@apollo/client"
import { Create } from "@material-ui/icons"
import { UserService } from "../../../services/user-service"
import { DbCloningStatus, UserRole } from "../../../api/graphql/graphql-global-types"
import { UserInfo } from "./user-info"
import { UserContext } from "../../../context/user-context"
import { toast } from "react-toastify"
import { DownloadManagerContext } from "../download-manager/download-manager-context"

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    flexShrink: 1,
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0 4px 16px 0 rgba(100, 101, 103, 0.25)",
  },
  logo: {
    width: "80%",
    height: "100%",
  },
  logoContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    height: 80,
    backgroundColor: theme.palette.secondary.dark,
  },
  listItem: {
    paddingLeft: "14px",
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
  },
  listItemText: {
    color: "white !important",
    fontSize: "14px",
  },

  listItemTextSelected: {
    color: "white !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  listItemTextRoot: {
    padding: 0,
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  list: {
    width: "100%",
    padding: 0,
  },
  subList: {
    width: "calc(100% - 25px)",
    paddingLeft: 25,
    padding: 0,
    borderTopColor: theme.palette.secondary.main,
    borderTopWidth: 1,
    borderTopStyle: "solid",
  },
  listItemIcon: {
    color: "white",
  },
  listItemIconRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 30,
    marginRight: 10,
  },
  listItemRoot: {
    width: "100%",
    height: 50,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  subListItemRoot: {
    height: "unset",
    paddingTop: 2,
    paddingBottom: 2,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  labelContainer: {
    backgroundColor: theme.palette.secondary.dark,
    color: "white",
  },
  sectionHeading: {
    color: "white",
    fontWeight: 700,
    fontSize: 18,
    marginLeft: 16,
    maxWidth: "calc(100% - 32px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  firstSectionHeading: {
    marginTop: 8,
  },
  sectionDivider: {
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    height: 2,
  },
  labelContainerOpen: {
    height: 40,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarGrid: {
    flexWrap: "unset",
    overflow: "hidden",
    height: "100%",
  },
  iconContainer: {
    position: "relative",
    display: "flex",
  },
  administrationIcon: {
    position: "absolute",
    bottom: -2,
    left: 10,
    width: 15,
    height: 15,
    stroke: theme.palette.secondary.main,
  },
  administrationIconSelected: {
    stroke: theme.palette.primary.main,
  },
}))

interface IMenuItem {
  title: string
  icon: string
  id: string
  route?: string
  action?: () => void
  subRoutes?: string[]
  subMenuItems?: IMenuItem[]
  hasEditIcon?: boolean
  roles?: UserRole[]
}

interface ISidebarProps {
  label?: string | undefined
}

export const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { label } = props
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false)
  const [isResetDialogOpen, setIsResetDialogOpen] = useState<boolean>(false)
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false)
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState<boolean>(false)
  const [showOpenDownloadsWarning, setShowOpenDownloadsWarning] = useState<boolean>(false)

  const { downloadsFinished } = useContext(DownloadManagerContext)

  const { user, resetUser } = useContext(UserContext)

  const history = useHistory()
  const location = useLocation()
  const client = useApolloClient()

  const [resetDatabase, { loading: resetLoading }] = useLazyQuery<ResetDatabaseResult>(RESET_DATABASE_QUERY, {
    onCompleted: () => onDBResetSuccess(),
    fetchPolicy: "no-cache",
  })

  const [requestDbCloning, { loading: requestDbCloningLoading }] = useMutation<RequestDbCloningResult>(
    REQUEST_DB_CLONING_MUTATION,
    {
      onCompleted: (data) => onCloneDbRequested(data),
      onError: () => onCloneDbRequestedError(),
      fetchPolicy: "no-cache",
    },
  )

  const collectionPointModuleRouterItems: IMenuItem[] = useMemo(
    () => [
      {
        id: PATH.DASHBOARD.id,
        title: t("navigation.dashboard"),
        icon: "dashboard",
        route: PATH.DASHBOARD.route,
        roles: PATH.DASHBOARD.roles,
      },
      {
        id: PATH.COLLECTIONPOINTS.id,
        title: t("navigation.collection_points"),
        icon: "delete",
        route: PATH.COLLECTIONPOINTS.route,
        subRoutes: [PATH.COLLECTIONPOINT.route],
        roles: PATH.COLLECTIONPOINTS.roles,
      },
      {
        id: PATH.TOUR_OVERVIEW.id,
        title: t("navigation.tour_overview"),
        icon: "map",
        route: PATH.TOUR_OVERVIEW.route,
        roles: PATH.TOUR_OVERVIEW.roles,
      },
      {
        id: PATH.TOUR_GENERATION.id,
        title: t("navigation.tour_generation"),
        icon: "add_location_sharp",
        route: PATH.TOUR_GENERATION.route,
        roles: PATH.TOUR_GENERATION.roles,
      },
      {
        id: PATH.CONTAINER_WARNINGS.id,
        title: t("navigation.container_warnings"),
        icon: "warning",
        route: PATH.CONTAINER_WARNINGS.route,
        roles: PATH.CONTAINER_WARNINGS.roles,
      },
      {
        id: PATH.COLLECTIONPOINTS_ADMINISTRATION.id,
        title: t("navigation.administration.collection_points"),
        icon: "delete",
        route: PATH.COLLECTIONPOINTS_ADMINISTRATION.route,
        hasEditIcon: true,
        roles: PATH.COLLECTIONPOINTS_ADMINISTRATION.roles,
      },
    ],
    [t],
  )

  const householdModuleRouterItems: IMenuItem[] = useMemo(
    () => [
      {
        id: PATH.HOUSEHOLD_OVERVIEW.id,
        title: t("navigation.household_overview"),
        icon: "delete",
        route: PATH.HOUSEHOLD_OVERVIEW.route,
        roles: PATH.HOUSEHOLD_OVERVIEW.roles,
      },
    ],
    [t],
  )

  const generalRouterItems: IMenuItem[] = useMemo(
    () => [
      {
        id: PATH.PARTNER_OVERVIEW.id,
        title: t("navigation.partner_overview"),
        icon: "local_shipping",
        route: PATH.PARTNER_OVERVIEW.route,
        subRoutes: [PATH.VEHICLE_OVERVIEW.route],
        roles: PATH.PARTNER_OVERVIEW.roles,
      },
      {
        id: PATH.DISPOSAL_MERCHANT_OVERVIEW.id,
        title: t("navigation.disposal_merchant_overview"),
        icon: "location_city",
        route: PATH.DISPOSAL_MERCHANT_OVERVIEW.route,
        roles: PATH.DISPOSAL_MERCHANT_OVERVIEW.roles,
      },
      {
        id: PATH.USER_MANAGEMENT.id,
        title: t("navigation.administration.user_management"),
        icon: "people",
        route: PATH.USER_MANAGEMENT.route,
        hasEditIcon: true,
        roles: PATH.USER_MANAGEMENT.roles,
      },
      {
        id: PATH.ROUTES_OVERVIEW.id,
        title: t("navigation.routes_overview"),
        icon: "navigation",
        route: PATH.ROUTES_OVERVIEW.route,
        roles: PATH.ROUTES_OVERVIEW.roles,
      },
      {
        id: PATH.GUIDANCELOCATIONS_ADMINISTRATION.id,
        title: t("navigation.guidance_administration.overview"),
        icon: "tune",
        subRoutes: [PATH.GUIDANCELOCATIONS_ADMINISTRATION.route],
        subMenuItems: [
          {
            id: PATH.GUIDANCELOCATIONS_ADMINISTRATION.id,
            title: t("navigation.guidance_administration.driver_locations"),
            icon: "delete",
            route: PATH.GUIDANCELOCATIONS_ADMINISTRATION.route,
            hasEditIcon: true,
            roles: PATH.GUIDANCELOCATIONS_ADMINISTRATION.roles,
          },
        ],
      },
    ],
    [t],
  )

  const bottomActions: IMenuItem[] = useMemo(() => {
    const actions = [
      {
        id: "logout",
        title: t("navigation.logout"),
        icon: "exit_to_app",
        action: () => {
          setIsLogoutDialogOpen(true)
        },
      },
    ]

    if (user?.role === UserRole.SUPER_ADMIN && window.location.hostname.startsWith("test-")) {
      actions.splice(0, 0, {
        id: "reset",
        title: t("navigation.reset"),
        icon: "restore",
        action: () => {
          setIsResetDialogOpen(true)
        },
      })
    }

    if (
      user?.role === UserRole.SUPER_ADMIN &&
      (window.location.hostname.startsWith("sta-") || window.location.hostname.startsWith("test-"))
    ) {
      actions.splice(0, 0, {
        id: "status",
        title: t("navigation.status"),
        icon: "info",
        action: () => {
          setIsStatusDialogOpen(true)
        },
      })
      actions.splice(0, 0, {
        id: "clone",
        title: t("navigation.clone"),
        icon: "cloud_download",
        action: () => {
          setIsCloneDialogOpen(true)
        },
      })
    }

    return actions
  }, [t, user])

  const onLogout = useCallback(() => {
    if (downloadsFinished || showOpenDownloadsWarning) {
      setIsLogoutDialogOpen(false)
      setShowOpenDownloadsWarning(false)
      UserService.logout(client)
      history.push(PATH.LOGIN.route)
      resetUser()
    } else {
      setShowOpenDownloadsWarning(true)
    }
  }, [downloadsFinished, showOpenDownloadsWarning, client, history, resetUser])

  const onDBResetSuccess = useCallback(() => {
    const timeout = 5000
    toast.success(t("reset.success", { timeout: Math.round(timeout / 1000) }))
    setTimeout(onLogout, timeout)
  }, [onLogout, t])

  const onCloneDbRequested = useCallback(
    (data: RequestDbCloningResult) => {
      switch (data.requestDbCloning.status) {
        case DbCloningStatus.Running:
          const timeout = 30000
          setTimeout(onLogout, timeout)
          toast.success(t("clone_db_request.success", { timeout: Math.round(timeout / 1000) }))
          break
        case DbCloningStatus.DatabasesIncompatibleError:
          toast.error(t("clone_db_request.errors.incompatible"))
          break
        default:
          toast.error(t("clone_db_request.errors.unknown"))
      }
    },
    [onLogout, t],
  )

  const onCloneDbRequestedError = useCallback((): void => {
    toast.warn(t("clone_db_request.not_allowed"))
  }, [t])

  const onMenuItemClick = useCallback(
    (item: IMenuItem) => {
      if (item.route) {
        history.push(item.route)
      } else if (item.action) {
        item.action()
      } else if (item.subMenuItems && item.subMenuItems.length > 0 && item.subMenuItems[0].route) {
        history.push(item.subMenuItems[0].route)
      }
    },
    [history],
  )

  const isItemSelected = useCallback(
    (item: IMenuItem): boolean => {
      if (
        !!matchPath(location.pathname, { path: item.route }) ||
        lodash.some(item.subRoutes || [], (subRoute) => !!matchPath(location.pathname, { path: subRoute }))
      ) {
        return true
      }
      return false
    },
    [location],
  )

  const renderMenu = useCallback(
    (menuItems: IMenuItem[], isSubList: boolean) => {
      return (
        <List className={isSubList ? classes.subList : classes.list}>
          {menuItems.map((item, index) => (
            <Fragment key={index}>
              <ListItem
                button={true}
                disableGutters={true}
                classes={{
                  root: classNames(classes.listItemRoot, {
                    [classes.subListItemRoot]: isSubList,
                  }),
                  selected: classes.selected,
                }}
                onClick={() => onMenuItemClick(item)}
                selected={isItemSelected(item)}
              >
                <ListItemIcon
                  classes={{
                    root: classes.listItemIconRoot,
                  }}
                >
                  <div className={classes.iconContainer}>
                    <Icon className={classes.listItemIcon}>{item.icon}</Icon>
                    {item.hasEditIcon && (
                      <Create
                        className={classNames(classes.listItemIcon, classes.administrationIcon, {
                          [classes.administrationIconSelected]: isItemSelected(item),
                        })}
                      />
                    )}
                  </div>
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  classes={{
                    primary: isItemSelected(item) ? classes.listItemTextSelected : classes.listItemText,
                    root: classes.listItemTextRoot,
                  }}
                />
              </ListItem>
              {!lodash.isNil(item.subMenuItems) &&
                !lodash.isEmpty(item.subMenuItems) &&
                renderMenu(item.subMenuItems, true)}
            </Fragment>
          ))}
        </List>
      )
    },
    [classes, isItemSelected, onMenuItemClick],
  )

  //callback for recursively filtering menuItems for roles
  const filterMenuItems = useCallback(
    (menuItems: IMenuItem[]): IMenuItem[] =>
      menuItems
        .map((menuItem) => {
          const hasRightToMenuItem = menuItem.roles ? menuItem.roles.includes(UserService.getRole()) : true

          if (!hasRightToMenuItem) {
            return null
          }

          let filteredSubMenuItems: IMenuItem[] = []
          if (menuItem.subMenuItems && menuItem.subMenuItems.length > 0) {
            filteredSubMenuItems = filterMenuItems(menuItem.subMenuItems)
          }

          //remove menuItem, if it has no route and no subMenu Items
          if (!menuItem.route && filteredSubMenuItems.length <= 0) {
            return null
          }

          return {
            ...menuItem,
            subMenuItems: filteredSubMenuItems,
          }
        })
        .filter((filteredMenuItem) => filteredMenuItem !== null) as IMenuItem[],
    [],
  )

  const generalMenuItems = useMemo(() => filterMenuItems(generalRouterItems), [filterMenuItems, generalRouterItems])
  const collectionPointModuleMenuItems = useMemo(() => filterMenuItems(collectionPointModuleRouterItems), [
    filterMenuItems,
    collectionPointModuleRouterItems,
  ])
  const householdModuleMenuItems = useMemo(() => filterMenuItems(householdModuleRouterItems), [
    filterMenuItems,
    householdModuleRouterItems,
  ])

  return (
    <Drawer
      variant={"permanent"}
      anchor={"left"}
      className={classNames(classes.drawer, classes.drawerOpen)}
      classes={{
        paper: classNames(classes.drawerPaper, classes.drawerOpen),
      }}
      open
    >
      <Grid
        container
        alignContent={"stretch"}
        direction={"column"}
        className={classes.sidebarGrid}
        justify="space-between"
      >
        <Grid item container>
          <Grid item className={classes.logoContainer} xs={12}>
            <Grid container={true} alignContent={"center"} justify={"center"} style={{ height: "100%" }}>
              <img src={theme.LOGO_PATH} className={classes.logo} alt="" />
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            {label && (
              <Grid
                item
                container
                xs={12}
                justify="center"
                alignContent="center"
                className={classNames(classes.labelContainer, classes.labelContainerOpen)}
              >
                <Text>{label}</Text>
              </Grid>
            )}
            <Grid item xs={12} container alignItems="center">
              {user?.rfidModuleEnabled && (
                <Typography className={classNames(classes.sectionHeading, classes.firstSectionHeading)}>
                  {t("portal.sidebar.collection_points")}
                </Typography>
              )}
              {renderMenu(collectionPointModuleMenuItems, false)}
              {user?.rfidModuleEnabled && (
                <>
                  <Divider className={classes.sectionDivider} />
                  <Typography className={classes.sectionHeading}>{t("portal.sidebar.households")}</Typography>
                  {renderMenu(householdModuleMenuItems, false)}
                  <Divider className={classes.sectionDivider} />
                </>
              )}
              {renderMenu(generalMenuItems, false)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item xs={12}>
            {renderMenu(bottomActions, false)}
          </Grid>
          <Grid item xs={12}>
            <UserInfo />
          </Grid>
        </Grid>
      </Grid>

      <LogoutDialog
        onLogout={onLogout}
        showOpenDownloadsWarning={showOpenDownloadsWarning}
        onClose={() => {
          setIsLogoutDialogOpen(false)
          setShowOpenDownloadsWarning(false)
        }}
        open={isLogoutDialogOpen}
      />
      <ResetDialog
        loading={resetLoading}
        onReset={() => {
          setIsResetDialogOpen(false)
          resetDatabase()
        }}
        onClose={() => setIsResetDialogOpen(false)}
        open={isResetDialogOpen}
      />
      <CloneDialog
        loading={requestDbCloningLoading}
        onAccept={() => {
          setIsCloneDialogOpen(false)
          requestDbCloning()
        }}
        onClose={() => setIsCloneDialogOpen(false)}
        open={isCloneDialogOpen}
      />
      <CloneDbStatusDialog onClose={() => setIsStatusDialogOpen(false)} open={isStatusDialogOpen} />
    </Drawer>
  )
}
