import React, { FC } from "react"
import { Grid, Chip, CircularProgress } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { ILocationCollectionItem } from "./location-assignment-dialog"
import { sortASC } from "../../../../utils/sort"
import { DistrictsResult, GET_DISTRICTS_QUERY } from "../../../../api/graphql/queries/get-districts"

interface IDistrictSelectionProps {
  selectedItems: ILocationCollectionItem[]
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
}

export const DistrictSelection: FC<IDistrictSelectionProps> = (props) => {
  const { onClick, selectedItems, searchString } = props

  const { data, loading } = useQuery<DistrictsResult>(GET_DISTRICTS_QUERY)

  const districts =
    data?.getDistricts
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((district) => district.name.toLowerCase().includes(searchString.toLowerCase())) || []

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        districts.map((district) => (
          <Grid item key={district.id}>
            <Chip
              label={district.name}
              color={
                selectedItems.find((item) => item.id === district.id && item.name === district.name)
                  ? "primary"
                  : "default"
              }
              onClick={() => onClick(district)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
