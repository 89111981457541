import { gql } from "@apollo/client"
import {
  createCollectionPoint,
  createCollectionPointVariables,
  createCollectionPoint_createCollectionPoint
} from "./types/createCollectionPoint"

export const CREATE_COLLECTION_POINT_MUTATION = gql`
  mutation createCollectionPoint(
    $id: ID
    $description: String!
    $location_number: String
    $street: String
    $postal: String
    $cadastral_name: String
    $cadastral_nr: String
    $place: String
    $town_id: ID!
    $fixed_unload_interval: Int
    $fixedInterval: Boolean
    $latitude: Float!
    $longitude: Float! 
    $overfill_manipulation_time: Float! 
    $overfill_rate: Float!
    $extrapolation_weightings: ExtrapolationWeightingsInput
  ) {
    createCollectionPoint(
      id: $id
      description: $description
      location_number: $location_number
      street: $street
      postal: $postal
      cadastral_name: $cadastral_name
      cadastral_nr: $cadastral_nr
      place: $place
      town_id: $town_id
      fixed_unload_interval: $fixed_unload_interval
      fixedInterval: $fixedInterval
      latitude: $latitude
      longitude: $longitude 
      overfill_rate: $overfill_rate
      overfill_manipulation_time: $overfill_manipulation_time
      extrapolation_weightings: $extrapolation_weightings
    ) {
      id
      cadastral_name
      cadastral_nr
      description
      location_number
      town {
        id
        name
      }
      fixed_unload_interval
      fixedInterval
      latitude
      longitude
      place
      postal
      street
      extrapolation_weightings {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
`
export type CreateCollectionPointResult = createCollectionPoint
export type CreateCollectionPoint = createCollectionPoint_createCollectionPoint
export type CreateCollectionPointVariables = createCollectionPointVariables
