import { gql } from "@apollo/client"
import {
  deleteDriverLocation,
  deleteDriverLocationVariables,
  deleteDriverLocation_deleteDriverLocation
} from "./types/deleteDriverLocation"

export const REMOVE_DRIVER_LOCATION_MUTATION = gql`
  mutation deleteDriverLocation($id: ID!) {
    deleteDriverLocation(id: $id) {
      error
    }
  }
`

export type RemoveDriverLocationResult = deleteDriverLocation
export type RemoveDriverLocation = deleteDriverLocation_deleteDriverLocation
export type RemoveDriverLocationVariables = deleteDriverLocationVariables
