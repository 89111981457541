import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, makeStyles, IconButton, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DatePicker, DatePickerProps } from "@material-ui/pickers"
import moment, { Moment } from "moment"
import classnames from "classnames"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  datepicker: {
    width: "100%",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
  },
  highlightInCurrentMonthDay: {
    color: "inherit",
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlightDisabled: {
    color: "#bfbfbf",
  },
  toolbarContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  yearLabel: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}))

// remove "onChange" from props as it returns nullable value (use "onWeekChanged" instead)
type WeekPickerProps = Omit<DatePickerProps, "onChange"> & {
  label?: string
  value?: moment.Moment
  onWeekChanged: (date: moment.Moment) => void
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

export const WeekPicker: FunctionComponent<WeekPickerProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { label, value, minDate, maxDate, onWeekChanged, ...rest } = props
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment())

  useEffect(() => {
    if (!lodash.isNil(value)) {
      setSelectedDate(value)
    }
  }, [value])

  const handleWeekChange = (date: moment.Moment | null) => {
    if (!lodash.isNil(date)) {
      setSelectedDate(date)
      onWeekChanged(date.startOf("week"))
    }
  }

  const renderWrappedWeekDay = (
    date: moment.Moment | null,
    selectedDate: moment.Moment | null,
    dayInCurrentMonth: boolean,
  ) => {
    const selected = selectedDate ? selectedDate : moment()
    const firstDay = selected.clone().startOf("week")
    const lastDay = selected.clone().endOf("week")

    return (
      <div
        className={classnames({
          [classes.highlight]: date!.isSameOrBefore(lastDay) && date!.isSameOrAfter(firstDay),
          [classes.firstHighlight]: date!.isSame(firstDay, "day"),
          [classes.endHighlight]: date!.isSame(lastDay, "day"),
        })}
      >
        <IconButton
          className={classnames(classes.day, {
            [classes.highlightInCurrentMonthDay]: dayInCurrentMonth,
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]:
              !dayInCurrentMonth && date!.isSameOrBefore(lastDay) && date!.isSameOrAfter(firstDay),
            [classes.highlightDisabled]: (maxDate && date!.isAfter(maxDate)) || (minDate && date!.isBefore(minDate)),
          })}
        >
          <span> {date!.clone().format("DD")} </span>
        </IconButton>
      </div>
    )
  }

  const formatWeekSelectLabel = (date: moment.Moment | null) => {
    return date
      ? t("week_picker.calendar_week_formatted", {
          week: date.format("WW"),
          year: date.format("YYYY"),
        })
      : ""
  }

  return (
    <DatePicker
      className={classes.datepicker}
      label={label || t("week_picker.calendar_week")}
      value={selectedDate}
      onChange={handleWeekChange}
      renderDay={renderWrappedWeekDay}
      labelFunc={formatWeekSelectLabel}
      clearable={false}
      minDate={minDate}
      maxDate={maxDate}
      ToolbarComponent={({ date }) => (
        <Grid container className={classes.toolbarContainer} justify="flex-start" direction="column">
          <Grid item>
            <Typography variant="subtitle1" className={classes.yearLabel}>
              {date?.format("YYYY")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">{t("week_picker.week_number", { week: date?.format("WW") })}</Typography>
          </Grid>
        </Grid>
      )}
      {...rest}
    />
  )
}
