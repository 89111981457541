import { gql } from "@apollo/client"
import {
  getCollectionPointsForMap,
  getCollectionPointsForMapVariables,
  getCollectionPointsForMap_getCollectionPointsWithRegionID_entries,
  getCollectionPointsForMap_getCollectionPointsWithRegionID_entries_containers,
} from "./types/getCollectionPointsForMap"

export const GET_COLLECTIONPOINTS_FOR_MAP_QUERY = gql`
  query getCollectionPointsForMap(
    $id: Int!
    $type: RegionType!
    $page: Int!
    $pagesize: Int!
    $filter: RegionFilterInput
    $date: DateTime
  ) {
    getCollectionPointsWithRegionID(id: $id, type: $type, page: $page, pagesize: $pagesize, filter: $filter) {
      entries {
        id
        latitude
        longitude
        collectionPointFilllevels(sorted: false, date: $date) {
          filllevel
          material_id
        }
        containers(sorted: false) {
          id
          last_time_send
          material_id
          lid_open
          lid_changed
          sensor_id
          defectiveSensor
        }
        fixed_unload_interval
      }
    }
  }
`

export type GetCollectionPointsForMapResult = getCollectionPointsForMap
export type GetCollectionPointsForMapVariables = getCollectionPointsForMapVariables
export type MapCollectionPoint = getCollectionPointsForMap_getCollectionPointsWithRegionID_entries
export type MapCollectionPointContainer = getCollectionPointsForMap_getCollectionPointsWithRegionID_entries_containers
