import { useLazyQuery, useMutation, useQuery, useSubscription } from "@apollo/client"
import React, { createContext, FunctionComponent, useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { FileExportStatus } from "../../../api/graphql/graphql-global-types"
import {
  CancelFileExportsResult,
  CancelFileExportsVariables,
  CANCEL_FILE_EXPORTS_MUTATION,
} from "../../../api/graphql/mutations/cancel-file-exports"
import {
  MarkFileExportAsDownloadedResult,
  MarkFileExportAsDownloadedVariables,
  MARK_FILE_EXPORTS_AS_DOWNLOADED_MUTATION,
} from "../../../api/graphql/mutations/mark-file-export-as-downloaded"
import {
  DownloadFileResult,
  DownloadFileVariables,
  DOWNLOAD_FILE_QUERY,
} from "../../../api/graphql/queries/download-file"
import { FileExport, FileExportsResult, FILE_EXPORT_QUERY } from "../../../api/graphql/queries/file-exports"
import {
  FileExportsSubscriptionResult,
  FILE_EXPORT_SUBSCRIPTION,
} from "../../../api/graphql/subscriptions/subscribe-file-exports"
import { ContentType, downloadBase64 } from "../../../utils/browser"
import i18next from "i18next"

export interface IDownloadManagerContext {
  fileExports: IFileExportItem[]
  cancelSingleFileExport: (id: string) => void
  cancelAllFileExport: () => void
  removeCompletedExport: (id: string) => void
  cancelDialogParams: CancelDownloadDialogParams
  downloadsFinished: boolean
  setTriggerQuery: (triggerQuery: boolean) => void
}

interface CancelDownloadDialogParams {
  heading: string
  text: string
  confirmCallack: () => void
  cancelCallback: () => void
  open: boolean
}

const defaultCancelDialogParams: CancelDownloadDialogParams = {
  heading: "",
  text: "",
  confirmCallack: () => null,
  cancelCallback: () => null,
  open: false,
}

export interface IFileExportItem extends FileExport {
  completedAt?: Date
}

export const DownloadManagerContext = createContext<IDownloadManagerContext>({
  fileExports: [],
  cancelSingleFileExport: () => null,
  cancelAllFileExport: () => null,
  removeCompletedExport: () => null,
  cancelDialogParams: defaultCancelDialogParams,
  downloadsFinished: false,
  setTriggerQuery: () => null,
})

export const DownloadManagerContextProvider: FunctionComponent = (props) => {
  const [fileExports, setFileExports] = useState<IFileExportItem[]>([])
  const [cancelDialogParams, setCancelDialogParams] = useState<CancelDownloadDialogParams>(defaultCancelDialogParams)
  const [triggerQuery, setTriggerQuery] = useState<boolean>(false)

  useQuery<FileExportsResult>(FILE_EXPORT_QUERY, {
    onCompleted: (data) => {
      if (data.fileExports) {
        updateFileExports(data.fileExports)
      }
    },
  })

  const [fileExportsQuery] = useLazyQuery<FileExportsResult>(FILE_EXPORT_QUERY, {
    onCompleted: (data) => {
      if (data.fileExports) {
        updateFileExports(data.fileExports)
      }
    },
  })

  useEffect(() => {
    if (triggerQuery) {
      setTriggerQuery(false)
      fileExportsQuery()
    }
  }, [triggerQuery, fileExportsQuery])

  const [downloadFile] = useLazyQuery<DownloadFileResult, DownloadFileVariables>(DOWNLOAD_FILE_QUERY)

  const onMarkAsDownloadedCompleted = useCallback(
    (id) => {
      const copy = fileExports.slice()
      const item = copy.find((entry) => entry.id === id)
      if (item) {
        item.status = FileExportStatus.DOWNLOADED
        item.completedAt = new Date()
      }
      setFileExports(copy)
    },
    [fileExports],
  )

  const [markFileExportAsDownloaded] = useMutation<
    MarkFileExportAsDownloadedResult,
    MarkFileExportAsDownloadedVariables
  >(MARK_FILE_EXPORTS_AS_DOWNLOADED_MUTATION)

  const showError = useCallback(() => {
    toast.error(i18next.t("export.download_error"))
  }, [])

  const checkForDownloadableFiles = useCallback(
    (newExports: IFileExportItem[]) => {
      newExports.forEach((e) => {
        if (e.status === FileExportStatus.SUCCESS) {
          downloadFile({ variables: { id: e.id } })
            .then((response) => {
              if (response.data) {
                if (response.data.downloadFile.fileInfo) {
                  const result = downloadBase64(response.data.downloadFile.fileInfo, ContentType.EXCEL)
                  if (result) {
                    markFileExportAsDownloaded({ variables: { id: e.id } })
                      .then((data) => {
                        if (data.data?.markFileExportAsDownloaded) {
                          onMarkAsDownloadedCompleted(e.id)
                        } else {
                          showError()
                        }
                      })
                      .catch(() => showError())
                  } else {
                    showError()
                  }
                } else if (response.data.downloadFile.alreadyDownloaded) {
                  onMarkAsDownloadedCompleted(e.id)
                } else {
                  showError()
                }
              } else {
                showError()
              }
            })
            .catch(() => showError())
        }
      })
    },
    [downloadFile, markFileExportAsDownloaded, showError, onMarkAsDownloadedCompleted],
  )

  const updateFileExports = useCallback(
    (newExports: IFileExportItem[], keepOpenEntries: boolean = true) => {
      checkForDownloadableFiles(newExports)

      if (keepOpenEntries) {
        const completedItems = fileExports.filter((item) => !!item.completedAt)
        setFileExports([...completedItems, ...newExports])
      } else {
        setFileExports(newExports)
      }
    },
    [fileExports, checkForDownloadableFiles],
  )

  useSubscription<FileExportsSubscriptionResult>(FILE_EXPORT_SUBSCRIPTION, {
    onData: (options) => {
      if (options.data.data?.subscribeTofileExports) {
        updateFileExports(options.data.data.subscribeTofileExports)
      }
    },
    shouldResubscribe: true,
    skip: fileExports.every((fe) => fe.completedAt),
  })

  const [cancelFileExportsMutation] = useMutation<CancelFileExportsResult, CancelFileExportsVariables>(
    CANCEL_FILE_EXPORTS_MUTATION,
  )

  const cancelFileExports = useCallback(
    (ids: string[], cancelHeading: string, cancelText: string) => {
      let openEntries = fileExports.filter(
        (fileExport) =>
          ids.includes(fileExport.id) &&
          [FileExportStatus.PROCESSING, FileExportStatus.REQUESTED, FileExportStatus.SUCCESS].includes(
            fileExport.status,
          ),
      )

      const newEntries = openEntries.concat(fileExports.filter((fileExport) => !ids.includes(fileExport.id)))

      if (openEntries.length) {
        setCancelDialogParams({
          heading: i18next.t(cancelHeading),
          text: i18next.t(cancelText),
          open: true,
          cancelCallback: () => {
            setCancelDialogParams(defaultCancelDialogParams)
          },
          confirmCallack: () => {
            if (newEntries.length !== fileExports.length) {
              updateFileExports(newEntries, false)
            }
            cancelFileExportsMutation({ variables: { ids: openEntries.map((entry) => entry.id) } })
            setCancelDialogParams(defaultCancelDialogParams)
          },
        })
      } else {
        if (newEntries.length !== fileExports.length) {
          updateFileExports(newEntries, false)
        }
      }
    },
    [cancelFileExportsMutation, fileExports, updateFileExports],
  )

  const cancelSingleFileExport = useCallback(
    (id: string) => {
      cancelFileExports(
        [id],
        i18next.t("export.cancel_dialog.heading_single"),
        i18next.t("export.cancel_dialog.text_single"),
      )
    },
    [cancelFileExports],
  )

  const cancelAllFileExport = useCallback(() => {
    cancelFileExports(
      fileExports.map((fileExport) => fileExport.id),
      i18next.t("export.cancel_dialog.heading_all"),
      i18next.t("export.cancel_dialog.text_all"),
    )
  }, [cancelFileExports, fileExports])

  const removeCompletedExport = useCallback(
    (id: string) => {
      const item = fileExports.find((fileExport) => fileExport.id === id)
      if (item?.status === FileExportStatus.DOWNLOADED) {
        setFileExports(fileExports.filter((fileExport) => fileExport.id !== id))
      }
    },
    [fileExports],
  )

  const downloadsFinished = useMemo(
    () => fileExports.every((fileExport) => fileExport.status === FileExportStatus.DOWNLOADED),
    [fileExports],
  )

  return (
    <DownloadManagerContext.Provider
      value={{
        fileExports,
        cancelSingleFileExport,
        cancelAllFileExport,
        removeCompletedExport,
        cancelDialogParams,
        downloadsFinished,
        setTriggerQuery,
      }}
    >
      {props.children}
    </DownloadManagerContext.Provider>
  )
}
