import { gql } from "@apollo/client"
import {
  updateVehicle,
  updateVehicleVariables,
  updateVehicle_updateVehicle,
} from "./types/updateVehicle"

export const UPDATE_VEHICLE_MUTATION = gql`
  mutation updateVehicle(
    $id: ID!
    $district_id: ID!
    $collection_partner_id: ID!
    $emission_standard: EmissionStandard!
    $licence_plate: String
    $type: String
    $trailer: Boolean!
    $length: Int
    $width: Int
    $height: Int
    $has_scale: Boolean
    $vehicles_to_materials: [VehicleToMaterialInput!]
    $operating_times: [VehicleOperatingTimesInput!]
    $tmp_driver: String
    $costs_per_hour: Float
    $costs_per_km: Float
    $departure_point_id: ID
    $end_point_id: ID
  ) {
    updateVehicle(
      id: $id
      district_id: $district_id
      collection_partner_id: $collection_partner_id
      emission_standard: $emission_standard
      licence_plate: $licence_plate
      type: $type
      trailer: $trailer
      length: $length
      width: $width
      height: $height
      has_scale: $has_scale
      vehicles_to_materials: $vehicles_to_materials
      operating_times: $operating_times
      tmp_driver: $tmp_driver
      costs_per_hour: $costs_per_hour
      costs_per_km: $costs_per_km
      departure_point_id: $departure_point_id
      end_point_id: $end_point_id
    ) {
      id
    }
  }
`

export type UpdateVehicleResult = updateVehicle
export type UpdateVehicle = updateVehicle_updateVehicle
export type UpdateVehicleVariables = updateVehicleVariables
