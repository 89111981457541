import { useMemo, useState } from "react"

export enum DuplicateAction {
  Include = "include",
  Exclude = "exclude",
}

interface IUseDuplicateHandlerProps<T> {
  toProcess: T[]
  included: T[]
}

interface INextResult<T> {
  done: boolean
  included: T[]
}

interface IUseDuplicateHandler<T> {
  current: T | undefined
  all: (action: DuplicateAction) => INextResult<T>
  next: (action: DuplicateAction) => INextResult<T>
  reset: () => void
  finished: boolean
  included: T[]
}

export const useDuplicateHandler = <T,>(props: IUseDuplicateHandlerProps<T>): IUseDuplicateHandler<T> => {
  const [processing, setProcessing] = useState<T[]>(props.toProcess)
  const [included, setIncluded] = useState<T[]>(props.included)

  const next = (action: DuplicateAction): INextResult<T> => {
    const current = processing[0]
    if (!current) return { done: true, included }

    const newProcessing = processing.slice(1)
    const done = newProcessing.length <= 0
    setProcessing(newProcessing)

    if (action === DuplicateAction.Include) {
      const newIncluded = [...included, current]
      setIncluded(newIncluded)
      return { done, included: newIncluded }
    }
    return { done, included }
  }

  const all = (action: DuplicateAction): INextResult<T> => {
    const newIncluded = action === DuplicateAction.Include ? [...included, ...processing] : included
    setProcessing([])
    setIncluded(newIncluded)
    return { done: true, included: newIncluded }
  }

  const reset = (): void => {
    setProcessing(props.toProcess)
    setIncluded(props.included)
  }

  const finished = useMemo(() => processing.length === 0, [processing])

  return {
    current: processing[0],
    all,
    next,
    reset,
    finished,
    included
  }
}
