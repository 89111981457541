import React from "react"
import { HouseholdOverviewFilterContextProvider } from "./context/household-overview-filter-context"
import { HouseholdOverviewFilter } from "./partials/household-overview-filter"
import { HouseholdOverviewTable } from "./partials/household-overview-table"
import { HouseholdOverviewMap } from "./partials/household-overview-map"
import { HouseholdOverviewHeader } from "./partials/household-overview-header"
import { HouseholdOverviewStatusCards } from "./partials/household-overview-status-cards"
import { HouseholdOverviewTableContextProvider } from "./context/household-overview-table-context"
import { HouseholdOverviewStatusContextProvider } from "./context/household-overview-status-context"
import { Grid } from "@material-ui/core"

interface IHouseholdOverviewPageProps {}

export const HouseholdOverviewPage: React.FC<IHouseholdOverviewPageProps> = (_props) => {
  return (
    <Grid container direction="column" spacing={2}>
      <HouseholdOverviewFilterContextProvider>
        <Grid item>
          <HouseholdOverviewHeader />
        </Grid>
        <Grid item>
          <HouseholdOverviewFilter />
        </Grid>
        <HouseholdOverviewStatusContextProvider>
          <Grid item>
            <HouseholdOverviewStatusCards />
          </Grid>
          <Grid item>
            <HouseholdOverviewMap />
          </Grid>
        </HouseholdOverviewStatusContextProvider>
        <Grid item>
          <HouseholdOverviewTableContextProvider>
            <HouseholdOverviewTable />
          </HouseholdOverviewTableContextProvider>
        </Grid>
      </HouseholdOverviewFilterContextProvider>
    </Grid>
  )
}
