import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Card, Grid } from "@material-ui/core"

import { Text } from "../../../partials/wrapper/text"
import { INFO_CARD_WIDTH, INFO_CARD_HEIGHT } from "../../../../utils/constants"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: INFO_CARD_WIDTH + theme.spacing(2),
    height: INFO_CARD_HEIGHT,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  text: {
    margin: theme.spacing(1),
  },
}))

interface IInfoCardProps {
  header: string
  header2?: string
  children?: any
}

export const InfoCard: FunctionComponent<IInfoCardProps> = (props) => {
  const classes = useStyles(props)
  return (
    <Fragment>
      <Card className={classes.card}>
        <Grid>
          <Text className={classes.text} variant="h6">
            {props.header}
          </Text>
          {props.header2 && (
            <Text className={classes.text} variant="subtitle2">
              {props.header2}
            </Text>
          )}
          {props.children}
        </Grid>
      </Card>
    </Fragment>
  )
}
