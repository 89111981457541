import lodash from "lodash"
import { MapCollectionPoint } from "../api/graphql/queries/get-collection-points-for-map"
import { CollectionPoint } from "../api/graphql/queries/get-collection-points-with-region-id"
import { containerHasIssues } from "./container"

export const hasContainerIssues = (collectionPoint: MapCollectionPoint, problemSensorInterval: number) => {
  const containers = collectionPoint.containers
  if (!containers || containers.length === 0) {
    return false
  }
  return containers.some((container) =>
    containerHasIssues(container, collectionPoint.fixed_unload_interval, problemSensorInterval),
  )
}

export const hasOpenLids = (collectionPoint: MapCollectionPoint) => {
  const containers = collectionPoint.containers
  if (!containers || containers.length === 0) {
    return false
  }

  return containers.some((container) => container.lid_open === true)
}

export const hasDefectiveSensors = (collectionPoint: CollectionPoint) => {

  const isSensorBased = lodash.isNull(collectionPoint.fixed_unload_interval);
  const containers = collectionPoint.containers;
  
  if (!containers || containers.length === 0 || !isSensorBased) {
    return false
  }
  return containers.some((container) => container.defectiveSensor === true);
}

export const showInvalidValueInMapPin = (collectionPoint: MapCollectionPoint, problemSensorInterval: number) => {
  const hasIssues = hasContainerIssues(collectionPoint, problemSensorInterval)
  if (!hasIssues) {
    return false
  }
  const containerMaterials = lodash
    .uniqBy(collectionPoint.containers, "material_id")
    .map((container) => container.material_id)

  return !containerMaterials.every((material) =>
    collectionPoint.collectionPointFilllevels.find(
      (collectionPointFilllevel) => collectionPointFilllevel.material_id === String(material),
    ),
  )
}

export const getAddressStringForCollectionPoint = (collectionPoint: CollectionPoint) => {
  const street = collectionPoint.street || ""
  const placeInfo = `${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town.name || ""}`
  let townInfo = ""
  if (collectionPoint.town.name && collectionPoint.place && collectionPoint.town.name !== collectionPoint.place) {
    townInfo = collectionPoint.town.name
  }

  const addressParts = [street, placeInfo, townInfo]

  return addressParts.filter((part) => !!part).join(", ")
}
