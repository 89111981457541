import React, { FC } from "react"
import lodash from "lodash"
import { TableRow, TableCell } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

interface ITableBodySkeletonProps {
  columns: number
  rows: number
}

export const TableBodySkeleton: FC<ITableBodySkeletonProps> = (props) => {
  const { columns, rows } = props

  const skeleton = lodash.range(0, rows).map((row) => (
    <TableRow key={`row_${row}`}>
      {lodash.range(0, columns).map((col) => (
        <TableCell key={`row_${row}_col_${col}`}>
          <Skeleton height={40} />
        </TableCell>
      ))}
    </TableRow>
  ))

  return <>{skeleton}</>
}
