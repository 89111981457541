import { gql } from "@apollo/client"
import { updateTour, updateTourVariables, updateTour_updateTour } from "./types/updateTour"

export const UPDATE_TOUR_MUTATION = gql`
  mutation updateTour($tour_id: ID!, $stops: [StopInput!]!) {
    updateTour(tour_id: $tour_id, stops: $stops) {
      error
      message
    }
  }
`

export type UpdateTourResult = updateTour
export type UpdateTour = updateTour_updateTour
export type UpdateTourVariables = updateTourVariables
