import React, { FunctionComponent } from "react"
import { Grid, makeStyles, Theme } from "@material-ui/core"
import { Card } from "./model/card"
import { CardTile } from "./card-tile"
import { Skeleton } from "@material-ui/lab"
import { CARD_TILE_WIDTH, CARD_TILE_HEIGHT } from "../../../../utils/constants"

const useStyles = makeStyles((theme: Theme) => ({
  cardsContainer: {
    minHeight: CARD_TILE_HEIGHT + theme.spacing(2),
  },
}))

interface ICardsContainerProps {
  cards: Card[]
  justify?:
    | "space-around"
    | "space-between"
    | "space-evenly"
    | "center"
    | "flex-end"
    | "flex-start"
    | undefined
  secondary?: boolean
  loading?: boolean
}

export const CardsContainer: FunctionComponent<ICardsContainerProps> = (props) => {
  const { cards, secondary, loading } = props
  const classes = useStyles()

  return (
    <Grid
      container
      direction={"row"}
      spacing={2}
      alignItems={"stretch"}
      justify={props.justify ? props.justify : "flex-start"}
      className={classes.cardsContainer}
    >
      {!loading
        ? cards.map((card, index) => (
            <Grid item key={index}>
              <CardTile
                headingText={card.heading}
                contentText={card.content}
                secondary={secondary}
                descriptionText={card.description}
              />
            </Grid>
          ))
        : [1, 2, 3, 4].map((number) => (
            <Grid item key={number}>
              <Skeleton
                height={CARD_TILE_HEIGHT}
                width={CARD_TILE_WIDTH}
                style={{ transform: "scale(1)" }}
              />
            </Grid>
          ))}
    </Grid>
  )
}

CardsContainer.defaultProps = {
  secondary: false,
}
