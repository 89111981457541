import { Grid } from "@material-ui/core"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { TourParametersContent } from "./tour-parameters-content"

interface ITourParametersDialogProps {
  tourParametersId: string | undefined
  open: boolean
  onClose: () => void
}

export const TourParametersDialog: FC<ITourParametersDialogProps> = (props) => {
  const { open, onClose, tourParametersId } = props
  const { t } = useTranslation()

  return (
    <ConfirmDialog
      open={open}
      onConfirm={onClose}
      onClose={onClose}
      heading={t("tour_generation.tour_parameters_dialog.title")}
      confirmText={t("tour_generation.tour_parameters_dialog.ok")}
      showNegativeButton={false}
    >
      <Grid container>
        <TourParametersContent tourParametersId={tourParametersId} />
      </Grid>
    </ConfirmDialog>
  )
}
