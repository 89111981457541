import { gql } from "@apollo/client"
import { deleteHouseholdContainer, deleteHouseholdContainerVariables } from "./types/deleteHouseholdContainer"

export const DELETE_HOUSEHOLD_CONTAINER_MUTATION = gql`
  mutation deleteHouseholdContainer($householdContainerId: ID!) {
    deleteHouseholdContainer(householdContainerId: $householdContainerId)
  }
`

export type DeleteHouseholdContainerResult = deleteHouseholdContainer
export type DeleteHouseholdContainerVariables = deleteHouseholdContainerVariables
