import { useMutation } from "@apollo/client"
import { Card, Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import React, { FunctionComponent, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { RemoveDriverLocationResult, RemoveDriverLocationVariables, REMOVE_DRIVER_LOCATION_MUTATION } from "../../../../api/graphql/mutations/remove-driver-location"
import { DriverLocation } from "../../../../api/graphql/queries/get-driver-locations"
import { useRefetch } from "../../../../context/refetch-context"
import { useSelectedDriverLocation } from "../../../../context/selected-driver-location-context"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    padding: theme.spacing(2),
  },
}))

interface IDriverLocationSelectionProps {
  driverLocations: DriverLocation[],
  loading: boolean
}

export const DriverLocationSelection: FunctionComponent<IDriverLocationSelectionProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [removableLocation, setRemovableLocaiton] = useState<DriverLocation|undefined>(undefined)
  const { driverLocations, loading } = props
  const { setNeedToRefetch } = useRefetch()
  const { setSelectedDriverLocation } = useSelectedDriverLocation()

  const toggleDeleteDialog = useCallback(() => setDeleteDialogOpen(!deleteDialogOpen), [
    setDeleteDialogOpen,
    deleteDialogOpen,
  ])

  const [ removeDriverLocation ] = useMutation<RemoveDriverLocationResult, RemoveDriverLocationVariables>(REMOVE_DRIVER_LOCATION_MUTATION, {
    onCompleted: () => {
      setNeedToRefetch(true)
      setDeleteDialogOpen(!deleteDialogOpen)
      setSelectedDriverLocation(undefined)
    },
    onError: () => toast.error(t("generic_error"))
  }) 

  const onRemove = async () => {
    if (!removableLocation) return
    const result = await removeDriverLocation({
      variables: {
        id: removableLocation.id
      }
    })
    if (result.data?.deleteDriverLocation.error === "true") {
      toast.error(t("guidance_administration.driver_location_delete_error"))
    }
  }

  return (
    <Grid container direction="column" spacing={1}>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={toggleDeleteDialog}
        content={t("guidance_administration.delete_dialog_text_driver_location")}
        onDelete={onRemove}
      />
      {!loading && driverLocations.map((location, index) => (
        <Grid item key={index}>
          <Card className={classes.card}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.title}>
                  {location.name}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <IconButton color="primary" onClick={() => {setSelectedDriverLocation(location)}}>
                      <Edit />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton color="primary" onClick={() => {
                      setRemovableLocaiton(location)
                      toggleDeleteDialog()
                    }}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
