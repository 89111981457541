import React, {
  FunctionComponent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react"
import { Grid, Button, CircularProgress, Chip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { UserManagementContext } from "../context/user-management-context"
import { useLazyQuery } from "@apollo/client"
import {
  GET_USER_QUERY,
  UserResult,
  UserVariables,
} from "../../../../api/graphql/queries/get-user"
import { getOptionsForRole, LocationType } from "../helper/get-options-for-role"
import {
  LocationAssignmentDialog,
  ILocationCollectionItem,
} from "./location-assignment/location-assignment-dialog"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { sortASC } from "../../../../utils/sort"

interface IUserManagementTownSelectorProps {
  selectedRole?: UserRole | undefined
  selectedAssociations: ILocationCollectionItem[]
  setSelectedAssociations: (locations: ILocationCollectionItem[]) => void
  selectedDistricts: ILocationCollectionItem[]
  setSelectedDistricts: (locations: ILocationCollectionItem[]) => void
  selectedTowns: ILocationCollectionItem[]
  setSelectedTowns: (locations: ILocationCollectionItem[]) => void
  selectedGuidedDriverLocations: ILocationCollectionItem[]
  setSelectedGuidedDriverLocations: (locations: ILocationCollectionItem[]) => void
  selectedPartnerId: number | null
  toggleOpenDialog: boolean
  setToggleOpenDialog: (toggleOpenDialog: boolean) => void
}

export const UserManagementTownSelector: FunctionComponent<IUserManagementTownSelectorProps> = (
  props
) => {
  const { t } = useTranslation()
  const {
    selectedRole,
    selectedAssociations,
    setSelectedAssociations,
    selectedDistricts,
    setSelectedDistricts,
    selectedTowns,
    setSelectedTowns,
    selectedPartnerId,
    toggleOpenDialog,
    setToggleOpenDialog,
    selectedGuidedDriverLocations,
    setSelectedGuidedDriverLocations
  } = props
  const { selectedUser } = useContext(UserManagementContext)

  const [
    selectedLocationType,
    setSelectedLocationType,
  ] = useState<LocationType | null>(null)

  const [
    newSelectedLocationType,
    setNewSelectedLocationType,
  ] = useState<LocationType | null>(null)

  const onQueryCompleted = (data: UserResult) => {
    const userDetails = data?.getUser || {
      towns: [] as any[],
      associations: [] as any[],
      districts: [] as any[],
      driver_locations: [] as any[],
    }
    const { associations, towns, districts, driver_locations } = userDetails
    const { locationType } = getOptionsForRole(
      selectedRole,
      associations,
      towns,
      districts,
      driver_locations
    )

    setSelectedLocationType(locationType)
    setSelectedAssociations(associations.sort((a, b) => sortASC(a.name, b.name)))
    setSelectedDistricts(districts.sort((a, b) => sortASC(a.name, b.name)))
    setSelectedTowns(towns.sort((a, b) => sortASC(a.name, b.name)))
    const mappedDriverLocations = driver_locations.map((driverLocation) => {
      return { id: driverLocation.id.toString(), name: driverLocation.name }
    })
    setSelectedGuidedDriverLocations(mappedDriverLocations.sort((a, b) => sortASC(a.name, b.name)))
  }

  const [userQuery, { loading }] = useLazyQuery<UserResult, UserVariables>(
    GET_USER_QUERY,
    {
      onCompleted: onQueryCompleted,
    }
  )

  useEffect(() => {
    const { locationType } = getOptionsForRole(selectedRole)
    setSelectedLocationType(locationType)
  }, [selectedRole, setSelectedLocationType])

  useEffect(() => {
    if (selectedUser) {
      userQuery({ variables: { email: selectedUser.email } })
    }
  }, [selectedUser, userQuery])

  const { hasAssignedLocations, possibleLocationTypes } = getOptionsForRole(
    selectedRole
  )

  useEffect(() => {
    if(toggleOpenDialog){
      setNewSelectedLocationType(possibleLocationTypes[0])
      setToggleOpenDialog(false)
    }
  }, [toggleOpenDialog, setToggleOpenDialog, possibleLocationTypes])

  const getCollectionForLocationType = (locationType: LocationType | null) => {
    switch (locationType) {
      case LocationType.ASSOCIATION:
        return selectedAssociations
      case LocationType.DISTRICT:
        return selectedDistricts
      case LocationType.TOWN:
        return selectedTowns
      case LocationType.LOCATION:
        return selectedGuidedDriverLocations
      default:
        return []
    }
  }

  const onSave = (items: ILocationCollectionItem[]) => {
    const sorted = items.sort((a, b) => sortASC(a.name, b.name))
    switch (newSelectedLocationType) {
      case LocationType.ASSOCIATION:
        setSelectedAssociations(sorted)
        setSelectedDistricts([])
        setSelectedTowns([])
        setSelectedGuidedDriverLocations([])
        break
      case LocationType.DISTRICT:
        setSelectedAssociations([])
        setSelectedDistricts(sorted)
        setSelectedTowns([])
        setSelectedGuidedDriverLocations([])
        break
      case LocationType.TOWN:
        setSelectedAssociations([])
        setSelectedDistricts([])
        setSelectedTowns(sorted)
        setSelectedGuidedDriverLocations([])
        break
      case LocationType.LOCATION:
        setSelectedAssociations([])
        setSelectedDistricts([])
        setSelectedTowns([])
        setSelectedGuidedDriverLocations(sorted)
        break
    }

    setSelectedLocationType(newSelectedLocationType)
    setNewSelectedLocationType(null)
  }

  const title = selectedLocationType
    ? t(`location_type.${selectedLocationType}`, {
        count: getCollectionForLocationType(selectedLocationType).length,
      })
    : ""

  return (
    <Fragment>
      <LocationAssignmentDialog
        type={newSelectedLocationType}
        collection={getCollectionForLocationType(newSelectedLocationType)}
        open={!!newSelectedLocationType}
        onSave={onSave}
        onClose={() => setNewSelectedLocationType(null)}
        selectedPartnerId={selectedPartnerId}
      />
      <Grid container direction="column" spacing={2}>
        {hasAssignedLocations && (
          <Grid container item justify="space-between">
            <Grid item>
              <Text bold>{title}</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                {possibleLocationTypes.map((type) => (
                  <Grid item key={type}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setNewSelectedLocationType(type)}
                      disabled={selectedRole === UserRole.COLLECTION_PARTNER && selectedPartnerId === null}
                    >
                      {t("user_management.data.assign", {
                        collection: t(`location_type.${type}`),
                      })}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {loading && (
          <Grid item container justify="center">
            <CircularProgress color="primary" />
          </Grid>
        )}
        {!loading &&
          hasAssignedLocations &&
          getCollectionForLocationType(selectedLocationType).length > 0 && (
            <Grid item container spacing={1}>
              {getCollectionForLocationType(selectedLocationType).map(
                (item) => (
                  <Grid item key={item.id}>
                    <Chip label={item.name} />
                  </Grid>
                )
              )}
            </Grid>
          )}
        {!loading &&
          hasAssignedLocations &&
          getCollectionForLocationType(selectedLocationType).length === 0 && (
            <Grid item container spacing={2} justify="center">
              <Text color="secondary">
                {selectedLocationType
                  ? t("user_management.data.nothing_assigned", {
                      collection: title,
                    })
                  : ""}
              </Text>
            </Grid>
          )}
      </Grid>
    </Fragment>
  )
}
