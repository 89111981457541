import { gql } from "@apollo/client"
import {
  uploadRoutes,
  uploadRoutesVariables,
  uploadRoutes_uploadRoutes,
} from "./types/uploadRoutes"

export const UPLOAD_ROUTES_MUTATION = gql`
  mutation uploadRoutes($routes: [RouteInput!]!, $driverLocationId: ID!) {
    uploadRoutes(routes: $routes, driverLocationId: $driverLocationId) {
      id
    }
  }
`

export type UploadRoutesResult = uploadRoutes
export type Route = uploadRoutes_uploadRoutes
export type UploadRoutesVariables = uploadRoutesVariables
