import { gql } from "@apollo/client"
import {
  getFlattenedCollectionPointFilllevels,
  getFlattenedCollectionPointFilllevelsVariables,
  getFlattenedCollectionPointFilllevels_getFlattenedCollectionPointFilllevels_fill_levels
} from "./types/getFlattenedCollectionPointFilllevels"

export const GETCOLLECTIONPOINTFILLLEVELS_QUERY = gql`
  query getFlattenedCollectionPointFilllevels(
    $id: Int!
    $from: DateTime!
    $to: DateTime!
  ) {
    getFlattenedCollectionPointFilllevels(id: $id, from: $from, to: $to) {
      material_id
      material_name
      fill_levels {
        timestamp
        value
        isExtrapolated
      }
    }
  }
`

export type CollectionPointFilllevelsResult = getFlattenedCollectionPointFilllevels
export type CollectionPointFilllevelsVariables = getFlattenedCollectionPointFilllevelsVariables
export type Filllevel = getFlattenedCollectionPointFilllevels_getFlattenedCollectionPointFilllevels_fill_levels
