import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Table, TableHead, TableRow, TableCell, TableBody, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"
import {
  TourExportPreviewCollectionPoint,
  TourExportPreviewMaterial,
} from "../../../../api/graphql/queries/tour-export-preview"
import classnames from "classnames"
import lodash from "lodash"
import moment from "moment"
import { Badge } from "../../user-management/partials/badge"
import { UserService } from "../../../../services/user-service"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../../utils/constants"

interface IStyleProps {
  materialsCount: number
}

export enum CollectionPointStatus {
  planned = "planned",
  added = "added",
  removed = "removed",
}

const useStyles = makeStyles((theme: Theme) => ({
  idColumn: {
    width: 40,
  },
  lastColumn: {
    borderRight: "none",
  },
  lastRow: {
    borderBottom: "none",
  },
  materialsHeaderCell: (props: IStyleProps) => ({
    paddingBottom: 0,
    width: lodash.max([props.materialsCount * 120, 240]),
  }),
  materialsCell: {
    width: 120,
  },
  materialsContainer: {
    marginLeft: -theme.spacing(1),
    width: `calc(100% + ${2 * theme.spacing(1)}px)`,
  },
  materialsHeader: {
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #bfc2c3",
  },
  materialHeader: (props: IStyleProps) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: `${100 / props.materialsCount}%`,
    borderRight: "1px solid #bfc2c3",
    "&:last-child": { borderRight: "none" },
  }),
  [CollectionPointStatus.added]: {
    color: "#4B963E",
    borderColor: "#4B963E",
  },
  [CollectionPointStatus.removed]: {
    color: "#E86161",
    borderColor: "#E86161",
  },
  [CollectionPointStatus.planned]: {
    color: "#606060",
    borderColor: "#606060",
  },
  badge: {
    padding: theme.spacing(0.5),
    margin: "auto",
  },
}))

export interface ITourExportPreviewCollectionPoint extends TourExportPreviewCollectionPoint {
  status: CollectionPointStatus
}

interface ITourExportPreviewTableProps {
  loading: boolean
  collectionPoints: ITourExportPreviewCollectionPoint[]
  materials: TourExportPreviewMaterial[]
  date: Date
  showDiff: boolean
}

export const TourExportPreviewTable: FunctionComponent<ITourExportPreviewTableProps> = (props) => {
  const { t } = useTranslation()
  const { loading, collectionPoints, materials, date, showDiff } = props
  const classes = useStyles({ materialsCount: materials.length })

  const getFilllevelInfo = (collectionPoint: TourExportPreviewCollectionPoint, materialId: string) => {
    const material = collectionPoint.materials.find((material) => material.material.id === materialId)
    if (lodash.isNil(material) || lodash.isNil(material.filllevel_percentage) || lodash.isNil(material.filllevel)) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {Math.round(material.filllevel_percentage)}%
        </Grid>
        <Grid item xs={12}>
          (
          {material.filllevel.toLocaleString(UserService.getLanguage(), {
            maximumFractionDigits: 2,
          })}
          kg)
        </Grid>
      </Grid>
    )
  }

  const getAddress = (collectionPoint: TourExportPreviewCollectionPoint) => {
    if (!collectionPoint.postal && !collectionPoint.town && !collectionPoint.street) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {collectionPoint.street || "-"}
        </Grid>
        <Grid item xs={12}>
          {collectionPoint.postal || collectionPoint.town || collectionPoint.place
            ? `${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town || ""}`
            : "-"}
        </Grid>

        {collectionPoint.place && collectionPoint.town && collectionPoint.place !== collectionPoint.town && (
          <Grid item xs={12}>
            {collectionPoint.town}
          </Grid>
        )}
      </Grid>
    )
  }

  const getFixedUnloadIntervalInfo = (collectionPoint: TourExportPreviewCollectionPoint) => {
    if (lodash.isNil(collectionPoint.fixed_unload_interval) || collectionPoint.fixed_unload_interval === 0) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {collectionPoint.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND
            ? t("collection_point_administration.data.unload_interval_on_demand")
            : t("tour_generation.preview_table.fixed_unload_interval_weeks", {
                count: Number(collectionPoint.fixed_unload_interval),
              })}
        </Grid>
        <Grid item xs={12}>
          {t("tour_generation.preview_table.last_emptying", {
            date: moment(collectionPoint.last_emptying).format(t("date_format")),
          })}
        </Grid>
      </Grid>
    )
  }

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {showDiff && (
            <TableCell align="center" className={classes.idColumn} rowSpan={2}>
              {t("tour_generation.preview_table.collection_point_status")}
            </TableCell>
          )}
          <TableCell align="center" className={classes.idColumn} rowSpan={2}>
            {t("tour_generation.preview_table.id")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.description")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.address")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.fixed_unload_interval")}
          </TableCell>
          {(loading || materials.length > 0) && (
            <TableCell
              align="center"
              className={classnames(classes.lastColumn, classes.materialsHeaderCell)}
              rowSpan={1}
              colSpan={materials.length}
            >
              <Grid container direction="column" className={classes.materialsContainer}>
                <Grid item className={classes.materialsHeader}>
                  {t("tour_generation.preview_table.materials", {
                    date: moment(date).format(t("date_format")),
                  })}
                </Grid>
                <Grid container direction="row" justify="space-around">
                  {materials.map((material) => (
                    <Grid item key={`material${material.material.id}`} className={classes.materialHeader}>
                      {material.material.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            {showDiff && (
              <TableCell align="center" className={classes.lastRow}>
                <Skeleton height={50} />
              </TableCell>
            )}
            <TableCell align="center" className={classnames(classes.lastRow, classes.idColumn)}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" className={classes.lastRow}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" className={classes.lastRow}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" className={classes.lastRow}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" className={classnames(classes.lastColumn, classes.lastRow)}>
              <Skeleton height={50} />
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          collectionPoints.map((cp, index) => (
            <TableRow key={cp.id}>
              {showDiff && (
                <TableCell align="center">
                  <Badge className={classnames(classes.badge, classes[cp.status])}>
                    {t(`tour_generation.preview_table.status.${cp.status}`)}
                  </Badge>
                </TableCell>
              )}
              <TableCell
                align="center"
                className={classnames(classes.idColumn, {
                  [classes.lastRow]: index === collectionPoints.length - 1,
                })}
              >
                {cp.id}
              </TableCell>
              <TableCell align="center" className={index === collectionPoints.length - 1 ? classes.lastRow : undefined}>
                {cp.description}
              </TableCell>
              <TableCell align="center" className={index === collectionPoints.length - 1 ? classes.lastRow : undefined}>
                {getAddress(cp)}
              </TableCell>
              <TableCell align="center" className={index === collectionPoints.length - 1 ? classes.lastRow : undefined}>
                {getFixedUnloadIntervalInfo(cp)}
              </TableCell>
              {materials.map((material) => (
                <TableCell
                  align="center"
                  key={`material${material.material.id}`}
                  className={classnames(classes.materialsCell, {
                    [classes.lastRow]: index === collectionPoints.length - 1,
                  })}
                >
                  {getFilllevelInfo(cp, material.material.id)}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
