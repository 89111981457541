import React, { FunctionComponent, useEffect, useState } from "react"
import {
  Button,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  makeStyles,
  Theme,
} from "@material-ui/core"
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded"
import { useTranslation } from "react-i18next"

interface IConfirmDialogProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  heading: string
  confirmText: string
  text?: string
  showNegativeButton?: boolean
  negativeText?: string
  loading?: boolean
  error?: boolean
  disabled?: boolean
  allowMultipleConfirms?: boolean
  size?: "lg" | "xs" | "sm" | "md" | "xl"
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    whiteSpace: "pre-line",
  },
  hedgehog: {
    color: theme.palette.primary.contrastText,
  },
}))

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    open,
    onClose,
    onConfirm,
    heading,
    text,
    confirmText,
    children,
    showNegativeButton = true,
    negativeText,
    loading,
    error,
    disabled,
    size,
    allowMultipleConfirms,
  } = props
  const [localDisabled, setLocalDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (open && !loading && !error) {
      setLocalDisabled(false)
    } else {
      setLocalDisabled(true)
    }
  }, [open, loading, error])

  const handleConfirmClick = () => {
    // allow only a single click
    if (!allowMultipleConfirms && localDisabled) {
      return
    }
    setLocalDisabled(true)
    onConfirm()
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={size}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText className={classes.text}>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {showNegativeButton && (
          <Button onClick={onClose} variant="contained" disabled={loading}>
            {negativeText || t("cancel")}
          </Button>
        )}
        <Button onClick={handleConfirmClick} color={"primary"} variant="contained" disabled={disabled || loading}>
          {error && <ErrorOutlineRoundedIcon />}
          {!error && loading && <CircularProgress className={classes.hedgehog} size={24} />}
          {!error && !loading && confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
