import { gql } from "@apollo/client"
import {
  deleteContainerWithID,
  deleteContainerWithIDVariables,
  deleteContainerWithID_deleteContainerWithID
} from "./types/deleteContainerWithID"

export const REMOVE_CONTAINER_MUTATION = gql`
  mutation deleteContainerWithID($id: ID!) {
    deleteContainerWithID(id: $id) {
      error
    }
  }
`

export type RemoveContainerResult = deleteContainerWithID
export type RemoveContainer = deleteContainerWithID_deleteContainerWithID
export type RemoveContainerVariables = deleteContainerWithIDVariables
