import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, makeStyles, Card as MaterialCard, TextField, Grid, Button, Chip, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"
import { PartnerDeleteDialog } from "./partner-delete-dialog"
import { Text } from "../../../partials/wrapper/text"
import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { CREATE_COLLECTION_PARTNER_MUTATION } from "../../../../api/graphql/mutations/create-collection-partner"
import { UPDATE_COLLECTION_PARTNER_MUTATION } from "../../../../api/graphql/mutations/update-collection-partner"
import { REMOVE_COLLECTION_PARTNER_MUTATION } from "../../../../api/graphql/mutations/remove-collection-partner"
import { useRefetch } from "../../../../context/refetch-context"
import { PATH, BASE_PATH, PARTNER_PATH } from "../../../../router/router"
import { PartnerDistrictSelector } from "./partner-district-selector"
import { District } from "../../../../api/graphql/queries/get-collection-partner-with-id"
import { LocationType } from "../../user-management/helper/get-options-for-role"
import { UserGroups } from "../../../../models/user-groups"
import { UserService } from "../../../../services/user-service"
import {
  GETCOLLECTIONPARTNERFORUSER_QUERY,
  CollectionPartnerResult,
} from "../../../../api/graphql/queries/get-collection-partner-for-user"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import lodash from "lodash"
import { DEFAULT_AUTOMATIC_BREAK, DEFAULT_AUTOMATIC_BREAK_REMINDER } from "../../../../utils/constants"
import { DeparturePointManagement } from "./departure-point/departure-point-management"
import { DeparturePointContextProvider } from "./departure-point/context/departure-point-context"
import { DisposalTrailerLocationContextProvider } from "./disposal-trailer-location/context/disposal-trailer-location-context"
import { DisposalTrailerLocationManagement } from "./disposal-trailer-location/disposal-trailer-location-management"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    height: "100%",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    overflowY: "scroll",
  },
  innerContainer: {
    padding: theme.spacing(1),
    height: "100%",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 18,
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "white",
    "&:hover": {
      backgroundColor: "#b71c1c",
    },
  },
  containerCard: {
    minWidth: 430,
    minHeight: 310,
    height: "calc(100vh - 110px)",
    flexWrap: "nowrap",
  },
  managementCard: {
    display: "flex",
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
  actions: {
    marginTop: theme.spacing(1),
  },
}))

interface IPartnerDataProps {}

export const PartnerData: FunctionComponent<IPartnerDataProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [id, setId] = useState<number>(0)
  const [name, setName] = useState<string>("")
  const [automaticBreak, setAutomaticBreak] = useState<number | null>(DEFAULT_AUTOMATIC_BREAK)
  const [automaticBreakReminder, setAutomaticBreakReminder] = useState<number | null>(DEFAULT_AUTOMATIC_BREAK_REMINDER)
  const [street, setStreet] = useState<string>("")
  const [postal, setPostal] = useState<string>("")
  const [town, setTown] = useState<string>("")
  const [contactName, setContactName] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [districts, setDistricts] = useState<District[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const { selectedPartner, setSelectedPartnerId, setSelectedPartner } = useSelectedPartner()
  const [isSaveCreateButtonDisabled, setIsSaveCreateButtonDisabled] = useState<boolean>(false)
  const { setNeedToRefetch } = useRefetch()
  const [createCollectionPartner] = useMutation(CREATE_COLLECTION_PARTNER_MUTATION)

  const [updateCollectionPartner] = useMutation(UPDATE_COLLECTION_PARTNER_MUTATION)

  const [removeCollectionPartner] = useMutation(REMOVE_COLLECTION_PARTNER_MUTATION)

  const history = useHistory()

  const onCollectionPartnerForUserQueryCompleted = () => {
    setSelectedPartner(collectionPartnerData?.getCollectionPartnerForUser)
    setSelectedPartnerId(collectionPartnerData?.getCollectionPartnerForUser.id)
  }

  const { data: collectionPartnerData } = useQuery<CollectionPartnerResult>(GETCOLLECTIONPARTNERFORUSER_QUERY, {
    skip: UserService.getRole() !== UserRole.COLLECTION_PARTNER,
    onCompleted: onCollectionPartnerForUserQueryCompleted,
  })

  const clearFields = () => {
    setName("")
    setStreet("")
    setPostal("")
    setTown("")
    setContactName("")
    setPhoneNumber("")
    setEmail("")
    setDistricts([])
    setAutomaticBreak(DEFAULT_AUTOMATIC_BREAK)
    setAutomaticBreakReminder(DEFAULT_AUTOMATIC_BREAK_REMINDER)
  }

  useEffect(() => {
    if (selectedPartner) {
      setId(selectedPartner.id)
      setName(selectedPartner.name)
      setAutomaticBreak(selectedPartner.automatic_break)
      setAutomaticBreakReminder(selectedPartner.automatic_break_reminder)
      setStreet(selectedPartner.street || "")
      setPostal(selectedPartner.postal || "")
      setTown(selectedPartner.town || "")
      setContactName(selectedPartner.contact_name || "")
      setPhoneNumber(selectedPartner.phone_number || "")
      setEmail(selectedPartner.email || "")
      setDistricts(selectedPartner.districts)
    } else {
      clearFields()
    }
  }, [selectedPartner])

  const validateFields = (): boolean => {
    if (!name || lodash.isNil(automaticBreak) || lodash.isNil(automaticBreakReminder)) {
      return false
    }
    return true
  }

  const onShowVehiclesClicked = () => {
    if (selectedPartner) {
      history.push(`${BASE_PATH}${PARTNER_PATH}/${selectedPartner.id}/${PATH.VEHICLE_OVERVIEW.id}`)
    }
  }
  const onDeletePartnerClicked = () => {
    setIsDeleteDialogOpen(true)
  }
  const onSavePartnerClicked = async () => {
    if (!validateFields()) {
      toast.error(t("partner_overview.errors.required"))
      return
    }
    setIsSaveCreateButtonDisabled(true)
    if (selectedPartner) {
      try {
        const res = await updateCollectionPartner({
          variables: {
            id,
            name,
            town,
            postal,
            street,
            contact_name: contactName,
            phone_number: phoneNumber,
            email,
            districtIds: districts.map((district) => district.id),
            automatic_break: automaticBreak,
            automatic_break_reminder: automaticBreakReminder,
          },
        })

        if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()
        toast.info(t("partner_overview.data.partner_updated"))
        setNeedToRefetch(true)
      } catch (e) {
        toast.error(t("partner_overview.data.could_not_update"))
      }
    } else {
      try {
        const res = await createCollectionPartner({
          variables: {
            name,
            town,
            postal,
            street,
            contact_name: contactName,
            phone_number: phoneNumber,
            email,
            districtIds: districts.map((district) => district.id),
            automatic_break: automaticBreak,
            automatic_break_reminder: automaticBreakReminder,
          },
        })
        if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()
        toast.info(t("partner_overview.data.partner_created"))
        setNeedToRefetch(true)
        clearFields()
      } catch (e) {
        toast.error(t("partner_overview.data.could_not_create"))
      }
    }
    setIsSaveCreateButtonDisabled(false)
  }

  const deletePartner = async () => {
    try {
      const res = await removeCollectionPartner({ variables: { id } })
      if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()

      toast.info(t("partner_overview.data.partner_deleted"))
      setNeedToRefetch(true)
    } catch (e) {
      toast.error(t("partner_overview.data.could_not_delete"))
    }

    setSelectedPartnerId(undefined)

    setIsDeleteDialogOpen(false)
  }

  return (
    <MaterialCard className={classes.containerCard}>
      <Grid container direction={"column"} className={classes.container}>
        <Grid item container direction={"column"} spacing={2}>
          <Grid item>
            <Text className={classes.heading} bold>
              {t("partner_overview.data.title")}
            </Text>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-search"
                fullWidth
                label={t("partner_overview.data.name")}
                type="search"
                required
                variant="outlined"
                placeholder="Saubermacher"
                value={name}
                onChange={(ev) => {
                  setName(ev.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-search"
                label={t("partner_overview.data.street")}
                type="search"
                fullWidth
                variant="outlined"
                placeholder="Neue Straße 3"
                value={street}
                onChange={(ev) => {
                  setStreet(ev.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-search"
                label={t("partner_overview.data.postal")}
                type="search"
                fullWidth
                variant="outlined"
                placeholder="8020"
                value={postal}
                onChange={(ev) => {
                  setPostal(ev.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-search"
                label={t("partner_overview.data.town")}
                type="search"
                fullWidth
                variant="outlined"
                placeholder="Graz"
                value={town}
                onChange={(ev) => {
                  setTown(ev.target.value)
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("partner_overview.data.automatic_break")}
                type="number"
                required
                fullWidth
                variant="outlined"
                value={(automaticBreak || DEFAULT_AUTOMATIC_BREAK) / 60}
                onChange={(ev) => {
                  setAutomaticBreak(ev.target.value ? parseInt(ev.target.value, 10) * 60 : null)
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label={t("partner_overview.data.automatic_break_reminder")}
                type="number"
                required
                fullWidth
                variant="outlined"
                value={automaticBreakReminder || DEFAULT_AUTOMATIC_BREAK_REMINDER}
                onChange={(ev) => {
                  setAutomaticBreakReminder(ev.target.value ? parseInt(ev.target.value, 10) : null)
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <MaterialCard>
              <Grid container spacing={1} className={classes.innerContainer}>
                <Grid item>
                  <Text>{t("partner_overview.data.contact_person")}</Text>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-search"
                      label={t("partner_overview.data.name")}
                      type="search"
                      fullWidth
                      variant="outlined"
                      placeholder="Max Mustermann"
                      value={contactName}
                      onChange={(ev) => {
                        setContactName(ev.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-search"
                      label={t("partner_overview.data.phone_number")}
                      type="search"
                      fullWidth
                      variant="outlined"
                      placeholder="+43 664 1234567"
                      value={phoneNumber}
                      onChange={(ev) => {
                        setPhoneNumber(ev.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-search"
                      label={t("partner_overview.data.email")}
                      type="search"
                      fullWidth
                      variant="outlined"
                      placeholder="mustermann@muster.at"
                      value={email}
                      onChange={(ev) => {
                        setEmail(ev.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </MaterialCard>
          </Grid>
          <Grid item>
            <MaterialCard>
              <Grid container spacing={1} className={classes.innerContainer}>
                <Grid item xs={12} container justify="space-between">
                  <Grid item>
                    <Text bold>
                      {t(`location_type.${LocationType.DISTRICT}`, {
                        count: districts.length,
                      })}
                      :
                    </Text>
                  </Grid>

                  {UserService.getRole() !== UserRole.COLLECTION_PARTNER && (
                    <PartnerDistrictSelector selectedDistricts={districts} onDistrictsUpdated={setDistricts} />
                  )}
                </Grid>
                <Grid item container spacing={1} direction="row">
                  {districts.map((district) => (
                    <Grid item key={district.id}>
                      <Chip label={district.name} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </MaterialCard>
          </Grid>
          {UserService.isInUserGroup(UserGroups.VEHICLE_MANAGEMENT) && selectedPartner && (
            <Grid item>
              <MaterialCard>
                <Grid item container direction="row" justify="space-between" className={classes.container}>
                  <Text bold>{t("partner_overview.data.vehicles")}</Text>
                  <Button variant="contained" type="button" color="default" onClick={onShowVehiclesClicked}>
                    {t("partner_overview.data.show_vehicle", {
                      count: selectedPartner.amount_vehicles,
                    })}
                  </Button>
                </Grid>
              </MaterialCard>
            </Grid>
          )}
          {selectedPartner && (
            <Grid item>
              <MaterialCard className={classes.managementCard}>
                <DeparturePointContextProvider partnerId={selectedPartner.id}>
                  <DeparturePointManagement />
                </DeparturePointContextProvider>
              </MaterialCard>
            </Grid>
          )}
          {selectedPartner && (
            <Grid item>
              <MaterialCard className={classes.managementCard}>
                <DisposalTrailerLocationContextProvider partnerId={selectedPartner.id}>
                  <DisposalTrailerLocationManagement />
                </DisposalTrailerLocationContextProvider>
              </MaterialCard>
            </Grid>
          )}
        </Grid>
        <Grid item container justify={"space-between"} className={classes.actions}>
          <Tooltip
            PopperProps={{
              style: {
                visibility: !selectedPartner || selectedPartner.is_deletable ? "hidden" : "visible",
              },
            }}
            placement="top"
            title={t("partner_overview.partner_delete_info") as string}
          >
            <Grid item>
              {UserService.getRole() !== UserRole.COLLECTION_PARTNER && (
                <Button
                  variant="contained"
                  type="button"
                  disabled={!selectedPartner || !selectedPartner.is_deletable}
                  onClick={onDeletePartnerClicked}
                  className={classes.deleteButton}
                >
                  {t("partner_overview.data.delete_partner")}
                </Button>
              )}
            </Grid>
          </Tooltip>
          <Grid item>
            <Button
              variant="contained"
              type="button"
              color="primary"
              onClick={onSavePartnerClicked}
              disabled={isSaveCreateButtonDisabled}
            >
              {selectedPartner ? t("partner_overview.data.save_partner") : t("partner_overview.data.create_partner")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PartnerDeleteDialog
        onDelete={deletePartner}
        onClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
      />
    </MaterialCard>
  )
}
