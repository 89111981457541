import { gql } from "@apollo/client"
import {
  updateCollectionPartner,
  updateCollectionPartnerVariables,
  updateCollectionPartner_updateCollectionPartner,
} from "./types/updateCollectionPartner"

export const UPDATE_COLLECTION_PARTNER_MUTATION = gql`
  mutation updateCollectionPartner(
    $id: ID!
    $name: String!
    $town: String!
    $postal: String!
    $street: String!
    $contact_name: String!
    $phone_number: String!
    $email: String!
    $districtIds: [ID!]!
    $automatic_break: Int!
    $automatic_break_reminder: Int!
  ) {
    updateCollectionPartner(
      id: $id
      name: $name
      town: $town
      postal: $postal
      street: $street
      contact_name: $contact_name
      phone_number: $phone_number
      email: $email
      districtIds: $districtIds
      automatic_break: $automatic_break
      automatic_break_reminder: $automatic_break_reminder
    ) {
      id
    }
  }
`

export type UpdateCollectionPartnerResult = updateCollectionPartner
export type UpdateCollectionPartner = updateCollectionPartner_updateCollectionPartner
export type UpdateCollectionPartnerVariables = updateCollectionPartnerVariables
