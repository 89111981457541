import { gql } from "@apollo/client"
import {
  householdStatuses,
  householdStatusesVariables,
  householdStatuses_households,
  householdStatuses_households_status,
} from "./types/householdStatuses"

// yes, plural in english is statuses.
// https://www.merriam-webster.com/dictionary/status
// https://www.dictionary.com/browse/status

export const HOUSEHOLD_STATUSES_QUERY = gql`
  query householdStatuses(
    $regionId: ID!
    $regionType: RegionType!
    $countyIds: [ID!]!
    $address: String
    $materialIds: [ID!]!
    $townIds: [ID!]!
    $showWarnings: Boolean!
  ) {
    households(
      regionId: $regionId
      regionType: $regionType
      countyIds: $countyIds
      address: $address
      materialIds: $materialIds
      townIds: $townIds
      showWarnings: $showWarnings
    ) {
      id
      street
      number
      postal
      place
      latitude
      longitude
      status(materialIds: $materialIds) {
        color
        materialStatus {
          materialId
          color
          amountContainers
          amountIntendedContainers
          lastUpdated
        }
      }
    }
  }
`

export type HouseholdStatus = householdStatuses_households_status
export interface HouseholdRequiredStatus extends HouseholdStatus {
  color: string
}

export type HouseholdStatusesResult = householdStatuses
export type HouseHoldWithOptionalStatus = householdStatuses_households
export interface HouseholdWithStatus extends householdStatuses_households {
  status: HouseholdRequiredStatus
}
export type HouseholdStatusesVariables = householdStatusesVariables
