import { gql } from "@apollo/client"
import {
  createDriverLocation,
  createDriverLocationVariables,
  createDriverLocation_createDriverLocation
} from "./types/createDriverLocation"

export const CREATE_DRIVER_LOCATION_MUTATION = gql`
  mutation createDriverLocation(
    $name: String!
  ) {
    createDriverLocation(
      name: $name
    ) {
      id
      name
    }
  }
`
export type CreateDriverLocationResult = createDriverLocation
export type CreateDriverLocation = createDriverLocation_createDriverLocation
export type CreateDriverLocationVariables = createDriverLocationVariables
