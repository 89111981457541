import { gql } from "@apollo/client"
import {
  averageCollectedAmountPerHourForDistrict,
  averageCollectedAmountPerHourForDistrictVariables,
  averageCollectedAmountPerHourForDistrict_averageCollectedAmountPerHourForDistrict,
} from "./types/averageCollectedAmountPerHourForDistrict"

export const GET_AVERAGE_COLLECTED_AMOUNT_PER_HOUR_FOR_DISTRICT_QUERY = gql`
  query averageCollectedAmountPerHourForDistrict($id: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    averageCollectedAmountPerHourForDistrict(id: $id, startDate: $startDate, endDate: $endDate) {
      collected_amount_per_hour {
        month
        collected_amount_per_hour
      }
      average
    }
  }
`

export type AverageCollectedAmountPerHourForDistrictResult = averageCollectedAmountPerHourForDistrict
export type AverageCollectedAmountPerHourForDistrictVariables = averageCollectedAmountPerHourForDistrictVariables
export type AverageCollectedAmountPerHour = averageCollectedAmountPerHourForDistrict_averageCollectedAmountPerHourForDistrict
