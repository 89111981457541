import { gql } from "@apollo/client"
import { abortTourCalculation, abortTourCalculationVariables } from "./types/abortTourCalculation"

export const ABORT_TOUR_CALCULATION_MUTATION = gql`
  mutation abortTourCalculation($tour_parameters_id: ID!) {
    abortTourCalculation(tour_parameters_id: $tour_parameters_id) {
      error
      message
    }
  }
`
export type AbortTourCalculationResult = abortTourCalculation
export type AbortTourCalculationVariables = abortTourCalculationVariables
