import { MapCollectionPointContainer } from "../api/graphql/queries/get-collection-points-for-map"
import lodash from "lodash"
import moment from "moment"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "./constants"

export const containerHasIssues = (
  container: MapCollectionPointContainer,
  collectionPointFixedUnloadInterval: number | null,
  problemSensorInterval: number = 10,
) => {
  const hasFixedUnloadInterval = Number(collectionPointFixedUnloadInterval) > 0
  if (hasFixedUnloadInterval) return false

  const isOnDemand = collectionPointFixedUnloadInterval === FIXED_UNLOAD_INTERVAL_ON_DEMAND
  const hasSensor = !lodash.isEmpty(container.sensor_id)

  if (isOnDemand && !hasSensor) {
    return false
  }

  return !lodash.isNil(container.last_time_send)
    ? moment().diff(moment(container.last_time_send), "days") >= problemSensorInterval
    : hasSensor //sensor has never sent
}
