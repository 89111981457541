import { gql } from "@apollo/client"
import { GetDbCloningStatus } from "./types/getDbCloningStatus"

export const GET_DB_CLONING_STATUS_QUERY = gql`
  query GetDbCloningStatus {
    getDbCloningStatus {
      id
      startedAt
      finishedAt
      status
    }
  }
`

export type GetDbCloningStatusResult = GetDbCloningStatus
