import { gql } from "@apollo/client"
import {
  upsertCollectionPointOpeningHours,
  upsertCollectionPointOpeningHoursVariables,
  upsertCollectionPointOpeningHours_upsertCollectionPointOpeningHours
} from "./types/upsertCollectionPointOpeningHours"

export const UPSERT_COLLECTION_POINT_OPENING_HOURS_MUTATION = gql`
    mutation upsertCollectionPointOpeningHours($overfillingAllowed: Boolean!, $accessibleWithTrailer: Boolean!, $openingHours: [OpeningHourInput!]!, $collectionPointId: ID!) {
    upsertCollectionPointOpeningHours(overfillingAllowed: $overfillingAllowed, accessibleWithTrailer: $accessibleWithTrailer, opening_hours: $openingHours, id: $collectionPointId) {
        id
        day
        from
        to
    }
    }
`

export type UpsertCollectionPointOpeningHoursResult = upsertCollectionPointOpeningHours
export type UpsertCollectionPointOpeningHoursVariables = upsertCollectionPointOpeningHoursVariables
export type OpeningHour = upsertCollectionPointOpeningHours_upsertCollectionPointOpeningHours
