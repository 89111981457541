import { gql } from "@apollo/client"
import {
  deleteCollectionPoint,
  deleteCollectionPointVariables,
  deleteCollectionPoint_deleteCollectionPoint
} from "./types/deleteCollectionPoint"

export const REMOVE_COLLECTION_POINT_MUTATION = gql`
  mutation deleteCollectionPoint($id: Float!) {
    deleteCollectionPoint(id: $id) {
      error
    }
  }
`

export type RemoveCollectionPointResult = deleteCollectionPoint
export type RemoveCollectionPoint = deleteCollectionPoint_deleteCollectionPoint
export type RemoveCollectionPointVariables = deleteCollectionPointVariables
