import { useLazyQuery, useMutation } from "@apollo/client"
import React, { createContext, FC, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  VALIDATE_COLLECTION_POINTS_FILE,
  ValidateCollectionPointsFileResult,
  ValidateCollectionPointsFileVariables,
  ValidateCollectionPointsFileError,
  CollectionPointForUpload,
} from "../../../../../api/graphql/queries/validate-collection-points-file"
import {
  UPLOAD_COLLECTION_POINTS_QUERY,
  UploadCollectionPointsResult,
  UploadCollectionPointsVariables,
} from "../../../../../api/graphql/mutations/upload-collection-points"
import {
  GET_DOCUMENT_TEMPLATE,
  GetDocumentTemplateResult,
  GetDocumentTemplateVariables,
} from "../../../../../api/graphql/queries/get-document-template"
import { useUnsavedDataContext } from "../../../../../context/unsaved-data-context"
import { useAssociationFilter } from "../../../../../context/AssociationFilterContext"
import { useDistrictFilter } from "../../../../../context/DistrictFilterContext"
import { UserService } from "../../../../../services/user-service"
import { DocumentTemplateType, RegionType } from "../../../../../api/graphql/graphql-global-types"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { PATH } from "../../../../../router/router"
import { downloadURI } from "../../../../../utils/browser"

interface IUploadCollectionPointsContext {
  file: File | null
  setFile: (file: File | null) => void
  validateFile: (file: File) => void
  validationLoading: boolean
  validationErrors: ValidateCollectionPointsFileError[]
  collectionPoints: CollectionPointForUpload[]
  uploadCollectionPoints: (collectionPoints: CollectionPointForUpload[]) => void
  uploadLoading: boolean
  downloadTemplateFile: () => void
  downloadTemplateFileLoading: boolean
  reset: () => void
}

export const UploadCollectionPointsContext = createContext<IUploadCollectionPointsContext>(
  {} as IUploadCollectionPointsContext,
)

export const useUploadCollectionPointsContext = (): IUploadCollectionPointsContext => {
  return useContext(UploadCollectionPointsContext)
}

const useUploadCollectionPointsProvider = (): IUploadCollectionPointsContext => {
  const [file, setFile] = useState<File | null>(null)
  const { setHasUnsavedData, setUnsavedDataTitle, setUnsavedDataContent } = useUnsavedDataContext()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const { t } = useTranslation()
  const { push } = useHistory()

  const [collectionPoints, setCollectionPoints] = useState<CollectionPointForUpload[]>([])
  const [validationErrors, setValidationErrors] = useState<ValidateCollectionPointsFileError[]>([])

  const handleValidateFileQueryCompleted = useCallback(
    (result: ValidateCollectionPointsFileResult) => {
      setCollectionPoints(result?.validateCollectionPointsFile?.collectionPoints || [])
      setValidationErrors(result?.validateCollectionPointsFile?.validation.errors || [])
      if (result?.validateCollectionPointsFile?.collectionPoints?.length) {
        setUnsavedDataTitle(t("collection_point_administration.upload.unsaved_data_prompt.title"))
        setUnsavedDataContent(t("collection_point_administration.upload.unsaved_data_prompt.content"))
        setHasUnsavedData(true)
      }
    },
    [setHasUnsavedData, setUnsavedDataContent, setUnsavedDataTitle, t],
  )

  const [validateFileQuery, { loading: validationLoading }] = useLazyQuery<
    ValidateCollectionPointsFileResult,
    ValidateCollectionPointsFileVariables
  >(VALIDATE_COLLECTION_POINTS_FILE, {
    onCompleted: handleValidateFileQueryCompleted,
    fetchPolicy: "no-cache",
  })

  const onUploadCollectionPointError = useCallback(() => {
    setHasUnsavedData(true)
    toast.error(t("collection_point_administration.upload.errors.unkown_error"))
  }, [t, setHasUnsavedData])

  const uploadCollectionPointsCompleted = useCallback(
    (result: UploadCollectionPointsResult) => {
      if (result?.uploadCollectionPoints.error === "false") {
        toast.info(t("collection_point_administration.upload.upload_successful"))
        push(`${PATH.COLLECTIONPOINTS_ADMINISTRATION.route}?refetch=true`)
      } else {
        onUploadCollectionPointError()
      }
    },
    [t, onUploadCollectionPointError, push],
  )

  const [uploadCollectionPointsMutation, { loading: uploadLoading }] = useMutation<
    UploadCollectionPointsResult,
    UploadCollectionPointsVariables
  >(UPLOAD_COLLECTION_POINTS_QUERY, {
    onCompleted: uploadCollectionPointsCompleted,
    onError: onUploadCollectionPointError,
  })

  const validateFile = useCallback(
    (file: File) => {
      validateFileQuery({
        variables: {
          file,
          regionId: (UserService.hasAssociationFilter() ? selectedAssociation?.id : selectedDistrict?.id) || "",
          regionType: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
        },
      })
    },
    [validateFileQuery, selectedAssociation, selectedDistrict],
  )

  const uploadCollectionPoints = useCallback(
    (collectionPoints: CollectionPointForUpload[]) => {
      setHasUnsavedData(false)
      uploadCollectionPointsMutation({
        variables: {
          regionId: (UserService.hasAssociationFilter() ? selectedAssociation?.id : selectedDistrict?.id) || "",
          regionType: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
          collectionPoints: collectionPoints.map((collectionPoint) => ({
            ...collectionPoint,
            collectionPointId: collectionPoint.collectionPointId,
            __typename: undefined,
            containers: collectionPoint.containers.map((container) => ({
              materialId: container.material.id,
              typeId: container.type.id,
              sensorId: container.sensorId,
              number: container.number,
            })),
          })),
        },
      })
    },
    [selectedAssociation, selectedDistrict, uploadCollectionPointsMutation, setHasUnsavedData],
  )

  const [downloadTemplateFile, { loading: downloadTemplateFileLoading }] = useLazyQuery<
    GetDocumentTemplateResult,
    GetDocumentTemplateVariables
  >(GET_DOCUMENT_TEMPLATE, {
    variables: {
      type: DocumentTemplateType.COLLECTION_POINT_BULK_UPLOAD,
    },
    onError: () => {
      toast.error(t("collection_point_administration.errors.template_file_error"))
    },
    onCompleted: (data) => {
      if (data?.getDocumentTemplate) {
        downloadURI(data.getDocumentTemplate.url)
      } else {
        toast.error(t("collection_point_administration.errors.template_file_error"))
      }
    },
  })

  const reset = useCallback(() => {
    setCollectionPoints([])
    setValidationErrors([])
    setHasUnsavedData(false)
    setUnsavedDataTitle("")
    setUnsavedDataContent("")
    setFile(null)
  }, [setCollectionPoints, setValidationErrors, setHasUnsavedData, setUnsavedDataTitle, setUnsavedDataContent, setFile])

  return {
    file,
    setFile,
    validateFile,
    validationLoading,
    validationErrors,
    collectionPoints,
    uploadCollectionPoints,
    uploadLoading,
    downloadTemplateFile,
    downloadTemplateFileLoading,
    reset,
  }
}

export const UploadCollectionPointsProvider: FC = (props) => {
  const value = useUploadCollectionPointsProvider()
  return <UploadCollectionPointsContext.Provider value={value}>{props.children}</UploadCollectionPointsContext.Provider>
}
