import { gql } from "@apollo/client"
import {
  deleteUser,
  deleteUserVariables,
  deleteUser_deleteUser,
} from "./types/deleteUser"

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($email: String!) {
    deleteUser(email: $email) {
      message
    }
  }
`

export type DeleteUserResult = deleteUser
export type DeleteUser = deleteUser_deleteUser
export type DeleteUserVariables = deleteUserVariables
