import { Checkbox, FormControlLabel, Grid, makeStyles } from "@material-ui/core"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import React, { FunctionComponent, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { Day } from "../../../api/graphql/graphql-global-types"
import { SPACING, theme } from "../../../styles/theme"
import { dayToNumber } from "../../../utils/day"
import { TimePicker } from "./time-picker"

const useStyles = makeStyles(() => ({
  timePicker: {
    margin: 0,
  },
}))

export interface OpeningHour {
  id?: string
  day: Day
  from: string // HH:mm
  to: string // HH:mm
  active?: boolean
}

interface IOpeningHoursPickerProps {
  openingHours: OpeningHour[]
  updateOpeningHours: (openingHours: OpeningHour[]) => void
  showCheckboxes?: boolean
  // returns an error message for an openingHour
  // returns an empty string if no error exists
  getErrorMsg?: (openingHour: OpeningHour) => string
}

export const OpeningHoursPicker: FunctionComponent<IOpeningHoursPickerProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { openingHours, updateOpeningHours, showCheckboxes, getErrorMsg = () => "" } = props

  // sort by weekday
  const openingHoursSortedByDay = openingHours.sort((a, b) => dayToNumber(a.day) - dayToNumber(b.day))

  const handleChangeCheckBox = (openingHour: OpeningHour, index: number) => (
    event: SyntheticEvent,
    checked: boolean,
  ) => {
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

    openingHour.active = checked

    copy[index] = openingHour
    updateOpeningHours(copy)
  }

  const handleChangeFromTime = (openingHour: OpeningHour, index: number) => (
    dateTime: MaterialUiPickersDate | null,
    value: any,
  ) => {
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

    openingHour.from = value

    copy[index] = openingHour
    updateOpeningHours(copy)
  }

  const handleChangeToTime = (openingHour: OpeningHour, index: number) => (
    dateTime: MaterialUiPickersDate | null,
    value: any,
  ) => {
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

    openingHour.to = value

    copy[index] = openingHour
    updateOpeningHours(copy)
  }

  return (
    <>
      {openingHoursSortedByDay.map((openingHour, index) => (
        <Grid container direction="row" alignItems={showCheckboxes ? "flex-end" : "center"} spacing={2} key={openingHour.day}>
          <Grid item xs={showCheckboxes ? 2 : 1}>
            {showCheckboxes ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!openingHour.active}
                    onChange={handleChangeCheckBox(openingHour, index)}
                    style={{
                      color: theme.PRIMARY_COLOR.light,
                      padding: `0 ${SPACING}px`,
                    }}
                  />
                }
                label={t(`weekdays.${openingHour.day}_short`)}
              />
            ) : (
              <>{t(`weekdays.${openingHour.day}_short`)}</>
            )}
          </Grid>
          <Grid item xs>
            <TimePicker
              time={openingHour.from}
              onChange={handleChangeFromTime(openingHour, index)}
              className={classes.timePicker}
              disabled={showCheckboxes && !openingHour.active}
              required
              label={t("date_picker.from")}
              error={getErrorMsg(openingHour)}
            />
          </Grid>
          <Grid item xs>
            <TimePicker
              time={openingHour.to}
              onChange={handleChangeToTime(openingHour, index)}
              className={classes.timePicker}
              disabled={showCheckboxes && !openingHour.active}
              required
              label={t("date_picker.to")}
              error={getErrorMsg(openingHour)}
            />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
