import { gql } from "@apollo/client"
import {
  deleteDisposalTrailerLocation,
  deleteDisposalTrailerLocationVariables,
  deleteDisposalTrailerLocation_deleteDisposalTrailerLocation,
} from "./types/deleteDisposalTrailerLocation"

export const DELETE_DISPOSAL_TRAILER_LOCATION_MUTATION = gql`
  mutation deleteDisposalTrailerLocation($disposal_trailer_location_id: ID!) {
    deleteDisposalTrailerLocation(disposal_trailer_location_id: $disposal_trailer_location_id) {
      ... on DisposalTrailerLocationDeleteSuccess {
        id
        message
      }
      ... on RequiredError {error}
      ... on DisposalTrailerLocationDeleteError {error}
      ... on GenericError {error}
    }
  }
`

export type DeleteDisposalTrailerLocationResult = deleteDisposalTrailerLocation
export type DeleteDisposalTrailerLocation = deleteDisposalTrailerLocation_deleteDisposalTrailerLocation
export type DeleteDisposalTrailerLocationVariables = deleteDisposalTrailerLocationVariables
