import React, { useState, useContext, createContext, useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import {
  GET_VEHICLE_WITH_ID_QUERY,
  VehicleResult,
  Vehicle
} from "../api/graphql/queries/get-vehicle-with-id"

interface ISelectedVehicleContextType {
  selectedVehicleId: number | undefined
  setSelectedVehicleId: (id: number | undefined) => void
  selectedVehicle: Vehicle | undefined
}

export const SelectedVehicleContext = createContext<
  ISelectedVehicleContextType
>({} as ISelectedVehicleContextType)

export const SelectedVehicleProvider = (props: any) => {
  const value = useSelectedVehicleProvider()

  return (
    <SelectedVehicleContext.Provider value={value}>
      {props.children}
    </SelectedVehicleContext.Provider>
  )
}

export const useSelectedVehicle = () => {
  return useContext(SelectedVehicleContext)
}

const useSelectedVehicleProvider = (): ISelectedVehicleContextType => {
  const [selectedVehicleId, setSelectedVehicleId] = useState<
    number | undefined
  >(undefined)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(
    undefined
  )
  const client = useApolloClient()
  useEffect(() => {
    if (selectedVehicleId) {
      // do call for vehicle
      const fetchVehicle = async () => {
        const { data } = await client.query({
          query: GET_VEHICLE_WITH_ID_QUERY,
          variables: { id: selectedVehicleId, ignoreTrailer: true }
        })

        setSelectedVehicle((data as VehicleResult).getVehicleWithId)
      }
      fetchVehicle()
    } else {
      setSelectedVehicle(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicleId])

  return {
    selectedVehicleId,
    setSelectedVehicleId,
    selectedVehicle
  }
}
