import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CollectionPointsFilter } from "../../collection-points/partials/collection-points-filter"
import { CompactCollectionPointsTable } from "../../../partials/layout/table/compact-collection-points-table"
import { useQuery } from "@apollo/client"
import { ExportReason, RegionType, StopStatus, STOP_TYPE } from "../../../../api/graphql/graphql-global-types"
import {
  GetCollectionPointsWithRegionIDResult,
  GetCollectionPointsWithRegionIDVariables,
  GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY,
  CollectionPoint,
} from "../../../../api/graphql/queries/get-collection-points-with-region-id"
import {
  CollectionPointFilllevelsForDateResult,
  CollectionPointFilllevelsForDateVariables,
  COLLECTION_POINTS_FOR_DATE_QUERY,
} from "../../../../api/graphql/queries/collection-point-filllevels-for-date"
import { useCollectionPointsFilter } from "../../../../context/CollectionPointsFilterContext"
import { MaterialsResult, GETMATERIALS_QUERY } from "../../../../api/graphql/queries/get-materials"
import lodash from "lodash"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { getAddressStringForCollectionPoint } from "../../../../utils/collectionpoint"
import { StopInfo } from "../../../../api/graphql/queries/get-tour-stats-with-id"
import { Skeleton } from "@material-ui/lab"

const useStyles = makeStyles((theme: Theme) => ({
  materialsHeading: {
    fontWeight: "bold",
  },
  dialog: {
    width: 912,
  },
}))

interface IAddCollectionPointDialogProps {
  newSequenceNumber: number
  open: boolean
  onClose: () => void
  onAddCollectionPoint: (stopInfo: StopInfo) => void
  districtId: number
  date: Date
}

export const AddCollectionPointDialog: FunctionComponent<IAddCollectionPointDialogProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { onClose, onAddCollectionPoint, open, districtId, newSequenceNumber, date } = props
  const { filter, tableProps, setTableProps } = useCollectionPointsFilter()
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<CollectionPoint>()
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)

  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { data: collectionPointsData, loading: collectionPointsLoading } = useQuery<
    GetCollectionPointsWithRegionIDResult,
    GetCollectionPointsWithRegionIDVariables
  >(GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY, {
    variables: {
      id: districtId,
      type: RegionType.DISTRICT,
      page: tableProps.page,
      pagesize: tableProps.pagesize,
      order: {
        orderBy: tableProps.order.orderBy,
        sortingType: tableProps.order.sortingType,
        materialIdToSortBy: tableProps.order.materialIdToSortBy,
      },
      filter,
    },
    skip: lodash.isNil(districtId) || isNaN(districtId),
  })

  const { data: filllevelData, loading: filllevelLoading, error: filllevelError } = useQuery<
    CollectionPointFilllevelsForDateResult,
    CollectionPointFilllevelsForDateVariables
  >(COLLECTION_POINTS_FOR_DATE_QUERY, {
    variables: {
      id: String(selectedCollectionPoint?.id),
      date,
    },
    skip: lodash.isNil(selectedCollectionPoint),
  })

  const onFilterUpdated = () => {
    setSelectedCollectionPoint(undefined)
    setTableProps({ ...tableProps, page: 0 })
  }

  const onCollectionPointConfirmed = () => {
    setConfirmOpen(false)
    if (selectedCollectionPoint && filllevelData) {
      const stopInfo: StopInfo = {
        stop_id: -1,
        sequence_number: newSequenceNumber,
        reason: ExportReason.MANUALLY_INSERTED,
        id: Number(selectedCollectionPoint.id),
        lat: selectedCollectionPoint.latitude,
        lng: selectedCollectionPoint.longitude,
        name: selectedCollectionPoint.description || "",
        place: selectedCollectionPoint.place,
        postal: String(selectedCollectionPoint.postal),
        street: selectedCollectionPoint.street,
        town: selectedCollectionPoint.town.name,
        fixed_unload_interval: selectedCollectionPoint.fixed_unload_interval,
        updated_at: null,
        type: STOP_TYPE.COLLECTION_POINT,
        weighings: [],
        collection_point_images: [],
        leave_at: null,
        vehicle_utilizations: [],
        stop_filllevels: filllevelData!.collectionPointFilllevelsForDate.map((filllevel) => ({
          filllevel: Math.round(Number(filllevel.fill_level.value)),
          amount: null,
          material: {
            id: String(filllevel.material_id),
            name: filllevel.material_name,
            __typename: "Material",
          },
          __typename: "StopFilllevel",
        })),
        status: StopStatus.NONE,
        __typename: "StopInfo",
      }

      onAddCollectionPoint(stopInfo)
    }
  }

  return (
    <Fragment>
      <ConfirmDialog
        confirmText={t("add_collection_point_dialog.add")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => onCollectionPointConfirmed()}
        open={confirmOpen}
        heading={t("add_collection_point_dialog.confirm_title", {
          id: selectedCollectionPoint?.id,
        })}
        loading={filllevelLoading}
        error={!!filllevelError}
      >
        {!lodash.isNil(selectedCollectionPoint) && (
          <Grid container direction="column" spacing={2}>
            <Grid item container direction="row" spacing={2}>
              <Grid item container direction="column" xs>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.sequence_number")}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.description")}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{t("add_collection_point_dialog.address")}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" xs>
                <Grid item>
                  <Typography>{newSequenceNumber + 1}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{selectedCollectionPoint.description}</Typography>
                </Grid>
                <Grid item>
                  <Typography>{getAddressStringForCollectionPoint(selectedCollectionPoint!)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="column">
              <Grid item>
                <Typography className={classes.materialsHeading}>
                  {t("add_collection_point_dialog.materials")}
                </Typography>
              </Grid>
              {!filllevelLoading && filllevelData ? (
                <Grid item>
                  {!!filllevelError ? (
                    <Typography color="textSecondary">{t("add_collection_point_dialog.materials_error")}</Typography>
                  ) : (
                    filllevelData.collectionPointFilllevelsForDate.map((filllevel) => (
                      <Grid item key={filllevel.material_id}>
                        {filllevel.material_name}: {Math.round(Number(filllevel.fill_level.value))}% (
                        {Math.round(filllevel.weight * 100) / 100}kg)
                      </Grid>
                    ))
                  )}
                </Grid>
              ) : (
                <Skeleton animation="wave" />
              )}
            </Grid>
          </Grid>
        )}
      </ConfirmDialog>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>{t("add_collection_point_dialog.title")}</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Grid container direction="column">
            <Grid item>
              <CollectionPointsFilter
                districtId={districtId}
                variant="tour_administration"
                onFilterUpdated={onFilterUpdated}
              />
            </Grid>
            <Grid item>
              <CompactCollectionPointsTable
                data={collectionPointsData ? collectionPointsData.getCollectionPointsWithRegionID.entries : []}
                selectedRow={Number(selectedCollectionPoint?.id)}
                loading={collectionPointsLoading}
                rowNumber={collectionPointsData?.getCollectionPointsWithRegionID.totalEntries || 0}
                onRowClicked={(id: number) => {
                  if (String(id) === selectedCollectionPoint?.id) {
                    setSelectedCollectionPoint(undefined)
                  } else {
                    setSelectedCollectionPoint(
                      collectionPointsData!.getCollectionPointsWithRegionID.entries.find((cp) => cp.id === String(id)),
                    )
                  }
                }}
                onPaginationChanged={() => setSelectedCollectionPoint(undefined)}
                onSortingChanged={() => setSelectedCollectionPoint(undefined)}
                materials={materialsData?.getMaterials || []}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justify="space-between">
            <Grid item>
              <Button onClick={onClose} autoFocus variant="contained">
                {t("add_collection_point_dialog.cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setConfirmOpen(true)}
                variant="contained"
                color="primary"
                disabled={lodash.isNil(selectedCollectionPoint)}
              >
                {t("add_collection_point_dialog.add")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
