import React, { useCallback, useContext } from "react"
import { Button, CircularProgress, Grid, Theme, makeStyles } from "@material-ui/core"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "@apollo/client"
import {
  HOUSEHOLD_CONTAINERS_EXPORT_QUERY,
  HouseholdContainersExportResult,
  HouseholdContainersExportVariables,
} from "../../../../api/graphql/queries/household-containers-export"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { UserService } from "../../../../services/user-service"
import { RegionType } from "../../../../api/graphql/graphql-global-types"
import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { DownloadManagerContext } from "../../../partials/download-manager/download-manager-context"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 200,
  },
}))

interface IHouseholdOverviewHeaderProps {}

export const HouseholdOverviewHeader: React.FC<IHouseholdOverviewHeaderProps> = (props) => {
  const { filter } = useContext(HouseholdOverviewFilterContext)
  const { t } = useTranslation()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const { setTriggerQuery } = useContext(DownloadManagerContext)
  const classes = useStyles()

  const [fileExport, { loading }] = useLazyQuery<HouseholdContainersExportResult, HouseholdContainersExportVariables>(
    HOUSEHOLD_CONTAINERS_EXPORT_QUERY,
    {
      onCompleted: (data) => {
        if (!data.householdContainersExport) {
          toast.error(t("export.unknown_error"))
        }
        setTriggerQuery(true)
      },
    },
  )

  const onClick = useCallback(() => {
    fileExport({
      variables: {
        countyIds: filter.selectedCounties.map((option) => option.id),
        materialIds: filter.selectedMaterials.map((option) => option.id),
        regionType: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
        regionId: UserService.hasAssociationFilter() ? String(selectedAssociation?.id) : String(selectedDistrict?.id),
        townIds: filter.selectedTowns.map((option) => option.id),
        address: filter.address ? filter.address : null,
        showWarnings: filter.showWarnings,
      },
    })
  }, [filter, selectedAssociation, fileExport, selectedDistrict])

  return (
    <Grid container justify="space-between">
      <Grid item>
        <PageHeading>{t("collection_points.heading")}</PageHeading>
      </Grid>
      <Grid item>
        <Button onClick={onClick} variant="contained" disabled={loading} color="primary" className={classes.button}>
          {!loading && t("collection_points.export_containers")}
          {loading && <CircularProgress size={25} />}
        </Button>
      </Grid>
    </Grid>
  )
}
