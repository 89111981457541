import React, { FunctionComponent, SyntheticEvent, useCallback, useMemo } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Card,
  Typography,
  TextField,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core"
import { Clear, Info } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { Autocomplete } from "@material-ui/lab"
import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import {
  GET_TOWNS_WITH_REGION_ID_QUERY,
  GetTownsWithRegionIDResult,
  GetTownsWithRegionIDVariables,
} from "../../../../api/graphql/queries/get-towns-with-region-id"
import { useQuery } from "@apollo/client"
import lodash from "lodash"
import { theme, SPACING } from "../../../../styles/theme"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { UserService } from "../../../../services/user-service"
import { CollectionType, RegionType } from "../../../../api/graphql/graphql-global-types"
import {
  useCollectionPointsFilter,
  ICollectionPointsFilter,
  defaultFilterValues,
} from "../../../../context/CollectionPointsFilterContext"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { GETMATERIALS_QUERY, Material, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { UploadCollectionPointsButton } from "../../collection-point-administration/partials/upload/upload-collection-points-button"
import { useCountyFilter } from "../../../../hooks/use-county-filter"
import { hasCollectionPointFilterChanged } from "../../../../utils/collection-point-filter"

const useStyles = makeStyles((theme: Theme) => ({
  filterContainerCard: {
    padding: theme.spacing(1),
  },
  filterContainerItems: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  filterHeading: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  filterContainer: {
    marginBottom: theme.spacing(1),
  },
  associationSelectCard: {
    height: "100%",
    display: "flex",
  },
  associationSelectContainer: {
    padding: theme.spacing(1),
  },
  townInput: {
    padding: "0!important",
  },
  inputLabel: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: 4,
    paddingRight: 4,
  },
  townSelect: {
    marginBottom: theme.spacing(1),
  },
  tourAdministrationFields: {
    paddingTop: theme.spacing(0.5),
  },
}))

const filllevelFilterValues = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10]

interface ICollectionPointsFilterProps {
  onFilterUpdated: () => void
  variant: "overview" | "administration" | "tour_administration"
  districtId?: number
}

export const CollectionPointsFilter: FunctionComponent<ICollectionPointsFilterProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedAssociation, associationsLoading } = useAssociationFilter()
  const { selectedDistrict, districtsLoading } = useDistrictFilter()
  const { onFilterUpdated, variant, districtId: districtIdFromProps } = props
  const isAdministrationVariant = variant === "administration"
  const isTourAdministrationVariant = variant === "tour_administration"
  const isOverviewVariant = variant === "overview"

  const { filter, setFilter, referenceDate, setReferenceDate } = useCollectionPointsFilter()

  const { data: materialsData, loading: materialsLoading } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { data: townsData, loading: townsLoading, error: townsError } = useQuery<
    GetTownsWithRegionIDResult,
    GetTownsWithRegionIDVariables
  >(GET_TOWNS_WITH_REGION_ID_QUERY, {
    variables: {
      id:
        districtIdFromProps ||
        (UserService.hasAssociationFilter() ? Number(selectedAssociation?.id) : Number(selectedDistrict?.id)),
      type:
        UserService.hasAssociationFilter() && lodash.isNil(districtIdFromProps)
          ? RegionType.ASSOCIATION
          : RegionType.DISTRICT,
      counties: filter.counties || [],
    },
    skip:
      lodash.isNil(districtIdFromProps) &&
      ((UserService.hasAssociationFilter() && !selectedAssociation) ||
        (!UserService.hasAssociationFilter() && !selectedDistrict)),
    fetchPolicy: "cache-first",
    onCompleted: (result) => {
      updateFilter({
        ...filter,
        towns: (filter.towns || []).filter((name) => result.getTownsWithRegionID.find((town) => town.name === name)),
      })
    },
  })

  const { options: countyOptions, loading: countiesLoading, error: countiesError } = useCountyFilter(
    !UserService.hasAssociationFilter() ? districtIdFromProps || Number(selectedDistrict?.id) : null,
    (result) =>
      updateFilter({
        ...filter,
        counties: (filter.counties || []).filter((id) => result.counties.find((county) => county.id === id)),
      }),
  )

  const updateFilter = useCallback(
    (newFilter: ICollectionPointsFilter) => {
      if (hasCollectionPointFilterChanged(newFilter, filter)) {
        setFilter(newFilter)
        onFilterUpdated()
      }
    },
    [setFilter, onFilterUpdated, filter],
  )

  const selectedCounties = useMemo(
    () =>
      filter.counties
        ?.map((id) => countyOptions.find((option) => option.id === id) as SelectOption)
        .filter((option) => !!option) || [],
    [filter, countyOptions],
  )

  const onFilterRemoveClicked = () => {
    updateFilter(defaultFilterValues)
    onFilterUpdated()
  }
  const collectionTypeOptions: CollectionType[] = Object.keys(CollectionType) as CollectionType[]
  const regionsLoading = useMemo(() => (UserService.hasAssociationFilter() ? associationsLoading : districtsLoading), [
    associationsLoading,
    districtsLoading,
  ])

  // admins always see forecast; other roles only if it's enabled for the region
  const showReferenceDate = useMemo(
    () =>
      isOverviewVariant &&
      (UserService.isAdmin() ||
        (!regionsLoading && UserService.hasAssociationFilter()
          ? selectedAssociation?.forecastEnabled
          : selectedDistrict?.forecastEnabled)),
    [selectedAssociation, selectedDistrict, regionsLoading, isOverviewVariant],
  )

  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      className={classes.filterContainer}
      direction={isAdministrationVariant ? "column" : "row"}
    >
      {!isTourAdministrationVariant && (
        <Grid item xs={isAdministrationVariant ? 12 : 2}>
          <Card className={!isAdministrationVariant ? classes.associationSelectCard : undefined}>
            <Grid
              direction="column"
              justify="flex-start"
              container
              spacing={2}
              className={classes.associationSelectContainer}
            >
              <Grid item>
                <RegionSelect />
              </Grid>
              {showReferenceDate && (
                <Grid container item direction="row" spacing={1} alignItems="flex-end">
                  <Grid item>
                    <Tooltip title={t("collection_points.filter.reference_date_info") as string} placement="bottom">
                      <Info color="primary" />
                    </Tooltip>
                  </Grid>
                  <Grid item xs>
                    <KeyboardDatePicker
                      label={t("collection_points.filter.reference_date")}
                      format={t("date_format")}
                      value={referenceDate}
                      minDate={new Date()}
                      fullWidth
                      onChange={(date) => date && setReferenceDate(date)}
                      okLabel={t("date_picker.ok")}
                      helperText={null}
                      data-testid="reference-date-picker"
                    />
                  </Grid>
                </Grid>
              )}

              {isAdministrationVariant && (
                <Grid item>
                  <UploadCollectionPointsButton />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid item xs={!isOverviewVariant ? 12 : 10}>
        <Card className={classes.filterContainerCard}>
          <Grid container spacing={2} direction={isAdministrationVariant ? "column" : "row"}>
            <Grid item>
              <Typography className={classes.filterHeading} variant={"h5"}>
                {t("collection_points.filter.heading")}
              </Typography>
            </Grid>
            <Grid container item xs alignItems={isTourAdministrationVariant ? "flex-start" : "center"}>
              <Grid
                item
                container
                xs={isAdministrationVariant ? 12 : 8}
                lg={isAdministrationVariant ? 12 : 9}
                spacing={1}
                direction={isAdministrationVariant ? "column" : "row"}
                className={isTourAdministrationVariant ? classes.tourAdministrationFields : undefined}
                alignItems={isOverviewVariant ? "flex-end" : "stretch"}
              >
                <Grid
                  item
                  xs={(isAdministrationVariant && 12) || (isOverviewVariant && 4) || (isTourAdministrationVariant && 4)}
                >
                  <TextField
                    className={classes.filterContainerItems}
                    id="outlined-search"
                    label={t("collection_points.filter.description")}
                    type="search"
                    variant="outlined"
                    value={filter.description}
                    onChange={(ev) => {
                      updateFilter({ ...filter, description: ev.target.value })
                    }}
                  />
                </Grid>
                {!UserService.isIndustry() && (
                  <Grid
                    item
                    xs={
                      (isAdministrationVariant && 12) || (isOverviewVariant && 2) || (isTourAdministrationVariant && 4)
                    }
                  >
                    <TextField
                      className={classes.filterContainerItems}
                      id="outlined-search"
                      label={t("collection_points.filter.postal_code")}
                      type="search"
                      variant="outlined"
                      placeholder="3580"
                      value={filter.postal}
                      onChange={(ev) => {
                        updateFilter({ ...filter, postal: ev.target.value })
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={(isAdministrationVariant && 12) || (isOverviewVariant && 2) || (isTourAdministrationVariant && 4)}
                >
                  <TextField
                    className={classes.filterContainerItems}
                    id="outlined-search"
                    label={t("collection_points.filter.container_number")}
                    type="search"
                    variant="outlined"
                    placeholder="2"
                    value={filter.containerNumber}
                    onChange={(ev) => {
                      updateFilter({ ...filter, containerNumber: ev.target.value })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={(isAdministrationVariant && 12) || (isOverviewVariant && 2) || (isTourAdministrationVariant && 3)}
                >
                  <TextField
                    className={classes.filterContainerItems}
                    id="outlined-search"
                    label={t("collection_points.filter.sensor_id")}
                    type="search"
                    variant="outlined"
                    value={filter.sensorId}
                    onChange={(ev) => {
                      updateFilter({ ...filter, sensorId: ev.target.value })
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={
                    (isAdministrationVariant && 12) ||
                    (isOverviewVariant && 2) ||
                    (isTourAdministrationVariant && !UserService.isIndustry() && 3) ||
                    (isTourAdministrationVariant && UserService.isIndustry() && 4)
                  }
                >
                  <TextField
                    className={classes.filterContainerItems}
                    id="outlined-search"
                    label={t("collection_points.filter.address")}
                    type="search"
                    variant="outlined"
                    placeholder="Hauptstraße 5"
                    value={filter.address}
                    onChange={(ev) => {
                      updateFilter({ ...filter, address: ev.target.value })
                    }}
                  />
                </Grid>
                {!UserService.hasAssociationFilter() && (
                  <Grid
                    item
                    xs={
                      (isAdministrationVariant && 12) ||
                      (isOverviewVariant && 4) ||
                      (isTourAdministrationVariant && !UserService.isIndustry() && 6) ||
                      (isTourAdministrationVariant && UserService.isIndustry() && 7)
                    }
                    className={isAdministrationVariant ? classes.townSelect : undefined}
                  >
                    {countyOptions && !countiesLoading && !countiesError && (
                      <CustomAutocomplete
                        id="collection-points-filter-counties"
                        title={t("collection_points.filter.counties_label")}
                        currentlySelectedValues={selectedCounties}
                        availableSelectOptions={countyOptions}
                        setCurrentlySelectedValues={(selectedOptions) =>
                          updateFilter({ ...filter, counties: selectedOptions.map((option) => option.id) })
                        }
                      />
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  xs={
                    (isAdministrationVariant && 12) ||
                    (isOverviewVariant && 4) ||
                    (isTourAdministrationVariant && !UserService.isIndustry() && 12) ||
                    (isTourAdministrationVariant && UserService.isIndustry() && 11)
                  }
                  className={isAdministrationVariant ? classes.townSelect : undefined}
                >
                  {townsData && !townsLoading && !townsError && filter.towns && (
                    <CustomAutocomplete
                      id="collection-points-filter-towns"
                      title={t("collection_points.filter.towns_label")}
                      currentlySelectedValues={filter.towns.map((name) => new SelectOption(name, name))}
                      availableSelectOptions={townsData.getTownsWithRegionID.map(
                        (town) =>
                          new SelectOption(
                            town.name,
                            lodash.isNil(town.town_code) ? town.name : `${town.name} - ${town.town_code}`,
                          ),
                      )}
                      setCurrentlySelectedValues={(selectedOptions) =>
                        updateFilter({ ...filter, towns: selectedOptions.map((option) => option.id) })
                      }
                    />
                  )}
                </Grid>
                {isOverviewVariant && (
                  <>
                    <Grid item xs={4}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="role-label" className={classes.inputLabel}>
                          {t("collection_points.filter.filllevel_label")}
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="role-label"
                          value={filter.minFilllevel}
                          displayEmpty
                          fullWidth
                          required
                          onChange={(event) => {
                            updateFilter({ ...filter, minFilllevel: Number(event.target.value) })
                          }}
                        >
                          <MenuItem value={-1}>{t("collection_points.filter.all_filllevels")}</MenuItem>
                          {filllevelFilterValues.map((value) => (
                            <MenuItem key={value} value={value}>
                              {`>= ${value}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={materialsData?.getMaterials.filter((m) => filter.materialIds?.includes(m.id)) || []}
                        options={lodash.sortBy(materialsData?.getMaterials, "name")}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, val) => option.id === val.id}
                        onChange={(_, selectedMaterials: Material[]) =>
                          updateFilter({ ...filter, materialIds: selectedMaterials.map((m) => m.id) })
                        }
                        id="material-select"
                        size="small"
                        loading={materialsLoading}
                        multiple
                        fullWidth
                        disableCloseOnSelect
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon color="primary" fontSize="small" />}
                              checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        renderInput={(params) => {
                          // enables wrapping of selected materials inside <select>
                          const newParams = {
                            ...params,
                            InputProps: {
                              ...params.InputProps,
                              style: { minHeight: "40px", maxHeight: "120px", height: "auto" },
                            },
                          }
                          return (
                            <TextField
                              {...newParams}
                              variant="outlined"
                              size={"small"}
                              label={t("collection_points.filter.materials")}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={filter.collectionTypes || []}
                        options={lodash.sortBy(collectionTypeOptions, (ct) =>
                          t(`collection_points.collection_type.${ct}`),
                        )}
                        getOptionLabel={(option) => t(`collection_points.collection_type.${option}`)}
                        getOptionSelected={(option, val) => option === val}
                        onChange={(_, collectionTypes: CollectionType[]) =>
                          updateFilter({ ...filter, collectionTypes })
                        }
                        size="small"
                        multiple
                        fullWidth
                        disableCloseOnSelect
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon color="primary" fontSize="small" />}
                              checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {t(`collection_points.collection_type.${option}`)}
                          </React.Fragment>
                        )}
                        renderInput={(params) => {
                          // enables wrapping of selected collection types inside <select>
                          const newParams = {
                            ...params,
                            InputProps: {
                              ...params.InputProps,
                              style: { minHeight: "40px", maxHeight: "120px", height: "auto" },
                            },
                          }
                          return (
                            <TextField
                              {...newParams}
                              variant="outlined"
                              size={"small"}
                              label={t("collection_points.filter.collection_type")}
                            />
                          )
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filter.onlyIssueContainers || false}
                            onChange={(event: SyntheticEvent, checked: boolean) => {
                              updateFilter({ ...filter, onlyIssueContainers: checked })
                            }}
                            style={{
                              color: theme.PRIMARY_COLOR.light,
                              padding: `0 ${SPACING}px`,
                            }}
                            value={filter.onlyIssueContainers}
                          />
                        }
                        label={t("collection_points.filter.only_issue_containers")}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid
                item
                container
                xs={isAdministrationVariant ? 12 : 4}
                lg={isAdministrationVariant ? 12 : 3}
                alignItems="center"
                justify={isAdministrationVariant ? "space-between" : "flex-end"}
                spacing={1}
                direction="row"
              >
                <Grid item>
                  <Tooltip placement="top" title={t("collection_points.filter.remove_filter") as string}>
                    <IconButton onClick={onFilterRemoveClicked}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
