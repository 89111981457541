import { Day } from "../api/graphql/graphql-global-types"
import { DisposalMerchantStatsOpeningHours } from "../api/graphql/queries/get-disposal-merchant-stats-with-id"
import { VehicleOperatingTime } from "../api/graphql/queries/get-vehicle-with-id"

export enum day {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}

export enum weekday {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
}

export interface DayOfWeek {
  week: number
  day: Day
}

export const getInitialDisposalMerchantOpeningHours = (
  values: DisposalMerchantStatsOpeningHours[],
  from: string,
  to: string,
): DisposalMerchantStatsOpeningHours[] => {
  return Object.keys(day).map((day) => {
    const existing = values.find((value) => value.openingHour.day === day)
    if (existing) {
      return existing
    }
    return {
      active: false,
      openingHour: {
        day,
        from,
        to,
      },
    } as DisposalMerchantStatsOpeningHours
  })
}

export const getInitialVehicleOperatingTimes = (
  values: VehicleOperatingTime[],
  maxTourDuration: number,
  minTourDuration: number,
): VehicleOperatingTime[] => {
  return Object.keys(day).map((day) => {
    const existing = values.find((value) => value.day === day)
    if (existing) {
      return existing
    }
    return {
      active: false,
      day,
      max_tour_duration: maxTourDuration,
      min_tour_duration: minTourDuration,
    } as VehicleOperatingTime
  })
}

export function dayToNumber(value: Day): number {
  switch (value) {
    case Day.monday:
      return 1
    case Day.tuesday:
      return 2
    case Day.wednesday:
      return 3
    case Day.thursday:
      return 4
    case Day.friday:
      return 5
    case Day.saturday:
      return 6
    case Day.sunday:
      return 7
  }
}

export const numberToDay = (value: number): Day => {
  switch (value % 7) {
    case 1:
      return Day.monday
    case 2:
      return Day.tuesday
    case 3:
      return Day.wednesday
    case 4:
      return Day.thursday
    case 5:
      return Day.friday
    case 6:
      return Day.saturday
    default:
      //Changed to `default:` from `case 0:` to stop typescript from complaining
      return Day.sunday
  }
}

export const dayOfWeekToNumber = ({ day, week }: DayOfWeek) => {
  return (week - 1) * 7 + dayToNumber(day)
}

export const isSameWeekDay = (a: DayOfWeek) => (b: DayOfWeek) => a.day === b.day && a.week === b.week
export const isSameDay = (dayOfWeek: DayOfWeek) => (day: Day) => day === dayOfWeek.day

/**
 * Predicate to sort days (Mo-Su).
 *
 * @example
 * const days = [Day.friday, Day.monday, Day.sunday]
 * days.sort(sortByDay) // [Day.monday, Day.friday, Day.sunday]
 */
export const sortByDay = (a: Day, b: Day) => dayToNumber(a) - dayToNumber(b)

export const sortByDayOfWeek = (a: DayOfWeek, b: DayOfWeek) => dayOfWeekToNumber(a) - dayOfWeekToNumber(b)
