import React, { FunctionComponent, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { AlgorithmType } from "../../../../api/graphql/graphql-global-types"

interface ITourGenerationAlgorithmVersionProps {}

export const TourGenerationAlgorithmVersion: FunctionComponent<ITourGenerationAlgorithmVersionProps> = (props) => {
    const { t } = useTranslation()
    const {
        version,
        setVersion,
        disposalTrailerLocations,
        tourWithoutContainers
    } = useContext(TourGenerationContext)

    const versions: SelectPair[] = useMemo(
        () => [
          // {
          //   title: "AGR",
          //   value: AlgorithmType.AGR,
          // },
          {
            title: "TO2",
            value: AlgorithmType.TO2,
          },
          {
            title: "TO2-Trailer",
            value: AlgorithmType.TO2TRAILER,
          },
        ],
        [],
      )
    
      const isAlgorithmVersion = (inputVersion: string): boolean => {
        const selectedVersion = AlgorithmType[inputVersion as keyof typeof AlgorithmType]
        return Object.values(AlgorithmType).indexOf(selectedVersion) !== -1;
      }
    
    return (
        <OutlinedSelect
            options={disposalTrailerLocations.length >= 0 ? versions : versions.filter(algorithm => algorithm.value !== AlgorithmType.TO2TRAILER)}
            name={t("tour_generation.data.version")}
            onValueChange={(value) => {
              isAlgorithmVersion(value) ? setVersion(AlgorithmType[value as keyof typeof AlgorithmType]) : setVersion(AlgorithmType.TO2)
            }}
            disabled={tourWithoutContainers}
            value={String(version)}
          />
        )
}
