import { gql } from "@apollo/client"
import { downloadFile, downloadFileVariables, downloadFile_downloadFile } from "./types/downloadFile"

export const DOWNLOAD_FILE_QUERY = gql`
  query downloadFile($id: ID!) {
    downloadFile(id: $id) {
      alreadyDownloaded
      fileInfo {
        base64
        filename
      }
    }
  }
`
export type DownloadFileResult = downloadFile
export type DownloadFileVariables = downloadFileVariables
export type FileDownload = downloadFile_downloadFile
