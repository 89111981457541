import { gql } from "@apollo/client"
import {
  updateRouteLocationVariables,
  updateRouteLocation_updateRouteLocation,
} from "./types/updateRouteLocation"

export const UPDATE_ROUTE_LOCATION_MUTATION = gql`
  mutation updateRouteLocation($routeId: ID!, $locationId: ID!) {
    updateRouteLocation(routeId: $routeId, locationId: $locationId) {
      error
      message
    }
  }
`
export type UpdateRouteLocationResult = updateRouteLocation_updateRouteLocation
export type UpdateRouteLocationVariables = updateRouteLocationVariables
