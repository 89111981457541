import React, { FunctionComponent, useState } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Card as MaterialCard,
  Button,
  TextField,
  Container,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GETCOLLECTIONPARTNERS_QUERY,
  CollectionPartnersResult,
} from "../../../../api/graphql/queries/get-collection-partners"
import { PartnerItem } from "./partner-item"

import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { useRefetch } from "../../../../context/refetch-context"
import { ContentContainer } from "../../../partials/layout/content/content-container"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column",
  },
  filterContainerCard: {
    padding: theme.spacing(2),
    width: 270,
  },
  filterContainerItems: {
    width: "100%",
  },
  snackBar: {
    marginTop: 10,
  },
  addPartnerButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  itemsContainer: {
    padding: 0,
    flex: "1 1 auto",
    overflowY: "auto",
  },
  containerTop: {
    padding: 0,
    marginBottom: theme.spacing(2),
    flex: "0 0 auto",
  },
}))

interface IPartnerSelectorProps {}

export const PartnerSelector: FunctionComponent<IPartnerSelectorProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [partnerName, setPartnerName] = useState<string>("")
  const { setSelectedPartnerId } = useSelectedPartner()

  const { needToRefetch, setNeedToRefetch } = useRefetch()

  const { data } = useQuery<CollectionPartnersResult>(GETCOLLECTIONPARTNERS_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  })

  const onFilterApplyClicked = () => {}

  const onFilterRemoveClicked = () => {}

  const onAddNewPartnerClicked = () => {
    setSelectedPartnerId(undefined)
  }

  return (
    <ContentContainer style={classes.container}>
      <Grid container className={classes.containerTop} direction="column">
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            className={classes.addPartnerButton}
            onClick={onAddNewPartnerClicked}
            fullWidth
          >
            <AddIcon className={classes.addIcon} />
          </Button>
        </Grid>
        <Grid item>
          <MaterialCard className={classes.filterContainerCard}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <TextField
                  className={classes.filterContainerItems}
                  id="outlined-search"
                  label={t("partner_overview.filter.partner_name")}
                  type="search"
                  variant="outlined"
                  placeholder="Saubermacher"
                  value={partnerName}
                  onChange={(ev) => {
                    setPartnerName(ev.target.value)
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={onFilterApplyClicked}
                >
                  {t("collection_points.filter.apply_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="default"
                  onClick={onFilterRemoveClicked}
                >
                  {t("collection_points.filter.remove_filter")}
                </Button>
              </Grid>
            </Grid>
          </MaterialCard>
        </Grid>
      </Grid>
      {data && (
        <Container className={classes.itemsContainer}>
          {data.getCollectionPartners.map((partner, index) => (
            <PartnerItem key={index} item={partner} />
          ))}
        </Container>
      )}
    </ContentContainer>
  )
}
