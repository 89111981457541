import React, { FunctionComponent } from "react"

import { SelectedTourProvider } from "../../../context/selected-tour-context"
import { TourOverview } from "./partials/tour-overview"
import { RefetchProvider } from "../../../context/refetch-context"
import { TourOverviewContextProvider } from "./context/tour-overview-context"

interface ITourOverViewPageProps {}

export const TourOverviewPage: FunctionComponent<ITourOverViewPageProps> = (props) => {
  return (
    <SelectedTourProvider>
      <RefetchProvider>
        <TourOverviewContextProvider>
          <TourOverview />
        </TourOverviewContextProvider>
      </RefetchProvider>
    </SelectedTourProvider>
  )
}
