import React, { FC, useState } from "react"
import { Button, Card, Chip, Grid, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { LocationType } from "../../user-management/helper/get-options-for-role"
import { sortASC } from "../../../../utils/sort"
import { ILocationCollectionItem, LocationAssignmentDialog } from "./location-assignment-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  materialsCard: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    height: `calc(100% - ${theme.spacing(2)}px)`,
    width: "100%",
  },
  assignBtns: {
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
}))

interface IDisposalMerchantTownSelectorProps {
  selectedDistricts: ILocationCollectionItem[]
  selectedTowns: ILocationCollectionItem[]
  setSelectedTowns: (locations: ILocationCollectionItem[]) => void
}

export const DisposalMerchantTownSelector: FC<IDisposalMerchantTownSelectorProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { selectedDistricts, selectedTowns, setSelectedTowns } = props
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const onSave = (items: ILocationCollectionItem[]) => {
    const sorted = items.sort((a, b) => sortASC(a.name, b.name))
    setSelectedTowns(sorted)
  }

  return (
    <>
      <Card className={classes.materialsCard}>
        <Grid container direction="column" spacing={2} justify="center">
          <Grid container item alignItems="center" justify="space-between">
            <Grid item>
              <Text bold>{t(`location_type.${LocationType.TOWN}`)}</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsDialogOpen(true)}
                    className={classes.assignBtns}
                    disabled={selectedDistricts.length === 0}
                  >
                    {t("user_management.data.assign", {
                      collection: t(`location_type.${LocationType.TOWN}`),
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {selectedTowns.length > 0 && (
            <Grid item container spacing={1}>
              {selectedTowns.map((item) => (
                <Grid item key={item.id}>
                  <Chip label={item.name} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Card>

      <LocationAssignmentDialog
        type={LocationType.TOWN}
        collection={selectedTowns}
        open={isDialogOpen}
        onSave={onSave}
        onClose={() => setIsDialogOpen(false)}
        selectedDistricts={selectedDistricts}
      />
    </>
  )
}
