import { gql } from "@apollo/client"
import {
  createOrUpdateContainer,
  createOrUpdateContainerVariables,
  createOrUpdateContainer_createOrUpdateContainer
} from "./types/createOrUpdateContainer"

export const CREATE_OR_UPDATE_CONTAINER_MUTATION = gql`
  mutation createOrUpdateContainer(
    $collection_point_id: ID!
    $container: ContainerInput!
  ) {
    createOrUpdateContainer(
      collection_point_id: $collection_point_id
      container: $container
    ) {
      id
      sensor_id
      number
      material {
        id
      }
      manufacturer {
        id
      }
      type {
        id
      }
      emptying_requested_at
    }
  }
`

export type CreateOrUpdateContainerResult = createOrUpdateContainer
export type CreateOrUpdateContainer = createOrUpdateContainer_createOrUpdateContainer
export type CreateOrUpdateContainerVariables = createOrUpdateContainerVariables
