import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Card, Grid } from "@material-ui/core"

import { Text } from "../../../partials/wrapper/text"
import { useSelectedVehicle } from "../../../../context/selected-vehicle-context"
import { Vehicles } from "../../../../api/graphql/queries/get-vehicles-with-partner-id"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(1),
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedCard: {
    boxShadow: "0 0 4px 0 rgba(75, 151, 62, 0.5)"
  },
  container: {
    height: "100%",
    padding: theme.spacing(1)
  },
  text: {
    fontSize: 15
  }
}))

interface IVehicleItemProps {
  item: Vehicles
}

export const VehicleItem: FunctionComponent<IVehicleItemProps> = props => {
  const classes = useStyles(props)
  const { selectedVehicleId, setSelectedVehicleId } = useSelectedVehicle()
  const { item } = props

  const onClick = () => {
    setSelectedVehicleId(props.item.id)
  }

  const isSelected = () => {
    if (!selectedVehicleId) return false
    return selectedVehicleId === props.item.id
  }

  return (
    <Fragment>
      <Card
        className={`${isSelected() && classes.selectedCard} ${classes.card}`}
        onClick={onClick}
      >
        <Grid className={classes.container} container direction="row">
          <Grid item xs={12}>
            <Text bold noWrap>
              {item.licence_plate || "-"}
            </Text>
          </Grid>
          <Grid item container xs={12} justify="space-between">
            <Grid item>
              <Text className={classes.text} noWrap>
                {item.type || "-"}
              </Text>
            </Grid>
            <Grid item>
              <Text className={classes.text} noWrap>
                {item.district.name}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
