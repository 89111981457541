import React, { FunctionComponent } from "react"
import { Theme, makeStyles, Tabs, Tab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ImageType } from "../../../../api/graphql/queries/get-initial-images-with-collection-point-id"

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    color: "#fff",
    fontWeight: "bold",
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  tabs: {
    height: "100%"
  }
}))

interface ICustomLightBoxTabsProps {
  imageTypes: ImageType[]
  onSelectionChange: (newValue: string) => void
  selectedImageType: string
}

export const CustomLightBoxTabs: FunctionComponent<ICustomLightBoxTabsProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { imageTypes, onSelectionChange, selectedImageType } = props

  return (
    <Tabs
      className={classes.tabs}
      value={selectedImageType}
      indicatorColor="primary"
      textColor="primary"
      onChange={(event: React.ChangeEvent<{}>, newValue: string) =>
        onSelectionChange(newValue)
      }
    >
      {imageTypes.map(type => (
        <Tab
          key={type.id}
          value={type.id}
          label={t(`image_types.${type.name.toLowerCase()}`)}
          className={classes.tab}
        />
      ))}
    </Tabs>
  )
}
