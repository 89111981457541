/*global google*/
import React, { FunctionComponent, Fragment, useState } from "react"
import { Theme, makeStyles, TextField, Card, Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete"
import { useGoogleMap } from "@react-google-maps/api"

import { Text } from "../wrapper/text"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: 10,
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  activeSuggestion: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    margin: 1
  },
  inactiveSuggestion: {
    backgroundColor: "#ffffff",
    cursor: "pointer",
    margin: 1
  }
}))

interface IPlaceSearcherProps {}

export const PlaceSearcher: FunctionComponent<IPlaceSearcherProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const instance = useGoogleMap()
  const [address, setAddress] = useState<string>("")

  const onSelect = async (address: any) => {
    setAddress(address)
    const res = await geocodeByAddress(address)
    const location = await getLatLng(res[0])

    if (instance) {
      ;(instance as any).zoom = 15

      instance.setCenter(
        new google.maps.LatLng(location.lat, location.lng)
      )
    }
  }

  return (
    <Fragment>
      <PlacesAutocomplete
        value={address}
        onChange={value => setAddress(value)}
        onSelect={onSelect}
      >
        {({ getInputProps, suggestions, loading }) => (
          <Card className={classes.card}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <TextField
                {...getInputProps({
                  placeholder: t("map.search")
                })}
                className={classes.textField}
              />
            </Grid>

            <Grid>
              {loading && <Text>{t("map.loading")}.</Text>}

              {suggestions.map((suggestion, idx) => {
                return (
                  <Grid
                    key={idx}
                    className={
                      suggestion.active
                        ? classes.activeSuggestion
                        : classes.inactiveSuggestion
                    }
                    onMouseDown={() => onSelect(suggestion.description)}
                  >
                    <Text>{suggestion.description}</Text>
                  </Grid>
                )
              })}
            </Grid>
          </Card>
        )}
      </PlacesAutocomplete>
    </Fragment>
  )
}
