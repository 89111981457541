import { Button } from "@material-ui/core"
import React, { FunctionComponent, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"
import { PATH } from "../../../../../router/router"

interface IUploadCollectionPointsButtonProps {}

export const UploadCollectionPointsButton: FunctionComponent<IUploadCollectionPointsButtonProps> = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const match = useRouteMatch()

  const handleButtonClicked = useCallback(() => {
    if (match?.path !== PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route) {
      push(PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route)
    }
  }, [push, match])

  return (
    <Button color="primary" variant="contained" fullWidth onClick={handleButtonClicked}>
      {t("collection_point_administration.upload.upload_collection_points")}
    </Button>
  )
}
