import { gql } from "@apollo/client"
import {
    createDisposalMerchantVacationTimes,
    createDisposalMerchantVacationTimesVariables,
    createDisposalMerchantVacationTimes_createDisposalMerchantVacationTimes
} from "./types/createDisposalMerchantVacationTimes"

export const CREATE_DISPOSAL_MERCHANT_VACATION_TIMES_MUTATION = gql`
  mutation createDisposalMerchantVacationTimes(
    $disposalMerchantId: ID!
    $vacationTimes: [DisposalMerchantVacationTimesInput!]!
  ) {
    createDisposalMerchantVacationTimes(
      disposalMerchantId: $disposalMerchantId
      vacationTimes: $vacationTimes
    ) {
      id
    }
  }
`

export type CreateDisposalMerchantVacationTimesResult = createDisposalMerchantVacationTimes
export type CreateDisposalMerchantVacationTimes = createDisposalMerchantVacationTimes_createDisposalMerchantVacationTimes
export type CreateDisposalMerchantVacationTimesVariables = createDisposalMerchantVacationTimesVariables