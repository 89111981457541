import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, makeStyles, Grid, TextField } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { CustomMap, IMarker, ICenterOption } from "../../partials/maps/custom-map"
import { toGeoFloat } from "../../../utils/map"
import { useDebounce } from "../../../utils/useDebounce"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"

const useStyles = makeStyles((theme: Theme) => ({
  mapWrapper: {
    width: "100%",
  },
}))

interface ISearchMapProps {
  lat: string
  long: string
  setLat: (lat: string) => void
  setLong: (long: string) => void
}

/**
 * Google map with searchbar above.
 */
export const SearchMap: FunctionComponent<ISearchMapProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { lat, long, setLat, setLong } = props

  const [address, setAddress] = useState<string>("")
  const debouncedAddress = useDebounce(address, 1000) as string

  useEffect(() => {
    if (debouncedAddress.trim().length > 0) {
      geocodeByAddress(debouncedAddress).then(async (result) => {
        const location = await getLatLng(result[0])
        if (location.lat && location.lng) {
          setLat(`${location.lat}`)
          setLong(`${location.lng}`)
        }
      })
    }
  }, [debouncedAddress, setLat, setLong])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          value={address}
          variant="outlined"
          fullWidth
          label={t("search")}
          onChange={(ev) => setAddress(ev.target.value)}
        />
      </Grid>
      <Grid item xs={12} className={classes.mapWrapper}>
        <CustomMap
          disableInfoWindow
          disableZoom
          center={
            {
              lat: toGeoFloat(lat),
              lng: toGeoFloat(long),
            } as ICenterOption
          }
          onMapClicked={(event) => {
            if (!event?.latLng) {
              return
            }
            setLat(`${event.latLng.lat()}`)
            setLong(`${event.latLng.lng()}`)
          }}
          markers={[
            {
              lat: toGeoFloat(lat),
              lng: toGeoFloat(long),
            } as IMarker,
          ]}
        />
      </Grid>
    </Grid>
  )
}
