import { gql } from "@apollo/client"
import { defaultTheme, defaultTheme_defaultTheme } from "./types/defaultTheme"

export const DEFAULT_THEME_QUERY = gql`
  query defaultTheme {
    defaultTheme {
      id
      name
      portal {
        primary_main
        primary_light
        primary_dark
        primary_contrast_text
        secondary_main
        secondary_light
        secondary_dark
        secondary_contrast_text
        error
        background
        text
      }
      logo_url
    }
  }
`

export type DefaultThemeResult = defaultTheme
export type DefaultTheme = defaultTheme_defaultTheme
