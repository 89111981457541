import React, { useContext } from "react"
import { HouseholdWithStatus } from "../../../../api/graphql/queries/household-statuses"
import { Grid, Theme, Typography, makeStyles } from "@material-ui/core"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import moment from "moment"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  indicator3: {
    width: 18,
    height: 18,
    borderRadius: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  indicator2: {
    width: 14,
    height: 14,
    borderRadius: 90,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  indicator1: {
    width: 10,
    height: 10,
    borderRadius: 90,
  },
  title: {
    fontWeight: 700,
  },
  statusText: {
    marginLeft: theme.spacing(1),
  },
}))

interface IHouseholdMarkerInfoWindowContentProps {
  household: HouseholdWithStatus
}

export const HouseholdMarkerInfoWindowContent: React.FC<IHouseholdMarkerInfoWindowContentProps> = (props) => {
  const { household } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { materialOptions } = useContext(HouseholdOverviewFilterContext)

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <Typography
          className={classes.title}
        >{`${household.street} ${household.number}, ${household.postal} ${household.place}`}</Typography>
      </Grid>
      {household.status.materialStatus.map((status) => (
        <Grid item key={`${household.id}-${status.materialId}`} container alignItems="center" spacing={1} wrap="nowrap">
          <Grid item>
            <div className={classes.indicator3} style={{ backgroundColor: status.color }}>
              <div className={classes.indicator2}>
                <div className={classes.indicator1} style={{ backgroundColor: status.color }} />
              </div>
            </div>
          </Grid>
          <Grid item container>
            <Typography className={classes.title}>
              {materialOptions.find((option) => option.id === status.materialId)?.name} ({status.amountContainers}/
              {status.amountIntendedContainers})
            </Typography>
            <Typography className={classes.statusText}>
              {t("status")} {status.lastUpdated ? `[${moment(status.lastUpdated).format("DD.MM.YY")}]` : "-"}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
