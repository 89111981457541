export const DEFAULT_ASSOCIATION_ID = "1"
export const DEFAULT_DISTRICT_ID = "1"

export const CARD_TILE_WIDTH = 210
export const CARD_TILE_HEIGHT = 110
export const INFO_CARD_WIDTH = 420
export const INFO_CARD_HEIGHT = 190

export const DEFAULT_AUTOMATIC_BREAK = 360
export const DEFAULT_AUTOMATIC_BREAK_REMINDER = 15
export const FIXED_UNLOAD_INTERVAL_ON_DEMAND = -1
export const MAX_EXTRAPOLATION_FILLLEVEL_PERCENTAGE = 115