import { Grid } from "@material-ui/core"
import moment from "moment"
import React, { FunctionComponent, useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { DayOfWeek } from "../../../../utils/day"
import { DividerText } from "../../../partials/layout/divider/divider-text"
import { Text } from "../../../partials/wrapper/text"
import {
  ICustomDeparturePoint,
  ICustomOperatingTime,
  IVehicleEntry
} from "./tour-generation-vehicle-selection"
import { TourGenerationVehicleConfigurationDay } from "./vehicles/tour-generation-vehicle-configuration-day"

interface ITourGenerationDaysProps {
  vehicle: IVehicleEntry
  onDaySelectionChanged: (days: DayOfWeek[]) => void
  onCustomDeparturePointEntryChange: (customDeparturePoint: ICustomDeparturePoint) => void
  onCustomOperatingTimeEntryChange: (customOperatingTime: ICustomOperatingTime) => void
}

export const TourGenerationDays: FunctionComponent<ITourGenerationDaysProps> = (props) => {
  const { t } = useTranslation()
  const { vehicle, onDaySelectionChanged, onCustomDeparturePointEntryChange, onCustomOperatingTimeEntryChange } = props

  const { tourWeeks } = useContext(TourGenerationContext)
  const getInitialCustomDepartureAndEndPoints = useCallback(() => {
    const initial: { [key: string]: boolean } = {}
    vehicle.customDeparturePoints.forEach((cdpe) => {
      initial[`${cdpe.day.week}-${cdpe.day.day}`] = true
    })
    return initial
  }, [vehicle])

  const [customDepartureAndEndPoint, setCustomDepartureAndEndPoint] = useState<{
    [key: string]: boolean
  }>(getInitialCustomDepartureAndEndPoints())

  const toggleCustomDepartureAndEndPoint = useCallback(
    (dayOfWeek) => {
      const temp = { ...customDepartureAndEndPoint }
      temp[`${dayOfWeek.week}-${dayOfWeek.day}`] = !temp[`${dayOfWeek.week}-${dayOfWeek.day}`]
      setCustomDepartureAndEndPoint(temp)
    },
    [setCustomDepartureAndEndPoint, customDepartureAndEndPoint],
  )

  const getInitialCustomOperatingTimes = useCallback(() => {
    const initial: { [key: string]: boolean } = {}
    vehicle.customOperatingTimes.forEach((cote) => {
      initial[`${cote.day.week}-${cote.day.day}`] = true
    })
    return initial
  }, [vehicle])

  const [customOperatingTimesEnabled, setCustomOperatingTimes] = useState<{
    [key: string]: boolean
  }>(getInitialCustomOperatingTimes())

  const toggleCustomOperatingTime = useCallback(
    (dayOfWeek) => {
      const temp = { ...customOperatingTimesEnabled }
      temp[`${dayOfWeek.week}-${dayOfWeek.day}`] = !temp[`${dayOfWeek.week}-${dayOfWeek.day}`]
      setCustomOperatingTimes(temp)
    },
    [setCustomOperatingTimes, customOperatingTimesEnabled],
  )

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container alignItems="center" justify="space-between">
        <Grid item>
          <Text bold>{t("days")}</Text>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={3}>
        {tourWeeks.map(({ week, weekStartDate, days }) => (
          <Grid container item xs={12} key={`week-${week}`} spacing={1}>
            {tourWeeks.length > 1 && <DividerText>{`${week}. ${t(`week_picker.calendar_week`)} ${moment(weekStartDate).isoWeek()}`}</DividerText>} 
            {days.map((dayOfWeek) => (
              <Grid item xs={12} key={`week-${week}-${dayOfWeek.day}`}>
                <TourGenerationVehicleConfigurationDay
                  dayOfWeek={dayOfWeek}
                  vehicle={vehicle}
                  onCustomDeparturePointEntryChange={onCustomDeparturePointEntryChange}
                  onCustomOperatingTimeEntryChange={onCustomOperatingTimeEntryChange}
                  customDepartureAndEndPoint={customDepartureAndEndPoint[`${dayOfWeek.week}-${dayOfWeek.day}`]}
                  customOperatingTimeEnabled={customOperatingTimesEnabled[`${dayOfWeek.week}-${dayOfWeek.day}`]}
                  onDaySelectionChanged={onDaySelectionChanged}
                  toggleCustomDepartureAndEndPoint={() => toggleCustomDepartureAndEndPoint(dayOfWeek)}
                  toggleCustomOperatingTime={() => toggleCustomOperatingTime(dayOfWeek)}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
