import { gql } from "@apollo/client"
import {
  updateDisposalMerchant,
  updateDisposalMerchantVariables,
  updateDisposalMerchant_updateDisposalMerchant,
} from "./types/updateDisposalMerchant"

export const UPDATE_DISPOSAL_MERCHANT_MUTATION = gql`
  mutation updateDisposalMerchant(
    $id: ID!
    $name: String!
    $town: String!
    $postal: Int
    $street: String!
    $contact_name: String!
    $phone_number: String!
    $email: String!
    $latitude: Float!
    $longitude: Float!
    $materials: [MaterialInput!]
    $deletedVacationTimeIds: [ID!]
    $opening_hours: [OpeningHourInput!]!
    $districtIds: [String!]!
    $townIds: [String!]
  ) {
    updateDisposalMerchant(
      id: $id
      name: $name
      town: $town
      postal: $postal
      street: $street
      contact_name: $contact_name
      phone_number: $phone_number
      email: $email
      latitude: $latitude
      longitude: $longitude
      materials: $materials
      deletedVacationTimeIds: $deletedVacationTimeIds
      opening_hours: $opening_hours
      districtIds: $districtIds
      townIds: $townIds
    ) {
      id
    }
  }
`

export type UpdateDisposalMerchantResult = updateDisposalMerchant
export type UpdateDisposalMerchant = updateDisposalMerchant_updateDisposalMerchant
export type UpdateDisposalMerchantVariables = updateDisposalMerchantVariables
