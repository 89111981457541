import { gql } from "@apollo/client"
import {
  createDisposalMerchant,
  createDisposalMerchantVariables,
  createDisposalMerchant_createDisposalMerchant,
} from "./types/createDisposalMerchant"

export const CREATE_DISPOSAL_MERCHANT_MUTATION = gql`
  mutation createDisposalMerchant(
    $name: String!
    $town: String!
    $postal: Int
    $street: String!
    $contact_name: String!
    $phone_number: String!
    $latitude: Float!
    $longitude: Float!
    $email: String!
    $materials: [MaterialInput!]
    $opening_hours: [OpeningHourInput!]!
    $districtIds: [String!]!
    $townIds: [String!]
  ) {
    createDisposalMerchant(
      name: $name
      town: $town
      postal: $postal
      street: $street
      contact_name: $contact_name
      phone_number: $phone_number
      latitude: $latitude
      longitude: $longitude
      email: $email
      materials: $materials
      opening_hours: $opening_hours
      districtIds: $districtIds
      townIds: $townIds
    ) {
      id
    }
  }
`

export type CreateDisposalMerchantResult = createDisposalMerchant
export type CreateDisposalMerchant = createDisposalMerchant_createDisposalMerchant
export type CreateDisposalMerchantVariables = createDisposalMerchantVariables
