import { gql } from "@apollo/client"
import {
  validateCollectionPointsFile,
  validateCollectionPointsFileVariables,
  validateCollectionPointsFile_validateCollectionPointsFile_validation_errors,
  validateCollectionPointsFile_validateCollectionPointsFile_validation,
  validateCollectionPointsFile_validateCollectionPointsFile_collectionPoints,
} from "./types/validateCollectionPointsFile"

export const VALIDATE_COLLECTION_POINTS_FILE = gql`
  query validateCollectionPointsFile($file: Upload!, $regionId: ID!, $regionType: RegionType!) {
    validateCollectionPointsFile(file: $file, regionId: $regionId, regionType: $regionType) {
      validation {
        isValid
        errors {
          error
          additionalData
        }
      }
      collectionPoints {
        collectionPointId
        locationNumber
        description
        street
        postalCode
        town
        cadastralTown
        cadastralNumber
        longitude
        latitude
        accessibleFrom
        accessibleTo
        fixedUnloadInterval
        accessibleWithTrailer
        containers {
          number
          sensorId
          material {
            id
          }
          type {
            id
          }
        }
      }
    }
  }
`

export type ValidateCollectionPointsFileResult = validateCollectionPointsFile
export type ValidateCollectionPointsFileVariables = validateCollectionPointsFileVariables
export type ValidateCollectionPointsFileError = validateCollectionPointsFile_validateCollectionPointsFile_validation_errors
export type CollectionPointsFileValidation = validateCollectionPointsFile_validateCollectionPointsFile_validation
export type CollectionPointForUpload = validateCollectionPointsFile_validateCollectionPointsFile_collectionPoints
