import { gql } from "@apollo/client"
import {
  getCurrentContainerWarnings,
  getCurrentContainerWarningsVariables,
  getCurrentContainerWarnings_getCurrentContainerWarnings
} from "./types/getCurrentContainerWarnings"

export const GET_CURRENT_CONTAINER_WARNINGS_QUERY = gql`
  query getCurrentContainerWarnings($regionId: ID!, $regionType: RegionType!) {
    getCurrentContainerWarnings(regionId: $regionId, regionType: $regionType) {
      id
      since
      warning_status
      warning_type
      inserted_at
      container {
        id
        number
        sensor_id
        material {
          id
          name
        }
        collection_point {
          id
          description
          street
          cadastral_name
          postal
        }
      }
    }
  }
`

export type GetCurrentContainerWarningsResult = getCurrentContainerWarnings
export type GetCurrentContainerWarningsVariables = getCurrentContainerWarningsVariables
export type ContainerWarning = getCurrentContainerWarnings_getCurrentContainerWarnings
