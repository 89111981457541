import React, { FunctionComponent } from "react"
import { useQuery, useApolloClient } from "@apollo/client"
import gql from "graphql-tag"
import { Route, Redirect } from "react-router"
import { PATH } from "../../../router/router"
import { UserService } from "../../../services/user-service"
import { UserGroups } from "../../../models/user-groups"

interface IAuthRouteProps {
  path?: string
  component?: any
  redirectTo?: string
}

const GET_AUTH = gql`
  query {
    authStatus @client {
      status
    }
  }
`

export const AuthRoute: FunctionComponent<IAuthRouteProps> = (props) => {
  const { data, loading } = useQuery(GET_AUTH, { fetchPolicy: "cache-only" })
  const { path, component, redirectTo, children } = props
  const client = useApolloClient()

  if (loading) return null

  if (
    !data?.authStatus?.status ||
    data?.authStatus?.status === "loggedOut" ||
    !UserGroups.PORTAL_ACCESS.includes(UserService.getRole())
  ) {
    UserService.logout(client)
    return <Redirect to={PATH.LOGIN.route} />
  }

  if (!redirectTo) {
    return <Route path={path} component={component} children={children} />
  }
  return <Redirect exact from={path} to={redirectTo} />
}
