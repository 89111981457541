import React, { FunctionComponent, useRef, MutableRefObject } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Theme, makeStyles, Card, Grid } from "@material-ui/core"

import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { RegionType } from "../../../../api/graphql/graphql-global-types"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { UserService } from "../../../../services/user-service"
import moment from "moment"
import {
  GET_AVERAGE_DAYS_OVERFILLED_FOR_REGION_QUERY,
  AverageDaysOverfilledForRegionResult,
  AverageDaysOverfilledForRegionVariables,
} from "../../../../api/graphql/queries/get-days-overfilled-for-region"
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
  BarChart,
  Bar,
  Tooltip,
} from "recharts"
import { theme, SPACING } from "../../../../styles/theme"
import { Text } from "../../../partials/wrapper/text"
import { Skeleton } from "@material-ui/lab"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(1),
    margin: "auto",
  },
}))

interface IDaysOverfilledChartProps {}

export const DaysOverfilledChart: FunctionComponent<IDaysOverfilledChartProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedAssociation } = useAssociationFilter()
  const { selectedDistrict } = useDistrictFilter()
  const ref = useRef(null)

  const { data, loading } = useQuery<AverageDaysOverfilledForRegionResult, AverageDaysOverfilledForRegionVariables>(
    GET_AVERAGE_DAYS_OVERFILLED_FOR_REGION_QUERY,
    {
      variables: {
        id: (UserService.hasAssociationFilter() ? selectedAssociation?.id : selectedDistrict?.id) || "",
        type: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
        startDate: moment().startOf("day").subtract(12, "months"),
        endDate: moment().startOf("day").subtract(1, "month"),
      },
      skip:
        (UserService.hasAssociationFilter() && !selectedAssociation) ||
        (!UserService.hasAssociationFilter() && !selectedDistrict),
    },
  )

  const getData = () => {
    if (!data) {
      return []
    }

    return data.getAverageDaysOverfilledForRegion.averageDaysPerMonth.map((entry) => ({
      date: moment()
        .year(entry.year)
        .month(entry.month - 1)
        .locale(UserService.getLanguage())
        .format("MMM"),
      value: Math.round(entry.days * 10) / 10,
    }))
  }

  // defined width for chart in order to have similar bar sizes
  const chartWidth = 140 + getData().length * 55
  const cardWidth = (ref as MutableRefObject<any>).current?.clientWidth - SPACING * 4
  const useCardWidth = chartWidth > cardWidth || loading

  return (
    <Grid item ref={ref} xs={12} md={6}>
      <Card className={classes.card} ref={ref}>
        <Text variant="h6" bold>
          {t("overfilled_days_chart.days_overfilled")}
        </Text>
        {loading ? (
          <Skeleton width="100%" height={300} style={{ transform: "scale(1)" }} />
        ) : (
          <ResponsiveContainer width={lodash.max([useCardWidth ? cardWidth : chartWidth, 300])} height={300}>
            <BarChart
              data={getData()}
              margin={{
                left: 70,
                top: 70,
                right: 70,
                bottom: 70,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />"
              <XAxis dataKey="date" />
              <YAxis
                tickFormatter={(value) =>
                  Number(value).toLocaleString(UserService.getLanguage(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })
                }
              >
                <Label position="top" offset={16}>
                  {t("overfilled_days_chart.average_days_label")}
                </Label>
              </YAxis>
              <Bar dataKey="value" fill={theme.PRIMARY_COLOR.light} />
              <Tooltip
                formatter={(value) => [
                  `${Number(value).toLocaleString(UserService.getLanguage(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })} ${t("overfilled_days_chart.days")}`,
                ]}
                cursor={false}
              />
              <ReferenceLine y={data?.getAverageDaysOverfilledForRegion.average} strokeWidth={2}>
                <Label position="insideBottomRight">
                  {t("overfilled_days_chart.average_days", {
                    days: data?.getAverageDaysOverfilledForRegion.average.toLocaleString(UserService.getLanguage(), {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })}
                </Label>
              </ReferenceLine>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Grid>
  )
}
