import React, { FunctionComponent, useContext } from "react"
import {
  Theme,
  makeStyles,
  Card,
  Grid,
  CardActionArea
} from "@material-ui/core"
import { User } from "../../../../api/graphql/queries/get-users"
import { UserManagementContext } from "../context/user-management-context"
import { Text } from "../../../partials/wrapper/text"
import { Badge } from "./badge"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: 10,
  },
  container: {
    height: "100%",
  },
  cardActionArea: {
    height: "100%",
    padding: 10,
  },
  selectedCard: {
    boxShadow: "0 0 4px 0 rgba(75, 151, 62, 0.5)",
  },
  text: {
    fontSize: 15,
  },
}))

interface IUserListItemProps {
  user: User
}

export const UserListItem: FunctionComponent<IUserListItemProps> = (props) => {
  const { user } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedUser, updateSelectedUser } = useContext(UserManagementContext)

  const isSelected = selectedUser && selectedUser.id === user.id

  return (
    <Card
      className={`${isSelected && classes.selectedCard} ${classes.card}`}
      onClick={() => updateSelectedUser(user)}
    >
      <CardActionArea className={classes.cardActionArea}>
        <Grid container className={classes.container} direction="column">
          <Grid item>
            <Text bold noWrap>
              {user.email || "-"}
            </Text>
          </Grid>
          <Grid item>
            <Text className={classes.text} noWrap>
              {`${user.first_name || "-"} ${user.last_name || "-"}`}
            </Text>
          </Grid>
          <Grid item container justify="flex-end">
            {user.role && (
              <Badge color="primary">{t(`user_role.${user.role}`)}</Badge>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
