import React, { FunctionComponent, useContext } from "react"
import { Theme, makeStyles, Card, Grid, Tooltip } from "@material-ui/core"
import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined"
import { Text } from "../../../partials/wrapper/text"
import { MinimalTour } from "../../../../api/graphql/queries/get-minimal-tours-with-region-id"
import moment from "moment"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { useTranslation } from "react-i18next"
import { Badge } from "../../user-management/partials/badge"
import { UserContext } from "../../../../context/user-context"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { hexToRgb } from "../../../../utils/color"
import { TourGenerationIcon } from "../../../partials/icons/tour-generation-icon"


const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(1),
    padding: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedCard: {
    boxShadow: `0 0 4px 0 rgba(${[...hexToRgb(theme.palette.primary.main)]}, 0.5)`,
  },
  container: {
    height: "100%",
  },
  text: {
    fontSize: 15,
  },
  badge: {
    fontSize: 12,
  },
  materialsContainer: {
    marginTop: theme.spacing(0.5),
  },
  manualTourBadge: {
    border: "2px solid",
    paddingRight: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.3),
    borderRadius: "50%",
  },
}))

interface ITourItemProps {
  num: number
  item: MinimalTour
}

export const TourItem: FunctionComponent<ITourItemProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { item } = props

  const { selectedTour, setSelectedTour } = useSelectedTour()
  const { user } = useContext(UserContext)

  const onClick = () => {
    setSelectedTour(props.item)
  }

  const isSelected = () => {
    if (!selectedTour) return false
    return selectedTour.id === props.item.id
  }

  return (
    <Card className={`${isSelected() && classes.selectedCard} ${classes.card}`} onClick={onClick}>
      <Grid container direction="column">
        <Grid item container alignItems="center" spacing={1} justify="flex-start">
          <Grid item>
            <LocalShippingOutlinedIcon />
          </Grid>
          <Grid item xs>
            <Grid container justify="space-between">
              <Text className={classes.text} bold>
                {item.vehicle.licence_plate}
              </Text>
              {item.manuallyCreated && (
                <Tooltip title={t("tour_overview.manual_tour") as string}>
                  <Grid item className={classes.manualTourBadge}>
                    M
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" justify="space-between" spacing={1}>
          <Grid item>
            <Text className={classes.text}>{moment(props.item.date).format(t("date_format"))}</Text>
          </Grid>
          <Grid item>
            <Text className={classes.text}>
              {t("tour_overview.collection_point", {
                count: props.item.collection_points!.length,
              })}
            </Text>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          spacing={1}
          justify="flex-start"
          className={classes.materialsContainer}
        >
          {user?.role === UserRole.SUPER_ADMIN && (
            <Grid item>
              <TourGenerationIcon withoutContainers={!!item.parameters?.without_containers} />
            </Grid>
          )}
          {item.materials.map((material) => (
            <Grid item key={`material_badge_${item.id}_${material.id}`}>
              <Badge color="primary" className={classes.badge}>
                {material.name}
              </Badge>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}
