import { gql } from "@apollo/client"
import {
  getRegionStats,
  getRegionStats_getRegionStats,
  getRegionStatsVariables,
  getRegionStats_getRegionStats_container_notifications,
  getRegionStats_getRegionStats_collection_partners,
  getRegionStats_getRegionStats_container_group_infos
} from "./types/getRegionStats"

export const GET_REGION_STATS_QUERY = gql`
  query getRegionStats($id: ID!, $type: RegionType!) {
    getRegionStats(id: $id, type: $type) {
      id
      type
      container_group_infos {
        id
        name
        avg_fill
        amount
      }
      next_emptying {
        emptying_date
        partner
      }
      last_emptying {
        emptying_date
        partner
      }
      collection_amount
      container_notifications {
        type
        collection_point_id
        container_id
        sensor_time_send
        value
        additional_field
      }
      amount_containers_above_filllevel_treshhold
      collected_amount_stats {
        material_id
        material_name
        amount
      }
      amount_containers_above_filllevel_treshhold
      collection_partners {
        id
        name
        amount_vehicles
        amount_hours(id: $id, type: $type)
        amount_regions
        amount_emptied_containers(id: $id, type: $type)
      }
    }
  }
`

export type RegionStatsResult = getRegionStats
export type RegionStatsVariables = getRegionStatsVariables
export type RegionStats = getRegionStats_getRegionStats
export type ContainerNotifications = getRegionStats_getRegionStats_container_notifications
export type CollectionPartner = getRegionStats_getRegionStats_collection_partners
export type ContainerGroupInfo = getRegionStats_getRegionStats_container_group_infos
