import { gql } from "@apollo/client"
import {
  getAdministrationCollectionPointsWithRegionID,
  getAdministrationCollectionPointsWithRegionIDVariables,
  getAdministrationCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries,
} from "./types/getAdministrationCollectionPointsWithRegionID"

export const GET_ADMINISTRATION_COLLECTIONPOINT_WITH_REGION_ID_QUERY = gql`
  query getAdministrationCollectionPointsWithRegionID(
    $id: Int!
    $type: RegionType!
    $page: Int!
    $pagesize: Int!
    $filter: RegionFilterInput
  ) {
    getCollectionPointsWithRegionID(
      id: $id
      type: $type
      page: $page
      pagesize: $pagesize
      filter: $filter
    ) {
      entries {
        id
        description
        street
        latitude
        longitude
        location_number
        cadastral_name
        cadastral_nr
        postal
        place
        accessible_with_trailer
        overfilling_allowed
        town {
          id
          name
        }
        fixed_unload_interval
        fixedInterval
        extrapolation_weightings {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        opening_hours {
          id
          day
          from
          to
        }
      }
    }
  }
`

export type GetAdminstrationCollectionPointsWithRegionIDResult = getAdministrationCollectionPointsWithRegionID
export type GetAdminstrationCollectionPointsWithRegionIDVariables = getAdministrationCollectionPointsWithRegionIDVariables
export type AdminstrationCollectionPoint = getAdministrationCollectionPointsWithRegionID_getCollectionPointsWithRegionID_entries
