import { gql } from "@apollo/client"
import {
  getManufacturers,
  getManufacturers_getManufacturers
} from "./types/getManufacturers"

export const GET_MANUFACTURERS_QUERY = gql`
  query getManufacturers {
    getManufacturers {
      id
      name
      construction_type
    }
  }
`

export type ManufacturersResult = getManufacturers
export type Manufacturer = getManufacturers_getManufacturers
