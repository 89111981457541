import React, { useContext, useEffect, Fragment } from "react"
import { Route, Switch, Redirect } from "react-router"
import { Portal } from "../portal/portal"
import { BASE_PATH, PATH } from "../../../router/router"
import { LoginPage } from "../login/login-page"
import { UserContext } from "../../../context/user-context"
import { UserService } from "../../../services/user-service"
import { ThemeProvider } from "@material-ui/core"
import { getTheme } from "../../../styles/theme"
import { useQuery } from "@apollo/client"
import { DEFAULT_THEME_QUERY, DefaultThemeResult } from "../../../api/graphql/queries/default-theme"
import { UnsavedDataPrompt } from "../../partials/unsaved-data-prompt/unsaved-data-prompt"

export const App: React.FunctionComponent = () => {
  const { user, loadUser } = useContext(UserContext)

  const { data, loading } = useQuery<DefaultThemeResult>(DEFAULT_THEME_QUERY, {})

  useEffect(() => {
    if (!user && UserService.isUserLoggedIn()) {
      loadUser()
    }
  }, [user, loadUser])

  if (loading || !data) {
    return <Fragment />
  }

  const theme = getTheme(data!.defaultTheme)

  return (
    <ThemeProvider theme={theme}>
      <UnsavedDataPrompt />
      <Switch>
        <Route path={PATH.LOGIN.route} render={(props) => <LoginPage {...props} />} />
        <Route path={BASE_PATH} render={(props) => <Portal {...props} />} />
        <Redirect to={PATH.LOGIN.route} />
      </Switch>
    </ThemeProvider>
  )
}
