import { gql } from "@apollo/client"
import {
  getContainerTypes,
  getContainerTypes_getContainerTypes
} from "./types/getContainerTypes"

export const GET_CONTAINER_TYPES_QUERY = gql`
  query getContainerTypes {
    getContainerTypes {
      id
      size
      size_unit
      emptying_type
      container_type_infos {
        material_id
        max_amount
      }
    }
  }
`

export type ContainerTypesResult = getContainerTypes
export type ContainerType = getContainerTypes_getContainerTypes
