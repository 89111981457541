import React, { FunctionComponent } from "react"

import { PartnerOverview } from "./partials/partner-overview"
import { SelectedPartnerProvider } from "../../../context/selected-partner-context"

interface IPartnerOverViewPageProps {}

export const PartnerOverviewPage: FunctionComponent<IPartnerOverViewPageProps> = props => {
  return (
    <SelectedPartnerProvider>
      <PartnerOverview />
    </SelectedPartnerProvider>
  )
}
