import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Card, Grid } from "@material-ui/core"

import { Text } from "../../../partials/wrapper/text"
import { useSelectedDisposalMerchant } from "../../../../context/selected-disposal-merchant-context"
import { DisposalMerchant } from "../../../../api/graphql/queries/get-disposal-merchants"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: 90,
    margin: theme.spacing(1),
    width: 270,
    "&:hover": {
      cursor: "pointer"
    }
  },
  selectedCard: {
    boxShadow: "0 0 4px 0 rgba(75, 151, 62, 0.5)"
  },
  container: {
    height: "100%",
    padding: theme.spacing(1)
  },
  text: {
    fontSize: 15
  }
}))

interface IDisposalMerchantItemProps {
  item: DisposalMerchant
}

export const DisposalMerchantItem: FunctionComponent<IDisposalMerchantItemProps> = props => {
  const classes = useStyles(props)
  const {
    setSelectedDisposalMerchantId,
    selectedDisposalMerchantId
  } = useSelectedDisposalMerchant()
  const { item } = props

  const onClick = () => {
    setSelectedDisposalMerchantId(props.item.id)
  }

  const isSelected = () => selectedDisposalMerchantId === props.item.id

  const postalTownString =
    item.postal && item.town
      ? `${item.postal} ${item.town}`
      : item.postal || item.town

  return (
    <Fragment>
      <Card
        className={`${isSelected() && classes.selectedCard} ${classes.card}`}
        onClick={onClick}
      >
        <Grid className={classes.container} container direction="row">
          <Grid item xs={12}>
            <Text bold noWrap>
              {item.name}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text className={classes.text} noWrap>
              {item.street || "-"}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text className={classes.text} noWrap>
              {postalTownString || "-"}
            </Text>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
