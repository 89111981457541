import React, { FunctionComponent, Fragment } from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"

import { Text } from "../../../partials/wrapper/text"

const useStyles = makeStyles((theme: Theme) => ({
  leftTextContainer: {
    width: 60
  },
  rightText: {
    wordWrap: "break-word"
  },
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3
  },
  rightTextContainer: {
    flex: 1,
    marginRight: 20
  }
}))

interface IInfoProps {
  amount: string
  description: string
}

export const Info: FunctionComponent<IInfoProps> = props => {
  const classes = useStyles(props)

  return (
    <Fragment>
      <Grid className={classes.container} container direction="row">
        <Grid className={classes.leftTextContainer}>
          <Text bold color="primary">
            {props.amount}
          </Text>
        </Grid>
        <Grid className={classes.rightTextContainer}>
          <Text className={classes.rightText}>{props.description}</Text>
        </Grid>
      </Grid>
    </Fragment>
  )
}
