import React, { FunctionComponent } from "react"
import { Theme, makeStyles, IconButtonProps, IconButton } from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import classnames from "classnames"

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    transform: (props: ExpandIconProps) => (!props.expanded ? "rotate(0deg)" : "rotate(180deg)"),
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}))

interface ExpandIconProps extends IconButtonProps {
  expanded: boolean
}

export const ExpandIcon: FunctionComponent<ExpandIconProps> = (props) => {
  const { expanded, ...other } = props
  const classes = useStyles(props)

  return (
    <IconButton {...other} className={classnames(classes.icon, props.className)}>
      <ExpandMore />
    </IconButton>
  )
}
