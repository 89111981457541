import {
  EmptyingType,
  UserRole,
  STOP_TYPE,
  ExportReason,
  ContainerWarningStatus,
  ContainerWarningType,
  CollectionType,
  HouseholdContainerCommentType,
} from "../api/graphql/graphql-global-types"
import { CollectionPointStatus } from "../components/pages/tour-generation/partials/tour-export-preview-table"
import { LocationType } from "../components/pages/user-management/helper/get-options-for-role"

export const en = {
  ok: "Ok",
  api_errors: {
    number_already_exists: "A container with the same number already exists.",
    not_found: "{{entity}} does not exist",
    create: "{{entity}} could not be created",
    update: "{{entity}} could not be updated",
    delete: "{{entity}} could not be deleted",
  },
  forbidden: "You have no authorization for this action.",
  warnmessage: "Warning message",
  login: "Login",
  email_address: "E-mail address",
  sign_in: "Login",
  password: "Password",
  collection_point: "Collection point",
  yesterday: "Yesterday",
  today: "Today",
  tomorrow: "Tomorrow",
  container: "Container",
  fill_level: "Filling level",
  type: "Type",
  fraction: "Fraction",
  sensor_id: "Sensor ID",
  not_available: "Not available",
  days: "Days",
  weeks: "Weeks",
  time: "Time",
  tour_time: "Tour duration",
  vehicle: "Vehicle",
  trailer_location: "Disposal trailer location",
  image: "Image",
  images: "Images",
  no_images: "No images",
  partner: "Partner",
  yes: "Yes",
  no: "No",
  cancel: "Cancel",
  continue: "Continue",
  save: "Save",
  search: "Search",
  collected_amount: "Collected amount",
  amount_chambers: "Amount chambers",
  association: "Collection region",
  district: "Collection region",
  assign: "Assign",
  generic_error: "Oops! Something went wrong.",
  invalid_format: "Invalid format",
  general_api_error: "Something went wrong. Please reload the page.",
  total: "Total",
  portal: {
    update_available: "Version <strong>{{version}}</strong> is available!",
    update: "Update",
    sidebar: {
      no_version: "DEV",
      collection_points: "Collection points",
      households: "Households",
    },
  },
  status: "Status",
  weekdays: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    monday_short: "Mo",
    tuesday_short: "Tu",
    wednesday_short: "We",
    thursday_short: "Th",
    friday_short: "Fr",
    saturday_short: "Sa",
    sunday_short: "Su",
  },
  collection_point_details: {
    last_emptying: "Last emptying",
    next_emptying: "Next emptying",
    average_filling_time: "Average filling time",
    container_last_send_warning: "Last sent on {{date}}",
    container_never_send_warning: "Sensor has never sent.",
    fixed_unload_interval: "Manual emptying interval",
    container_lid_open_since: "Lid open since {{date}}",
    defective_sensor: "Sensor may be damaged. Please check.",
    fixed_unload_interval_info: "Filllevels derived from manual unload intervals.",
    edit: "Edit",
    full_since: "Since {{days}} days",
    full_since_more: "Since >{{days}} days",
    full_in: "In {{days}} days",
    full_in_more: "In >{{days}} days",
    full_heading: "Filllevel 100%",
    filllevel_derived_from_driver_feedback: "Filllevel derived from driver feedback.",
  },
  navigation: {
    tour_overview: "Tour overview",
    tour_generation: "Tour generation",
    partner_overview: "Partner/Vehicles",
    collection_points: "Collection points",
    dashboard: "Dashboard",
    disposal_merchant_overview: "Disposal company / Recycler",
    administration: {
      overview: "Administration",
      collection_points: "Collection point",
      user_management: "User",
    },
    household_overview: "Containers",
    example: "Example",
    logout: "Logout",
    close: "Close",
    clone: "Clone PRD",
    status: "Clone status",
    reset: "Reset",
    routes_overview: "Route management",
    guidance_administration: {
      overview: "Guidance administration",
      driver_locations: "Driver locations",
    },
    container_warnings: "Container warnings",
  },
  user_management: {
    heading: "User management",
    please_select_user: "Please select a user",
    data: {
      nothing_assigned: "No {{collection}} are assigned to you",
      title: "User data of {{firstName}} {{lastName}}",
      first_name: "First name",
      last_name: "Last name",
      email: "Email",
      role: "Role",
      password: "Password",
      partner: "Partner",
      create_user: "Create",
      save_user: "Save",
      delete_user: "Delete",
      assign: "{{collection}} Assign",
      password_changed: "Password changed",
      user_created: "User was created",
      user_updated: "User was updated",
      user_deleted: "User was deleted",
      could_not_create_user: "User could not be created",
      could_not_update_user: "User could not be updated",
      could_not_delete_user: "User could not be deleted",
      delete_dialog: {
        heading: "Delete",
        confirm_text: "Delete",
        text: "Do you really want to delete this user?",
      },
    },
    new_user: {
      title: "Create user",
    },
    filter: {
      heading: "Filter",
      first_name: "First name",
      last_name: "Last name",
      role: "Role",
      apply_filter: "Apply filter",
      remove_filter: "Reset filter",
    },
  },
  partner_overview: {
    heading: "Partner",
    filter: {
      partner_name: "Name",
    },
    data: {
      title: "Partner data",
      name: "Name",
      street: "Street",
      postal: "Postal code",
      town: "Town",
      contact_person: "Contact person",
      phone_number: "Phone number",
      email: "E-Mail",
      automatic_break: "Break after (hours)",
      automatic_break_reminder: "Warning notice x minutes before break",
      vehicles: "Vehicles",
      show_vehicle: "{{count}} Vehicle",
      show_vehicle_plural: "{{count}} Vehicles",
      assign_districts: "Assign collection regions",
      delete_partner: "Delete partner",
      save_partner: "Save",
      create_partner: "Create",
      delete_prompt: "Do you really want to delete the partner?",
      agree_on_delete: "Yes, delete!",
      could_not_create: "Partner could not be created",
      could_not_update: "Partner could not be updated",
      could_not_delete: "Partner could not be deleted",
      partner_created: "Partner successfully created",
      partner_updated: "Partner successfully updated",
      partner_deleted: "Partner successfully deleted",
      name_required: "Name is a required field",
    },
    errors: {
      required: "All required fields(*) must be filled in.",
    },
    partner_delete_info: "A partner can only be deleted, if no users are assigned.",
    departure_point: {
      heading: "Departure points",
      create_departure_point: "Create departure point",
      delete_dialog_text_departure_point: "Do you really want to delete the departure point?",
      no_departure_points_available_for_collection_partner:
        "There are no departure points available for this collection partner.",
      no_departure_point_assigned: "not assigned",
      not_deletable: "Departure point cannot be deleted as it is still associated with a vehicle and/or a tour.",
    },
    disposal_trailer_location: {
      heading: "Disposal Trailer Locations",
      create_disposal_trailer_location: "Create disposal trailer location",
      delete_dialog_text_disposal_trailer_location: "Do you really want to delete the disposal trailer location?",
      no_disposal_trailer_locations_available_for_collection_partner:
        "There are no disposal trailer locations available for this collection partner.",
      no_disposal_trailer_location_assigned: "not assigned",
      success: {
        created: "Disposal trailer location successfully created",
        updated: "Disposal trailer location successfully updated",
        deleted: "Disposal trailer location successfully deleted",
      },
      errors: {
        duplicate: "A disposal trailer location with the same name, latitude and longitude does already exist",
        required_by_tour_generation:
          "Disposal trailer location is used by a running or scheduled tour generation and cannot be deleted",
      },
    },
  },
  collection_points: {
    heading: "Overview",
    association: "Collection region",
    export_containers: "Export containers",
    last_send_warning:
      "At least one sensor has not been transmitting since {{days}} days and there is no fixed emptying interval assigned.",
    open_lid_warning: "At least one container is not closed.",
    defective_sensor_warning: "At least one container might have a defective sensor",
    warnings: {
      [ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK]:
        "Collection point has been automatically switched to driver feedback due to sensor problems for at least one material (since {{date}}). Please check sensors.",
      [ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA]:
        "Driver feedback: not enough data available for at least one material.",
    },
    filter: {
      heading: "Filter",
      postal_code: "Postal code",
      container_number: "Container no.",
      sensor_id: "Sensor ID",
      address: "Address",
      materials: "Materials",
      apply_filter: "Apply",
      remove_filter: "Reset filter",
      towns_label: "Communities - Code",
      counties_label: "Districts",
      only_issue_containers: "show warnings only",
      filllevel_label: "Filling level",
      all_filllevels: "all",
      description: "Description",
      reference_date: "Reference date",
      reference_date_info:
        "By adjusting the date, you can see the change of fill levels in the collection area. Tours that have already been generated are taken into account.",
      collection_type: "Collection Type",
    },
    table: {
      id: "Id",
      description: "Description",
      address: "Address",
      date_next_emptying: "Next emptying",
      date_last_emptying: "Last emptying",
      material: "Material",
      fill_level: "Filling level",
      count_containers: "{{count}} container",
      count_containers_plural: "{{count}} containers",
      number_of_container: "Number of containers",
    },
    cards: {
      last_emptying: "Last emptying",
      next_emptying: "Next emptying",
      partner: "Partner",
      collection_amount_last_month: "Last month",
      collection_points: "Collection points",
      total: "Total",
    },
    notifications: {
      critical_battery: "Container (#{{container_id}}) - critical battery status",
      offline: "Container (#{{container_id}}) has not sent any data since {{days}}!",
      full: "Container (#{{container_id}}) is full!",
      critical_filllevel_change:
        "Container (#{{container_id}}) recorded a critical filling leap on the {{date}} from {{value}} to {{additional_field}}!",
    },
    info_cards: {
      region: {
        amount_containers_above_filllevel_treshhold: "Amount of containers above 50%",
        amount_fraction: "Last month",
        amount_material_emptied: "{{material}} emptied {{timeRange}}",
      },
      partners: {
        title: "Partner {{name}}",
        amount_emptied_containers: "Emptied container",
        amount_hours: "Performed hours",
        amount_regions: "Collection region",
        amount_regions_plural: "Collection region",
        amount_vehicles: "Vehicle",
        amount_vehicles_plural: "Vehicles",
      },
    },
    export_dialog: {
      title: "Container export",
      export: "Export",
      filter: "Use filter",
    },
    collection_type: {
      [CollectionType.ON_INTERVAL]: "Fixed Interval",
      [CollectionType.ON_DEMAND]: "On Demand",
      [CollectionType.ON_SENSOR]: "Sensor based",
    },
  },
  tour_overview: {
    heading: "Tour from",
    heading_prefix_manual: "Manual",
    download: "Download",
    tour_without_containers: "Tour was generated for taking inventory of containers.",
    tour_with_containers: "Tour was generated for emptying collection points.",
    manual_tour: "Manual Tour",
    in_progress: "Tour has not yet been completed",
    planned_duration: "[planned]",
    actual_duration: "[actually]",
    collection_points: "Collection points",
    table: {
      mark: "Mark",
      type: "Type",
      name: "Name",
      address: "Address",
      fill_level: "Filling level",
      show_images: "Show images",
      no_images: "No images available",
      add_collection_point: "Add collection point",
    },
    tooltip: {
      // TODO: Confirm texts
      description: "Description",
      last_emptying: "Last Emptying",
      fill_levels: "Fill Levels",
      leave_at: "Scheduled at",
      completed_at: "Completed at",
      vehicle_utilization: "Truck Utilization",
    },
    defect_containers: {
      head: "Defect Containers",
      included: "Included in tour",
      excluded: "Excluded from tour",
      table: {
        mark: "Mark",
        date: "Date of Tour",
        sensor_id: "Sensor ID",
        material: "Material",
        address: "Address",
        filllevel: "Filllevel",
        link: "Collection point",
        reason: "Manual Emptying",
        error: "Defect containers could not be fetched.",
        empty: "No defect containers for this tour.",
        collection_point_tooltip: "Go To Collection Point",
      },
    },
    stop_types: {
      [STOP_TYPE.DEPARTURE_POINT]: "Truck parking space",
      [STOP_TYPE.COLLECTION_POINT]: "Collection point",
      [STOP_TYPE.DISPOSAL_MERCHANT]: "Disposal merchant",
      [STOP_TYPE.TRAILER_LOCATION]: "Trailer Location",
    },
    reason: "Manual Emptying",
    export_reasons: {
      [ExportReason.EMPTYING_REQUESTED]: "Emptying requested",
      [ExportReason.INTERVAL_DUE]: "Fixed unload interval",
      [ExportReason.MANUALLY_INSERTED]: "-",
      [ExportReason.MANUALLY_INSERTED_BY_DRIVER]: "-",
      [ExportReason.HIGH_FILLLEVEL]: "-",
      [ExportReason.OTHER]: "-",
      interval: "{{interval}} week(s)",
      on_demand: "On demand",
    },
    collection_point: "{{count}} Collection point",
    collection_point_plural: "{{count}} Collection points",
    planned: "planned",
    actual: "actual",
    distance_driven: "Driven km",
    duration_warning: "Tour edited subsequently.",
    confirm_delete_text: "Do you really want to delete the tour?",
    tour_deleted: "Tour successfully deleted",
    could_not_delete_tour: "Tour could not be deleted",
    tour_export_dialog: {
      title: "Tour Export",
      subtitle: "Tours in the selected time range are downloaded and stored in an Excel-file.",
      time_range: "Time range",
      cancel_btn: "Cancel",
      export_btn: "Export",
    },
    details: {
      on_site: "On Site",
      disposal_merchant: "Disposal merchant",
      warning: "Strong deviation! -> Please check driver inputs and any sensors",
    },
  },
  edit_tour_dialog: {
    title: "Edit tour",
    cancel: "Cancel",
    save: "Save",
    confirm_delete_text: 'Do you really want to remove the collection point "{{description}}" from the tour?',
    tour_edited: "Tour successfully edited",
    could_not_edit_tour: "Tour could not be edited",
  },
  add_collection_point_dialog: {
    title: "Add collection point",
    cancel: "Cancel",
    add: "Add",
    description: "Description",
    address: "Address",
    sequence_number: "Mark",
    materials: "Materials",
    materials_error: "Materials could not be fetched.",
    confirm_title: "Add collection point #{{id}}",
  },
  disposal_merchant_overview: {
    heading: "Disposal merchant",
    filter: {
      disposal_merchant_name: "Name",
    },
    data: {
      title: "Disposal merchant data",
      name: "Name",
      street: "Street",
      postal: "Postal code",
      town: "Town",
      contact_person: "Contact person",
      phone_number: "Phone number",
      email: "E-mail",
      location: "Position",
      delete_disposal_merchant: "Delete disposal merchant",
      save_disposal_merchant: "Save",
      create_disposal_merchant: "Create",
      delete_prompt: "Do you really want to delete the disposal merchant?",
      agree_on_delete: "Yes, delete!",
      could_not_create: "Disposal merchant could not be created",
      could_not_update: "Disposal merchant could not be updated",
      could_not_delete: "Disposal merchant could not be deleted",
      disposal_merchant_created: "Disposal merchant successfully created",
      disposal_merchant_updated: "Disposal merchant successfully updated",
      disposal_merchant_deleted: "Disposal merchant successfully deleted",
      name_required: "Name is a required field",
      material: "Material",
      materials: "Materials",
      no_materials: "No materials selected.",
      opening_hours: "Opening hours",
      vacation_times: "Vacation times",
      no_vacation_times: "No vacation time entered.",
      overlapping_vacation_times: "Vacation times must not overlap",
      invalid_opening_hours: "Opening hours are invalid",
      district_required: "You must select at least one collection region",
      wrong_towns: "You must select at least one city from each collection region",
    },
  },
  vehicle_overview: {
    back_to_partner_overview: "Partner selection",
    partner_not_found: "Partner with ID '{{partnerID}}' was not found",
    data: {
      title: "Vehicle data",
      district: "Collection region",
      towns: "Towns",
      emission_standard: "Emission standard",
      licence_plate: "Licence plate",
      type: "Vehicle type",
      trailer: "Trailer",
      length: "Length [cm]",
      width: "Width [cm]",
      height: "Height [cm]",
      costs_per_hour: "Costs per hour [€]",
      costs_per_km: "Costs per km [€]",
      scale: "Scale",
      create_vehicle: "Create",
      delete_vehicle: "Delete vehicle",
      save_vehicle: "Save",
      vehicle_created: "Vehicle successfully created.",
      could_not_create: "Vehicle could not be created.",
      vehicle_updated: "Vehicle successfully updated.",
      could_not_update: "Vehicle could not be updated.",
      vehicle_deleted: "Vehicle successfully deleted.",
      could_not_delete: "Vehicle could not be deleted.",
      delete_prompt: "Do you really want to delete this vehicle?",
      agree_on_delete: "Yes, delete!",
      chambers: "Chambers",
      chambers_vehicle: "Chambers Vehicle",
      chambers_trailer: "Chambers Trailer",
      chamber_amount: "Loading capacity [kg]",
      material: "Material",
      no_chambers: "No chambers entered.",
      chamber_required: "At least one chamber is required.",
      operating_time: "Operating time",
      min_tour_duration: "Min. tour duration",
      max_tour_duration: "Max. tour duration",
      starting_point: "Starting point",
      end_point: "Destination point",
      departure_point_name: "Name",
      latitude: "Latitude",
      longitude: "Longitude",
      driver: "Driver",
      no_driver: "No driver",
    },
  },
  date_picker: {
    cancel: "Cancel",
    clear: "Reset",
    ok: "Ok",
    from: "From:",
    to: "To:",
  },
  tour_selector: {
    choose: "Please select a tour.",
  },
  example: {
    heading: "Example",
  },
  table: {
    data_loading: "Loading data",
    no_data: "No data available",
    previous: "Previous",
    next: "Next",
    page: "Page",
    rows: "Entries",
    of: "of",
  },
  errors: {
    no_access: "You have no access to the Waste Collection Portal!",
    generic: "An unexpected error has occurred.",
    no_association_dashboard_data: "No data could be loaded for this collection region.",
    no_district_dashboard_data: "No data could be loaded for this collection region.",
    no_next_tour: "No tour could be loaded.",
    no_association_tours_found: "For this collection region no tours could be found with the current filter settings.",
    no_district_tours_found: "For this collection region no tours could be found with the current filter settings.",
    wrong_login: "Wrong e-mail or wrong password",
    min_date: "Date must be before the of-date.",
  },
  logout: {
    title: "Logout",
    text: "Do you really want to log out?",
    open_downloads_text: "Some Downloads are still in progress. Do you still want to logout?",
  },
  clone_db_request: {
    success: "Request successful. You will be logged out in {{timeout}} seconds. Come back in 5 minutes.",
    pending: "Cloning is already in progress and cannot be started again.",
    not_allowed: "Cloning is not allowed on this system.",
    errors: {
      incompatible: "The target database is not compatible with this system.",
      unknown: "An unexpected error occurred. Try again.",
    },
  },
  clone: {
    header:
      "Do you really want to clone the production system? All data of the current system will be deleted. This cannot be undone and takes up to 5 minutes.",
  },
  clone_status: {
    success: "The production system was successfully cloned on {{date}}.",
    pending: "Cloning is in progress since {{date}}. This can take up to 5 minutes.",
    delete_tourgenerations_failed_warning:
      "An unexpected error prevented the deletion of schedule tour generations. Please manually check scheduled tour generations.",
    scheduled_tours_left_warning:
      "One or more scheduled tour generations could not be aborted. Please manually check scheduled tour generations.",
    null: "The production system has not been cloned yet.",
    error: "An error occurred while cloning the production system.",
    code: "Code",
  },
  reset: {
    header: "Do you really want to reset the system?",
    success: "Request successful. You will be logged out in {{timeout}} seconds. Come back in 5 minutes.",
  },
  map: {
    search: "Address search...",
    loading: "Loading...",
  },
  image_types: {
    collection_point: "Collection point",
    documentation: "Documentation",
    weighing: "Weighing slip",
  },
  weighings: {
    gross: "Gross",
    tare: "Tare",
    net: "Net",
    number: "Weighing slip number",
  },
  collection_point_administration: {
    collection_points: "Collection point",
    create_collection_point: "Create collection point",
    collection_point_info: "Collection point info",
    delete_collection_point: "Delete collection point",
    edit_collection_point_opening_hours: "Edit opening hours",
    collection_point_settings: "Settings",
    accessible_with_trailer: {
      label: "Accessible with trailer",
      tooltip: "If activated, this collection point can also be approached and planned with a trailer attached",
    },
    avoid_overfill: {
      label: "Avoid Overfill",
      tooltip:
        "If activated, an attempt is made to avoid overcrowding at this collection point regardless of the optimization profiles selected",
    },
    data: {
      description: "Description",
      location_number: "Location number",
      cadastral_name: "Cadastral community",
      cadastral_number: "Cadastral number",
      town: "Community - Community code",
      street: "Street",
      postal: "Postal code",
      place: "Place",
      create: "Create",
      save: "Save",
      cancel: "Cancel",
      manufacturer: "Manufacturer",
      sensor_id: "Sensor ID",
      material: "Material",
      number: "Container number",
      container_type: "Container type",
      emptying_types: {
        [EmptyingType.LIFTING]: "Hub",
        [EmptyingType.POUR]: "Pour",
        [EmptyingType.LIFTING_OEKOBELL]: "Hub Ökobell",
        [EmptyingType.LIFTING_OEKOLINE]: "Hub Ökoline",
        [EmptyingType.UF]: "UF",
      },
      fixed_unload_interval: "Fixed emptying interval",
      fixed_interval: "Fix interval",
      fixed_interval_info: "When selected, the collection point gets planned according to the planned interval only",
      unload_interval_on_demand: "On demand",
      request_emptying: "Emptying requested",
      extrapolation_weighting: "Weightings",
      extrapolation_weighting_info:
        "Apply the daily change of filllevel by these percents. This does not affect the absolute change per week.",
      opening_hours: {
        title: "Edit Opening Hours",
      },
    },
    unit: {
      week: "{{count}} Week",
      week_plural: "{{count}} Weeks",
    },
    errors: {
      required: "All required fields (*) must be filled in.",
      cadastral_number_invalid: "The number for the cadastral community must consist only of numbers.",
      postal_invalid: "The postal code must consist only of numbers.",
      weighting_invalid:
        "Weightings must be greater than or equal to zero. At least one weighting must be greater than zero.",
      opening_hour_difference_invalid: "From and to must be at least 1 hour apart.",
      opening_hour_upsert_error: "Opening hours could not be saved!",
      template_file_error: "Template file could not be downloaded!",
    },
    collection_point_updated: "Collection point successfully updated.",
    collection_point_created: "Collection point successfully created.",
    collection_point_deleted: "Collection point successfully deleted.",
    could_not_create_collection_point: "Collection point could not be created.",
    could_not_update_collection_point: "Collection point could not be updated.",
    could_not_delete_collection_point: "Collection point could not be deleted.",
    delete_dialog_text_collection_point: "Do you really want to delete the collection point?",
    delete_dialog_text_container: "Do you really want to delete the container?",
    images: {
      add: "Add picture for documentation",
      view: "View",
      dropzone_text: "Drag files or click to upload pictures for documentation",
      cancel: "Cancel",
      upload: "Upload",
      images_uploaded: "Pictures were successfully uploaded.",
      could_not_upload_images: "Pictures could not be uploaded.",
    },
    create_container: "Create container",
    delete_container: "Delete container",
    change_container: "Change container",
    change: "Change",
    confirm_change: "Yes, change",
    confirm_change_heading: "Do you really want to change?",
    container_created: "Container successfully created.",
    container_updated: "Container successfully updated.",
    container_deleted: "Container successfully deleted.",
    container_changed: "Container wurde erfolgreich getauscht.",
    could_not_create_container: "Container could not be created.",
    could_not_update_container: "Container could not be updated.",
    could_not_delete_container: "Container could not be deleted.",
    could_not_change_container: "Container could not be changed.",
    upload: {
      upload_successful: "Collection points were imported successfully.",
      upload_collection_points: "Upload List",
      dropzone_text: "Drag files or click to upload collection points",
      dropzone_underline_text: "If you do not have the template, download it <0>here</0>.",
      upload_not_possible: "Upload not possible",
      collection_point_count: "{{collectionPointsCount}} Collection Points",
      import: "Import",
      cancel: "Cancel",
      empty: "Your input is empty!",
      errors: {
        invalid_format:
          "Please make sure you are using the latest upload template and have filled in all required fields.",
        invalid_container_type_info: "Container data not supported. Please contact the support.",
        invalid_accessible_times:
          "Access times of collection points have an unreadable format. Please check the entries and try again!",
        invalid_coordinates: "GPS coordinates are missing or incorrect. Please check the input and try again.",
        connected_district_not_found:
          "No matching collection region could be found for the selected collection region. Please contact the support.",
        connected_association_not_found:
          "No matching collection region could be found for the selected collection region. Please contact the support.",
        unkown_error: "An unknown error has occurred. Please try again later.",
      },
      table: {
        description: "Description",
        street: "Street",
        town: "Town",
        latitude: "Lat",
        longitude: "Long",
        number_of_containers: "# containers",
      },
      duplicate_dialog: {
        title: "Upload or skip collection points?",
        body: `The collection point {{description}} with coordinates ({{latitude}}/{{longitude}}) already exists for this collection region. How should be proceeded?`,
        options: {
          exclude: "Skip this collection point",
          include: "Upload and extend: Collection point will be extended by the containers",
        },
        remember_action: "Remember for all collection points",
      },
      unsaved_data_prompt: {
        title: "Cancel Upload",
        content:
          "The upload process is not completely finished.\nData is lost when canceling the operation.\n\nDo you still want to cancel the process?",
      },
      back: "Back",
    },
  },
  routes_management: {
    title: "Route management",
    import_routes: "Import routes",
    dropzone_text: "Drag files or click to upload routes",
    cancel: "Cancel",
    import: "Import",
    filename: "file name",
    name: "Tour name",
    routes_uploaded: "Routes were successfully uploaded.",
    route_deleted: "Routes were successfully deleted.",
    could_not_upload_routes: "Routes could not be uploaded.",
    could_not_delete_route: "Route could not be deleted.",
    delete_dialog_text_route: "Do you really want to delete the route?",
    replace_routes_title: "Replace existing route",
    replace_routes_text: "Do you really want to replace following routes?",
    replace: "Replace",
    choose_location: "Location",
    add_stop_info: "Stop info saved succesfully",
    delete_stop_info: "Stop info deleted succesfully",
    confirm_delete_stop_info_text: "Do you really want to delete this information?",
    delete: "delete",
    open_images: "open images",
    no_images: "no images",
    location_select: {
      success: "Location changed",
      error: "Location could not be changed",
    },
    table: {
      id: "ID",
      filename: "file name",
      name: "tour name",
      date: "Date",
      stopName: "Stop",
      lat: "Latitude",
      long: "Longitude",
      eventType: "Type",
      info: "Info",
      sequence_number: "Sequencenumber",
      images: "Images",
      empty: "Route is empty.",
    },
    errors: {
      could_not_load: "An error occured while trying to load route",
      no_relevant_info: "Could not display event information",
      no_info_provided: "Invalid Data!",
      add_stop_info: "Failed to save stop info",
      delete_stop_info: "Failed to delete stop info",
    },
  },
  guidance_administration: {
    driver_location_create: "New driver location",
    driver_location_save: "Update driver location",
    driver_location_delete: "Delete driver location",
    driver_location_delete_error: "Could not delete driver location",
    driver_location: {
      title: "Driver Locations",
      data: {
        name: "Name",
        name_required: "Missing name",
      },
    },
    action: {
      create: "Create",
      save: "Save",
      cancel: "Cancel",
    },
    driver_location_updated: "Driver location successfully updated.",
    driver_location_created: "Driver location successfully created.",
    driver_location_deleted: "Driver location successfully deleted.",
    driver_location_create_error: "Driver location could not be created.",
    driver_location_update_error: "Driver location could not be updated.",
    could_not_delete_driver_location: "Driver location could not be deleted.",
    delete_dialog_text_driver_location: "Do you really want to delete the driver location?",
    filter_driver_location: "Filter routes by location:",
  },
  delete_dialog: {
    no: "no",
    agree_on_delete: "Yes, delete",
  },
  user_role: {
    [UserRole.SUPER_ADMIN]: "Super admin",
    [UserRole.TOWN_ADMIN]: "Community admin",
    [UserRole.DRIVER]: "Driver",
    [UserRole.ASSOCIATION]: "Association",
    [UserRole.COLLECTION_PARTNER]: "Collection partner",
    [UserRole.GUIDANCE_ADMIN]: "Guidance admin",
    [UserRole.GUIDED_DRIVER]: "Guided driver",
    [UserRole.RFID_APP]: "RFID App",
    all: "All",
  },
  departure_point_context: {
    errors: {
      could_not_load: "Could not load depots",
    },
  },
  departure_point: {
    data: {
      name: "Name",
      lat: "Lat",
      long: "Long",
      search: "Search",
    },
  },
  disposal_trailer_location_context: {
    errors: {
      could_not_load: "Could not load disposal trailer locations",
    },
  },
  disposal_trailer_location: {
    data: {
      name: "Name",
      lat: "Lat",
      long: "Long",
      search: "Search",
    },
  },
  location_type: {
    [LocationType.ASSOCIATION]: "Collection region",
    [LocationType.DISTRICT]: "Collection region",
    [LocationType.TOWN]: "Community",
    [LocationType.LOCATION]: "Driver Location",
    [`${LocationType.ASSOCIATION}_plural`]: "Collection regions",
    [`${LocationType.DISTRICT}_plural`]: "Collection regions",
    [`${LocationType.TOWN}_plural`]: "Communities",
    [`${LocationType.LOCATION}_plural`]: "Driver Locations",
  },
  overfilled_days_chart: {
    days: "Days",
    average_days: "Ø {{days}} Days",
    average_days_label: "Average days of overfilling",
    days_overfilled: "Days of overfilling",
  },
  top_overfilled_collection_points_chart: {
    collection_point_info: "Collection Point #{{id}}: {{days}} days",
    days_label: "Days of overfilling",
    title: "Collection points with the most days of overfilling",
  },
  emptyings_per_hour_chart: {
    emptyings_per_hour: "Container emptying per hour",
    average_emptyings_label: "Average emptying per hour",
    average_emptyings: "Ø {{emptyings}} Emptyings",
    emptyings: "Emptyings",
  },
  collected_amount_per_hour_chart: {
    collected_amount_per_hour: "Collected Amount(t) per hour",
    average_collected_amount_label: "Ø t/h",
    average_collected_amount: "Ø {{collected_amount}}t",
  },
  container_warnings: {
    table: {
      container_number: "Container number",
      sensor_id: "Sensor ID",
      material: "Material",
      description: "Description",
      address: "Address",
      status: "Status",
      link: "Link",
      message: "Warning",
      error: "Warnings could not be fetched",
      update_error: "Status could not be updated",
      update_success: "Status updated",
      empty: "There are currently no warnings",
      collection_point_tooltip: "Go To Collection Point",
    },
    message: {
      [ContainerWarningType.LID_OPEN]: "Lid is open since {{date}}",
      [ContainerWarningType.NO_RECENT_SENSOR_DATA]: "No sensor data received since {{date}}",
      [ContainerWarningType.DEFECTIVE_SENSOR]: "Sensor may be damaged. Please check.",
      [ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK]:
        "Collection point has been automatically switched to driver feedback due to sensor problems (since {{date}}). Please check sensors.",
      [ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA]: "Driver feedback: not enough data available.",
    },
    status: {
      [ContainerWarningStatus.OPEN]: "Open",
      [ContainerWarningStatus.PROGRESS]: "In progress",
      [ContainerWarningStatus.DONE]: "Done",
    },
  },
  tour_generation: {
    heading: "Tour generation",
    materials_invalid: "The material entries of some vehicles are invalid. (>0kg)",
    use_custom_point: "Use Custom Departure or End Point",
    use_custom_operating_times: "Use Custom Operating Times",
    reference_date_info:
      "The reference date specifies the day of the next tour and must be chronologically after the last day of the current tour.",
    considered_tour_days_info:
      'Number of past days on which tours are to be taken into account. If a container was emptied on one of these days and the sensor did not send a fill level, the fill level is set to "0".',
    feature_not_enabled: "Feature not enabled",
    feature_not_enabled_info: "Tour generation is not enabled for this user. Please contact support to enable it.",
    contact_support: "Contact support",
    data: {
      parameters: "Tour parameters",
      tour_without_containers: "Generate tour for taking inventory of containers",
      first_week: "First week of collection",
      second_week: "Second week of collection",
      threshold: "Threshold",
      threshold_min: "Threshold min.",
      threshold_2nd_week: "Threshold 2nd week",
      driving_days: "Driving days",
      generate_tours: "Generate Tours",
      tour_generation_successfully: "Tour generation successfully started.",
      could_not_generate: "Tour generation could not be started.",
      could_not_generate_no_recyclers:
        "No recycler is stored for the selected collection area - tour generation is not possible",
      materials: "Materials to be picked up",
      material_number: "Material {{number}}",
      no_materials_selected: "no materials selected",
      no_vehicles_available: "no vehicles available for the selected materials",
      vehicles: "Vehicles",
      version: "Version",
      max_termination_time: "max. termination time",
      max_iterations: "max. iterations",
      schedule_date: "schedule date",
      reference_date: "reference date",
      considered_tour_days: "considered tour days",
      disposal_trailer_locations: "Trailer locations",
      tour_generation_in_past:
        "At least one day of operation of the tour to be calculated is in the past. Should the tour generation be continued anyway?",
      attention: "Attention",
      continue_tour_generation: "continue tour generation",
      error_message: "Please select {{number}} trailer locations or less.",
    },
    table: {
      first_week: "First week of collection",
      calendar_week_formatted: "{{year}} CW{{week}}",
      district: "Collection region",
      week_count: "Weekly interval",
      threshold: "Threshold",
      driving_days: "Driving days",
      status: "Status",
      details: "Details",
      generation_started: "Calculation started on: {{date}}",
      generation_scheduled: "Calculation scheduled at: {{date}}",
      actions: "Actions",
      materials: "Materials",
      version: "Version",
      reference_date: "Reference Date",
    },
    preview_table: {
      id: "ID",
      description: "Description",
      address: "Address",
      fixed_unload_interval: "Fixed unload interval",
      fixed_unload_interval_weeks: "{{count}} week",
      fixed_unload_interval_weeks_plural: "{{count}} weeks",
      materials: "Materials (Status: {{date}})",
      last_emptying: "Last emptying: {{date}}",
      collection_point_status: "Status",
      status: {
        [CollectionPointStatus.added]: "Added",
        [CollectionPointStatus.removed]: "Removed",
        [CollectionPointStatus.planned]: "Planned",
      },
    },
    confirm_dialog: {
      cancel: "Cancel",
      generate: "Generate",
      schedule: "Schedule",
      title: "Generate tour",
      collection_point_info: "{{count}} collection point",
      collection_point_info_plural: "{{count}} collection points",
      materials: "Materials",
      confirm_title: "Do you really want to generate the tour?",
      confirm_text: "The tour generation can take up to 12 hours.",
      ok: "Ok",
      export: "Download Preview",
    },
    tour_parameters_dialog: {
      title: "Details",
      ok: "Ok",
      data: {
        district: "Collection region",
        calculation_start: "Calculation start",
        calculation_end: "Calculation end",
        version: "Version",
        threshold: "Threshold",
        threshold_min: "Threshold min.",
        departure_point: "Start: {{departure_point}}",
        end_point: "End: {{departure_point}}",
      },
    },
    preview_dialog: {
      title: "Preview",
      collection_point_info_planned: "{{count}} collection point planned",
      collection_point_info_planned_plural: "{{count}} collection points planned",
      collection_point_info_actual: "{{count}} collection point calculated by tour optimization algorithm",
      collection_point_info_actual_plural: "{{count}} collection points calculated by tour optimization algorithm",
      materials_info_planned: "Planned: {{materials}}",
      materials_info_actual: "Calculated: {{materials}}",
      ok: "Ok",
      filter: {
        added_collection_points: "Collection points added by tour optimization algorithm",
        removed_collection_points: "Collection points removed by tour optimization algorithm",
        planned_collection_points: "Originally planned collection points",
      },
    },
    disposal_merchant_availability_dialog: {
      no_available_disposal_merchants_for_single_day:
        "There does not seem to be a suitable unloading point for {{day}} at the moment. You can change the unloading point settings or adjust the tour parameters. To do this, press Cancel.",
      no_available_disposal_merchants_for_several_days:
        "For several days of the tour to be calculated, there seems to be no suitable unloading point at the moment. You can change the unloading point settings or adjust the tour parameters. To do this, press Cancel.",
      customize_unloading_point: "Customize Unloading Point",
    },
    actions: {
      regenerate: "Generate tour again",
      show_details: "Show details",
      abort_calculation: "Abort calculation",
      abort_scheduled_calculation: "Delete scheduled calculation",
      delete_tour_generation: "Delete tour generation",
    },
    abort_calculation: {
      heading: "Abort calculation?",
      text: "Do you really want to abort the calculation?",
      abort_calculation: "Abort calculation",
      abort_successfully: "Calculation aborted successfully.",
      continue_calculation: "Continue calculation",
      could_not_abort: "Could not abort the calculation.",
    },
    delete_generation: {
      heading: "Delete tour generation?",
      text: "Do you really want to delete the tour generation?",
      delete_generation: "Delete tour generation",
      delete_generation_successful: "Tour generation deleted successfully.",
      could_not_delete: "Could not delete tour generation.",
    },
    schedule_dialog: {
      title: "Schedule tour generation",
      max_termination_time_info: "The value for the max. termination time has to be between 15 minutes and 24 hours.",
      schedule_date_info: "The schedule date must be in the future.",
      confirm: "Schedule",
    },
    cancel_scheduled_calculation: {
      heading: "Delete scheduled calculation?",
      text: "Do you really want to abort the scheduled calculation?",
      delete: "Delete",
      cancelled_successfully: "Scheduled calculation aborted successfully.",
      could_not_cancel: "Could not abort the scheduled calculation.",
    },
    concurrent_tour_dialog: {
      title: "Concurrent Tour",
      description: "The current tour overlaps with an already calculated or planned tour.",
      replace_concurrent_tour: "Cancel existing tour",
      keep_concurrent_tour: "Keep both tours",
    },
  },
  week_picker: {
    calendar_week: "Calendar week",
    calendar_week_formatted: "CW{{week}} ({{year}})",
    calendar_week_short: "CW{{week}}",
    week_number: "Week {{week}}",
    week_number_short: "W{{week}}",
  },
  date_format: "DD/MM/YYYY",
  date_time_format: "DD/MM/YYYY hh:mm A",
  date_time_input_format: "DD/MM/YYYY HH:mm",
  day_of_month_format: "MMM Do",
  date_time_seconds_format: "DD/MM/YYYY hh:mm:ss A",
  custom_autocomplete: {
    selectAll: "Select all",
    deselectAll: "Deselect all",
    clearSelection: "Clear Selection",
    tagText: "selected",
  },
  validation: {
    time: {
      notpossible: "Given time is invalid",
    },
  },
  export: {
    download_error: "File could not be downloaded.",
    cancel_dialog: {
      heading_all: "Cancel Downloads",
      text_all: "Do you really want to cancel all downloads?",
      heading_single: "Cancel Download",
      text_single: "Do you really want to cancel the download?",
    },
    download_item: {
      queued: "queued",
      processing: "processing",
      finished: "finished",
      error: "An unknown error ocurred",
    },
    downloads_finished: "Downloads Finished",
    preparing_downloads: "Preparing Downloads",
    unknown_error: "An unknown error ocurred",
  },
  households: {
    filter: {
      heading: "Filter",
      counties: "Districts",
      address: "Address",
      materials: "Materials",
      towns: "Communities - Code",
      show_warnings: "show warnings only",
      reset_filter: "Reset filter",
    },
    table: {
      id: "ID",
      address: "Address",
      material: "Material",
      container_type: "Container type",
      comment: "Comment",
      rfid: "RFID",
      no_data: "No data could be found for these filter settings.",
      delete_container: "Remove container",
    },
    comment_types: {
      [HouseholdContainerCommentType.CONTAINER_OLD]: "old container",
      [HouseholdContainerCommentType.CONTAINER_NOT_PROVIDED]: "container not provided",
      [HouseholdContainerCommentType.CONTAINER_DEFECTIVE]: "container defective",
    },
  },
  household_overview: {
    status: {
      collection_points: "Collection points",
      total: "Total",
      amount_chambers: "Amount chambers",
      amount_containers_chipped: "Amount chipped containers",
    },
    delete_dialog: {
      heading: "Do you really want to remove this container?",
    },
  },
}

// Overwrite translation for industry users
export const en_industry = {
  association: "Location",
  district: "Location",
  collection_points: {
    association: "Location",
    info_cards: {
      partners: {
        amount_regions: "Location",
        amount_regions_plural: "Locations",
      },
    },
    filter: {
      town_label: "Area - Area code",
    },
  },
  partner_overview: {
    data: {
      assign_districts: "Assign collection regions",
    },
  },
  vehicle_overview: {
    data: {
      district: "Location",
    },
  },
  tour_overview: {
    stop_types: {
      disposal_merchant: "Unloading point",
    },
  },
  disposal_merchant_overview: {
    heading: "Unloading point",
    data: {
      title: "Unloading point data",
      delete_disposal_merchant: "Delete unloading point",
      delete_prompt: "Do you really want to delete the uploading point?",
      could_not_create: "Unloading point cannot be created",
      could_not_update: "Unloading point cannot be updated",
      could_not_delete: "Unloading point cannot be deleted",
      disposal_merchant_created: "Unloading point successfully created",
      disposal_merchant_updated: "Unloading point successfully updated",
      disposal_merchant_deleted: "Unloading point successfully deleted",
    },
  },
  errors: {
    no_association_dashboard_data: "No data could be loaded for this location.",
    no_association_tours_found: "No tours were found for this location with the current filter settings.",
    no_district_dashboard_data: "No data could be loaded for this location.",
    no_district_tours_found: "No tours were found for this location with the current filter settings.",
  },
  location_type: {
    [LocationType.ASSOCIATION]: "Location",
    [`${LocationType.ASSOCIATION}_plural`]: "Locations",
    [LocationType.TOWN]: "Area",
    [`${LocationType.TOWN}_plural`]: "Areas",
    [LocationType.DISTRICT]: "Location",
    [`${LocationType.DISTRICT}_plural`]: "Locations",
    [LocationType.LOCATION]: "Driver Location",
    [`${LocationType.LOCATION}_plural`]: "Driver Locations",
  },
  collection_point_administration: {
    data: {
      town: "Area - Area code",
    },
  },
  user_role: {
    TOWN_ADMIN: "Area manager",
    ASSOCIATION: "Industrial costumer",
  },
  navigation: {
    disposal_merchant_overview: "Unloading point",
  },
}
