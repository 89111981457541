import React, { FunctionComponent, useState } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Card as MaterialCard,
  Button,
  TextField,
  Container
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GETDISPOSALMERCHANTS_QUERY,
  DisposalMerchantsResult
} from "../../../../api/graphql/queries/get-disposal-merchants"
import { DisposalMerchantItem } from "./disposal-merchant-item"

import { useSelectedDisposalMerchant } from "../../../../context/selected-disposal-merchant-context"
import { useRefetch } from "../../../../context/refetch-context"
import { ContentContainer } from "../../../partials/layout/content/content-container"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column",
  },
  filterContainerCard: {
    padding: theme.spacing(2),
    width: 270
  },
  filterContainer: {
    width: 270
  },
  filterContainerItems: {
    width: "100%"
  },
  snackBar: {
    marginTop: 10
  },
  addDisposalMerchantButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  addIcon: {
    color: theme.palette.primary.main
  },
  itemsContainer: {
    padding: 0,
    flex: "1 1 auto",
    overflowY: "auto"
  },
  containerTop: {
    padding: 0,
    marginBottom: theme.spacing(2),
    flex: "0 0 auto"
  }
}))

interface IDisposalMerchantSelectorProps {}

export const DisposalMerchantSelector: FunctionComponent<IDisposalMerchantSelectorProps> = props => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [disposalMerchantName, setDisposalMerchantName] = useState<string>("")
  const { setSelectedDisposalMerchantId } = useSelectedDisposalMerchant()

  const { needToRefetch, setNeedToRefetch } = useRefetch()

  const { data } = useQuery<DisposalMerchantsResult>(
    GETDISPOSALMERCHANTS_QUERY,
    {
      fetchPolicy: needToRefetch ? "network-only" : "cache-first",
      onCompleted: () => setNeedToRefetch(false)
    }
  )

  const onFilterApplyClicked = () => {}

  const onFilterRemoveClicked = () => {}

  const onAddNewDisposalMerchantClicked = () => {
    setSelectedDisposalMerchantId(undefined)
  }

  return (
    <ContentContainer style={classes.container}>
      <Grid className={classes.containerTop}>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            className={classes.addDisposalMerchantButton}
            onClick={onAddNewDisposalMerchantClicked}
            fullWidth
          >
            <AddIcon className={classes.addIcon} />
          </Button>
        </Grid>
        <Grid item>
          <MaterialCard className={classes.filterContainerCard}>
            <Grid
              container
              direction={"column"}
              className={classes.filterContainer}
              spacing={1}
            >
              <Grid item>
                <TextField
                  className={classes.filterContainerItems}
                  id="outlined-search"
                  label={t(
                    "disposal_merchant_overview.filter.disposal_merchant_name"
                  )}
                  type="search"
                  variant="outlined"
                  placeholder="Saubermacher"
                  value={disposalMerchantName}
                  onChange={ev => {
                    setDisposalMerchantName(ev.target.value)
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={onFilterApplyClicked}
                >
                  {t("collection_points.filter.apply_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.filterContainerItems}
                  variant="contained"
                  type="button"
                  color="default"
                  onClick={onFilterRemoveClicked}
                >
                  {t("collection_points.filter.remove_filter")}
                </Button>
              </Grid>
            </Grid>
          </MaterialCard>
        </Grid>
      </Grid>
      {data && (
        <Container className={classes.itemsContainer}>
          {data.getDisposalMerchants.map((disposalMerchant, index) => (
            <DisposalMerchantItem key={index} item={disposalMerchant} />
          ))}
        </Container>
      )}
    </ContentContainer>
  )
}
