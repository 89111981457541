import { gql } from "@apollo/client"
import { getToursAsExcelFile, getToursAsExcelFileVariables } from "./types/getToursAsExcelFile"

export const GET_TOURS_AS_EXCEL_FILE_QUERY = gql`
  query getToursAsExcelFile($to: DateTime!, $from: DateTime!, $tourIds: [ID!]!) {
    getToursAsExcelFile(to: $to, from: $from, tour_ids: $tourIds)
  }
`
export type GetToursAsExcelFileResult = getToursAsExcelFile
export type GetToursAsExcelFileVariables = getToursAsExcelFileVariables
