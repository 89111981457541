import React, { FunctionComponent } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { TourGenerationData } from "./partials/tour-generation-data"
import { TourGenerationTable } from "./partials/tour-generation-table"
import { RefetchProvider } from "../../../context/refetch-context"
import { TourGenerationContextProvider} from "../../../context/tour-generation-context"

interface ITourGenerationPageProps {}

export const TourGenerationPage: FunctionComponent<ITourGenerationPageProps> = (props) => {
  const { t } = useTranslation()
  return (
    <RefetchProvider>
      <TourGenerationContextProvider>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <PageHeading>{t("tour_generation.heading")}</PageHeading>
        </Grid>
        <Grid item container>
          <TourGenerationData />
        </Grid>
        <Grid item>
          <TourGenerationTable />
        </Grid>
      </Grid>
      </TourGenerationContextProvider>
    </RefetchProvider>
  )
}
