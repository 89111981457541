import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core"
import lodash from "lodash"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { TableBodySkeleton } from "../../../partials/layout/table/table-body-skeleton"
import { DefectContainersRow } from "./defect-containers-row"
import { ExportReason } from "../../../../api/graphql/graphql-global-types"

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderLeft: "1px solid", // fix border after filllevels
    borderColor: theme.palette.grey[400],
  },
}))

export type DefectContainer = {
  tourParametersId: string
  containerId: string
  collectionPointId: string
  filllevel: number | null
  materialName: string
  sensorId: string
  street: string
  place: string
  sequenceNumber?: number
  date?: Date
  reason?: ExportReason
}

interface DefectContainersTableProps {
  defectContainers: DefectContainer[]
  loading?: boolean
  error?: boolean
}

export const DefectContainersTable: FC<DefectContainersTableProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { defectContainers, loading, error } = props

  const materials = lodash.uniq(defectContainers.map((c) => c.materialName)).sort()

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.mark")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.date")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.sensor_id")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.material")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.address")}
            </TableCell>
            {!lodash.isEmpty(materials) && (
              <TableCell colSpan={materials.length} align="center">
                {t("tour_overview.defect_containers.table.filllevel")}
              </TableCell>
            )}
            <TableCell className={classes.border} rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.reason")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("tour_overview.defect_containers.table.link")}
            </TableCell>
          </TableRow>
          {!lodash.isEmpty(materials) && (
            <TableRow>
              {materials.map((material) => (
                <TableCell rowSpan={1} key={material} align="center">
                  {material}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {error && (
            <TableRow>
              <TableCell align="center" colSpan={100}>
                {t("tour_overview.defect_containers.table.error")}
              </TableCell>
            </TableRow>
          )}

          {!error && loading && <TableBodySkeleton columns={8} rows={10} />}

          {!error &&
            !loading &&
            defectContainers?.map((container, index) => (
              <DefectContainersRow
                key={index}
                container={container}
                materialNames={materials}
              />
            ))}

          {/* empty */}
          {!error && !loading && defectContainers && defectContainers.length <= 0 && (
            <TableRow>
              <TableCell align="center" colSpan={100}>
                {t("tour_overview.defect_containers.table.empty")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
