import { gql } from "@apollo/client"
import {
  getDefectContainers,
  getDefectContainersVariables,
  getDefectContainers_getExcludedDefectContainers,
  getDefectContainers_getIncludedDefectContainers
} from "./types/getDefectContainers"

export const GET_DEFECT_CONTAINERS_QUERY = gql`
query getDefectContainers($tourParametersId: ID!) {
  getExcludedDefectContainers(tourParametersId: $tourParametersId) {
    tourParametersId
    containerId
    container {
      id
      collection_point {
        id
      }
    }
    filllevel
    materialId
    material {
      id
      name
    }
    sensorId
    street
    cadastral
    postal
    updatedAt
    insertedAt
  }
  getIncludedDefectContainers(tourParametersId: $tourParametersId) {
    tourParametersId
    containerId
    container {
      id
      collection_point {
        id
      }
    }
    sequenceNumber
    date
    reason
    filllevel
    materialId
    material {
      id
      name
    }
    sensorId
    street
    cadastral
    postal
    updatedAt
    insertedAt
  }
}
`

export type DefectContainersResult = getDefectContainers
export type DefectContainersResultVariables = getDefectContainersVariables
export type ExcludedDefectContainersResult = getDefectContainers_getExcludedDefectContainers
export type IncludedDefectContainersResult = getDefectContainers_getIncludedDefectContainers
