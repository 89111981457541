import { gql } from "@apollo/client"
import {
  availableVehiclesForTourGeneration,
  availableVehiclesForTourGenerationVariables,
  availableVehiclesForTourGeneration_availableVehiclesForTourGeneration,
  availableVehiclesForTourGeneration_availableVehiclesForTourGeneration_operating_times,
} from "./types/availableVehiclesForTourGeneration"

export const AVAILABLE_VEHICLES_FOR_TOUR_GENERATION_QUERY = gql`
  query availableVehiclesForTourGeneration($distric_id: ID!, $start_date: DateTime!) {
    availableVehiclesForTourGeneration(distric_id: $distric_id, start_date: $start_date) {
      id
      licence_plate
      trailer
      departurePoint {
        id
        name
        latitude
        longitude
        vehicleCount
        tourCount
      }
      endPoint {
        id
        name
        latitude
        longitude
        vehicleCount
        tourCount
      }
      vehicleToMaterial {
        material {
          id
          name
        }
        amount
        is_trailer
      }
      collection_partner {
        id
      }
      daysForTourGeneration(start_date: $start_date)
      operating_times {
        day
        min_tour_duration
        max_tour_duration
      }
    }
  }
`

export type AvailableVehiclesForTourGenerationResult = availableVehiclesForTourGeneration
export type AvailableVehicleForTourGeneration = availableVehiclesForTourGeneration_availableVehiclesForTourGeneration
export type AvailableVehiclesForTourGenerationVariables = availableVehiclesForTourGenerationVariables
export type VehicleOperatingTimes = availableVehiclesForTourGeneration_availableVehiclesForTourGeneration_operating_times
