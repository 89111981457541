import { KeyboardTimePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import moment from "moment"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

const TIME_FORMAT = "HH:mm"

interface ITimePickerProps {
  time: string // HH:mm
  onChange: (date: MaterialUiPickersDate, value: string | null | undefined) => void
  className?: string
  disabled?: boolean
  label?: string
  required?: boolean
  error?: string
}

export const TimePicker: FunctionComponent<ITimePickerProps> = (props) => {
  const { t } = useTranslation()
  const { time, onChange, className, disabled, label, required, error } = props

  return (
    <KeyboardTimePicker
      fullWidth
      ampm={false}
      className={className}
      format={TIME_FORMAT}
      disabled={disabled}
      required={required}
      label={label}
      invalidDateMessage={t("validation.time.notpossible")}
      // use property only if error is set
      {...(error ? { error: true } : {})}
      {...(error ? { helperText: error } : {})}
      color="primary"
      margin="normal"
      id="time-picker"
      KeyboardButtonProps={{
        "aria-label": "change time",
      }}
      inputValue={time}
      value={moment(time, TIME_FORMAT)}
      onChange={onChange}
    />
  )
}
