import React, { FunctionComponent, useCallback } from "react"
import { Theme, makeStyles, Grid, Button, Typography, CircularProgress } from "@material-ui/core"
import { CollectionPointAdministrationSelector } from "./collection-point-administration-selector"
import { useTranslation } from "react-i18next"
import { Add } from "@material-ui/icons"
import { CollectionPointAdministrationData } from "./collection-point-administration-data"
import { CollectionPointsFilter } from "../../collection-points/partials/collection-points-filter"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { UploadCollectionPointsPage } from "./upload/upload-collection-points-page"
import { UploadCollectionPointsProvider } from "./upload/upload-collection-points-context"
import { Switch } from "react-router"
import { AuthRoute } from "../../../partials/wrapper/auth.route"
import { PATH } from "../../../../router/router"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) => ({
  leftSection: {
    width: 300,
    minHeight: "100%",
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
  },
  filterContainer: {
    flex: "0 0 auto",
  },
  createContainer: {
    flex: "0 0 auto",
  },
  selectorContainer: {  
    maxWidth: '100%',
    marginBottom: theme.spacing(2)
  },
  dataContainer: {
    minHeight: "100%",
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
  },
}))

interface ICollectionPointAdministrationProps {}

export const CollectionPointAdministration: FunctionComponent<ICollectionPointAdministrationProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setSelectedCollectionPoint, isLoading } = useCollectionPointAdministrationContext()
  const { push } = useHistory()

  const onFilterUpdated = () => {
    setSelectedCollectionPoint(undefined)
  }

  const handleCreateButtonClicked = useCallback(() => {
    setSelectedCollectionPoint(undefined)
    push(PATH.COLLECTIONPOINTS_ADMINISTRATION.route)
  }, [setSelectedCollectionPoint, push])

  return (
    <Grid container direction="row" spacing={2} className={classes.container}>
      <Switch>
        <AuthRoute
          path={PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route}
          children={
            <UploadCollectionPointsProvider>
              <UploadCollectionPointsPage />
            </UploadCollectionPointsProvider>
          }
        />
        <AuthRoute
          children={
            <>
              <Grid container item direction="column" className={classes.leftSection} spacing={3}>
                <Grid
                  item
                  container
                  justify="space-between"
                  direction="row"
                  alignItems="center"
                  className={classes.createContainer}
                >
                  <Grid item xs>
                    <Typography className={classes.title}>
                      {t("collection_point_administration.create_collection_point")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleCreateButtonClicked}
                      fullWidth
                      color="primary"
                    >
                      <Add color="primary" />
                    </Button>
                  </Grid>
                </Grid>
                <Grid item className={classes.filterContainer}>
                  <CollectionPointsFilter onFilterUpdated={onFilterUpdated} variant="administration" />
                </Grid>
                <Grid item className={classes.selectorContainer}>
                  <CollectionPointAdministrationSelector />
                </Grid>
              </Grid>
              <Grid item xs className={classes.dataContainer}>
                {isLoading ? (
                  <Grid item className={classes.loadingIndicator}>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <CollectionPointAdministrationData />
                )}
              </Grid>
            </>
          }
        />
      </Switch>
    </Grid>
  )
}
