import React, { FunctionComponent, Fragment, useContext, useMemo, useState } from "react"
import {
  Theme,
  makeStyles,
  Grid,
  Typography,
  TextField,
  Tooltip,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { WeekPicker } from "../../../partials/date-picker/week-picker"
import { UserService } from "../../../../services/user-service"
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers"
import { Info } from "@material-ui/icons"
import { TourGenerationMaterials } from "./tour-generation-materials"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import {
  DistrictsWithTownsResult,
  DistrictWithTowns,
  GET_DISTRICTS_WITH_TOWNS_QUERY,
} from "../../../../api/graphql/queries/get-districts-with-towns"
import { useQuery } from "@apollo/client"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { DEFAULT_DISTRICT_ID } from "../../../../utils/constants"
import { Moment } from "moment"
import { AlgorithmType, UserRole } from "../../../../api/graphql/graphql-global-types"
import { TourGenerationDisposalTrailerLocations } from "./tour-generation-disposal-trailer-locations"
import { TourGenerationAlgorithmVersion } from "./tour-generation-alogrithm-version"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { theme, SPACING } from "../../../../styles/theme"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontWeight: "bold",
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  weekPickerContainer: {
    paddingLeft: `${theme.spacing(2.5)}px!important`,
    paddingRight: `${theme.spacing(2.5)}px!important`,
  },
  considerTourDaysContainer: {
    paddingRight: `${theme.spacing(2.5)}px!important`,
  },
}))

interface ITourGenerationFormProps {}

export const TourGenerationForm: FunctionComponent<ITourGenerationFormProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    district,
    setDistrict,
    towns,
    setTowns,
    firstWeek,
    setFirstWeek,
    secondWeek,
    setSecondWeek,
    threshold,
    setThreshold,
    thresholdMin,
    setThresholdMin,
    version,
    setVersion,
    maxTerminationTime,
    setMaxTerminationTime,
    maxIterations,
    setMaxIterations,
    maxTerminationTimeValid,
    referenceDate,
    setReferenceDate,
    referenceDateValid,
    consideredTourDays,
    setConsideredTourDays,
    tourWithoutContainers,
    setTourWithoutContainers,
  } = useContext(TourGenerationContext)
  const [districtOptions, setDistrictOptions] = useState<SelectPair[]>([])

  // data for district select
  const { data: districtsData } = useQuery<DistrictsWithTownsResult>(GET_DISTRICTS_WITH_TOWNS_QUERY, {
    onCompleted: (data) => {
      if (data?.getDistricts.length) {
        setDistrictOptions(data.getDistricts.map((district) => new SelectPair(district.id, district.name)))
        const defaultDistrict = data.getDistricts.find((d) => d.id === DEFAULT_DISTRICT_ID)
        const defaultDistrictData = defaultDistrict ? defaultDistrict : data.getDistricts[0]
        setDistrict(defaultDistrictData)
        defaultDistrictData.allow_tourgeneration_town_multiselect ? setTowns(defaultDistrictData.towns) : setTowns([])
      }
    },
  })

  const updateFirstWeek = (date: Moment) => {
    setFirstWeek(date)
    if (date.isSameOrAfter(secondWeek, "week")) {
      setSecondWeek(date.clone().add(1, "week"))
    }
  }

  const updateSecondWeek = (date: Moment) => {
    setSecondWeek(date)
    if (date.isSameOrBefore(firstWeek, "week")) {
      setFirstWeek(date.clone().subtract(1, "week"))
    }
  }

  const getThreshold = (newThreshold: number | null) => {
    if (!newThreshold) {
      return null
    } else if (newThreshold < 0) {
      return 0
    } else if (newThreshold > 100) {
      return 100
    }
    return newThreshold
  }

  const checkThreshold = () => {
    if (threshold === null) {
      setThreshold(0)
    }
    if (thresholdMin === null) {
      setThresholdMin(0)
    }
  }

  const checkConsiderTourDays = () => {
    if (consideredTourDays === null) {
      setConsideredTourDays(0)
    }
  }

  const mappedTownOptions = useMemo(() => {
    return district?.towns.map((town) => new SelectOption(String(town.id), town.name)) || []
  }, [district])

  const setSelectedTowns = (selectedOptions: SelectOption[]) => {
    const selectedTowns = district?.towns.filter((town) => selectedOptions.map((x) => x.id).includes(town.id))
    setTowns(selectedTowns ?? [])
  }

  const getTownSelectionWidth = (district: DistrictWithTowns | null) => {
    return district && district.allow_tourgeneration_town_multiselect ? 6 : 12
  }

  const onDistrictChanged = (district_id: string) => {
    if (districtsData) {
      const district = districtsData.getDistricts.find((d) => d.id.toString() === district_id)
      setDistrict(district || null)
      setVersion(AlgorithmType.TO2)
      if (district?.allow_tourgeneration_town_multiselect === true) {
        setTowns(district && district.towns ? district.towns : [])
      } else {
        setTowns([])
      }
    }
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.heading}>{t("tour_generation.data.parameters")}</Typography>
      </Grid>
      {UserService.getRole() === UserRole.SUPER_ADMIN && (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={tourWithoutContainers}
                onChange={(_, checked: boolean) => setTourWithoutContainers(checked)}
                color="primary"
                style={{
                  color: theme.PRIMARY_COLOR.light,
                  padding: `0 ${SPACING}px`,
                }}
              />
            }
            label={t("tour_generation.data.tour_without_containers")}
          />
        </Grid>
      )}
      <Grid item container direction="row" spacing={2} alignItems="center" className={classes.wrapper}>
        <Grid item xs={getTownSelectionWidth(district)}>
          <OutlinedSelect
            options={districtOptions}
            name={t("vehicle_overview.data.district")}
            onValueChange={onDistrictChanged}
            value={district ? district.id : ""}
          />
        </Grid>
        {district?.allow_tourgeneration_town_multiselect && (
          <Grid item xs={6}>
            <CustomAutocomplete
              id="tour-generation-towns"
              title={t("vehicle_overview.data.towns")}
              currentlySelectedValues={towns.map((town) => new SelectOption(String(town.id), town.name))}
              availableSelectOptions={mappedTownOptions}
              setCurrentlySelectedValues={setSelectedTowns}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TourGenerationAlgorithmVersion />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={t("tour_generation.data.threshold")}
            type="number"
            inputProps={{
              min: "0",
              max: "100",
            }}
            onBlur={checkThreshold}
            fullWidth
            variant="outlined"
            value={threshold}
            onChange={(ev) => {
              setThreshold(getThreshold(ev.target.value ? parseInt(ev.target.value, 10) : null))
            }}
            disabled={tourWithoutContainers}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={
              version === AlgorithmType.AGR
                ? t("tour_generation.data.threshold_2nd_week")
                : t("tour_generation.data.threshold_min")
            }
            type="number"
            inputProps={{
              min: "0",
              max: "100",
            }}
            onBlur={checkThreshold}
            fullWidth
            variant="outlined"
            value={thresholdMin}
            onChange={(ev) => {
              setThresholdMin(getThreshold(ev.target.value ? parseInt(ev.target.value, 10) : null))
            }}
            disabled={tourWithoutContainers}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <Grid item xs={6} className={classes.weekPickerContainer}>
          <WeekPicker label={t("tour_generation.data.first_week")} value={firstWeek} onWeekChanged={updateFirstWeek} />
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={classes.weekPickerContainer}
          direction="row"
          alignItems="flex-end"
          spacing={1}
        >
          {version === AlgorithmType.AGR ? (
            <WeekPicker
              label={t("tour_generation.data.second_week")}
              value={secondWeek}
              onWeekChanged={updateSecondWeek}
              disabled={tourWithoutContainers}
            />
          ) : (
            <Fragment>
              <Grid item xs>
                <KeyboardDatePicker
                  id="date-picker-dialog-from"
                  label={t("tour_generation.data.reference_date")}
                  format={t("date_format")}
                  value={referenceDate}
                  minDate={firstWeek}
                  fullWidth
                  onChange={setReferenceDate}
                  cancelLabel={t("date_picker.cancel")}
                  okLabel={t("date_picker.ok")}
                  clearLabel={t("date_picker.clear")}
                  error={!referenceDateValid}
                  helperText={null}
                  disabled={tourWithoutContainers}
                />
              </Grid>
              <Grid item>
                <Tooltip title={t("tour_generation.reference_date_info") as string}>
                  <Info color="primary" />
                </Tooltip>
              </Grid>
            </Fragment>
          )}
        </Grid>
        {UserService.getRole() === UserRole.SUPER_ADMIN && (
          <Fragment>
            <Grid
              item
              container
              xs={6}
              className={classes.weekPickerContainer}
              direction="row"
              alignItems="flex-end"
              spacing={1}
            >
              <Grid item xs>
                <KeyboardTimePicker
                  fullWidth
                  ampm={false}
                  openTo="hours"
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  label={t("tour_generation.data.max_termination_time")}
                  value={maxTerminationTime}
                  onChange={(date) => setMaxTerminationTime(date as Moment)}
                  error={!maxTerminationTimeValid}
                  helperText={null}
                />
              </Grid>
              <Grid item>
                <Tooltip title={t("tour_generation.schedule_dialog.max_termination_time_info") as string}>
                  <Info color="primary" />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("tour_generation.data.max_iterations")}
                type="number"
                inputProps={{
                  min: "0",
                }}
                onBlur={checkThreshold}
                fullWidth
                variant="outlined"
                value={maxIterations || ""}
                onChange={(ev) => {
                  setMaxIterations(Number(ev.target.value) === 0 ? null : Number(ev.target.value))
                }}
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              direction="row"
              spacing={1}
              alignItems="flex-end"
              className={classes.considerTourDaysContainer}
            >
              <Grid item xs>
                <TextField
                  label={t("tour_generation.data.considered_tour_days")}
                  type="number"
                  inputProps={{
                    min: "0",
                  }}
                  onBlur={checkConsiderTourDays}
                  fullWidth
                  variant="outlined"
                  value={consideredTourDays}
                  onChange={(ev) => setConsideredTourDays(Number(ev.target.value))}
                  disabled={tourWithoutContainers}
                />
              </Grid>
              <Grid item>
                <Tooltip title={t("tour_generation.considered_tour_days_info") as string}>
                  <Info color="primary" />
                </Tooltip>
              </Grid>
            </Grid>
          </Fragment>
        )}
        <Grid item xs={6}>
          <TourGenerationDisposalTrailerLocations />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item container direction="row" spacing={2} alignItems="center">
        <TourGenerationMaterials />
      </Grid>
    </Grid>
  )
}
