import { gql } from "@apollo/client"
import {
  getDistrictsWithTowns_getDistricts_towns,
  getDistrictsWithTowns_getDistricts,
  getDistrictsWithTowns,
} from "./types/getDistrictsWithTowns"

export const GET_DISTRICTS_WITH_TOWNS_QUERY = gql`
  query getDistrictsWithTowns {
    getDistricts {
      id
      name
      allow_tourgeneration_town_multiselect
      disposalTrailerLocationLimit
      allowMultipleWeeks
      towns {
        id
        name
      }
    }
  }
`

export type DistrictsWithTownsResult = getDistrictsWithTowns
export type DistrictWithTowns = getDistrictsWithTowns_getDistricts
export type DistrictTown = getDistrictsWithTowns_getDistricts_towns
