import React from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { Text } from "../../wrapper/text"

interface IHeadingProps {
  children: String
}

const useStyles = makeStyles((theme: Theme) => ({
  pageHeading: {
    color: theme.palette.primary.main
  }
}))

export const PageHeading: React.FunctionComponent<IHeadingProps> = props => {
  const classes = useStyles()
  const { children } = props
  return (
    <Text className={classes.pageHeading} variant={"h4"} bold>
      {children.toUpperCase()}
    </Text>
  )
}
