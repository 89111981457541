import { gql } from "@apollo/client"
import {
  getCollectionPointWithID,
  getCollectionPointWithIDVariables,
  getCollectionPointWithID_getCollectionPointWithID,
  getCollectionPointWithID_getCollectionPointWithID_collectionPointFilllevels,
  getCollectionPointWithID_getCollectionPointWithID_containers,
} from "./types/getCollectionPointWithID"

export const GETCOLLECTIONPOINTWITHID_QUERY = gql`
  query getCollectionPointWithID($id: Int!, $date: DateTime) {
    getCollectionPointWithID(id: $id) {
      id
      description
      latitude
      longitude
      postal
      place
      containers(sorted: false) {
        id
        number
        type {
          id
          name
        }
        material_id
        material {
          id
          name
        }
        last_time_send
        lid_open
        lid_changed
        sensor_id
        defectiveSensor
        filllevelDerivedFromDriverFeedback
        containerWarnings {
          warning_type
          since
        }
      }
      town {
        id
        name
        association {
          forecastEnabled
        }
        district {
          forecastEnabled
        }
      }
      overfill_rate
      overfill_manipulation_time
      next_emptying
      last_emptying
      fixed_unload_interval
      collectionPointFilllevels(sorted: false, date: $date) {
        count_containers
        filllevel
        material_id
        material {
          id
          name
        }
      }
      street
      location_number
      cadastral_name
      cadastral_nr
      fixed_unload_interval
    }
  }
`

export type CollectionPointResult = getCollectionPointWithID
export type CollectionPoint = getCollectionPointWithID_getCollectionPointWithID
export type CollectionPointFilllevels = getCollectionPointWithID_getCollectionPointWithID_collectionPointFilllevels
export type CollectionPointVariables = getCollectionPointWithIDVariables
export type Container = getCollectionPointWithID_getCollectionPointWithID_containers
