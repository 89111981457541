import React, { useContext, useEffect, useMemo, useState } from "react"
import { HouseholdOverviewStatusContext } from "../context/household-overview-status-context"
import { HouseholdMarker } from "./household-marker"
import { GoogleMap, MarkerClusterer } from "@react-google-maps/api"
import { ClusterIconStyle } from "@react-google-maps/marker-clusterer"
import { Box, Theme, makeStyles, useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

interface IHouseholdOverviewMapProps {}

const MAP_HEIGHT = 400

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    position: "relative",
    borderRadius: 4,
  },
  mapOverlay: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
  },
}))

export const HouseholdOverviewMap: React.FC<IHouseholdOverviewMapProps> = (props) => {
  const { households, loading } = useContext(HouseholdOverviewStatusContext)
  const [map, setMap] = useState<google.maps.Map>()
  const classes = useStyles()
  const theme = useTheme()

  const center = useMemo(() => {
    const sum = households.reduce(
      (acc, household) => {
        return { sumLat: acc.sumLat + household.latitude, sumLng: acc.sumLng + household.longitude }
      },
      { sumLat: 0, sumLng: 0 },
    )

    return households.length
      ? {
          lat: sum.sumLat / households.length,
          lng: sum.sumLng / households.length,
        }
      : undefined
  }, [households])

  useEffect(() => {
    if (!map) {
      return
    }

    const bounds = new window.google.maps.LatLngBounds()
    households.forEach((household) => {
      bounds.extend({
        lat: household.latitude,
        lng: household.longitude,
      })
    })
    map.fitBounds(bounds)
  }, [map, households])

  const clusterIconStyles: ClusterIconStyle[] = useMemo(() => {
    const svg = `data:image/svg+xml;utf8,<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="24" fill="%23${
      theme.palette.primary.main.split("#")[1]
    }" opacity="0.75" /></svg>`

    return [
      { textColor: theme.palette.primary.contrastText, height: 32, width: 32, url: svg, textSize: 14 },
      { textColor: theme.palette.primary.contrastText, height: 40, width: 40, url: svg, textSize: 14 },
      { textColor: theme.palette.primary.contrastText, height: 48, width: 48, url: svg, textSize: 14 },
      { textColor: theme.palette.primary.contrastText, height: 56, width: 56, url: svg, textSize: 14 },
      { textColor: theme.palette.primary.contrastText, height: 64, width: 64, url: svg, textSize: 14 },
    ] as ClusterIconStyle[]
  }, [theme])

  return (
    <GoogleMap
      center={center}
      onLoad={setMap}
      mapContainerStyle={{
        width: "100%",
        height: MAP_HEIGHT,
      }}
      mapContainerClassName={classes.map}
    >
      {loading && (
        <div className={classes.mapOverlay}>
          <Skeleton variant="rect" height={MAP_HEIGHT} />
        </div>
      )}
      {!loading && (
        <MarkerClusterer maxZoom={20} styles={clusterIconStyles}>
          {(clusterer) => (
            <Box>
              {households.map((household) => (
                <HouseholdMarker key={household.id} household={household} clusterer={clusterer} />
              ))}
            </Box>
          )}
        </MarkerClusterer>
      )}
    </GoogleMap>
  )
}
