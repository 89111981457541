import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react"
import { Grid, Chip, CircularProgress, Button } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import { ILocationCollectionItem } from "./location-assignment-dialog"
import { sortASC } from "../../../../utils/sort"
import {
  GET_TOWNS_FROM_DISTRICTS_ID,
  TownsFromDistrict,
} from "../../../../api/graphql/queries/get-towns-from-districts-id"
import * as _ from "lodash"
import { Text } from "../../../partials/wrapper/text"
import { useTranslation } from "react-i18next"

interface IBtnState {
  [key: string]: boolean
}

interface ITownSelectionProps {
  selectedItems: ILocationCollectionItem[]
  setSelectedTowns: (locations: ILocationCollectionItem[]) => void
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
  selectedDistricts: ILocationCollectionItem[]
  setError: (value: boolean) => void
}

export const TownSelection: FunctionComponent<ITownSelectionProps> = (props) => {
  const { t } = useTranslation()
  const { onClick, selectedItems, searchString, selectedDistricts, setError, setSelectedTowns } = props

  const { data, loading } = useQuery<TownsFromDistrict>(GET_TOWNS_FROM_DISTRICTS_ID, {
    variables: {
      districtIds: selectedDistricts.map((district) => district.id),
    },
  })

  const towns = useMemo(() => _.groupBy(data?.getTownsFromDistrictsId, "district.id"), [data])
  const btnSelected: IBtnState = useMemo(() => {
    if (towns && Object.keys(towns).length > 0) {
      return Object.keys(towns)
        .map((key: string) => {
          return { [key]: towns[key].every((entry) => selectedItems.find((item) => item.id === entry.id)) }
        })
        .reduce((acc, curr) => ({ ...acc, ...curr }))
    }
    return {}
  }, [towns, selectedItems])

  useEffect(() => {
    if (selectedDistricts.length > 1) {
      const errorTemp: boolean[] = new Array(selectedDistricts.length).fill(false)
      for (let i = 0; i < selectedDistricts.length; i++) {
        errorTemp[i] = !_.some(selectedItems, { district_id: Number(selectedDistricts[i].id) })
      }
      // Here we check if the array has at least 1 element true, but not all of them equal to true (or false)
      if (_.includes(errorTemp, true) && !_.every(errorTemp)) {
        setError(true)
      } else {
        setError(false)
      }
    } else {
      setError(false)
    }
  }, [selectedDistricts, selectedItems, setError, t])

  const selectAllClick = useCallback(
    (key: string) => {
      if (!btnSelected[key]) {
        setSelectedTowns([
          ...selectedItems.filter((town) => town.district_id !== Number(key)),
          ...towns[key].map((town) => ({
            id: town.id,
            name: town.name,
            district_id: town.district_id,
          })),
        ])
      } else {
        setSelectedTowns(selectedItems.filter((town) => town.district_id !== Number(key)))
      }
    },
    [towns, setSelectedTowns, selectedItems, btnSelected],
  )

  return (
    <Grid container spacing={4}>
      {loading && (
        <Grid item container justify="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        Object.keys(towns).map((key) => (
          <Grid container item key={key} direction="row" alignItems="center">
            <Grid container item xs={2} direction="column" spacing={1} alignItems="center">
              <Grid item xs>
                <Text bold>{towns[key][0].district?.name}:</Text>
              </Grid>
              <Grid item xs>
                <Button color="primary" variant="outlined" onClick={() => selectAllClick(key)}>
                  {t(`custom_autocomplete.${btnSelected[key] ? "deselectAll" : "selectAll"}`)}
                </Button>
              </Grid>
            </Grid>
            <Grid item container xs={9} spacing={1}>
              {towns[key]
                .sort((a, b) => sortASC(a.name, b.name))
                .filter((town) => town.name.toLowerCase().includes(searchString.toLowerCase()))
                .map((town) => (
                  <Grid item key={town.id}>
                    <Chip
                      label={town.name}
                      color={
                        selectedItems.find((item) => item.id === town.id && item.name === town.name)
                          ? "primary"
                          : "default"
                      }
                      onClick={() => onClick(town)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ))}
    </Grid>
  )
}
