const baseLocal = "http://localhost:4000"
const baseDev = "https://dev-smart-collection-api.denovo.cloud"
const baseTest = "https://test-smart-collection-api.denovo.cloud"
const baseSta = "https://sta-smart-collection-api.denovo.cloud"
const basePrd = "https://smart-collection-api.denovo.cloud"

const graphqlPath = "/graphql"
const subscriptionPath = "/graphql"

export function getBaseGraphqlApiUrl(): string {
  return `${getBaseRestApiUrl()}${graphqlPath}`
}

export function getBaseSubscriptionApiUrl(): string {
  return `${getBaseRestApiUrl()}${subscriptionPath}`.replace("http", "ws")
}

export function getBaseRestApiUrl(): string {
  if (window.location.href.includes("localhost")) {
    return baseLocal
  } else if (window.location.href.includes("sta-")) {
    return baseSta
  } else if (window.location.href.includes("dev-") || window.location.href.includes("dev.")) {
    return baseDev
  } else if (window.location.href.includes("test-") || window.location.href.includes("demo.")) {
    return baseTest
  }
  return basePrd
}
