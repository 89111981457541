import { gql } from "@apollo/client"
import { getAssociations, getAssociations_getAssociations } from "./types/getAssociations"

export const GETASSOCIATIONS_QUERY = gql`
  query getAssociations {
    getAssociations {
      id
      name
      forecastEnabled
      latitude
      longitude
      rfidModuleEnabled
    }
  }
`

export type AssociationsResult = getAssociations
export type Association = getAssociations_getAssociations
