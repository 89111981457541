import { gql } from "@apollo/client"
import {
  updateDisposalMerchantVacationTime,
  updateDisposalMerchantVacationTimeVariables,
  updateDisposalMerchantVacationTime_updateDisposalMerchantVacationTime
} from "./types/updateDisposalMerchantVacationTime"

export const UPDATE_DISPOSAL_MERCHANT_VACATION_TIME_MUTATION = gql`
  mutation updateDisposalMerchantVacationTime(
    $id: ID!
    $vacationTime: DisposalMerchantVacationTimesInput!
  ) {
    updateDisposalMerchantVacationTime(
      id: $id
      vacationTime: $vacationTime
    ) {
      id
    }
  }
`

export type UpdateDisposalMerchantVacationTimesResult = updateDisposalMerchantVacationTime
export type UpdateDisposalMerchantVacationTimes = updateDisposalMerchantVacationTimeVariables
export type UpdateDisposalMerchantVacationTimesVariables = updateDisposalMerchantVacationTime_updateDisposalMerchantVacationTime