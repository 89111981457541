import { gql } from "@apollo/client"
import { uploadCollectionPoints, uploadCollectionPointsVariables } from "./types/uploadCollectionPoints"

export const UPLOAD_COLLECTION_POINTS_QUERY = gql`
  mutation uploadCollectionPoints(
    $regionId: ID!
    $regionType: RegionType!
    $collectionPoints: [UploadCollectionPointInput!]!
  ) {
    uploadCollectionPoints(regionId: $regionId, regionType: $regionType, collectionPoints: $collectionPoints) {
      error
      message
    }
  }
`

export type UploadCollectionPointsResult = uploadCollectionPoints
export type UploadCollectionPointsVariables = uploadCollectionPointsVariables
