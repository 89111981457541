import i18next from "i18next"
import lodash from "lodash"
import { ContainerType } from "../api/graphql/queries/get-container-types"

export const getContainerTypLabel = (containerType: ContainerType, materialId?: string): string => {
  const emptyingType = i18next.t(`collection_point_administration.data.emptying_types.${containerType.emptying_type}`)
  const containerTypeInfo = materialId
    ? containerType.container_type_infos.find((typeInfo) => String(typeInfo.material_id) === materialId)
    : null
  let maxAmountInfo = ""
  if (!lodash.isNil(containerTypeInfo)) {
    maxAmountInfo = ` - ${containerTypeInfo.max_amount}kg`
  }

  let label = `${emptyingType} ${containerType.size}${containerType.size_unit}${maxAmountInfo}`

  return label
}
