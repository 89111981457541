import { gql } from "@apollo/client"
import {
  getTownsFromDistrictsId,
  getTownsFromDistrictsId_getTownsFromDistrictsId,
} from "./types/getTownsFromDistrictsId"

export const GET_TOWNS_FROM_DISTRICTS_ID = gql`
  query getTownsFromDistrictsId($districtIds: [String!]!) {
    getTownsFromDistrictsId(districtIds: $districtIds) {
      id
      name
      district_id
      district {
        id
        name
      }
    }
  }
`

export type TownsFromDistrict = getTownsFromDistrictsId
export type TownFromDistrict = getTownsFromDistrictsId_getTownsFromDistrictsId
