import { gql } from "@apollo/client"
import { getTowns } from "./types/getTowns"

export const GETTOWNS_QUERY = gql`
  query getTowns {
    getTowns {
      id
      name
    }
  }
`

export type TownsResult = getTowns
