import { gql } from "@apollo/client"
import {
  deleteRoute,
  deleteRouteVariables,
} from "./types/deleteRoute"

export const DELETE_ROUTE_MUTATION = gql`
  mutation deleteRoute($id: ID!) {
    deleteRoute(id: $id) {
      message
    }
  }
`

export type DeleteRouteResult = deleteRoute
export type DeleteRouteVariables = deleteRouteVariables
