import { gql } from "@apollo/client"
import {
  getCollectionPointStatsWithID,
  getCollectionPointStatsWithID_getCollectionPointStatsWithID,
  getCollectionPointStatsWithIDVariables,
  getCollectionPointStatsWithID_getCollectionPointStatsWithID_next_emptying,
} from "./types/getCollectionPointStatsWithID"

export const GETCOLLECTIONPOINTSTATS_QUERY = gql`
  query getCollectionPointStatsWithID($id: Int!) {
    getCollectionPointStatsWithID(id: $id) {
      id
      collection_point {
        description
        latitude
        longitude
        postal
        street
        town {
          name
        }
        next_emptying
        last_emptying
        collectionPointFilllevels(sorted: false) {
          count_containers
          filllevel
          material {
            id
            name
          }
        }
      }
      next_emptying {
        emptying_date
        partner
      }
      last_emptying {
        emptying_date
        partner
      }
      avg_full
    }
  }
`

export type CollectionPointStatsResult = getCollectionPointStatsWithID
export type CollectionPointStatsVariables = getCollectionPointStatsWithIDVariables
export type CollectionPointStats = getCollectionPointStatsWithID_getCollectionPointStatsWithID
export type EmptyingStats = getCollectionPointStatsWithID_getCollectionPointStatsWithID_next_emptying
