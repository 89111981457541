import { Card, Divider, Grid, IconButton, makeStyles, Theme, Typography, useTheme } from "@material-ui/core"
import { Check, Clear, Settings } from "@material-ui/icons"
import React, { FunctionComponent, useCallback, useContext, useState } from "react"
import { VehicleToMaterial } from "../../../../../api/graphql/queries/get-vehicle-with-id"
import { TourGenerationContext } from "../../../../../context/tour-generation-context"
import { DayOfWeek } from "../../../../../utils/day"
import { TourGenerationVehicleMaterials } from "../tour-generation-vehicle-materials"
import { ICustomDeparturePoint, ICustomOperatingTime, IVehicleEntry } from "../tour-generation-vehicle-selection"
import { TourGenerationVehicleConfigurationDialog } from "./tour-generation-vehicle-configuration-dialog"
import { TourGenerationVehicleCardDays } from "./tour-generetion-vehicle-card-days"

const useStyles = makeStyles((theme: Theme) => ({
  card: {},
  heading: {
    fontWeight: "bold",
  },
  dividerContainer: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  checkBoxContainer: {
    width: 30,
    minHeight: "100%",
    cursor: "pointer",
  },
  content: {
    padding: theme.spacing(1),
  },
  check: {
    color: theme.palette.primary.contrastText,
  },
  clear: {
    color: "#000",
  },
}))

interface ITourGenerationVehicleCardProps {
  vehicle: IVehicleEntry
  handleChangeSelection: (vehicleId: number) => (days: DayOfWeek[]) => void
  updateMaterials: (vehicle: IVehicleEntry) => (materials: VehicleToMaterial[]) => void
  handleChangeCustomDeparturePoint: (vehicleId: number) => (customDeparturePoin: ICustomDeparturePoint) => void
  handleChangeCustomOperatingTimes: (vehicleId: number) => (customOperatingTimes: ICustomOperatingTime) => void
  handleChangeActive: (vehicleId: number) => () => void
}

export const TourGenerationVehicleCard: FunctionComponent<ITourGenerationVehicleCardProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [configurationOpen, setConfigurationOpen] = useState<boolean>(false)
  const {
    vehicle,
    updateMaterials,
    handleChangeSelection,
    handleChangeCustomDeparturePoint,
    handleChangeCustomOperatingTimes,
    handleChangeActive,
  } = props

  const toggleConfigurationDialog = useCallback(() => setConfigurationOpen(!configurationOpen), [
    configurationOpen,
    setConfigurationOpen,
  ])

  const { tourWithoutContainers } = useContext(TourGenerationContext)

  return (
    <>
      <TourGenerationVehicleConfigurationDialog
        open={configurationOpen}
        onClose={toggleConfigurationDialog}
        vehicle={vehicle}
        handleChangeSelection={handleChangeSelection}
        onCustomDeparturePointEntryChange={handleChangeCustomDeparturePoint(vehicle.vehicleId)}
        onCustomOperatingTimeEntryChange={handleChangeCustomOperatingTimes(vehicle.vehicleId)}
        updateMaterials={updateMaterials}
      />
      <Card className={classes.card}>
        <Grid container>
          <Grid item className={classes.checkBoxContainer}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.checkBoxContainer}
              style={{
                backgroundColor: vehicle.isActive ? theme.palette.primary.light : theme.palette.grey[300],
              }}
              onClick={handleChangeActive(vehicle.vehicleId)}
            >
              {vehicle.isActive && <Check className={classes.check} />}
              {!vehicle.isActive && <Clear className={classes.clear} />}
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container direction="column" className={classes.content}>
              <Grid item>
                <Typography className={classes.heading}>{vehicle.licencePlate}</Typography>
              </Grid>
              <Grid item container>
                <Grid item xs>
                  <TourGenerationVehicleCardDays vehicle={vehicle} handleChangeSelection={handleChangeSelection} />
                </Grid>
                {!tourWithoutContainers && (
                  <>
                    <Grid item className={classes.dividerContainer}>
                      <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs>
                      <TourGenerationVehicleMaterials
                        notEditable
                        selectedMaterials={vehicle.materials}
                        updateSelectedMaterials={updateMaterials(vehicle)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={toggleConfigurationDialog}>
              <Settings />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
