import { ICollectionPointsFilter } from "../context/CollectionPointsFilterContext"
import { compareArrays } from "./compare"

export const hasCollectionPointFilterChanged = (
  newFilter: ICollectionPointsFilter,
  oldFilter: ICollectionPointsFilter,
) =>
  newFilter.address !== oldFilter.address ||
  newFilter.containerNumber !== oldFilter.containerNumber ||
  newFilter.description !== oldFilter.description ||
  newFilter.minFilllevel !== oldFilter.minFilllevel ||
  newFilter.onlyIssueContainers !== oldFilter.onlyIssueContainers ||
  newFilter.postal !== oldFilter.postal ||
  newFilter.sensorId !== oldFilter.sensorId ||
  !compareArrays(
    newFilter.collectionTypes,
    oldFilter.collectionTypes,
    (a, b) => a.every((entry) => b.includes(entry)) && b.every((entry) => a.includes(entry)),
  ) ||
  !compareArrays(
    newFilter.counties,
    oldFilter.counties,
    (a, b) => a.every((entry) => b.includes(entry)) && b.every((entry) => a.includes(entry)),
  ) ||
  !compareArrays(
    newFilter.materialIds,
    oldFilter.materialIds,
    (a, b) => a.every((entry) => b.includes(entry)) && b.every((entry) => a.includes(entry)),
  ) ||
  !compareArrays(
    newFilter.towns,
    oldFilter.towns,
    (a, b) => a.every((entry) => b.includes(entry)) && b.every((entry) => a.includes(entry)),
  )
