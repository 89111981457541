import { UserRole } from "../../../../api/graphql/graphql-global-types"

export enum LocationType {
  ASSOCIATION = "ASSOCIATION",
  TOWN = "TOWN",
  DISTRICT = "DISTRICT",
  LOCATION = "LOCATION",
}

interface ILocationDictionaryItem {
  accessor: "associations" | "towns" | "districts" | "locations"
}

export const LocationTypeDictionary: {
  [key: string]: ILocationDictionaryItem
} = {
  [LocationType.ASSOCIATION]: {
    accessor: "associations",
  },
  [LocationType.TOWN]: {
    accessor: "towns",
  },
  [LocationType.DISTRICT]: {
    accessor: "districts",
  },
  [LocationType.LOCATION]: {
    accessor: "locations",
  },
}

interface IRoleOptions {
  hasAssignedLocations: boolean
  collection: any[]
  locationType: LocationType
  possibleLocationTypes: LocationType[]
}

export const getOptionsForRole = (
  role: UserRole | undefined,
  associations: any[] | null = [],
  towns: any[] | null = [],
  districts: any[] | null = [],
  locations: any[] | null = [],
): IRoleOptions => {
  switch (role) {
    case UserRole.ASSOCIATION:
      const possibleLocationTypes = [LocationType.ASSOCIATION, LocationType.TOWN]
      if (associations && associations.length >= 1) {
        return {
          collection: associations || [],
          hasAssignedLocations: true,
          locationType: LocationType.ASSOCIATION,
          possibleLocationTypes,
        }
      } else {
        return {
          collection: towns || [],
          hasAssignedLocations: true,
          locationType: LocationType.TOWN,
          possibleLocationTypes,
        }
      }
    case UserRole.COLLECTION_PARTNER:
      return {
        collection: districts || [],
        hasAssignedLocations: true,
        locationType: LocationType.DISTRICT,
        possibleLocationTypes: [LocationType.DISTRICT],
      }
    case UserRole.DRIVER:
      return {
        collection: districts || [],
        hasAssignedLocations: true,
        locationType: LocationType.DISTRICT,
        possibleLocationTypes: [LocationType.DISTRICT],
      }
    case UserRole.GUIDED_DRIVER:
      return {
        collection: locations || [],
        hasAssignedLocations: true,
        locationType: LocationType.LOCATION,
        possibleLocationTypes: [LocationType.LOCATION],
      }
    case UserRole.SUPER_ADMIN:
      return {
        collection: [],
        hasAssignedLocations: false,
        locationType: LocationType.ASSOCIATION,
        possibleLocationTypes: [LocationType.ASSOCIATION],
      }
    case UserRole.TOWN_ADMIN:
      return {
        collection: towns || [],
        hasAssignedLocations: true,
        locationType: LocationType.TOWN,
        possibleLocationTypes: [LocationType.TOWN],
      }
    case UserRole.RFID_APP:
      return {
        collection: districts || [],
        hasAssignedLocations: true,
        locationType: LocationType.DISTRICT,
        possibleLocationTypes: [LocationType.DISTRICT],
      }
    default:
      return {
        collection: [],
        hasAssignedLocations: false,
        locationType: LocationType.ASSOCIATION,
        possibleLocationTypes: [LocationType.ASSOCIATION],
      }
  }
}
