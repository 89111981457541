import { gql } from "@apollo/client"
import { getTourExportPreviewAsExcelFile, getTourExportPreviewAsExcelFileVariables } from "./types/getTourExportPreviewAsExcelFile"

export const GET_TOUR_EXPORT_PREVIEW_AS_EXCEL_FILE_QUERY = gql`
  query getTourExportPreviewAsExcelFile($tour_export_preview: TourExportPreviewInput!) {
    getTourExportPreviewAsExcelFile(tour_export_preview: $tour_export_preview) {
      base64
      filename
    }
  }
`
export type GetTourExportPreviewAsExcelFileResult = getTourExportPreviewAsExcelFile
export type GetTourExportPreviewAsExcelFileVariables = getTourExportPreviewAsExcelFileVariables
