import { gql } from "@apollo/client"
import {
  topOverfilledCollectionPointsPerMonth,
  topOverfilledCollectionPointsPerMonthVariables,
  topOverfilledCollectionPointsPerMonth_topOverfilledCollectionPointsPerMonth,
  topOverfilledCollectionPointsPerMonth_topOverfilledCollectionPointsPerMonth_days_overfilled,
} from "./types/topOverfilledCollectionPointsPerMonth"

export const TOP_OVERFILLED_COLLECTION_POINTS_PER_MONTH_QUERY = gql`
  query topOverfilledCollectionPointsPerMonth(
    $id: ID!
    $type: RegionType!
    $startDate: DateTime!
    $endDate: DateTime!
    $rowNum: Int!
  ) {
    topOverfilledCollectionPointsPerMonth(
      id: $id
      type: $type
      startDate: $startDate
      endDate: $endDate
      rowNum: $rowNum
    ) {
      month
      year
      days_overfilled {
        collection_point_id
        days
      }
    }
  }
`

export type TopOverfilledCollectionPointsResult = topOverfilledCollectionPointsPerMonth
export type TopOverfilledCollectionPointsVariables = topOverfilledCollectionPointsPerMonthVariables
export type TopOverfilledCollectionPoints = topOverfilledCollectionPointsPerMonth_topOverfilledCollectionPointsPerMonth
export type OverfilledEntry = topOverfilledCollectionPointsPerMonth_topOverfilledCollectionPointsPerMonth_days_overfilled
