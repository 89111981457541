import { gql } from "@apollo/client"
import {
  removeDeparturePoint,
  removeDeparturePointVariables,
  removeDeparturePoint_removeDeparturePoint,
} from "./types/removeDeparturePoint"

export const REMOVE_DEPARTURE_POINT_MUTATION = gql`
  mutation removeDeparturePoint($id: ID!) {
    removeDeparturePoint(id: $id) {
      error
      message
    }
  }
`

export type RemoveDeparturePointResult = removeDeparturePoint
export type RemoveDeparturePoint = removeDeparturePoint_removeDeparturePoint
export type RemoveDeparturePointVariables = removeDeparturePointVariables
