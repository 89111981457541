import { gql } from "@apollo/client"
import {
  updatePassword,
  updatePasswordVariables,
  updatePassword_updatePassword,
} from "./types/updatePassword"

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($email: String!, $password: String!) {
    updatePassword(email: $email, password: $password) {
      id
      first_name
      last_name
      role
      email
    }
  }
`

export type UpdatePasswordResult = updatePassword
export type UpdatePassword = updatePassword_updatePassword
export type UpdatePasswordVariables = updatePasswordVariables
