import { gql } from "@apollo/client"
import {
  getCollectionPartnerWithID,
  getCollectionPartnerWithID_getCollectionPartnerWithID,
  getCollectionPartnerWithID_getCollectionPartnerWithID_districts,
} from "./types/getCollectionPartnerWithID"

export const GETCOLLECTIONPARTNERWITHID_QUERY = gql`
  query getCollectionPartnerWithID($id: ID!) {
    getCollectionPartnerWithID(id: $id) {
      id
      name
      town
      street
      postal
      email
      contact_name
      phone_number
      districts {
        id
        name
      }
      amount_vehicles
      is_deletable
      automatic_break
      automatic_break_reminder
    }
  }
`

export type CollectionPartnerResult = getCollectionPartnerWithID
export type CollectionPartner = getCollectionPartnerWithID_getCollectionPartnerWithID
export type District = getCollectionPartnerWithID_getCollectionPartnerWithID_districts
