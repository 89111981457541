import { gql } from "@apollo/client"
import {
  averageEmptyingsPerHourForDistrict,
  averageEmptyingsPerHourForDistrictVariables,
  averageEmptyingsPerHourForDistrict_averageEmptyingsPerHourForDistrict,
} from "./types/averageEmptyingsPerHourForDistrict"

export const GET_AVERAGE_EMPTYINGS_PER_HOUR_FOR_DISTRICT_QUERY = gql`
  query averageEmptyingsPerHourForDistrict($id: ID!, $startDate: DateTime!, $endDate: DateTime!) {
    averageEmptyingsPerHourForDistrict(id: $id, startDate: $startDate, endDate: $endDate) {
      emptyingsPerHour {
        month
        emptyings_per_hour
      }
      average
    }
  }
`

export type AverageEmptyingsPerHourForDistrictResult = averageEmptyingsPerHourForDistrict
export type AverageEmptyingsPerHourForDistrictVariables = averageEmptyingsPerHourForDistrictVariables
export type AverageEmptyingsPerHour = averageEmptyingsPerHourForDistrict_averageEmptyingsPerHourForDistrict
