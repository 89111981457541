import React, { FunctionComponent, useState, useEffect } from "react"
import Lightbox from "react-image-lightbox"
import { ImageType } from "../../../../api/graphql/queries/get-initial-images-with-collection-point-id"
import { CustomLightBoxTabs } from "./custom-lightbox-tabs"
import { IImage } from "../../../../utils/lightbox"

interface ICustomLightBoxProps {
  images: IImage[]
  imageTypes?: ImageType[]
  onClose: () => void
}

export const CustomLightBox: FunctionComponent<ICustomLightBoxProps> = (props) => {
  const { images, onClose, imageTypes = [] } = props

  const [imageIndex, setImageIndex] = useState<number>(0)
  const [selectedImageTypeId, setSelectedImageTypeId] = useState<string>()
  const [filteredImages, setFilteredImages] = useState<IImage[]>(images)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  const handleImageTypeChange = (newValue: string) => {
    setSelectedImageTypeId(newValue)
    setImageIndex(0)
    setFilteredImages(images.filter((image) => image.typeId === newValue))
  }

  if (!selectedImageTypeId && imageTypes[0]) {
    handleImageTypeChange(imageTypes[0].id)
  }

  return (
    <Lightbox
      mainSrc={filteredImages[imageIndex].url}
      nextSrc={
        filteredImages.length > 1 ? filteredImages[imageIndex % filteredImages.length].url || "invalid" : undefined
      }
      prevSrc={
        filteredImages.length > 1
          ? filteredImages[(imageIndex + filteredImages.length - 1) % filteredImages.length].url || "invalid"
          : undefined
      }
      onCloseRequest={onClose}
      onMovePrevRequest={() => setImageIndex((imageIndex + filteredImages.length - 1) % filteredImages.length)}
      onMoveNextRequest={() => setImageIndex((imageIndex + 1) % filteredImages.length)}
      reactModalStyle={{ overlay: { zIndex: 1300 } }}
      imageTitle={filteredImages[imageIndex].title}
      imageCaption={filteredImages[imageIndex].caption}
      toolbarButtons={[
        <CustomLightBoxTabs
          imageTypes={imageTypes}
          onSelectionChange={handleImageTypeChange}
          selectedImageType={selectedImageTypeId || "0"}
        />,
      ]}
    />
  )
}
