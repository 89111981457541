import React, { FunctionComponent, ReactNode } from "react"
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from "@material-ui/core"
import { useTranslation } from "react-i18next"

interface IInfoDialogProps {
  open: boolean
  onClose: () => void
  content?: ReactNode
  title?: ReactNode
}

export const InfoDialog: FunctionComponent<IInfoDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, content, title } = props
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <Button onClick={onClose}>{t("ok")}</Button>
      </DialogActions>
    </Dialog>
  )
}
