import { gql } from "@apollo/client"
import {
  getTownsWithRegionID,
  getTownsWithRegionIDVariables,
  getTownsWithRegionID_getTownsWithRegionID,
} from "./types/getTownsWithRegionID"

export const GET_TOWNS_WITH_REGION_ID_QUERY = gql`
  query getTownsWithRegionID($id: Int!, $type: RegionType!, $counties: [ID!]!) {
    getTownsWithRegionID(id: $id, type: $type, counties: $counties) {
      id
      name
      town_code
    }
  }
`

export type GetTownsWithRegionIDResult = getTownsWithRegionID
export type GetTownsWithRegionIDVariables = getTownsWithRegionIDVariables
export type Town = getTownsWithRegionID_getTownsWithRegionID
