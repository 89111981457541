import React, { Fragment, FunctionComponent, useCallback, useContext, useState } from "react"
import {
  Theme,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ExpandIcon } from "../expand-icon/expand-icon"
import { Close } from "@material-ui/icons"
import { DownloadItem } from "./download-item"
import { DownloadManagerContext } from "./download-manager-context"
import { ConfirmDialog } from "../layout/dialog/confirm-dialog"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    zIndex: theme.zIndex.modal + 1,
    position: "fixed",
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
  },
  actionIcon: {
    color: theme.palette.primary.contrastText,
  },
  action: {
    marginTop: 0,
    marginRight: 0,
  },
  cardContent: {
    width: 250,
    padding: theme.spacing(1),
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },

  divider: {
    marginLeft: `-${theme.spacing(2)}px`,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  },
}))

interface IDownloadManagerProps {}

export const DownloadManager: FunctionComponent<IDownloadManagerProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const { fileExports, cancelAllFileExport, cancelDialogParams, downloadsFinished } = useContext(DownloadManagerContext)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const onCancelAllFileExportsClicked = useCallback(() => {
    cancelAllFileExport()
  }, [cancelAllFileExport])

  const classes = useStyles()
  const { t } = useTranslation()

  if (!fileExports.length) {
    return null
  }

  return (
    <>
      <ConfirmDialog
        open={cancelDialogParams.open}
        heading={cancelDialogParams.heading}
        text={cancelDialogParams.text}
        confirmText={t("yes")}
        negativeText={t("no")}
        onConfirm={cancelDialogParams.confirmCallack}
        onClose={cancelDialogParams.cancelCallback}
      />
      <Card className={classes.card} data-testid="download-manager-card">
        <CardHeader
          title={
            <Typography className={classes.title}>
              {t(`export.${downloadsFinished ? "downloads_finished" : "preparing_downloads"}`)}
            </Typography>
          }
          className={classes.header}
          classes={{ action: classes.action }}
          action={
            <>
              <ExpandIcon size="small" className={classes.actionIcon} expanded={expanded} onClick={handleExpandClick} />
              <IconButton size="small" className={classes.actionIcon} onClick={onCancelAllFileExportsClicked}>
                <Close />
              </IconButton>
            </>
          }
        />
        <Collapse in={expanded} timeout="auto">
          <CardContent className={classes.cardContent}>
            {fileExports.map((fileExport, index) => (
              <Fragment key={`download_item_${fileExport.id}`}>
                <DownloadItem fileExport={fileExport} />
                {index !== fileExports.length - 1 && <Divider className={classes.divider} />}
              </Fragment>
            ))}
          </CardContent>
        </Collapse>
      </Card>
    </>
  )
}
