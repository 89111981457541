import { gql } from "@apollo/client"
import { getDistricts, getDistricts_getDistricts } from "./types/getDistricts"

export const GET_DISTRICTS_QUERY = gql`
  query getDistricts {
    getDistricts {
      id
      name
      forecastEnabled
      rfidModuleEnabled
    }
  }
`

export type DistrictsResult = getDistricts
export type District = getDistricts_getDistricts
