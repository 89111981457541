import { gql } from "@apollo/client"
import {
  getContainersWithCollectionPointId,
  getContainersWithCollectionPointIdVariables,
  getContainersWithCollectionPointId_getContainersWithCollectionPointId
} from "./types/getContainersWithCollectionPointId"

export const GET_CONTAINERS_WITH_COLLECTION_POINT_ID_QUERY = gql`
  query getContainersWithCollectionPointId($collection_point_id: ID!) {
    getContainersWithCollectionPointId(
      collection_point_id: $collection_point_id
    ) {
      id
      sensor_id
      number
      material {
        id
      }
      manufacturer {
        id
      }
      type {
        id
      }
      emptying_requested_at
      collection_point {
        accessible_with_trailer
        overfilling_allowed
      }
    }
  }
`

export type GetContainersResult = getContainersWithCollectionPointId
export type Container = getContainersWithCollectionPointId_getContainersWithCollectionPointId
export type GetContainersVariables = getContainersWithCollectionPointIdVariables
