import React, { FC, PropsWithChildren, useContext, useMemo } from "react"
import { HouseholdOverviewFilterContext } from "./household-overview-filter-context"
import {
  HOUSEHOLD_STATUSES_QUERY,
  HouseholdStatusesResult,
  HouseholdStatusesVariables,
  HouseholdWithStatus,
} from "../../../../api/graphql/queries/household-statuses"
import { useQuery } from "@apollo/client"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { useAssociationFilter } from "../../../../context/AssociationFilterContext"
import { UserService } from "../../../../services/user-service"
import { RegionType } from "../../../../api/graphql/graphql-global-types"

interface IHouseholdOverviewStatusContext {
  loading: boolean
  households: HouseholdWithStatus[]
}

export const HouseholdOverviewStatusContext = React.createContext<IHouseholdOverviewStatusContext>({
  loading: false,
  households: [],
})

export const HouseholdOverviewStatusContextProvider: FC = (props: PropsWithChildren<{}>) => {
  const { filter } = useContext(HouseholdOverviewFilterContext)
  const { selectedDistrict } = useDistrictFilter()
  const { selectedAssociation } = useAssociationFilter()

  const { data, loading, called } = useQuery<HouseholdStatusesResult, HouseholdStatusesVariables>(
    HOUSEHOLD_STATUSES_QUERY,
    {
      variables: {
        countyIds: filter.selectedCounties.map((option) => option.id),
        materialIds: filter.selectedMaterials.map((option) => option.id),
        regionType: UserService.hasAssociationFilter() ? RegionType.ASSOCIATION : RegionType.DISTRICT,
        regionId: UserService.hasAssociationFilter() ? String(selectedAssociation?.id) : String(selectedDistrict?.id),
        townIds: filter.selectedTowns.map((option) => option.id),
        address: filter.address ? filter.address : null,
        showWarnings: filter.showWarnings,
      },
      skip: (UserService.hasAssociationFilter() && !selectedAssociation?.id) || !selectedDistrict?.id,
    },
  )

  const households: HouseholdWithStatus[] = useMemo(
    () => (data?.households.filter((entry) => entry.status.color) as HouseholdWithStatus[]) || [],
    [data],
  )

  return (
    <HouseholdOverviewStatusContext.Provider value={{ loading: loading || !called, households }}>
      {props.children}
    </HouseholdOverviewStatusContext.Provider>
  )
}
