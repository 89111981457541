import React, { FunctionComponent, useContext, useMemo, useState } from "react"
import { Theme, makeStyles, Card, Grid, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Text } from "../../../partials/wrapper/text"
import { DeparturePointContext } from "../../partner-overview/partials/departure-point/context/departure-point-context"
import { DeparturePoint } from "../../../../api/graphql/queries/get-departure-points"
import { useTranslation } from "react-i18next"
import { Cancel, Check } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  autocomplete: {
    padding: "0 !important",
    paddingLeft: "4px !important",
  },
  assignedText: {
    background: "rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}))

export const formatDeparturePoint = (departurePoint?: DeparturePoint) =>
  departurePoint ? `${departurePoint.id} - ${departurePoint.name}` : ""

interface IVehicleOperationPointProps {
  id: number | null
  setId: (id: number | null) => void
  heading: string,
  error?: boolean
  setError?: (hasError: boolean) => void
}

export const VehicleOperationPoint: FunctionComponent<IVehicleOperationPointProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { heading, id, setId, error = false, setError } = props
  const { departurePoints, departurePointsLoading } = useContext(DeparturePointContext)

  const selected = useMemo(
    () => departurePoints.find((departurePoint) => departurePoint.id === id),
    [departurePoints, id],
  )

  const [inputText, setInputText] = useState<string>(formatDeparturePoint(selected))

  return (
    <Card className={classes.card}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Text bold>{heading}</Text>
        </Grid>

        <Grid item xs={12}>
          {!departurePointsLoading && departurePoints.length > 0 && (
            <Autocomplete<DeparturePoint>
              freeSolo
              loading={departurePointsLoading}
              options={departurePoints}
              getOptionLabel={formatDeparturePoint}
              classes={{
                input: classes.autocomplete,
              }}
              inputValue={inputText}
              onInputChange={(ev, value) => setInputText(value)}
              onChange={(ev: any, value: DeparturePoint | null | string) => {
                if(setError) {
                  setError(false)
                }
                if (typeof value !== "string") {
                  setId(value?.id || null)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  error={error}
                  required
                  fullWidth
                  label={t("assign")}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                  }}
                />
              )}
            />
          )}
          {!departurePointsLoading && departurePoints.length <= 0 && (
            <Text>
              {t(
                "partner_overview.departure_point.no_departure_points_available_for_collection_partner",
              )}
            </Text>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center" className={classes.assignedText}>
            {selected ? <Check /> : <Cancel />}
            <Text>
              {selected
                ? formatDeparturePoint(selected)
                : t("partner_overview.departure_point.no_departure_point_assigned").toUpperCase()}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
