/**
 * Round to nearest multiple of `to`. \
 * Use a negative value for `to` to round negative values down.
 *
 * @example
 * round(14, 10) => 10
 * round(15, 10) => 20
 * round(105, 100) => 100
 * round(150, 100) => 200
 *
 * /// negative values
 * round(-16, 10) => -20
 * round(-15, 10) => -10
 * round(-15, -10) => -20
 *
 * @param value
 * @param to defaults to 10, must not be 0
 */
 export const roundToNearest = (value: number, to = 10) => {
  return Math.round(value / to) * to
}
