import { gql } from "@apollo/client"
import { getDriverLocations, getDriverLocations_driverLocations} from "./types/getDriverLocations"

export const GET_DRIVER_LOCATIONS_QUERY = gql`
  query getDriverLocations {
    driverLocations {
      id
      name
    }
  }
`

export type DriverLocationResult = getDriverLocations
export type DriverLocation = getDriverLocations_driverLocations
