import moment from "moment"
import lodash from "lodash"
import {
  Image,
  ImageType,
} from "../api/graphql/queries/get-initial-images-with-collection-point-id"
import { StopInfoWeighing } from "../api/graphql/queries/get-tour-stats-with-id"
import i18next from "i18next"

export interface IImage {
  url: string
  title: string | null
  caption: string | null
  typeId: string
}

export const getImagesFromCollectionPointImages = (collectionPointImages: Image[]): IImage[] => {
  return collectionPointImages
    .filter((image) => !!image.image_type)
    .map((image) => ({
      url: image.url,
      title: image.sub_image_type ? (image.sub_image_type as ImageType).name : null,
      caption: moment(image.inserted_at).format(i18next.t("date_time_seconds_format")),
      typeId: lodash.get(image, "image_type.id"),
    }))
}

export const getImageTypesFromCollectionPointImages = (
  collectionPointImages: Image[],
): ImageType[] => {
  return lodash.uniqBy(
    collectionPointImages
      .filter((image) => !!image.image_type)
      .map((image) => image.image_type as ImageType),
    "id",
  )
}

export const getImagesFromWeighings = (weighings: StopInfoWeighing[]): IImage[] =>
  weighings.map((weighing) => {
    const titleString = `${i18next.t("weighings.gross")}: ${weighing.gross}t, ${i18next.t(
      "weighings.net",
    )}: ${weighing.net}t, ${i18next.t("weighings.tare")}: ${weighing.tare}t, ${i18next.t(
      "weighings.number",
    )}: ${weighing.weighing_note_number || "-"}`
    return {
      url: weighing.weighing_note_image as string,
      title: titleString,
      caption: moment(weighing.inserted_at).format(i18next.t("date_time_seconds_format")),
      typeId: "0",
    }
  })
