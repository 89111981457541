import { Divider, Grid } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import { Text } from "../../wrapper/text"

interface IDividerTextProps {}

export const DividerText: FunctionComponent<IDividerTextProps> = (props) => {
  return (
    <Grid container item xs={12} justify="space-between" alignItems="center" spacing={2}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        <Text bold>{props.children}</Text>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  )
}
