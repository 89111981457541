import React, { FunctionComponent, useState, useContext, useMemo } from "react"
import { Theme, makeStyles, Grid, Typography, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { GETMATERIALS_QUERY, Material, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { useQuery } from "@apollo/client"
import { Add, Cancel } from "@material-ui/icons"
import classnames from "classnames"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { TourGenerationContext } from "../../../../context/tour-generation-context"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    fontWeight: "bold",
  },
  addButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  noDataInfo: {
    textAlign: "center",
  },
  deleteButton: {
    cursor: "pointer",
  },
  wrapper: {
    padding: theme.spacing(2),
  },
}))

interface ITourGenerationMaterialsProps {}

export const TourGenerationMaterials: FunctionComponent<ITourGenerationMaterialsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { materials, setMaterials, tourWithoutContainers } = useContext(TourGenerationContext)

  const getNextAvailableMaterial = () => {
    if (!materialsData?.getMaterials) {
      return undefined
    }

    return materialsData.getMaterials.find(
      (material) => !materials.find((selectedMaterial) => material.id === selectedMaterial.id),
    )
  }

  const getMaterialsForSelect = (selectedMaterial: Material): SelectPair[] => {
    if (!materialsData?.getMaterials) {
      return []
    }

    return materialsData.getMaterials
      .filter(
        (material) =>
          material.id === selectedMaterial.id.toString() || !materials.find((m) => m.id.toString() === material.id),
      )
      .map((material) => new SelectPair(material.id, material.name))
  }

  const updateMaterials = (newValue: string, index: number): void => {
    const copy: Material[] = JSON.parse(JSON.stringify(materials))
    copy[index] = materialsData!.getMaterials.find((material) => material.id.toString() === newValue) as Material

    setMaterials(copy)
  }

  const disableAddButton = useMemo<boolean>(
    () => !materialsData || materials.length === materialsData.getMaterials.length || tourWithoutContainers,
    [materialsData, materials, tourWithoutContainers],
  )

  return (
    <Grid item container direction="column" spacing={2} className={classes.wrapper}>
      <Grid item container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.heading}>{t("tour_generation.data.materials")}</Typography>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            className={classes.addButton}
            onClick={() => setMaterials(materials.concat(getNextAvailableMaterial() || []))}
            disabled={disableAddButton}
            fullWidth
          >
            <Add
              className={classnames({
                [classes.addIcon]: !disableAddButton,
              })}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {materials.length ? (
          materials.map((material, index) => (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              spacing={1}
              xs={6}
              key={`material${index}`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
            >
              <Grid item xs>
                <OutlinedSelect
                  options={getMaterialsForSelect(material)}
                  name={t("tour_generation.data.material_number", { number: index + 1 })}
                  onValueChange={(newValue) => updateMaterials(newValue, index)}
                  value={material.id.toString()}
                  disabled={tourWithoutContainers}
                />
              </Grid>
              {index === hoveredIndex && !tourWithoutContainers && (
                <Grid item onClick={() => setMaterials(materials.filter((_, i) => index !== i))}>
                  <Grid item className={classes.deleteButton}>
                    <Cancel color="error" width={24} height={24} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <Grid item className={classes.noDataInfo} xs={12}>
            <Typography>{t("tour_generation.data.no_materials_selected")}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
