import { gql } from "@apollo/client"
import {
  removeDisposalMerchant,
  removeDisposalMerchantVariables,
  removeDisposalMerchant_removeDisposalMerchant
} from "./types/removeDisposalMerchant"

export const REMOVE_DISPOSAL_MERCHANT_MUTATION = gql`
  mutation removeDisposalMerchant($id: ID!) {
    removeDisposalMerchant(id: $id) {
      error
    }
  }
`

export type RemoveDisposalMerchantResult = removeDisposalMerchant
export type RemoveDisposalMerchant = removeDisposalMerchant_removeDisposalMerchant
export type RemoveDisposalMerchantVariables = removeDisposalMerchantVariables
