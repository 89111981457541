import { gql } from "@apollo/client"
import { resetDatabase } from "./types/resetDatabase"

export const RESET_DATABASE_QUERY = gql`
  query resetDatabase {
    resetDatabase {
      error
      message
    }
  }
`

export type ResetDatabaseResult = resetDatabase
