import React, { FunctionComponent, useState, useEffect, useCallback, useContext } from "react"
import {
  Theme,
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Grid,
  TextField,
  Card,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { SearchMap } from "../../../../partials/maps/search-map"
import { defaultLocation, toGeoFloat } from "../../../../../utils/map"
import { useMutation, useQuery } from "@apollo/client"
import { DisposalTrailerLocationContext } from "./context/disposal-trailer-location-context"
import { toast } from "react-toastify"
import { DisposalTrailerLocation } from "../../../../../api/graphql/queries/get-disposal-trailer-locations-by-collection-partner"
import {
  CreateDisposalTrailerLocationResult,
  CreateDisposalTrailerLocationVariables,
  CREATE_DISPOSAL_TRAILER_LOCATION_MUTATION,
} from "../../../../../api/graphql/mutations/create-disposal-trailer-location"
import {
  UpdateDisposalTrailerLocationResult,
  UpdateDisposalTrailerLocationVariables,
  UPDATE_DISPOSAL_TRAILER_LOCATION_MUTATION,
} from "../../../../../api/graphql/mutations/update-disposal-trailer-location"
import { DistrictsResult, GET_DISTRICTS_QUERY } from "../../../../../api/graphql/queries/get-districts"
import { useDistrictFilter } from "../../../../../context/DistrictFilterContext"
import { DistrictSelect } from "../../../../partials/region-select/district-select"

const useStyles = makeStyles((theme: Theme) => ({
  searchMap: {
    padding: theme.spacing(2),
  },
}))

interface IDisposalTrailerLocationManagementDialogProps {
  open: boolean
  onClose: () => void
  disposalTrailerLocation?: DisposalTrailerLocation
}

export const DisposalTrailerLocationManagementDialog: FunctionComponent<IDisposalTrailerLocationManagementDialogProps> = (
  props,
) => {
  const { open, onClose, disposalTrailerLocation } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [openToggle, setOpenToggle] = useState<boolean>(false)
  const { partnerId, refetchDisposalTrailerLocations } = useContext(DisposalTrailerLocationContext)
  const { selectedDistrict, setDistrictId } = useDistrictFilter()

  const [name, setName] = useState("")
  const [lat, setLat] = useState<string>(`${defaultLocation.latitude}`)
  const [long, setLong] = useState<string>(`${defaultLocation.longitude}`)

  const customOnClose = useCallback(() => {
    onClose()
    setName("")
    setLat(`${defaultLocation.latitude}`)
    setLong(`${defaultLocation.longitude}`)
    setOpenToggle(false)
  }, [setName, setLat, setLong, setOpenToggle, onClose])

  const { data: districtsData } = useQuery<DistrictsResult>(GET_DISTRICTS_QUERY, {
    onError: () => toast.error(t("generic_error")),
  })

  const [createDisposalTrailerLocationMutation, { loading: createLoading }] = useMutation<
    CreateDisposalTrailerLocationResult,
    CreateDisposalTrailerLocationVariables
  >(CREATE_DISPOSAL_TRAILER_LOCATION_MUTATION, {
    onCompleted: (data) => {
      switch (data?.createDisposalTrailerLocation.__typename) {
        case "DisposalTrailerLocation":
          toast.success(t("partner_overview.disposal_trailer_location.success.created"))
          customOnClose()
          refetchDisposalTrailerLocations()
          break
        case "DistrictNotFoundError":
          toast.error(t("api_errors.not_found", { entity: t("district") }))
          break
        case "CollectionPartnerNotFoundError":
          toast.error(t("api_errors.not_found", { entity: t("partner") }))
          break
        case "DisposalTrailerLocationDuplicateError":
          toast.error(t("partner_overview.disposal_trailer_location.errors.duplicate"))
          break
        default:
          if (data?.createDisposalTrailerLocation) {
            toast.error(t("generic_error"))
          }
      }
    },
  })

  const [updateDisposalTrailerLocationMutation, { loading: updateLoading }] = useMutation<
    UpdateDisposalTrailerLocationResult,
    UpdateDisposalTrailerLocationVariables
  >(UPDATE_DISPOSAL_TRAILER_LOCATION_MUTATION, {
    onCompleted: (data) => {
      switch (data?.updateDisposalTrailerLocation.__typename) {
        case "DisposalTrailerLocation":
          toast.success(t("partner_overview.disposal_trailer_location.success.updated"))
          customOnClose()
          refetchDisposalTrailerLocations()
          break
        case "DistrictNotFoundError":
          toast.error(t("api_errors.not_found", { entity: t("district") }))
          break
        case "CollectionPartnerNotFoundError":
          toast.error(t("api_errors.not_found", { entity: t("partner") }))
          break
        case "DisposalTrailerLocationNotFoundError":
          toast.error(t("api_errors.not_found", { entity: t("trailer_location") }))
          break
        case "DisposalTrailerLocationDuplicateError":
          toast.error(t("partner_overview.disposal_trailer_location.errors.duplicate"))
          break
        default:
          if (data?.updateDisposalTrailerLocation) {
            toast.error(t("generic_error"))
          }
      }
    },
  })

  useEffect(() => {
    if (open && disposalTrailerLocation && !openToggle && districtsData) {
      setName(disposalTrailerLocation.name)
      setLat(`${disposalTrailerLocation.latitude}`)
      setLong(`${disposalTrailerLocation.longitude}`)
      setDistrictId(disposalTrailerLocation.district_id)
      setOpenToggle(true)
    }
  }, [open, disposalTrailerLocation, openToggle, districtsData, setDistrictId])

  const validateName = (name: string) => name.length > 0
  const validateCoordinate = (coordinate: string) => !isNaN(parseFloat(coordinate))
  const validateDistrict = (districtId?: string) => districtId && districtId.length > 0
  const valid = useCallback(() => {
    return (
      validateName(name) &&
      validateCoordinate(lat) &&
      validateCoordinate(long) &&
      validateDistrict(selectedDistrict?.id)
    )
  }, [name, lat, long, selectedDistrict])

  const onCreate = useCallback(() => {
    if (partnerId && valid()) {
      createDisposalTrailerLocationMutation({
        variables: {
          disposal_trailer_location: {
            name,
            latitude: toGeoFloat(lat),
            longitude: toGeoFloat(long),
            collection_partner_id: `${partnerId}`,
            district_id: selectedDistrict?.id || "",
          },
        },
      })
    }
  }, [createDisposalTrailerLocationMutation, valid, partnerId, name, lat, long, selectedDistrict])

  const onSave = useCallback(() => {
    if (partnerId && disposalTrailerLocation?.id && valid()) {
      updateDisposalTrailerLocationMutation({
        variables: {
          disposal_trailer_location_id: disposalTrailerLocation.id,
          disposal_trailer_location: {
            name,
            latitude: toGeoFloat(lat),
            longitude: toGeoFloat(long),
            district_id: selectedDistrict?.id || disposalTrailerLocation.district_id,
          },
        },
      })
    }
  }, [
    partnerId,
    updateDisposalTrailerLocationMutation,
    valid,
    name,
    lat,
    long,
    disposalTrailerLocation,
    selectedDistrict,
  ])

  return (
    <Dialog open={open} onClose={customOnClose}>
      <DialogTitle>
        {!!disposalTrailerLocation?.id
          ? disposalTrailerLocation.name
          : t("partner_overview.disposal_trailer_location.create_disposal_trailer_location")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label={t("disposal_trailer_location.data.name")}
              fullWidth
              variant="outlined"
              value={name}
              onChange={(ev) => {
                setName(ev.target.value)
              }}
              error={!validateName(name)}
            />
          </Grid>
          <Grid item xs={6}>
            <DistrictSelect />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("disposal_trailer_location.data.lat")}
              fullWidth
              variant="outlined"
              value={lat}
              onChange={(ev) => {
                setLat(ev.target.value)
              }}
              error={!validateCoordinate(lat)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("disposal_trailer_location.data.long")}
              fullWidth
              variant="outlined"
              value={long}
              onChange={(ev) => {
                setLong(ev.target.value)
              }}
              error={!validateCoordinate(long)}
            />
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.searchMap}>
              <SearchMap lat={lat} long={long} setLat={setLat} setLong={setLong} />
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={customOnClose} disabled={createLoading || updateLoading}>
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={!!disposalTrailerLocation?.id ? onSave : onCreate}
          disabled={createLoading || updateLoading || !valid()}
        >
          {!!disposalTrailerLocation?.id ? t("save") : t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
