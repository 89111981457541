export const getFormattedSensorId = (sensorId: string | null) => {
  if (!sensorId) {
    return ""
  }

  return sensorId
    .replace(/-/g, "")
    .split("")
    .map((char, index) => (index !== 0 && index % 2 === 0 ? `-${char}` : char))
    .join("")
}
