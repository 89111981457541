import React, { FunctionComponent, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { useQuery } from "@apollo/client"
import {
  DisposalTrailerLocation,
  DISPOSALTRAILERLOCATIONS_QUERY,
  DisposalTrailerLocationsResult,
} from "../../../../api/graphql/queries/get-disposal-trailer-locations"
import { AlgorithmType } from "../../../../api/graphql/graphql-global-types"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"

interface ITourGenerationDisposalTrailerLocationsProps {}

export const TourGenerationDisposalTrailerLocations: FunctionComponent<ITourGenerationDisposalTrailerLocationsProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { version, district, disposalTrailerLocations, setDisposalTrailerLocations } = useContext(TourGenerationContext)

  const trailerQueryCompleted = () => {
    if (
      district?.disposalTrailerLocationLimit === 1 &&
      disposalTrailerLocationsData?.disposalTrailerLocationsByDistrict[0]
    ) {
      setDisposalTrailerLocations([disposalTrailerLocationsData.disposalTrailerLocationsByDistrict[0]])
    } else {
      setDisposalTrailerLocations(disposalTrailerLocationsData?.disposalTrailerLocationsByDistrict || [])
    }
  }

  const { data: disposalTrailerLocationsData } = useQuery<DisposalTrailerLocationsResult>(
    DISPOSALTRAILERLOCATIONS_QUERY,
    {
      variables: { district_id: district ? district.id : 0 },
      skip: !district,
      onCompleted: trailerQueryCompleted,
    },
  )

  const mappedDisposalTrailerLocations = useMemo(() => {
    return (
      disposalTrailerLocationsData?.disposalTrailerLocationsByDistrict.map(
        (disposalTrailerLocation) => new SelectOption(String(disposalTrailerLocation.id), disposalTrailerLocation.name),
      ) || []
    )
  }, [disposalTrailerLocationsData])

  const setSelectedDisposalTrailers = (selectedOptions: SelectOption[]) => {
    const selectedDisposalTrailers = disposalTrailerLocationsData?.disposalTrailerLocationsByDistrict.filter(
      (disposalTrailerLocation) => selectedOptions.map((x: SelectOption) => x.id).includes(disposalTrailerLocation.id),
    )
    setDisposalTrailerLocations(selectedDisposalTrailers ?? [])
  }

  const disposalTrailersSelectPair = useMemo(() => {
    return (
      disposalTrailerLocationsData?.disposalTrailerLocationsByDistrict.map(
        (disposalTrailerLocation) =>
          new SelectPair(disposalTrailerLocation.id.toString(), disposalTrailerLocation.name),
      ) || []
    )
  }, [disposalTrailerLocationsData])

  const multipleChoiceError = useMemo(() => {
    if (district && district.disposalTrailerLocationLimit) {
      return disposalTrailerLocations.length > district?.disposalTrailerLocationLimit!
    }

    return false
  }, [disposalTrailerLocations, district])

  if (disposalTrailerLocations.length >= 0 && version === AlgorithmType.TO2TRAILER) {
    if (district?.disposalTrailerLocationLimit === 1) {
      return (
        <OutlinedSelect
          disabled={!disposalTrailersSelectPair?.length}
          options={disposalTrailersSelectPair}
          name={t("tour_generation.data.disposal_trailer_locations")}
          onValueChange={(value) => {
            if (disposalTrailerLocationsData) {
              setDisposalTrailerLocations(
                [
                  disposalTrailerLocationsData.disposalTrailerLocationsByDistrict.find(
                    (d) => d.id.toString() === value,
                  )!,
                ] || [],
              )
            }
          }}
          value={disposalTrailerLocations?.length ? disposalTrailerLocations[0].id.toString() : ""}
        />
      )
    }

    return (
      <CustomAutocomplete
        error={multipleChoiceError}
        helperText={
          multipleChoiceError
            ? t("tour_generation.data.error_message", { number: district?.disposalTrailerLocationLimit! })
            : ""
        }
        id="tour-generation-disposal-trailers"
        title={t("tour_generation.data.disposal_trailer_locations")}
        currentlySelectedValues={disposalTrailerLocations.map(
          (disposalTrailerLocation: DisposalTrailerLocation) =>
            new SelectOption(String(disposalTrailerLocation.id), disposalTrailerLocation.name),
        )}
        availableSelectOptions={mappedDisposalTrailerLocations}
        setCurrentlySelectedValues={setSelectedDisposalTrailers}
        disabled={!mappedDisposalTrailerLocations.length}
      />
    )
  }
  return null
}
