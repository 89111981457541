import { gql } from "@apollo/client"
import {
  getMinimalRegionStats,
  getMinimalRegionStatsVariables,
} from "./types/getMinimalRegionStats"

export const GET_MINIMAL_REGION_STATS_QUERY = gql`
  query getMinimalRegionStats(
    $id: Int!
    $type: RegionType!
    $filter: RegionFilterInput
  ) {
    getMinimalRegionStats(
      id: $id
      type: $type
      filter: $filter
    ) {
      id
      type
      container_group_infos {
        id
        name
        avg_fill
        amount
      }
      next_emptying {
        emptying_date
        partner
      }
      last_emptying {
        emptying_date
        partner
      }
      collection_amount
      container_notifications {
        type
        collection_point_id
        container_id
        sensor_time_send
        value
        additional_field
      }
    }
  }
`

export type MinimalRegionStatsResult = getMinimalRegionStats
export type GetMinimalRegionStatsVariables = getMinimalRegionStatsVariables
