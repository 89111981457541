import React, { FunctionComponent, Fragment } from "react"
import { useTranslation } from "react-i18next"

import { InfoCard } from "./info-card"
import { Info } from "./info"
import { CollectionPartner } from "../../../../api/graphql/queries/get-region-stats"
import { CardInfoHelper } from "../../../../utils/CardInfoHelper"
import { UserService } from "../../../../services/user-service"

interface IPartnerStatsProps {
  partner: CollectionPartner
}

export const PartnerStats: FunctionComponent<IPartnerStatsProps> = (props) => {
  const { t } = useTranslation()
  const { partner } = props

  const getReadableAmount = (amount: number) => {
    return amount < 0 ? 0 : amount
  }

  return (
    <Fragment>
      <InfoCard
        header={t("collection_points.info_cards.partners.title", {
          name: partner.name,
        })}
      >
        <Info
          amount={String(getReadableAmount(partner.amount_vehicles))}
          description={t("collection_points.info_cards.partners.amount_vehicles", {
            count: partner.amount_vehicles,
          })}
        />
        <Info
          amount={String(getReadableAmount(partner.amount_regions))}
          description={t("collection_points.info_cards.partners.amount_regions", {
            count: partner.amount_regions,
          })}
        />
        <Info
          amount={String(getReadableAmount(partner.amount_emptied_containers))}
          description={`${t(
            "collection_points.info_cards.partners.amount_emptied_containers",
          )} ${CardInfoHelper.getLastMonthString()}`}
        />
        {partner.amount_hours > 0 && (
          <Info
            amount={String(
              getReadableAmount(partner.amount_hours).toLocaleString(UserService.getLanguage(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
            )}
            description={`${t(
              "collection_points.info_cards.partners.amount_hours",
            )} ${CardInfoHelper.getLastMonthString()}`}
          />
        )}
      </InfoCard>
    </Fragment>
  )
}
