import React, { useContext, useMemo } from "react"
import { HouseholdOverviewStatusContext } from "../context/household-overview-status-context"
import { Card, Grid, Theme, Typography, makeStyles } from "@material-ui/core"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  gridCardItem: {
    display: "flex",
  },
  card: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    minWidth: 270,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "flex",
  },
  subLoader: {
    ...theme.typography.subtitle1,
  },
  h6Loader: {
    ...theme.typography.h6,
  },
}))

interface IHouseholdOverviewStatusCardsProps {}

export const HouseholdOverviewStatusCards: React.FC<IHouseholdOverviewStatusCardsProps> = (props) => {
  const { households, loading } = useContext(HouseholdOverviewStatusContext)
  const { materialOptions } = useContext(HouseholdOverviewFilterContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const { materialContainerDictionary, amountChipped } = useMemo(() => {
    const dict: { [key: string]: number } = {}

    let amountContainers = 0
    let amountIntendedContainers = 0

    households.forEach((household) =>
      household.status.materialStatus.forEach((status) => {
        dict[status.materialId] = (dict[status.materialId] || 0) + status.amountContainers

        amountContainers += status.amountContainers
        amountIntendedContainers += status.amountIntendedContainers
      }),
    )

    return {
      materialContainerDictionary: dict,
      amountChipped: Math.round((amountContainers / amountIntendedContainers) * 100),
    }
  }, [households])

  return (
    <Grid container justify="center" spacing={3}>
      <Grid item className={classes.gridCardItem}>
        <Card className={classes.card}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={120} className={classes.subLoader} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.collection_points")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={60} className={classes.h6Loader} />}
              {!loading && <Typography variant="h6">{households.length}</Typography>}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={120} className={classes.subLoader} />}
              {!loading && <Typography variant="subtitle1">{t("household_overview.status.total")}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className={classes.gridCardItem}>
        <Card className={classes.card}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={150} className={classes.subLoader} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.amount_chambers")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={100} className={classes.h6Loader} />}
              {!loading && (
                <Typography variant="h6">{Object.values(materialContainerDictionary).join(" | ")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={210} className={classes.subLoader} />}
              {!loading && (
                <Typography variant="subtitle1">
                  {Object.keys(materialContainerDictionary)
                    .map((materialId) => materialOptions.find((option) => option.id === materialId)?.name)
                    .join(" | ")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item className={classes.gridCardItem}>
        <Card className={classes.card}>
          <Grid container direction="column" alignItems="center" justify="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={120} className={classes.subLoader} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.amount_containers_chipped")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={80} className={classes.h6Loader} />}
              {!loading && <Typography variant="h6">{amountChipped}%</Typography>}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
