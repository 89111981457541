import { gql } from "@apollo/client"
import { addRouteStopEvent, addRouteStopEventVariables } from "./types/addRouteStopEvent"

export const ADD_ROUTE_STOP_EVENT = gql`
  mutation addRouteStopEvent($routeStopId: ID!, $routeEventType: EventType!, $eventInfo: String!, $documentationImages: [Upload!]!) {
    addRouteStopEvent(
      routeStopId: $routeStopId
      routeEventType: $routeEventType
      eventInfo: $eventInfo
      documentationImages: $documentationImages
    ) {
      message
    }
  }
`
export type AddRouteStopEventResult = addRouteStopEvent
export type AddRouteStopEventVariables = addRouteStopEventVariables
