import { gql } from "@apollo/client"
import {
  updateOpeningHour,
  updateOpeningHourVariables,
  updateOpeningHour_updateOpeningHour
} from "./types/updateOpeningHour"

export const UPDATE_OPENING_HOUR_MUTATION = gql`
  mutation updateOpeningHour($id: ID!, $opening_hour: OpeningHourInput!) {
    updateOpeningHour(id: $id, opening_hour: $opening_hour) {
      id
    }
  }
`

export type UpdateOpeningHourResult = updateOpeningHour
export type UpdateOpeningHour = updateOpeningHour_updateOpeningHour
export type UpdateOpeningHourVariables = updateOpeningHourVariables
