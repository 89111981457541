import { gql } from "@apollo/client"
import {
  disposalTrailerLocationsByCollectionPartner,
  disposalTrailerLocationsByCollectionPartnerVariables,
  disposalTrailerLocationsByCollectionPartner_disposalTrailerLocationsByCollectionPartner,
} from "./types/disposalTrailerLocationsByCollectionPartner"

export const GET_DISPOSAL_TRAILER_LOCATIONS_BY_COLLECTION_PARTNER = gql`
  query disposalTrailerLocationsByCollectionPartner($collection_partner_id: ID!) {
    disposalTrailerLocationsByCollectionPartner(collection_partner_id: $collection_partner_id) {
      id
      latitude
      longitude
      name
      district_id
    }
  }
`

export type DisposalTrailerLocationsByCollectionPartnerResult = disposalTrailerLocationsByCollectionPartner
export type DisposalTrailerLocationsByCollectionPartnerVariables = disposalTrailerLocationsByCollectionPartnerVariables
export type DisposalTrailerLocation = disposalTrailerLocationsByCollectionPartner_disposalTrailerLocationsByCollectionPartner
