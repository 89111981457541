import { useQuery } from "@apollo/client"
import { SelectOption } from "../components/partials/customselect/custom-select-component-commons"
import { useMemo, useState } from "react"
import { GETMATERIALS_QUERY, MaterialsResult } from "../api/graphql/queries/get-materials"

export const useMaterialFilter = (onQueryCompleted: (data: MaterialsResult) => void) => {
  const { data, loading, error } = useQuery<MaterialsResult>(GETMATERIALS_QUERY, {
    fetchPolicy: "cache-first",
    onCompleted: onQueryCompleted,
  })

  const [selectedMaterials, setSelectedMaterials] = useState<SelectOption[]>([])
  const options = useMemo(
    () => data?.getMaterials.map((material) => new SelectOption(material.id, material.name)) || [],
    [data],
  )

  return {
    options,
    selectedMaterials,
    setSelectedMaterials,
    loading,
    error,
  }
}
