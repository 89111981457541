import React, { FunctionComponent } from "react"
import {
  Theme,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { tmpNames } from "../../../../utils/tempNamelist"

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%"
  }
}))

interface ITmpVehicleDriverSelectProps {
  driver: string | null
  updateDriver: (driver: string | null) => void
}

export const TmpVehicleDriverSelect: FunctionComponent<ITmpVehicleDriverSelectProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { driver, updateDriver } = props

  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useRef<HTMLLabelElement>(null)
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth)
  }, [])

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor="outlined-select">
        {t("vehicle_overview.data.driver")}
      </InputLabel>
      <Select
        value={driver || ""}
        onChange={event => updateDriver((event.target.value as string) || null)}
        inputProps={{
          name: "driver",
          id: "outlined-select"
        }}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="outlined-input"
            id="outlined-input"
          />
        }
      >
        <MenuItem value="">
          <em>{t("vehicle_overview.data.no_driver")}</em>
        </MenuItem>
        {tmpNames.map(name => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
