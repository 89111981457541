import { useMutation } from "@apollo/client"
import { Button, CircularProgress, Grid, IconButton, makeStyles, TextField, Theme, Tooltip } from "@material-ui/core"
import { Check, Delete, OpenInNew } from "@material-ui/icons"
import moment from "moment"
import React, { Fragment, FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { EventType } from "../../../../api/graphql/graphql-global-types"
import {
  AddRouteStopEventResult,
  AddRouteStopEventVariables,
  ADD_ROUTE_STOP_EVENT,
} from "../../../../api/graphql/mutations/add-route-stop-event"
import {
  RemoveRouteStopEventInfoResult,
  RemoveRouteStopEventInfoVariables,
  REMOVE_ROUTE_STOP_EVENT_INFO,
} from "../../../../api/graphql/mutations/remove-route-stop-event-info"
import { RouteStop } from "../../../../api/graphql/queries/get-route"
import { useRefetch } from "../../../../context/refetch-context"
import { IImage } from "../../../../utils/lightbox"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { CustomLightBox } from "../../../partials/layout/lightbox/custom-lightbox"
import { Text } from "../../../partials/wrapper/text"

const useStyles = makeStyles((theme: Theme) => ({
  infoWindow: {
    minHeight: 10,
    width: 350,
  },
  propertyName: {
    width: 50,
  },
  propertyContainer: {
    alignItems: "center",
    marginBottom: 0,
  },
  outerContainer: {
    marginRight: 20,
  },
  buttonContainer: {
    alignItems: "center",
    justify: "flex-end",
    marginRight: 50,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  infoText: {
    marginTop: 12
  },
  addButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`,
  },
}))

interface IRouteDetailsMapInfoWindowProps {
  stop: RouteStop
}

const propNameSpacing = 5
const propValueSpacing = 7

export const RouteDetailsMapInfoWindow: FunctionComponent<IRouteDetailsMapInfoWindowProps> = (props) => {
  const { stop } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [showImages, setShowImages] = useState<boolean>(false)
  const [selectedInfoId, setSelectedInfoId] = useState<string | undefined>()
  const [newInfo, setNewInfo] = useState<string | undefined>(undefined)
  const [infoToDelete, setInfoToDelete] = useState<string | undefined>(undefined)
  const { setNeedToRefetch } = useRefetch()

  const [addRouteStopEventQuery, { loading: updateLoading }] = useMutation<
    AddRouteStopEventResult,
    AddRouteStopEventVariables
  >(ADD_ROUTE_STOP_EVENT, {
    onCompleted: () => onMutationComplete(t("routes_management.add_stop_info")),
    onError: () => toast.error(t("routes_management.errors.add_stop_info")),
  })

  const [removeRouteStopEventInfo] = useMutation<RemoveRouteStopEventInfoResult, RemoveRouteStopEventInfoVariables>(
    REMOVE_ROUTE_STOP_EVENT_INFO,
    {
      onCompleted: () => onDeleteMutationComplete(t("routes_management.delete_stop_info")),
      onError: () => toast.error(t("routes_management.errors.delete_stop_info")),
    },
  )

  const onMutationComplete = (message: string) => {
    toast.info(message)
    setNeedToRefetch(true)
  }

  const onDeleteMutationComplete = (message: string) => {
    setIsDeleteModalOpen(false)
    onMutationComplete(message)
  }

  const saveInfo = () => {
    if (!newInfo || !newInfo.trim().length) {
      toast.error(t("routes_management.errors.no_info_provided"))
      return
    }
    addRouteStopEventQuery({
      variables: {
        routeStopId: stop.id,
        routeEventType: EventType.BULK,
        eventInfo: newInfo,
        documentationImages: []
      },
    })
  }

  const onDeleteStopEventClicked = (id: string) => {
    setInfoToDelete(id)
    setIsDeleteModalOpen(true)
  }

  const deleteStopEvent = () => {
    removeRouteStopEventInfo({ variables: { routeStopEventId: infoToDelete as string } })
  }
  
  const getImagesFromStopEvents = (): IImage[] => {
    const event = stop.events.find(info => info.id === selectedInfoId)
    if(!event || !event.images){
      return []
    }

    return event.images.map((image) => ({
      url: image.url,
      title: event.info,
      caption: moment(image.inserted_at).format(t("date_time_seconds_format")),
      typeId: "",
    }))
  }

  const getInfoUiElement = () => {
    const eventsWithRelevantInfos =  stop.events.filter(event => event && event.event_type === EventType.BULK && event.info)
    if (eventsWithRelevantInfos.length) {
      const eventInfo = eventsWithRelevantInfos[0]
      return (
        <Fragment>
          <Grid item xs={7}>
            <Text bold={false}>{eventInfo.info}</Text>
          </Grid>
          <Grid item xs={2}>
            {eventInfo.images && eventInfo.images.length > 0 && (
              <Tooltip placement="top" title={t("routes_management.open_images") as string}>
                <IconButton
                  onClick={() => {
                    setSelectedInfoId(eventInfo.id)
                    setShowImages(true)
                  }}
                >
                  <OpenInNew />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={t("routes_management.delete") as string}>
              <IconButton onClick={(e) => onDeleteStopEventClicked(eventInfo.id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Grid>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Grid container direction="row" alignItems="flex-end" justify="center">
            <Grid item xs={12}>
              <TextField
                id="addInfo"
                value={newInfo}
                onChange={(ev) => {
                  setNewInfo(ev.target.value)
                }}
                required
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.buttonContainer}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <Button type="button" variant="outlined" className={classes.addButton} onClick={saveInfo} fullWidth>
                {!updateLoading && <Check className={classes.icon} />}
                {updateLoading && <CircularProgress />}
              </Button>
            </Grid>
          </Grid>
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      {showImages && (
        <CustomLightBox
          images={getImagesFromStopEvents()}
          onClose={() => {
            setSelectedInfoId(undefined)
            setShowImages(false)
          }}
        />
      )}
      <DeleteDialog
        open={isDeleteModalOpen}
        onClose={() => {
          setInfoToDelete(undefined)
          setIsDeleteModalOpen(false)
        }}
        content={t("routes_management.confirm_delete_stop_info_text")}
        onDelete={deleteStopEvent}
      />
      <Grid container className={classes.infoWindow}>
        <Grid container direction="column" spacing={0}>
          <Grid container justify="center" alignItems="center" direction="row" className={classes.outerContainer}>
            <Grid container className={classes.propertyContainer}>
              <Grid item xs={propNameSpacing}>
                <Text bold className={classes.propertyName}>
                  {t("routes_management.table.lat").toUpperCase()}
                </Text>
              </Grid>
              <Grid item xs={propValueSpacing}>
                <Text bold={false}>{stop.latitude}</Text>
              </Grid>
            </Grid>
            <Grid container className={classes.propertyContainer}>
              <Grid item xs={propNameSpacing}>
                <Text bold className={classes.propertyName}>
                  {t("routes_management.table.long").toUpperCase()}
                </Text>
              </Grid>
              <Grid item xs={propValueSpacing}>
                <Text bold={false}>{stop.longitude}</Text>
              </Grid>
            </Grid>
            <Grid container className={classes.propertyContainer}>
              <Grid container justify="flex-start" direction="row">
                <Grid item xs={propNameSpacing} className={classes.infoText}>
                  <Text bold className={classes.propertyName}>
                    {t("routes_management.table.info").toUpperCase()}
                  </Text>
                </Grid>
                <Grid item xs={propValueSpacing}>
                  <Grid container className={classes.outerContainer} direction="row" alignItems="center">
                    {getInfoUiElement()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  )
}
