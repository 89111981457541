import { gql } from "@apollo/client"
import {
  getAverageDaysOverfilledForRegion,
  getAverageDaysOverfilledForRegionVariables,
  getAverageDaysOverfilledForRegion_getAverageDaysOverfilledForRegion,
} from "./types/getAverageDaysOverfilledForRegion"

export const GET_AVERAGE_DAYS_OVERFILLED_FOR_REGION_QUERY = gql`
  query getAverageDaysOverfilledForRegion(
    $id: ID!
    $type: RegionType!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getAverageDaysOverfilledForRegion(id: $id, type: $type, startDate: $startDate, endDate: $endDate) {
      averageDaysPerMonth {
        month
        year
        days
      }
      average
    }
  }
`

export type AverageDaysOverfilledForRegionResult = getAverageDaysOverfilledForRegion
export type AverageDaysOverfilledForRegionVariables = getAverageDaysOverfilledForRegionVariables
export type AverageDaysOverfilled = getAverageDaysOverfilledForRegion_getAverageDaysOverfilledForRegion