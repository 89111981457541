import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react"
import { Grid } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../partials/layout/selection/outlined-select"
import { useAssociationFilter } from "../../../context/AssociationFilterContext"
import { SelectPair } from "../layout/selection/select-pair"
import { CustomSnackBarContent } from "../../partials/wrapper/CustomSnackBarContent"
import { Skeleton } from "@material-ui/lab"
import { Association } from "../../../api/graphql/queries/get-associations"

interface IAssociationSelectProps {
  filterOption?: (entry: Association) => boolean
}

export const AssociationSelect: FunctionComponent<IAssociationSelectProps> = (props) => {
  const { filterOption } = props
  const { t } = useTranslation()
  const {
    setAssociationId,
    selectedAssociation,
    associations,
    associationError,
    associationsLoading,
  } = useAssociationFilter()

  const options = useMemo(() => {
    if (filterOption) {
      return associations
        .filter(filterOption)
        .map((association) => new SelectPair(association.id.toString(), association.name))
    }
    return associations.map((association) => new SelectPair(association.id.toString(), association.name))
  }, [associations, filterOption])

  useEffect(() => {
    if (selectedAssociation && options.length && !options.find((o) => o.value === selectedAssociation.id)) {
      setAssociationId(options[0].value)
    }
  }, [options, selectedAssociation, setAssociationId])

  const handleAssociationChange = useCallback(
    (newId: string) => {
      const association = options.find((association) => association.value === newId)
      if (!!association) {
        setAssociationId(association.value)
      }
    },
    [options, setAssociationId],
  )

  if (associationError) {
    return <CustomSnackBarContent variant="error" message={t("errors.generic")} />
  }
  if (associationsLoading) {
    return (
      <Grid container justify="center" alignItems="center">
        <Skeleton height={50} width="100%" />
      </Grid>
    )
  }

  return (
    <OutlinedSelect
      options={options}
      name={t("association")}
      onValueChange={(newValue) => handleAssociationChange(newValue)}
      value={selectedAssociation?.id || ""}
    />
  )
}
