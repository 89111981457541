import { gql } from "@apollo/client"
import { householdContainersExport, householdContainersExportVariables } from "./types/householdContainersExport"

// yes, plural in english is statuses.
// https://www.merriam-webster.com/dictionary/status
// https://www.dictionary.com/browse/status

export const HOUSEHOLD_CONTAINERS_EXPORT_QUERY = gql`
  query householdContainersExport(
    $regionId: ID!
    $regionType: RegionType!
    $countyIds: [ID!]!
    $address: String
    $materialIds: [ID!]!
    $townIds: [ID!]!
    $showWarnings: Boolean!
  ) {
    householdContainersExport(
      regionId: $regionId
      regionType: $regionType
      countyIds: $countyIds
      address: $address
      materialIds: $materialIds
      townIds: $townIds
      showWarnings: $showWarnings
    )
  }
`

export type HouseholdContainersExportResult = householdContainersExport
export type HouseholdContainersExportVariables = householdContainersExportVariables
