import React, { FunctionComponent } from "react"
import { Dialog, DialogContent, DialogActions, Button, DialogContentText, DialogTitle } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

interface ILogoutDialogProps {
  open: boolean
  onClose: TFunction
  onLogout: TFunction
  showOpenDownloadsWarning: boolean
}

export const LogoutDialog: FunctionComponent<ILogoutDialogProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t("logout.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.showOpenDownloadsWarning ? <b>{t("logout.open_downloads_text")}</b> : t("logout.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {t("no")}
        </Button>
        <Button onClick={props.onLogout} color="primary" autoFocus>
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
