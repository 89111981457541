import React, { FunctionComponent } from "react"
import { CollectionPointAdministration } from "./partials/collection-point-administration"
import { RefetchProvider } from "../../../context/refetch-context"
import { CollectionPointAdministrationProvider } from "./collection-point-administration-context"
import { useRouteMatch } from "react-router-dom"
import { PATH } from "../../../router/router"
import lodash from "lodash"

interface ICollectionPointAdministrationPageProps {}

export const CollectionPointAdministrationPage: FunctionComponent<ICollectionPointAdministrationPageProps> = (
  props,
) => {
  const match = useRouteMatch()
  const collectionPointId =
    match?.path !== PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route
      ? lodash.get(match, "params.collectionPointId")
      : undefined

  return (
    <RefetchProvider>
      <CollectionPointAdministrationProvider collectionPointId={collectionPointId}>
        <CollectionPointAdministration />
      </CollectionPointAdministrationProvider>
    </RefetchProvider>
  )
}
