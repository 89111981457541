import { useMutation } from "@apollo/client"
import { Button, Card, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import lodash from "lodash"
import React, { FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { CreateDriverLocationResult, CreateDriverLocationVariables, CREATE_DRIVER_LOCATION_MUTATION } from "../../../../api/graphql/mutations/create-driver-location"
import { UpdateDriverLocationResult, UpdateDriverLocationVariables, UPDATE_DRIVER_LOCATION_MUTATION } from "../../../../api/graphql/mutations/update-driver-location"
import { useRefetch } from "../../../../context/refetch-context"
import { useSelectedDriverLocation } from "../../../../context/selected-driver-location-context"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  buttonLabel: {
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 8,
    paddingTop: 4
  },
  actionButton: {
    width: 240
  },
  card: {
    padding: theme.spacing(2),
  },
  driverLocation: {
    flexGrow: 1,
  }
}))

interface IDriverLocationDataProps {}

export const DriverLocationData: FunctionComponent<IDriverLocationDataProps> = (
  props,
) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setNeedToRefetch } = useRefetch()
  const { selectedDriverLocation, setSelectedDriverLocation } = useSelectedDriverLocation()
  const [name, setName] = useState<string>("")
  const [id, setId] = useState<string>("")
    
  const [createDriverLocation] = useMutation<CreateDriverLocationResult, CreateDriverLocationVariables>(CREATE_DRIVER_LOCATION_MUTATION)
  const [updateDriverLocation] = useMutation<UpdateDriverLocationResult, UpdateDriverLocationVariables>(UPDATE_DRIVER_LOCATION_MUTATION, {
    onError: () => toast.error(t("generic_error"))
  }) 

  useEffect(() => {
    if (selectedDriverLocation) {
      setName(selectedDriverLocation.name)
      setId(selectedDriverLocation.id)
    } else {
      setName("")
      setId("")
    }
  }, [selectedDriverLocation])

  const onClickCreateDriverLocation = async () => {
    const validationError = validateFields()
    if (validationError) {
      toast.error(t(`guidance_administration.driver_location.data.${validationError}`))
      return
    }
    const result = await createDriverLocation({
      variables: {
        name: name
      }
    })
    const driverLocation = result.data?.createDriverLocation
    if (!lodash.isNil(driverLocation)) {
      toast.info(
        t("guidance_administration.driver_location_created")
      )
    } else {
      toast.error(t("guidance_administration.driver_location_create_error"))
    }
    setSelectedDriverLocation(driverLocation)
    setNeedToRefetch(true)
  }

  const validateFields = (): string => {
    if (!name || name === "") {
      return "name_required"
    }
    return ""
  }

  const onSaveDriverLocation = async () => {
    const validationError = validateFields()
    if (validationError) {
      toast.error(t(`guidance_administration.driver_location.data.${validationError}`))
      return
    }
    try {
      const executionResult = await updateDriverLocation({
        variables: {
          id,
          name
        },
      })
      if((executionResult as any).errors){
        toast.error(t("guidance_administration.driver_location_update_error"))
      }
      else{
        toast.info(t("guidance_administration.driver_location_updated"))
      }

      setNeedToRefetch(true)
    } catch(e) {
      toast.error(t("guidance_administration.driver_location_update_error"))
    }
  }

  return (
        <Card className={classes.card}>
          <Grid container direction="column" spacing={3}>
            <Grid container item direction="row" spacing={3}>
              <Grid item className={classes.driverLocation}>
                <Typography className={classes.title}>
                  {!selectedDriverLocation ? t("guidance_administration.driver_location_create") : t("guidance_administration.driver_location_save")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField 
                label={t("guidance_administration.driver_location.data.name")}
                type="search"
                variant="outlined"
                value={name}
                fullWidth
                onChange={(event) => {
                  setName(event.target.value)
                }}
                required
              />
            </Grid>
            <Grid item container direction="row" justify="flex-start" spacing={2}>
              <Grid item>
                <Button
                  className={classes.actionButton}
                  type="button"
                  variant="contained"
                  onClick={() => setSelectedDriverLocation(undefined)}
                  fullWidth
                >
                  {t("guidance_administration.action.cancel")}
                </Button>
              </Grid>
              <Grid item>
                { selectedDriverLocation ? 
                  <Button
                    className={classes.actionButton}
                    type="button"
                    variant="contained"
                    onClick={onSaveDriverLocation}
                    fullWidth
                    color="primary"
                  >
                    {t("guidance_administration.action.save")}
                  </Button>
                  :
                  <Button
                    className={classes.actionButton}
                    type="button"
                    variant="contained"
                    onClick={onClickCreateDriverLocation}
                    fullWidth
                    color="primary"
                  >
                    {t("guidance_administration.action.create")}
                  </Button>
                }
              </Grid>
            </Grid>
        </Grid>
    </Card>
  )
}
