import { gql } from "@apollo/client"
import {
  updateDisposalTrailerLocation,
  updateDisposalTrailerLocationVariables,
  updateDisposalTrailerLocation_updateDisposalTrailerLocation,
} from "./types/updateDisposalTrailerLocation"

export const UPDATE_DISPOSAL_TRAILER_LOCATION_MUTATION = gql`
  mutation updateDisposalTrailerLocation($disposal_trailer_location_id:ID!, $disposal_trailer_location: DisposalTrailerLocationUpdateInput!) {
    updateDisposalTrailerLocation(disposal_trailer_location_id: $disposal_trailer_location_id, disposal_trailer_location: $disposal_trailer_location) {
      ... on DisposalTrailerLocation {
        id
        latitude
        longitude
        name
        district_id
        collection_partner_id
      }
      ... on DisposalTrailerLocationNotFoundError {error}
      ... on DistrictNotFoundError {error}
      ... on CollectionPartnerNotFoundError {error}
      ... on DisposalTrailerLocationDuplicateError {error unique}
      ... on DisposalTrailerLocationUpdateError {error}
      ... on GenericError {error}
    }
  }
`

export type UpdateDisposalTrailerLocationResult = updateDisposalTrailerLocation
export type UpdateDisposalTrailerLocation = updateDisposalTrailerLocation_updateDisposalTrailerLocation
export type UpdateDisposalTrailerLocationVariables = updateDisposalTrailerLocationVariables
