import React, { FunctionComponent, useState, useEffect } from "react"
import { Theme, makeStyles, Grid, TextField, FormControlLabel, Checkbox, Button, Card } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DistrictsResult, GET_DISTRICTS_QUERY } from "../../../../api/graphql/queries/get-districts"
import { useQuery, useMutation } from "@apollo/client"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { Text } from "../../../partials/wrapper/text"
import { theme } from "../../../../styles/theme"
import { useSelectedVehicle } from "../../../../context/selected-vehicle-context"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"
import { EmissionStandard, VehicleOperatingTimesInput } from "../../../../api/graphql/graphql-global-types"
import {
  CREATE_VEHICLE_MUTATION,
  CreateVehicleResult,
  CreateVehicleVariables,
} from "../../../../api/graphql/mutations/create-vehicle"
import {
  UPDATE_VEHICLE_MUTATION,
  UpdateVehicleResult,
  UpdateVehicleVariables,
} from "../../../../api/graphql/mutations/update-vehicle"
import {
  UPDATE_VEHICLE_OPERATING_TIMES_MUTATION,
  UpdateVehicleOperatingTimesResult,
  UpdateVehicleOperatingTimesVariables,
} from "../../../../api/graphql/mutations/update-vehicle-operating-times"
import {
  REMOVE_VEHICLE_MUTATION,
  RemoveVehicleResult,
  RemoveVehicleVariables,
} from "../../../../api/graphql/mutations/remove-vehicle"
import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { toast } from "react-toastify"
import { useRefetch } from "../../../../context/refetch-context"
import { VehicleDeleteDialog } from "./vehicle-delete-dialog"
import { VehicleMaterials } from "./vehicle-materials"
import { VehicleOperatingTimes } from "./vehicle-operating-times"
import { VehicleOperationPoint } from "./vehicle-operation-point"
import {
  VehicleToMaterial,
  VehicleOperatingTime,
  VehicleDistrict,
} from "../../../../api/graphql/queries/get-vehicle-with-id"
import { getInitialVehicleOperatingTimes } from "../../../../utils/day"
import { TmpVehicleDriverSelect } from "./tmp-vehicle-driver-select"
import { DEFAULT_DISTRICT_ID } from "../../../../utils/constants"
import lodash from "lodash"
import { DeparturePointContextProvider } from "../../partner-overview/partials/departure-point/context/departure-point-context"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "100%",
  },
  heading: {
    fontSize: 18,
  },
  snackBar: {
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "white",
    "&:hover": {
      backgroundColor: "#b71c1c",
    },
  },
  buttonContainer: {
    marginTop: "auto",
  },
  card: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    padding: `${theme.spacing(2)}px 0 0 ${theme.spacing(2)}px`,
    height: `calc(100% - 3 * ${theme.spacing(2)}px)`,
    width: "100%",
    overflowY: "auto",
  },
  content: {
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
  },
}))

const emissionStandards: SelectPair[] = [
  new SelectPair(EmissionStandard.euro5, "EURO 5"),
  new SelectPair(EmissionStandard.euro5eev, "EURO 5 EEV"),
  new SelectPair(EmissionStandard.euro6, "EURO 6"),
  new SelectPair(EmissionStandard.euro7, "EURO 7"),
]

interface IVehicleDataProps {}

export const VehicleData: FunctionComponent<IVehicleDataProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedVehicle, setSelectedVehicleId } = useSelectedVehicle()

  const [departurePointId, setDeparturePointId] = useState<number | null>(null)
  const [endPointId, setEndPointId] = useState<number | null>(null)
  const [id, setId] = useState<number>(0)
  const [district, setDistrict] = useState<VehicleDistrict>()
  const [licencePlate, setLicencePlate] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [trailer, setTrailer] = useState<boolean>(false)
  const [length, setLength] = useState<number | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  const [hasScale, setHasScale] = useState<boolean>(false)
  const [vehicleEmissionStandard, setVehicleEmissionStandard] = useState<EmissionStandard>(EmissionStandard.euro5)
  const [vehicleToMaterial, setVehicleToMaterial] = useState<VehicleToMaterial[]>([])
  const [trailerToMaterial, setTrailerToMaterial] = useState<VehicleToMaterial[]>([])
  const [operatingTimes, setOperatingTimes] = useState<VehicleOperatingTime[]>(
    getInitialVehicleOperatingTimes([], 12, 0),
  )
  const [tmpDriver, setTmpDriver] = useState<string | null>(null)
  const [costsPerHour, setCostsPerHour] = useState<number | null>(null)
  const [costsPerKm, setCostsPerKm] = useState<number | null>(null)

  const [isSaveCreateButtonDisabled, setIsSaveCreateButtonDisabled] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [departurePointError, setDeparturePointError] = useState<boolean>(false)
  const [destinationPointError, setDestinationPointError] = useState<boolean>(false)

  const { selectedPartnerId } = useSelectedPartner()
  const { setNeedToRefetch } = useRefetch()

  useEffect(() => {
    if (selectedVehicle) {
      setId(selectedVehicle.id)
      setDistrict(selectedVehicle.district)
      setLicencePlate(selectedVehicle.licence_plate)
      setType(selectedVehicle.type)
      setTrailer(selectedVehicle.trailer)
      setLength(selectedVehicle.length)
      setWidth(selectedVehicle.width)
      setHeight(selectedVehicle.height)
      setHasScale(selectedVehicle.has_scale)
      setVehicleEmissionStandard((selectedVehicle.emission_standard || EmissionStandard.euro5) as EmissionStandard)
      setVehicleToMaterial(
        (selectedVehicle.vehicleToMaterial || []).filter((vehicleToMaterial) => !vehicleToMaterial.is_trailer),
      )
      setTrailerToMaterial(
        (selectedVehicle.vehicleToMaterial || []).filter((vehicleToMaterial) => vehicleToMaterial.is_trailer),
      )
      setOperatingTimes(getInitialVehicleOperatingTimes(selectedVehicle.operating_times || [], 12, 0))
      setCostsPerHour(selectedVehicle.costs_per_hour)
      setCostsPerKm(selectedVehicle.costs_per_km)

      setDeparturePointId(selectedVehicle.departurePoint?.id || null)
      setDeparturePointError(false)
      setEndPointId(selectedVehicle.endPoint?.id || null)
      setDestinationPointError(false)

      setTmpDriver(selectedVehicle.tmp_driver)
    } else {
      clearFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicle])

  const clearFields = () => {
    if (districtsData) {
      setDistrict(districtsData.getDistricts[0])
    }
    setLicencePlate("")
    setType("")
    setTrailer(false)
    setLength(null)
    setWidth(null)
    setHeight(null)
    setHasScale(false)
    setVehicleEmissionStandard(EmissionStandard.euro5)
    setVehicleToMaterial([])
    setTrailerToMaterial([])
    setOperatingTimes(getInitialVehicleOperatingTimes([], 12, 0))
    setEndPointId(null)
    setDeparturePointId(null)
    setTmpDriver(null)
    setCostsPerHour(null)
    setCostsPerKm(null)
  }

  const { data: districtsData, error: districtsError } = useQuery<DistrictsResult>(GET_DISTRICTS_QUERY)
  let districts: SelectPair[] = []

  const [createVehicle] = useMutation<CreateVehicleResult, CreateVehicleVariables>(CREATE_VEHICLE_MUTATION)

  const [updateVehicle] = useMutation<UpdateVehicleResult, UpdateVehicleVariables>(UPDATE_VEHICLE_MUTATION)

  const [updateVehicleOperatingTimes] = useMutation<
    UpdateVehicleOperatingTimesResult,
    UpdateVehicleOperatingTimesVariables
  >(UPDATE_VEHICLE_OPERATING_TIMES_MUTATION)

  const [removeVehicle] = useMutation<RemoveVehicleResult, RemoveVehicleVariables>(REMOVE_VEHICLE_MUTATION)

  if (districtsData) {
    districts = districtsData.getDistricts.map((district) => new SelectPair(district.id.toString(), district.name))
    if (!district) {
      const defaultDistrict = districtsData.getDistricts.find((d) => d.id === DEFAULT_DISTRICT_ID)
      setDistrict(defaultDistrict ? defaultDistrict : districtsData.getDistricts[0])
    }
  }

  const onSaveVehicleClicked = async () => {
    setIsSaveCreateButtonDisabled(true)

    if (!departurePointId || !endPointId) {
      setDeparturePointError(!departurePointId)
      setDestinationPointError(!endPointId)
      setIsSaveCreateButtonDisabled(false)
      return
    }

    const chamberMapper = (entry: VehicleToMaterial, isTrailer: boolean) => ({
      amount: entry.amount,
      material_id: Number(entry.material.id),
      is_trailer: isTrailer,
    })
    const vehiclesToMaterials = vehicleToMaterial
      .map((entry) => chamberMapper(entry, false))
      .concat(trailerToMaterial.map((entry) => chamberMapper(entry, true)))

    // variables for create and update mutation
    const variables = {
      district_id: district ? district.id : "",
      collection_partner_id: String(selectedPartnerId),
      emission_standard: vehicleEmissionStandard,
      licence_plate: licencePlate,
      type,
      trailer,
      length,
      width,
      height,
      has_scale: hasScale,
      operating_times: operatingTimes
        .filter((operatingTimeOfDay) => !operatingTimeOfDay.id)
        .map(
          (operatingTimeOfDay) =>
            ({
              active: operatingTimeOfDay.active,
              day: operatingTimeOfDay.day,
              max_tour_duration: operatingTimeOfDay.max_tour_duration || 0,
              min_tour_duration: operatingTimeOfDay.min_tour_duration || 0,
            } as VehicleOperatingTimesInput),
        ),
      vehicles_to_materials: vehiclesToMaterials,
      tmp_driver: tmpDriver,
      costs_per_hour: costsPerHour,
      costs_per_km: costsPerKm,
    }

    if (selectedVehicle) {
      try {
        let res = await updateVehicle({
          variables: {
            ...variables,
            id: String(id),
            departure_point_id: departurePointId ? `${departurePointId}` : null,
            end_point_id: endPointId ? `${endPointId}` : null,
          },
        })

        // update all operating times
        await Promise.all(
          operatingTimes
            .filter((operatingTimeOfDay) => !!operatingTimeOfDay.id)
            .map(async (operatingTimeOfDay) => {
              await updateVehicleOperatingTimes({
                variables: {
                  id: String(operatingTimeOfDay.id),
                  operating_time: {
                    active: operatingTimeOfDay.active,
                    day: operatingTimeOfDay.day,
                    max_tour_duration: operatingTimeOfDay.max_tour_duration || 0,
                    min_tour_duration: operatingTimeOfDay.min_tour_duration || 0,
                  },
                },
              })
            }),
        )

        if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()
        toast.info(t("vehicle_overview.data.vehicle_updated"))
        setNeedToRefetch(true)
      } catch (e) {
        toast.error(t("vehicle_overview.data.could_not_update"))
      }
    } else {
      try {
        let res = await createVehicle({
          variables: {
            ...variables,
            departure_point_id: departurePointId ? `${departurePointId}` : null,
            end_point_id: endPointId ? `${endPointId}` : null,
          },
        })
        if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()
        toast.info(t("vehicle_overview.data.vehicle_created"))
        setNeedToRefetch(true)
        clearFields()
      } catch (e) {
        toast.error(t("vehicle_overview.data.could_not_create"))
      }
    }
    setIsSaveCreateButtonDisabled(false)
  }

  const deleteVehicle = async () => {
    try {
      const res = await removeVehicle({ variables: { id: String(id) } })
      if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()

      toast.info(t("vehicle_overview.data.vehicle_deleted"))
      setNeedToRefetch(true)
    } catch (e) {
      toast.error(t("vehicle_overview.data.could_not_delete"))
    }

    setSelectedVehicleId(undefined)
    setIsDeleteDialogOpen(false)
  }

  if (districtsError) {
    return <CustomSnackBarContent className={classes.snackBar} variant="error" message={t("errors.generic")} />
  }

  const isOperatingTimeValid = ({ min_tour_duration, max_tour_duration }: VehicleOperatingTime) =>
    min_tour_duration <= max_tour_duration

  const areAllOperatingTimesValid = (operatingTimes: VehicleOperatingTime[]) =>
    operatingTimes.every(isOperatingTimeValid)

  const isTrailerSet = (isTrailer: boolean, trailers: VehicleToMaterial[]) => {
    return !isTrailer || (trailers && trailers.length > 0)
  }

  const isValid = () => {
    return areAllOperatingTimesValid(operatingTimes) && isTrailerSet(trailer, trailerToMaterial)
  }

  return (
    <Card className={classes.card}>
      <Grid className={classes.container} container spacing={2} direction="row" alignItems="flex-start">
        <Grid item container spacing={2} direction="row" alignItems="stretch" className={classes.content}>
          <Grid item xs={12}>
            <Text className={classes.heading} bold>
              {t("vehicle_overview.data.title")}
            </Text>
          </Grid>
          {/* Basic Fields of Vehicle */}
          <Grid item xs={4}>
            <OutlinedSelect
              options={districts}
              name={t("vehicle_overview.data.district")}
              onValueChange={(value) => {
                if (districtsData) {
                  setDistrict(districtsData.getDistricts.find((d) => d.id.toString() === value))
                }
              }}
              value={district ? district.id : ""}
            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelect
              options={emissionStandards}
              name={t("vehicle_overview.data.emission_standard")}
              onValueChange={(value) => setVehicleEmissionStandard(value as EmissionStandard)}
              value={vehicleEmissionStandard || EmissionStandard.euro5}
            />
          </Grid>
          <Grid item xs={4}>
            <TmpVehicleDriverSelect driver={tmpDriver} updateDriver={setTmpDriver} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.licence_plate")}
              type="search"
              fullWidth
              variant="outlined"
              value={licencePlate}
              onChange={(ev) => {
                setLicencePlate(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.type")}
              type="search"
              fullWidth
              variant="outlined"
              value={type}
              onChange={(ev) => {
                setType(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={trailer}
                  onChange={() => setTrailer(!trailer)}
                  style={{
                    color: theme.PRIMARY_COLOR.light,
                  }}
                />
              }
              label={t(`vehicle_overview.data.trailer`)}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasScale}
                  onChange={() => setHasScale(!hasScale)}
                  style={{
                    color: theme.PRIMARY_COLOR.light,
                  }}
                />
              }
              label={t(`vehicle_overview.data.scale`)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.length")}
              type="number"
              inputProps={{ min: 0 }}
              fullWidth
              variant="outlined"
              value={lodash.isNumber(length) ? length : ""}
              onChange={(ev) => {
                setLength(parseInt(ev.target.value, 10))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.width")}
              type="number"
              inputProps={{ min: 0 }}
              fullWidth
              variant="outlined"
              value={lodash.isNumber(width) ? width : ""}
              onChange={(ev) => {
                setWidth(parseInt(ev.target.value, 10))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.height")}
              type="number"
              inputProps={{ min: 0 }}
              fullWidth
              variant="outlined"
              value={lodash.isNumber(height) ? height : ""}
              onChange={(ev) => {
                setHeight(parseInt(ev.target.value, 10))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.costs_per_hour")}
              type="number"
              inputProps={{ min: 0, step: 0.01 }}
              fullWidth
              variant="outlined"
              value={lodash.isNumber(costsPerHour) ? costsPerHour : ""}
              onChange={(ev) => {
                setCostsPerHour(parseFloat(ev.target.value))
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("vehicle_overview.data.costs_per_km")}
              type="number"
              inputProps={{ min: 0, step: 0.01 }}
              fullWidth
              variant="outlined"
              value={lodash.isNumber(costsPerKm) ? costsPerKm : ""}
              onChange={(ev) => {
                setCostsPerKm(parseFloat(ev.target.value))
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Card for managing relations to materials */}
            <VehicleMaterials
              title={t("vehicle_overview.data.chambers_vehicle")}
              selectedMaterials={vehicleToMaterial}
              updateSelectedMaterials={setVehicleToMaterial}
            />
          </Grid>
          {trailer && (
            <Grid item xs={12}>
              {/* Card for managing relations to materials */}
              <VehicleMaterials
                title={t("vehicle_overview.data.chambers_trailer")}
                selectedMaterials={trailerToMaterial}
                updateSelectedMaterials={setTrailerToMaterial}
                required
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.cardContainer}>
            {/* Card for managing operating hours per day */}
            <VehicleOperatingTimes
              operatingTimes={operatingTimes}
              updateOperatingTimes={setOperatingTimes}
              isOperatingTimeValid={isOperatingTimeValid}
            />
          </Grid>
          <DeparturePointContextProvider partnerId={selectedPartnerId}>
            <Grid item xs={12} md={6} className={classes.cardContainer}>
              {/* Card for managing starting point of the vehicle */}
              <VehicleOperationPoint
                id={departurePointId}
                setId={setDeparturePointId}
                heading={t("vehicle_overview.data.starting_point")}
                error={departurePointError}
                setError={setDeparturePointError}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.cardContainer}>
              {/* Card for managing end point of the vehicle */}
              <VehicleOperationPoint
                id={endPointId}
                setId={setEndPointId}
                heading={t("vehicle_overview.data.end_point")}
                error={destinationPointError}
                setError={setDestinationPointError}
              />
            </Grid>
          </DeparturePointContextProvider>
        </Grid>
        {/* Buttons for creating/updating/saving */}
        <Grid item container justify={"space-between"} className={classes.buttonContainer}>
          <Button
            variant="contained"
            type="button"
            disabled={!selectedVehicle}
            onClick={() => setIsDeleteDialogOpen(true)}
            className={classes.deleteButton}
          >
            {t("vehicle_overview.data.delete_vehicle")}
          </Button>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={onSaveVehicleClicked}
            disabled={isSaveCreateButtonDisabled || !isValid()}
          >
            {selectedVehicle ? t("vehicle_overview.data.save_vehicle") : t("vehicle_overview.data.create_vehicle")}
          </Button>
        </Grid>
      </Grid>
      {/* Dialog for delete confirmation */}
      <VehicleDeleteDialog
        onDelete={deleteVehicle}
        onClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
      />
    </Card>
  )
}
