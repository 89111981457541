import { gql } from "@apollo/client"
import {
  getVehiclesWithPartnerId,
  getVehiclesWithPartnerIdVariables,
  getVehiclesWithPartnerId_getVehiclesWithPartnerId,
  getVehiclesWithPartnerId_getVehiclesWithPartnerId_district
} from "./types/getVehiclesWithPartnerId"

export const GET_VEHICLES_WITH_PARTNER_ID = gql`
  query getVehiclesWithPartnerId($partner_id: ID!) {
    getVehiclesWithPartnerId(partner_id: $partner_id) {
      id
      type
      licence_plate
      district {
        id
        name
      }
    }
  }
`

export type VehiclesResult = getVehiclesWithPartnerId
export type Vehicles = getVehiclesWithPartnerId_getVehiclesWithPartnerId
export type DistrictOfVehicle = getVehiclesWithPartnerId_getVehiclesWithPartnerId_district
export type VehiclesVariables = getVehiclesWithPartnerIdVariables
