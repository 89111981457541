import React, { FunctionComponent, Fragment, useContext, useMemo } from "react"
import { Theme, makeStyles, Card, Grid, Typography } from "@material-ui/core"
import { Card as CardModel } from "../../../partials/layout/card/model/card"
import { useTranslation } from "react-i18next"
import { Container } from "../../../../api/graphql/queries/get-collection-points-with-id"
import { Text } from "../../../partials/wrapper/text"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { CardsContainer } from "../../../partials/layout/card/cards-container"
import { FilllevelChart } from "./filllevel-chart"
import { useQuery } from "@apollo/client"
import {
  FlattenedContainerFilllevelsResult,
  GET_FLATTENED_CONTAINER_FILLLEVELS_QUERY,
} from "../../../../api/graphql/queries/get-flattened-container-fill-levels"
import moment from "moment"
import {
  ContainerStatsResult,
  GETCONTAINERSTATS_QUERY,
  ContainerStatsVariables,
} from "../../../../api/graphql/queries/get-container-stats-with-id"
import lodash from "lodash"
import { Info, ReportProblem } from "@material-ui/icons"
import { UserContext } from "../../../../context/user-context"
import { getFlattenedContainerFilllevels } from "../../../../api/graphql/queries/types/getFlattenedContainerFilllevels"
import { containerHasIssues } from "../../../../utils/container"
import { ContainerWarningType } from "../../../../api/graphql/graphql-global-types"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
  },
  manufacturer: {
    color: theme.palette.primary.main,
  },
  iconContainer: {
    display: "flex",
  },
  criticalIcon: {
    fill: "#dd8800",
    marginRight: theme.spacing(1),
  },
  warningIcon: {
    fill: "#ffcc00",
    marginRight: theme.spacing(1),
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
}))

interface IContainerStatsCardProps {
  container: Container
  forecastEnabled: boolean
  fixedUnloadInterval: number | null
  prepareFillLevel(fillLevel: number | null): number | null
}
const now = moment()
const yesterday = now.clone().subtract(1, "day")
const tomorrow = now.clone().add(1, "day")

export const ContainerStatsCard: FunctionComponent<IContainerStatsCardProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { container, fixedUnloadInterval, forecastEnabled } = props
  const { showOpenLidWarning, showDefectiveSensorWarning } = useContext(UserContext)

  const hasContainerIssues = containerHasIssues(container, fixedUnloadInterval, 10)

  const { loading, error, data } = useQuery<FlattenedContainerFilllevelsResult>(
    GET_FLATTENED_CONTAINER_FILLLEVELS_QUERY,
    {
      variables: {
        id: Number(container.id),
        from: now.clone().subtract(6, "month").toDate(),
        to: now.clone().add(1, "month").toDate(),
      },
    },
  )

  const todaysFilllevel = useMemo(() => {
    const now = moment()
    return data?.getFlattenedContainerFilllevels.find((filllevel) => now.isSame(filllevel.timestamp, "day"))?.value
  }, [data])

  const containerFilllevelString = useMemo(() => {
    if (!lodash.isNil(todaysFilllevel)) {
      return `${Math.min(Math.round(todaysFilllevel), 100)}%`
    }

    return t("not_available")
  }, [todaysFilllevel, t])

  const { data: statsData } = useQuery<ContainerStatsResult, ContainerStatsVariables>(GETCONTAINERSTATS_QUERY, {
    variables: {
      id: Number(container.id),
    },
  })

  // TODO: refactor all warnings to be displayed from "containerWarnings" field resolver
  const collectionPointFeedbackWarnings = useMemo(() => {
    const warnings = container.containerWarnings.filter((warning) =>
      [
        ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK,
        ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA,
      ].includes(warning.warning_type),
    )

    return warnings.map((warning, index) => (
      <Grid key={`warning_${container.id}_${index}`} container item justify="flex-end">
        <Typography>
          {t(`container_warnings.message.${warning.warning_type}`, {
            date: moment(warning.since).format(t("date_format")),
          })}
        </Typography>
        <ReportProblem className={classes.criticalIcon} />
      </Grid>
    ))
  }, [container, classes, t])

  if (loading) return null

  if (error) return null

  let manufacturer
  let manufacturerName

  const getDaysUntilContainerFullContent = (data: getFlattenedContainerFilllevels): string => {
    const filllevels = data.getFlattenedContainerFilllevels
    // multiple filllevels per day allowed
    const today = filllevels.filter((f) => now.isSame(f.timestamp, "day"))

    if (today.some((f) => f.value && f.value >= 100)) {
      // look in past (include today) since when this container is full
      const pastFilllevels = filllevels.filter((f) => moment(f.timestamp).isSameOrBefore(now, "day"))
      // index before container was full
      const closestNonFullIndex = pastFilllevels.reverse().findIndex((f) => f.value && f.value < 100)
      if (closestNonFullIndex >= 1) {
        const fullSince = pastFilllevels[closestNonFullIndex - 1]
        if (now.isSame(fullSince.timestamp, "day")) {
          return t("today")
        } else if (yesterday.isSame(fullSince.timestamp, "day")) {
          return t("yesterday")
        }
        return t("collection_point_details.full_since", { days: now.diff(fullSince.timestamp, "day") })
      }

      // container full in all past filllevels, use earliest filllevel as reference
      return t("collection_point_details.full_since_more", { days: now.diff(filllevels[0].timestamp, "day") })
    } else {
      // look in future when this container will be full
      const nextFull = filllevels
        .filter((f) => moment(f.timestamp).isAfter(now, "day"))
        .find((f) => f.value && f.value >= 100)

      if (nextFull) {
        if (tomorrow.isSame(nextFull.timestamp, "day")) {
          return t("tomorrow")
        }
        return t("collection_point_details.full_in", { days: moment(nextFull.timestamp).diff(now, "day") })
      }
    }

    if (!forecastEnabled) {
      return t("not_available")
    }

    // container was not full in the past and will not be full in the near future
    // use last filllevel as reference if filllevels exist until today
    if (today.some((f) => f.value != null) && filllevels.length > 0) {
      return t("collection_point_details.full_in_more", {
        days: moment(filllevels[filllevels.length - 1].timestamp).diff(now, "day"),
      })
    }
    return t("not_available")
  }

  const cards = statsData
    ? ([
        {
          content: statsData!.getContainerStatsWithID.container_type_name,
          heading: t("type"),
        },
        {
          content: statsData!.getContainerStatsWithID.material_name,
          heading: t("fraction"),
        },
        {
          content: statsData!.getContainerStatsWithID.sensor_id || "-",
          heading: t("sensor_id"),
        },
        {
          content: containerFilllevelString,
          heading: t("fill_level"),
        },
        {
          content: data ? getDaysUntilContainerFullContent(data) : "-",
          heading: t("collection_point_details.full_heading"),
        },
      ] as CardModel[])
    : ([] as CardModel[])
  if (statsData) {
    manufacturer = statsData.getContainerStatsWithID.manufacturer
    manufacturerName = manufacturer ? manufacturer.name : statsData!.getContainerStatsWithID.alternative_manufacturer
  }

  return (
    <Fragment>
      <Card className={classes.container}>
        <Grid container direction="column">
          <Grid container item direction="row" justify="space-between">
            <Grid item>
              <Text variant="h6" bold>
                {`${t("container")} ${container.number || "-"}`}
              </Text>
            </Grid>
            <Grid item>
              {fixedUnloadInterval && fixedUnloadInterval > 0 && (
                <Grid container item justify="space-around">
                  <Text>{t("collection_point_details.fixed_unload_interval_info")}</Text>
                  &nbsp;
                  <Info color="secondary" className={classes.infoIcon} />
                </Grid>
              )}
              {hasContainerIssues && (
                <Grid container item justify="flex-end">
                  <Typography>
                    {container.last_time_send
                      ? t("collection_point_details.container_last_send_warning", {
                          date: moment(container.last_time_send).format(t("date_format")),
                        })
                      : t("collection_point_details.container_never_send_warning")}
                  </Typography>
                  <ReportProblem className={classes.criticalIcon} />
                </Grid>
              )}
              {collectionPointFeedbackWarnings}
              {showDefectiveSensorWarning && container.defectiveSensor && (
                <Grid container item justify="flex-end">
                  <Typography>{t("collection_point_details.defective_sensor")}</Typography>
                  <ReportProblem className={classes.criticalIcon} />
                </Grid>
              )}
              {showOpenLidWarning && statsData?.getContainerStatsWithID?.sensor_id && container.lid_open && (
                <Grid container item justify="flex-end">
                  <Typography>
                    {container.lid_changed &&
                      t("collection_point_details.container_lid_open_since", {
                        date: moment(container.lid_changed).format(t("date_format")),
                      })}
                  </Typography>
                  <ReportProblem className={classes.warningIcon} />
                </Grid>
              )}
              {container.filllevelDerivedFromDriverFeedback && !lodash.isNil(todaysFilllevel) && (
                <Grid container item justify="flex-end">
                  <Typography>{t("collection_point_details.filllevel_derived_from_driver_feedback")}</Typography>
                  <Info color="secondary" className={classes.infoIcon} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item>
              <Text variant={"h6"} noWrap className={classes.manufacturer}>
                {manufacturerName}
              </Text>
            </Grid>
            {manufacturer?.construction_type && (
              <Grid item>
                <Text variant={"subtitle1"} noWrap>
                  ({manufacturer.construction_type})
                </Text>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* Stats */}
        <ContentContainer>
          <CardsContainer justify="center" cards={cards} secondary />
        </ContentContainer>

        {/* CollectionPoint Chart */}
        {data && (
          <ContentContainer>
            <FilllevelChart
              data={data.getFlattenedContainerFilllevels.map((item) => ({
                ...item,
                isExtrapolated: !!item.isExtrapolated,
              }))}
              prepareFillLevel={props.prepareFillLevel}
            />
          </ContentContainer>
        )}
      </Card>
    </Fragment>
  )
}
