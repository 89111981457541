import { useQuery } from "@apollo/client"
import { COUNTIES_QUERY, CountiesResult, CountiesVariables } from "../api/graphql/queries/counties"
import { SelectOption } from "../components/partials/customselect/custom-select-component-commons"
import { useMemo, useState } from "react"
import { UserService } from "../services/user-service"

export const useCountyFilter = (districtId: number | null, onQueryCompleted: (data: CountiesResult) => void) => {
  const { data, loading, error } = useQuery<CountiesResult, CountiesVariables>(COUNTIES_QUERY, {
    variables: { districtId: String(districtId) },
    fetchPolicy: "cache-first",
    skip: !districtId || UserService.hasAssociationFilter(),
    onCompleted: onQueryCompleted,
  })

  const [selectedCounties, setSelectedCounties] = useState<SelectOption[]>([])
  const options = useMemo(() => data?.counties.map((county) => new SelectOption(county.id, county.name)) || [], [data])

  return {
    options,
    selectedCounties,
    setSelectedCounties,
    loading,
    error,
  }
}
