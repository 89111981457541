import { gql } from "@apollo/client"
import { deleteFinishedOrCancelledTourGeneration, deleteFinishedOrCancelledTourGenerationVariables } from "./types/deleteFinishedOrCancelledTourGeneration"

export const DELETE_FINISHED_OR_CANCELLED_TOUR_GENERATION = gql`
  mutation deleteFinishedOrCancelledTourGeneration($tour_parameters_id: ID!) {
    deleteFinishedOrCancelledTourGeneration(tour_parameters_id: $tour_parameters_id) {
      error
      message
    }
  }
`

export type DeleteTourGenerationResult = deleteFinishedOrCancelledTourGeneration
export type DeleteTourGenerationVariables = deleteFinishedOrCancelledTourGenerationVariables
