import { gql } from "@apollo/client"
import { markFileExportAsDownloaded, markFileExportAsDownloadedVariables } from "./types/markFileExportAsDownloaded"

export const MARK_FILE_EXPORTS_AS_DOWNLOADED_MUTATION = gql`
  mutation markFileExportAsDownloaded($id: ID!) {
    markFileExportAsDownloaded(id: $id)
  }
`
export type MarkFileExportAsDownloadedResult = markFileExportAsDownloaded
export type MarkFileExportAsDownloadedVariables = markFileExportAsDownloadedVariables
