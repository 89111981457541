import gql from "graphql-tag";
import { updateDriverLocation, updateDriverLocationVariables, updateDriverLocation_updateDriverLocation } from "./types/updateDriverLocation";

export const UPDATE_DRIVER_LOCATION_MUTATION = gql`
    mutation updateDriverLocation(
        $id: ID!
        $name: String!
    ){
        updateDriverLocation(
            id: $id
            name: $name
        ) {
            id
        }
    }
`
export type UpdateDriverLocationResult = updateDriverLocation
export type UpdateDriverLocation = updateDriverLocation_updateDriverLocation
export type UpdateDriverLocationVariables = updateDriverLocationVariables
