import { useMemo, useState } from "react"

export function usePagination<T>(allEntries: T[]) {  
  const [pageSize, setPageSize] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const count = useMemo(() => allEntries.length, [allEntries])

  return {
    count,
    pageSize,
    currentPage,
    setPageSize,
    setCurrentPage,
    entries: allEntries.slice(currentPage * pageSize, currentPage * pageSize + pageSize),
  }
}
