import { gql } from "@apollo/client"
import {
  getContainerStatsWithID,
  getContainerStatsWithIDVariables
} from "./types/getContainerStatsWithID"

export const GETCONTAINERSTATS_QUERY = gql`
  query getContainerStatsWithID($id: Int!) {
    getContainerStatsWithID(id: $id) {
      id
      container_type_name
      material_name
      sensor_id
      filllevel
      manufacturer {
        construction_type
        name
      }
      alternative_manufacturer
    }
  }
`

export type ContainerStatsResult = getContainerStatsWithID
export type ContainerStatsVariables = getContainerStatsWithIDVariables
