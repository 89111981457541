import React from "react"
import { Theme, makeStyles, Grid } from "@material-ui/core"

interface IHeadingProps {
  children: any
  style?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  contentContainer: {
    marginTop: theme.spacing(2)
  }
}))

export const ContentContainer: React.FunctionComponent<
  IHeadingProps
> = props => {
  const classes = useStyles()
  const { children } = props
  return (
    <Grid className={`${props.style} ${classes.contentContainer}`}>
      {children}
    </Grid>
  )
}
