import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react"
import { Checkbox, FormControlLabel, Grid, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Day } from "../../../api/graphql/graphql-global-types"
import { OpeningHour, OpeningHoursPicker } from "../date-picker/opening-hours-picker"
import { ConfirmDialog } from "../layout/dialog/confirm-dialog"
import lodash from "lodash"
import moment from "moment"
import { theme } from "../../../styles/theme"

interface IOpeningHoursDialogProps {
  open: boolean
  openingHours: OpeningHour[]
  accessibleWithTrailer: boolean
  overfillingAllowed: boolean
  onConfirm: (newOpeningHours: OpeningHour[], accessibleWithTrailer: boolean, overfillingAllowed: boolean) => void
  onClose: () => void
  loading?: boolean
}

export const OpeningHoursDialog: FunctionComponent<IOpeningHoursDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, openingHours, accessibleWithTrailer, overfillingAllowed, onClose, onConfirm, loading } = props
  const [editableOpeningHours, setEditableOpeningHours] = useState<OpeningHour[]>([])
  const [tempAccessibleWithTrailer, setTempAccessibleWithTrailer] = useState<boolean | undefined>(undefined)
  const [avoidOverfill, setAvoidOverfill] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    setTempAccessibleWithTrailer(accessibleWithTrailer)
    setAvoidOverfill(!overfillingAllowed)
  }, [accessibleWithTrailer, overfillingAllowed])

  const getDefaultOpeningHours = (): OpeningHour[] => {
    const defaultOpeningHour: Pick<OpeningHour, "from" | "to"> = { from: "00:00", to: "23:59" }
    return Object.values(Day).map((d) => ({ ...defaultOpeningHour, day: d }))
  }

  useEffect(() => {
    // do not mutate original prop
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHours))
    // fill missing days with default values
    const union = lodash.unionBy(copy, getDefaultOpeningHours(), (item) => item.day)
    setEditableOpeningHours(union)
  }, [openingHours, open])

  const handleConfirmClick = useCallback((openingHours: OpeningHour[]) => {
    onConfirm(openingHours, tempAccessibleWithTrailer ?? false, avoidOverfill != null ? !avoidOverfill : true)
  }, [onConfirm, tempAccessibleWithTrailer, avoidOverfill])

  const getErrorMsg = (openingHour: OpeningHour) => {
    const from = moment(openingHour.from, "HH:mm", true)
    const to = moment(openingHour.to, "HH:mm", true)

    if (from.isValid() && to.isValid() && from.isAfter(to.subtract(1, "h"))) {
      return t("collection_point_administration.errors.opening_hour_difference_invalid")
    }

    return ""
  }

  const isTimeValid = (time: string) => moment(time, "HH:mm", true).isValid()

  const areAllValid = (openingHours: OpeningHour[]) =>
    openingHours.every((oh) => getErrorMsg(oh) === "" && isTimeValid(oh.from) && isTimeValid(oh.to))

  const handleChangeAccessibleWithTrailer = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setTempAccessibleWithTrailer(event.target.checked)
  }, [setTempAccessibleWithTrailer])

  const handleChangeOverfillingAllowed = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAvoidOverfill(event.target.checked)
  }, [setAvoidOverfill])

  return (
    <ConfirmDialog
      open={open}
      loading={loading}
      disabled={!areAllValid(editableOpeningHours)}
      onClose={onClose}
      onConfirm={() => handleConfirmClick(editableOpeningHours)}
      confirmText={t("save")}
      heading={t("collection_point_administration.collection_point_settings")}
    >
      <OpeningHoursPicker
        openingHours={editableOpeningHours}
        updateOpeningHours={setEditableOpeningHours}
        getErrorMsg={getErrorMsg}
      />
      <Grid item container xs={12} spacing={2} direction="column" style={{ marginTop: "1rem" }}>
        <Grid item>
          <Tooltip title={t("collection_point_administration.accessible_with_trailer.tooltip").toString()}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tempAccessibleWithTrailer}
                  onChange={handleChangeAccessibleWithTrailer}
                  name="accessibleWithTrailer"
                  inputProps={{ 'aria-label': 'accessible-with-trailer' }}
                  style={{ color: theme.PRIMARY_COLOR.main }}
                />
              }
              label={t("collection_point_administration.accessible_with_trailer.label").toString()}
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title={t("collection_point_administration.avoid_overfill.tooltip").toString()}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={avoidOverfill}
                  onChange={handleChangeOverfillingAllowed}
                  name="avoidOverfill"
                  style={{ color: theme.PRIMARY_COLOR.main }}
                  inputProps={{ 'aria-label': 'overfilling-allowed' }}
                />
              }
              label={t("collection_point_administration.avoid_overfill.label").toString()}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </ConfirmDialog>
  )
}
