import React, { createContext, useContext, useState } from "react"
import { DriverLocation } from "../api/graphql/queries/get-driver-locations"

interface ISelectedDriverLocationContextType {
  selectedDriverLocation: DriverLocation | undefined
  setSelectedDriverLocation: (driverLocation: DriverLocation | undefined) => void
}

export const SelectedDriverLocationContext = createContext<ISelectedDriverLocationContextType>(
  {} as ISelectedDriverLocationContextType,
)

export const SelectedDriverLocationProvider = (props: any) => {
  const value = useSelectedDriverLocationProvider()

  return (
    <SelectedDriverLocationContext.Provider value={value}>
      {props.children}
    </SelectedDriverLocationContext.Provider>
  )
}

export const useSelectedDriverLocation = () => {
  return useContext(SelectedDriverLocationContext)
}

const useSelectedDriverLocationProvider = (): ISelectedDriverLocationContextType => {
  const [selectedDriverLocation, setSelectedDriverLocation] = useState<
    DriverLocation | undefined
  >(undefined)

  return {
    selectedDriverLocation,
    setSelectedDriverLocation,
  }
}
