import React, { FunctionComponent } from "react"
import { Grid, makeStyles, Theme } from "@material-ui/core"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { useTranslation } from "react-i18next"
import { RefetchProvider } from "../../../../context/refetch-context"
import { DisposalMerchantSelector } from "./disposal-merchant-selector"
import { DisposalMerchantData } from "./disposal-merchant-data"

const useStyles = makeStyles((theme: Theme) => ({
  snackBar: {
    marginTop: 10
  },
  data: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    overflowY: "auto"
  },
  container: {
    height: "calc(100vh - 56px)", // 56px equals all paddings and margins
    display: "flex",
    flexFlow: "column"
  },
  pageHeadingContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "0 0 auto",
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  contentContainer: {
    padding: `0 ${theme.spacing(1)}px`,
    flex: "1 1 auto",
    overflowY: "auto",
    height: "100%"
  }
}))

interface IDisposalMerchantOverviewProps {}

export const DisposalMerchantOverview: FunctionComponent<IDisposalMerchantOverviewProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles(props)

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.pageHeadingContainer}>
        <PageHeading>{t("disposal_merchant_overview.heading")}</PageHeading>
      </Grid>
      <Grid container item direction="row" className={classes.contentContainer}>
        <RefetchProvider>
          <DisposalMerchantSelector />
          <Grid className={classes.data}>
            <Grid item>
              <DisposalMerchantData />
            </Grid>
          </Grid>
        </RefetchProvider>
      </Grid>
    </Grid>
  )
}
