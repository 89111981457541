import React, { SyntheticEvent, useContext } from "react"
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { RegionSelect } from "../../../partials/region-select/region-select"
import { UserService } from "../../../../services/user-service"
import { CustomAutocomplete } from "../../../partials/customselect/custom-select"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { Clear } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) => ({
  regionSelectCard: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  filterCard: {
    padding: theme.spacing(2),
  },
  filterHeading: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  filterContainerItem: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
  resetFilterButton: {
    marginTop: 4,
  },
}))

interface IHouseholdOverviewFilterProps {}

export const HouseholdOverviewFilter: React.FC<IHouseholdOverviewFilterProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { palette } = useTheme()

  const {
    filter,
    updateFilter,
    address,
    setAddress,
    countyOptions,
    materialOptions,
    townOptions,
    resetFilter,
  } = useContext(HouseholdOverviewFilterContext)

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={3} md={2}>
        <Card className={classes.regionSelectCard}>
          <RegionSelect
            filterAssociations={(entry) => entry.rfidModuleEnabled}
            filterDistricts={(entry) => entry.rfidModuleEnabled}
          />
        </Card>
      </Grid>
      <Grid item xs={9} md={10}>
        <Card className={classes.filterCard}>
          <Grid container spacing={2} direction="row">
            <Grid item>
              <Typography className={classes.filterHeading} variant={"h5"}>
                {t("households.filter.heading")}
              </Typography>
            </Grid>
            <Grid container item xs alignItems="center" spacing={1}>
              {!UserService.hasAssociationFilter() && (
                <Grid item xs={6} md={4}>
                  {!!countyOptions.length && (
                    <CustomAutocomplete
                      id="households-filter-counties"
                      title={t("households.filter.counties")}
                      currentlySelectedValues={filter.selectedCounties}
                      availableSelectOptions={countyOptions}
                      setCurrentlySelectedValues={(newValues) =>
                        updateFilter({ ...filter, selectedCounties: newValues })
                      }
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={6} md={4}>
                <TextField
                  className={classes.filterContainerItem}
                  id="outlined-search"
                  label={t("households.filter.address")}
                  type="search"
                  variant="outlined"
                  placeholder="Hauptstraße 5"
                  value={address}
                  onChange={(ev) => setAddress(ev.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomAutocomplete
                  id="households-filter-materials"
                  title={t("households.filter.materials")}
                  currentlySelectedValues={filter.selectedMaterials}
                  availableSelectOptions={materialOptions}
                  setCurrentlySelectedValues={(newValues) => updateFilter({ ...filter, selectedMaterials: newValues })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <CustomAutocomplete
                  id="households-filter-towns"
                  title={t("households.filter.towns")}
                  currentlySelectedValues={filter.selectedTowns}
                  availableSelectOptions={townOptions}
                  setCurrentlySelectedValues={(newValues) => updateFilter({ ...filter, selectedTowns: newValues })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.showWarnings}
                      onChange={(_event: SyntheticEvent, checked: boolean) =>
                        updateFilter({ ...filter, showWarnings: checked })
                      }
                      style={{
                        color: palette.primary.light,
                      }}
                      value={filter.showWarnings}
                    />
                  }
                  label={t("households.filter.show_warnings")}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip placement="top" title={t("households.filter.reset_filter") as string}>
                <IconButton onClick={resetFilter} size="small" className={classes.resetFilterButton}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
