import { gql } from "@apollo/client"
import {
  updateDeparturePoint,
  updateDeparturePointVariables,
  updateDeparturePoint_updateDeparturePoint
} from "./types/updateDeparturePoint"

export const UPDATE_DEPARTURE_POINT_MUTATION = gql`
  mutation updateDeparturePoint(
    $id: ID!
    $departure_point: DeparturePointInput!
  ) {
    updateDeparturePoint(id: $id, departure_point: $departure_point) {
      id
    }
  }
`

export type UpdateDeparturePointResult = updateDeparturePoint
export type UpdateDeparturePoint = updateDeparturePoint_updateDeparturePoint
export type UpdateDeparturePointVariables = updateDeparturePointVariables
