import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from './constants';
import { Card } from "../components/partials/layout/card/model/card"
import {
  EmptyingStats,
} from "../api/graphql/queries/get-collection-point-stats-with-id"
import i18next from "i18next"
import moment from "moment"
import { CollectedAmountStats } from "../api/graphql/queries/get-tour-stats-with-id"
import { CollectionPointFilllevels } from "../api/graphql/queries/get-collection-points-with-id"
import lodash from "lodash"
import { UserService } from "../services/user-service"
import { ContainerGroupInfo } from '../api/graphql/queries/get-region-stats'

export class CardInfoHelper {
  public static getLastEmptyingCard(emptying: EmptyingStats): Card {
    if (!emptying) {
      return {
        heading: i18next.t("not_available"),
        content: i18next.t("collection_points.cards.last_emptying"),
      }
    }

    return CardInfoHelper.getEmptyingCard(
      emptying,
      `${i18next.t("collection_points.cards.last_emptying")}`,
    )
  }

  public static getNextEmptyingCard(emptying: EmptyingStats): Card {
    if (!emptying) {
      return {
        heading: i18next.t("not_available"),
        content: i18next.t("collection_points.cards.next_emptying"),
      }
    }

    return CardInfoHelper.getEmptyingCard(
      emptying,
      i18next.t("collection_points.cards.next_emptying"),
    )
  }

  public static getCountOfCollectionPointsCard(count: number | undefined): Card {
    return {
      description: i18next.t("collection_points.cards.total"),
      content: count == null ? "-" : `${count}`,
      heading: i18next.t("collection_points.cards.collection_points")
    }
  }

  private static getEmptyingCard(emptying: EmptyingStats, heading: string): Card {
    return {
      content: emptying
        ? moment(emptying!.emptying_date).format(i18next.t("date_format"))
        : i18next.t("not_available"),
      description: emptying.partner || "nA",
      heading,
    }
  }

  public static getFillLevelCard(collectionPointFilllevels: CollectionPointFilllevels[]): Card {
    const content = collectionPointFilllevels
      .map((filllevel) => `${filllevel.filllevel}%`)
      .join(" | ")

    const description = collectionPointFilllevels
      .map((filllevel) => filllevel.material.name)
      .join(" | ")
    return {
      content,
      description,
      heading: i18next.t("fill_level"),
    }
  }

  public static getAverageFullCard(days: number | null): Card {
    return {
      content: days ? `${days} ${i18next.t("days")}` : i18next.t("not_available"),
      heading: i18next.t("collection_point_details.average_filling_time"),
    }
  }

  public static getFixedUnloadInterval(weeks: number): Card {
    let content = i18next.t("not_available")
    if (weeks) {
      content =
        weeks === FIXED_UNLOAD_INTERVAL_ON_DEMAND
          ? i18next.t("collection_point_administration.data.unload_interval_on_demand")
          : `${weeks} ${i18next.t("weeks")}`
    }
    
    return {
      content,
      heading: i18next.t("collection_point_details.fixed_unload_interval"),
    }
  }

  public static getContainerInfoGroupCard(containerInfos: ContainerGroupInfo[]): Card {
    const content = containerInfos.map((containerInfo) => containerInfo.amount).join(" | ")

    const description = containerInfos.map((containerInfo) => containerInfo.name).join(" | ")

    return { description, content, heading: i18next.t("amount_chambers") }
  }

  public static getContainerInfoGroupCardForCollectionPoint(filllevelInfos: CollectionPointFilllevels[]): Card {
    const content = filllevelInfos.map((filllevelInfo) => filllevelInfo.count_containers).join(" | ")

    const description = filllevelInfos.map((filllevelInfo) => filllevelInfo.material.name).join(" | ")

    return { description, content, heading: i18next.t("amount_chambers") }
  }

  public static getCollectedAmountCard(collectedAmount: CollectedAmountStats[]): Card {
    const content =
      collectedAmount
        .map((ca) =>
          (ca.amount / 1000).toLocaleString(UserService.getLanguage(), {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        )
        .join("t | ") + "t"

    const description = collectedAmount.map((ca) => ca.material_name).join(" | ")

    return { heading: i18next.t("collected_amount"), content, description }
  }

  public static getLast30DaysCollectedAmountCard(amount: any): Card {
    const content =
      (amount / 1000).toLocaleString(UserService.getLanguage(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " t"

    return {
      heading: i18next.t("collected_amount"),
      content,
      description: this.getLastMonthString(),
    }
  }

  public static getLastMonthString(): string {
    return moment().subtract(1, "month").format("MMMM YYYY")
  }

  public static getTourDurationCard(duration: number, actual_duration: number | null): Card {
    const heading = i18next.t("tour_time")

    let content = this.getFormattedTourDuration(duration)
    if (!lodash.isNil(actual_duration)) {
      content += ` | ${this.getFormattedTourDuration(actual_duration)}`
    }

    return {
      heading,
      content,
      description: `${i18next.t("tour_overview.planned")}${
        !lodash.isNil(actual_duration) ? ` | ${i18next.t("tour_overview.actual")}` : ""
      }`,
    }
  }

  public static getDistanceDrivenCard(distance_driven: number): Card {
    const heading = i18next.t("tour_overview.distance_driven")
    const kilometers = (distance_driven / 1000).toLocaleString(UserService.getLanguage(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return {
      heading,
      content: kilometers,
    }
  }

  public static getFormattedTourDuration(duration: number): string {
    let hours: any = Math.floor(duration / 3600)
    let minutes: any = Math.floor((duration % 3600) / 60)

    return `${hours}h ${minutes}m`
  }

  public static getPartnerCard(partner: string): Card {
    const heading = i18next.t("partner")
    const content = partner
    return { heading, content }
  }

  public static getVehicleCard(vehicle: string): Card {
    return { heading: i18next.t("vehicle"), content: vehicle }
  }
}
