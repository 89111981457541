import React, { FunctionComponent, useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ConfirmDialog } from "../layout/dialog/confirm-dialog"
import { useUnsavedDataContext } from "../../../context/unsaved-data-context"

// https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
export const UnsavedDataPrompt: FunctionComponent = () => {
  const { hasUnsavedData, setHasUnsavedData, unsavedDataTitle, unsavedDataContent } = useUnsavedDataContext()
  const history = useHistory()
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState("")
  const { t } = useTranslation()

  useEffect(() => {
    if (hasUnsavedData) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        setShowPrompt(true)
        return "true"
      })
    }

    return () => {
      history.block(() => {})
    }
  }, [history, hasUnsavedData])

  const handleOk = useCallback(async () => {
    history.block(() => {})
    history.push(currentPath)
    setHasUnsavedData(false)
    setShowPrompt(false)
  }, [currentPath, history, setHasUnsavedData])

  const handleCancel = useCallback(async () => {
    setShowPrompt(false)
  }, [])

  if (!showPrompt) {
    return null
  }

  return (
    <ConfirmDialog
      open={showPrompt}
      heading={unsavedDataTitle}
      text={unsavedDataContent}
      confirmText={t("yes")}
      negativeText={t("no")}
      onConfirm={handleOk}
      onClose={handleCancel}
    />
  )
}
