import { TourExportPreviewCollectionPoint } from "../api/graphql/queries/tour-export-preview"
import { TourPreviewInput } from "../api/graphql/graphql-global-types"

export const convertCollectionPointsToPreviewEntries = (
  collectionPoints: TourExportPreviewCollectionPoint[],
) => {
  const entries: TourPreviewInput[] = []
  collectionPoints.forEach((collectionPoint: TourExportPreviewCollectionPoint) => {
    collectionPoint.materials.forEach((material) => {
      entries.push({
        collection_point_id: collectionPoint.id,
        material_id: material.material.id,
        filllevel: material.filllevel_percentage,
        last_emptying: collectionPoint.last_emptying,
        max_amount: material.max_amount,
      })
    })
  })
  return entries
}
