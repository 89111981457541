import { gql } from "@apollo/client"
import { subscribeTofileExports } from "./types/subscribeTofileExports"

export const FILE_EXPORT_SUBSCRIPTION = gql`
  subscription subscribeTofileExports {
    subscribeTofileExports {
      id
      filename
      status
    }
  }
`

export type FileExportsSubscriptionResult = subscribeTofileExports
