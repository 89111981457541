import React, { FC, useContext } from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ContainerWarningsRow } from "./container-warnings-row"
import moment from "moment"
import { ContainerWarningsContext } from "../../../../context/container-warnings-context"
import { TableBodySkeleton } from "../../../partials/layout/table/table-body-skeleton"

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxHeight: "85vh",
  },
  centered: {
    textAlign: "center",
  },
}))

interface IContainerWarningsProps {}

export const ContainerWarningsTable: FC<IContainerWarningsProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { error, loading, warnings } = useContext(ContainerWarningsContext)

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">{t("container_warnings.table.container_number")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.sensor_id")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.material")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.description")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.address")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.message")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.status")}</TableCell>
            <TableCell align="center">{t("container_warnings.table.link")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {error && (
            <TableRow>
              <TableCell className={classes.centered} colSpan={100}>
                {t("container_warnings.table.error")}
              </TableCell>
            </TableRow>
          )}

          {loading && <TableBodySkeleton columns={8} rows={10} />}

          {!error &&
            !loading &&
            warnings &&
            warnings
              // ORDER BY since, inserted_at
              .sort((a, b) => moment(a.since).diff(b.since) || moment(a.inserted_at).diff(b.inserted_at))
              .map((warning) => <ContainerWarningsRow key={warning.id} warning={warning} />)}

          {/* empty */}
          {!error && !loading && warnings && warnings.length <= 0 && (
            <TableRow>
              <TableCell className={classes.centered} colSpan={100}>
                {t("container_warnings.table.empty")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
