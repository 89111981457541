import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useApolloClient, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import {
  DELETE_HOUSEHOLD_CONTAINER_MUTATION,
  DeleteHouseholdContainerVariables,
} from "../../../../api/graphql/mutations/delete-household-container"
import { DeleteDisposalTrailerLocationResult } from "../../../../api/graphql/mutations/delete-disposal-trailer-location"
import { HOUSEHOLD_CONTAINERS_QUERY } from "../../../../api/graphql/queries/household-containers"
import { HOUSEHOLD_STATUSES_QUERY } from "../../../../api/graphql/queries/household-statuses"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { HouseholdContainerForTable } from "../context/household-overview-table-context"

interface IHouseholdContainerDeleteConfirmDialogProps {
  container: HouseholdContainerForTable
  open: boolean
  onClose: VoidFunction
}

export const HouseholdContainerDeleteConfirmDialog: React.FC<IHouseholdContainerDeleteConfirmDialogProps> = (props) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const { container, open, onClose } = props

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [deleteMutation] = useMutation<DeleteDisposalTrailerLocationResult, DeleteHouseholdContainerVariables>(
    DELETE_HOUSEHOLD_CONTAINER_MUTATION,
    {
      onError: () => toast.error(t("generic_error")),
      onCompleted: () => {
        client.refetchQueries({ include: [HOUSEHOLD_CONTAINERS_QUERY, HOUSEHOLD_STATUSES_QUERY] }).then(() => {
          setIsLoading(false)
          onClose()
        })
      },
    },
  )

  const onConfirm = useCallback(() => {
    deleteMutation({ variables: { householdContainerId: container.id } })
    setIsLoading(true)
  }, [container, deleteMutation])

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={isLoading}
      confirmText={t("yes")}
      heading={t("household_overview.delete_dialog.heading")}
      negativeText={t("no")}
    />
  )
}
