import React, { FunctionComponent, Fragment, useState } from "react"
import { Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { ImportRouteDialog } from "./import-route-dialog"
import { RoutesForLocation } from "../../../../api/graphql/queries/get-routes-for-location"

interface IImportRouteButtonProps {
  routes: RoutesForLocation[]
}

export const ImportRouteButton: FunctionComponent<IImportRouteButtonProps> = (props) => {
  const { t } = useTranslation()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { routes } = props

  return (
    <Fragment>
      <ImportRouteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} routes={routes} />
      <Button variant="contained" type="button" color="primary" onClick={() => setDialogOpen(true)}>
        {t("routes_management.import_routes")}
      </Button>
    </Fragment>
  )
}
