import { gql } from "@apollo/client"
import { deleteTour, deleteTourVariables, deleteTour_deleteTour } from "./types/deleteTour"

export const DELETE_TOUR_MUTATION = gql`
  mutation deleteTour($tour_id: ID!) {
    deleteTour(tour_id: $tour_id) {
      error
      message
    }
  }
`

export type DeleteTourResult = deleteTour
export type DeleteTour = deleteTour_deleteTour
export type DeleteTourVariables = deleteTourVariables
