import React, { FunctionComponent, useCallback } from "react"
import { Theme, makeStyles, Grid, Typography, Card, CircularProgress } from "@material-ui/core"
import { AdminstrationCollectionPoint } from "../../../../api/graphql/queries/get-administration-collection-points-with-region-id"
import lodash from "lodash"
import classnames from "classnames"
import { useTranslation } from "react-i18next"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { useHistory } from "react-router-dom"
import { PATH, replaceUriParameter } from "../../../../router/router"

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
    },
  },
  selectedCard: {
    boxShadow: "0 0 4px 0 rgba(75, 151, 62, 0.5)",
  },
  description: {
    fontWeight: "bold",
  },
  loadingIndicator: {
    paddingTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  collectionPointsContainer: {
    maxWidth: "100%",
    maxHeight: 500,
    overflowY: "auto",
  },
}))

interface ICollectionPointAdministrationSelectorProps {}

export const CollectionPointAdministrationSelector: FunctionComponent<ICollectionPointAdministrationSelectorProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    selectedCollectionPoint,
    setSelectedCollectionPoint,
    collectionPointsLoading,
    collectionPointsError,
    collectionPointsData,
  } = useCollectionPointAdministrationContext()

  const { push } = useHistory()

  const onCardClicked = useCallback(
    (collectionPoint: AdminstrationCollectionPoint) => {
      setSelectedCollectionPoint(collectionPoint)
      const url = replaceUriParameter(
        `${PATH.COLLECTIONPOINTS_ADMINISTRATION_PRE_SELECT.route}`,
        "collectionPointId",
        collectionPoint.id,
      )
      push(url)
    },
    [setSelectedCollectionPoint, push],
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.title}>{t("collection_point_administration.collection_points")}</Typography>
      </Grid>
      {!collectionPointsLoading && !collectionPointsError && !lodash.isNil(collectionPointsData) && (
        <Grid item className={classes.collectionPointsContainer} xs={12}>
          <Grid container>
            {collectionPointsData!.getCollectionPointsWithRegionID.entries.map((collectionPoint) => (
              <Grid item xs={12} key={collectionPoint.id}>
                <Card
                  className={classnames(classes.card, {
                    [classes.selectedCard]:
                      selectedCollectionPoint && collectionPoint.id === selectedCollectionPoint.id,
                  })}
                  onClick={() => onCardClicked(collectionPoint)}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid item className={classnames(classes.content, classes.description)}>
                      {collectionPoint.description || "-"}
                    </Grid>
                    <Grid item container direction="column">
                      <Grid item className={classes.content}>
                        {collectionPoint.street || "-"}
                      </Grid>
                      <Grid item className={classes.content}>
                        {`${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town.name}`}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {collectionPointsLoading && (
        <Grid item className={classes.loadingIndicator}>
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
