import React, { FunctionComponent } from "react"
import { useHistory } from "react-router-dom"
import {
  Theme,
  makeStyles,
  Grid,
  Button,
  Link,
  Container
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GET_VEHICLES_WITH_PARTNER_ID,
  VehiclesResult,
  VehiclesVariables
} from "../../../../api/graphql/queries/get-vehicles-with-partner-id"
import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { useSelectedVehicle } from "../../../../context/selected-vehicle-context"
import { useRefetch } from "../../../../context/refetch-context"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { PATH } from "../../../../router/router"
import { KeyboardArrowLeft } from "@material-ui/icons"
import { Text } from "../../../partials/wrapper/text"
import { VehicleItem } from "./vehicle-item"
import lodash from "lodash"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    display: "flex",
    flexDirection: "column"
  },
  snackBar: {
    marginTop: 10
  },
  addVehicleButton: {
    borderWidth: 0.5,
    borderRadius: 3,
    borderColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  addIcon: {
    color: theme.palette.primary.main
  },
  itemsContainer: {
    width: 270,
    padding: 0,
    flex: "1 1 auto",
    overflowY: "auto"
  },
  containerTop: {
    padding: 0,
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    flex: "0 0 auto"
  },
  link: {
    cursor: "pointer"
  }
}))

interface IVehicleSelectorProps {}

export const VehicleSelector: FunctionComponent<IVehicleSelectorProps> = props => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { selectedPartnerId } = useSelectedPartner()
  const { setSelectedVehicleId } = useSelectedVehicle()

  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const history = useHistory()

  const { data } = useQuery<VehiclesResult, VehiclesVariables>(
    GET_VEHICLES_WITH_PARTNER_ID,
    {
      fetchPolicy: needToRefetch ? "network-only" : "cache-first",
      onCompleted: () => setNeedToRefetch(false),
      skip: !selectedPartnerId,
      variables: {
        partner_id: String(selectedPartnerId)
      }
    }
  )

  const onAddNewVehicleClicked = () => {
    setSelectedVehicleId(undefined)
  }

  return (
    <ContentContainer style={classes.container}>
      <Grid className={classes.containerTop}>
        <Grid
          container
          item
          spacing={1}
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Link
              onClick={() => history.push(PATH.PARTNER_OVERVIEW.route)}
              className={classes.link}
            >
              <Grid container>
                <Grid item>
                  <KeyboardArrowLeft />
                </Grid>
                <Grid item>
                  <Text>{t("vehicle_overview.back_to_partner_overview")}</Text>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              className={classes.addVehicleButton}
              onClick={onAddNewVehicleClicked}
              fullWidth
            >
              <AddIcon className={classes.addIcon} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {data && (
        <Container className={classes.itemsContainer}>
          {lodash
            .sortBy(data.getVehiclesWithPartnerId, [
              vehicle => vehicle.licence_plate
            ])
            .map((vehicle, index) => (
              <VehicleItem key={index} item={vehicle} />
            ))}
        </Container>
      )}
    </ContentContainer>
  )
}
