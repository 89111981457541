import { gql } from "@apollo/client"
import {
  getDisposalMerchantStatsWithId,
  getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId,
  getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_materials,
  getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_disposalMerchantToOpeningHours,
  getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_vacation_times,
} from "./types/getDisposalMerchantStatsWithId"

export const GETDISPOSALMERCHANTSTATSWITHID_QUERY = gql`
  query getDisposalMerchantStatsWithId($id: Int!) {
    getDisposalMerchantStatsWithId(id: $id) {
      disposal_merchant {
        id
        name
        town
        street
        postal
        email
        latitude
        longitude
        contact_name
        phone_number
        materials {
          id
          name
        }
        towns {
          id
          name
          district_id
        }
        districts {
          id
          name
        }
        disposalMerchantToOpeningHours {
          opening_hours_id
          active
          openingHour {
            id
            day
            from
            to
          }
        }
        vacation_times {
          id
          from
          to
        }
      }
    }
  }
`

export type DisposalMerchantStatsResult = getDisposalMerchantStatsWithId
export type DisposalMerchantStats = getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId
export type DisposalMerchantStatsMaterial = getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_materials
export type DisposalMerchantStatsOpeningHours = getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_disposalMerchantToOpeningHours
export type DisposalMerchantStatsVacationTimes = getDisposalMerchantStatsWithId_getDisposalMerchantStatsWithId_disposal_merchant_vacation_times
