import React, { FunctionComponent } from "react"

import { VehicleOverview } from "./partials/vehicle-overview"
import { SelectedVehicleProvider } from "../../../context/selected-vehicle-context"
import { SelectedPartnerProvider } from "../../../context/selected-partner-context"

interface IVehicleOverViewPageProps {}

export const VehicleOverviewPage: FunctionComponent<IVehicleOverViewPageProps> = props => {
  return (
    <SelectedPartnerProvider>
      <SelectedVehicleProvider>
        <VehicleOverview />
      </SelectedVehicleProvider>
    </SelectedPartnerProvider>
  )
}