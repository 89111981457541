// will be removed in the future
export const tmpNames = [
  "Fahrer 1",
  "Fahrer 2",
  "Fahrer 3",
  "Fahrer 4",
  "Fahrer 5",
  "Fahrer 6",
  "Fahrer 7",
  "Fahrer 8",
  "Fahrer 9",
  "Fahrer 10"
]
