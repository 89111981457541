import React, { FunctionComponent } from "react"
import { RouteContextProvider } from "./partials/route-context"
import { RouteDetails } from "./partials/route-details"
import lodash from "lodash"
import { useRouteMatch } from "react-router-dom"
import { RefetchProvider } from "../../../context/refetch-context"

interface IRouteDetailsPageProps {}

export const RouteDetailsPage: FunctionComponent<IRouteDetailsPageProps> = (props) => {
  const match = useRouteMatch<{ routeId: string }>()
  const routeId = parseInt(lodash.get(match, "params.routeId"), 10)
  return (
    <RefetchProvider>
      <RouteContextProvider routeId={routeId}>
        <RouteDetails />
      </RouteContextProvider>
    </RefetchProvider>
  )
}
